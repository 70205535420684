import { BarTooltipProps } from '@nivo/bar';
import { Col } from 'components';
import { StyledChartTooltip } from 'components/shared/ChartTooltip/ChartTooltip.styles';
import ChartTooltipAmount from 'components/shared/ChartTooltipAmount/ChartTooltipAmount';
import { PropsWithChildren } from 'react';
import { ITransformedData } from '../../types';
import { useStoreState } from 'state';
import { notUndefinedTypeGuard } from 'utils';

const Tooltip = ({
  id,
  value,
  data,
}: PropsWithChildren<BarTooltipProps<ITransformedData>>) => {
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const isExternalPnl = id === 'externalPnl';
  const currency = currencyByCode(entityCurrencyCode);
  const valuesToDisplay = Object.keys(data)
    .map((key) => {
      const isPnlKey = key.includes('pnl');

      if (isPnlKey) {
        const currencyCode = key.replace('pnl', '');
        const pnlValue = data[key];
        const isNumberValue = typeof pnlValue === 'number';

        if (currencyCode && isNumberValue) {
          return {
            currencyCode,
            value: pnlValue,
          };
        }
      }

      return undefined;
    })
    .filter(notUndefinedTypeGuard);

  return (
    <StyledChartTooltip>
      <Col>
        {isExternalPnl && (
          <ChartTooltipAmount
            amount={value}
            currencySymbol={currency?.symbol}
            title="FX PnL from Xero: "
          />
        )}

        {!isExternalPnl &&
          valuesToDisplay.map(({ currencyCode, value }) => (
            <ChartTooltipAmount
              amount={value}
              currencySymbol={currencyByCode(entityCurrencyCode)?.symbol}
              title={`${currencyCode}:`}
            />
          ))}
      </Col>
    </StyledChartTooltip>
  );
};

export default Tooltip;
