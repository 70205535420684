import { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import auth from 'services/auth';
import { useStoreState } from 'state';
import { isEmulated } from 'utils';

const PrivateRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
  const {
    isUserRegistrationDone,
    isEntityOnboarded,
    userId,
    isEntityEnabled,
    isAuthLoading,
  } = useStoreState((state) => state.UserState);

  const isInCompanyRegistration = rest.location?.pathname.includes(
    'company-registration'
  );
  const isPhoneVerified = auth.currentUser?.multiFactor.enrolledFactors.find(
    (item) => item.factorId === 'phone'
  );

  // wait for isAuthLoading to be false to ensure we don't redirect before we have checked the auth status of the user
  if (!userId && !isAuthLoading) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: {
              pathname: rest.location?.pathname,
            },
          },
        }}
      />
    );
  }

  if (!isUserRegistrationDone && !isEmulated()) {
    return <Redirect to="/sign-up?step=3" />;
  }

  if (
    !!userId &&
    isEntityOnboarded &&
    isUserRegistrationDone &&
    isEntityEnabled &&
    !isPhoneVerified &&
    !isInCompanyRegistration &&
    !isEmulated()
  ) {
    return <Redirect to="/mfa-setup" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        // @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
