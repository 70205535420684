import { FC, MouseEvent } from 'react';
import { StaleCustomButton } from 'components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { IClient } from 'state/user';
import { useStoreState } from 'state';
import { useHistory } from 'react-router';
import { getReportsPageLink } from 'utils/links';

interface OwnProps {
  data: IClient;
  value: number;
}

const FxGainLossCell: FC<OwnProps> = ({ data, value }) => {
  const history = useHistory();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { entityCurrency } = data;

  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    history.push(getReportsPageLink());
  };

  return (
    <StaleCustomButton variant="link" onClick={onClickHandler}>
      {parseIntoCurrencyStringWithSymbol(
        value,
        currencyByCode(entityCurrency)?.symbol,
        0
      )}
    </StaleCustomButton>
  );
};

export default FxGainLossCell;
