export interface IGenerateSearchQueryParams {
  searchQuery?: string;
  page?: number;
  size?: number;
  filters?: { [key: string]: string[] }[];
  /** Refer to https://www.elastic.co/guide/en/app-search/current/facets.html for more information */
  facets?: {
    [k: string]: {
      type: 'value' | 'range';
      size?: number;
      ranges?: {
        from: number;
        to: number;
      }[];
    };
  };
  sortFields?: string[];
}

export const generateSearchQuery = (
  params: IGenerateSearchQueryParams
): string => {
  const searchQuery = encodeURIComponent(params.searchQuery ?? '');
  const page = params.page;
  const size = params.size;
  const filters = params.filters
    ? encodeURIComponent(JSON.stringify(params.filters))
    : '';
  const sortFields = params.sortFields
    ? encodeURIComponent(JSON.stringify(params.sortFields))
    : '';
  const facets = params.facets
    ? encodeURIComponent(JSON.stringify(params.facets))
    : '';

  return `?searchQuery=${searchQuery}&page=${page}&size=${size}&filters=${filters}&sortFields=${sortFields}&facets=${facets}`;
};
