import { Title, Paragraph, Col } from 'components';
import CongratulationsCard from 'components/shared/CongratulationsCard/CongratulationsCard';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { getDashboardPageLink, getInvoicesPageLink } from 'utils';
import Button from '../Button/Button';

const CARDS = [
  {
    location: 'https://youtu.be/DLRDBi9Lp-Q',
    title: 'Prebooking exchange',
    icon: 'rates',
  },
  {
    location: 'https://youtu.be/DLRDBi9Lp-Q',
    title: 'Managing risks',
    icon: 'currency',
  },
  {
    location: 'https://youtu.be/DLRDBi9Lp-Q',
    title: 'Currency healthcheck',
    icon: 'transfer',
  },
];

const StepCongrats = () => {
  const history = useHistory();
  const theme = useTheme();

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        Congrats! You’re all set!
      </Title>

      <Paragraph mb mbValue={theme.spacing.xl}>
        We are crunching numbers for you and putting everything together. We
        invite you to watch our guide videos while you wait!
      </Paragraph>

      <Col gap={theme.spacing.s}>
        {CARDS.map(({ location, title, icon }) => (
          <CongratulationsCard location={location} title={title} icon={icon} />
        ))}
      </Col>

      <Col gap={theme.spacing.m} mt mtValue={theme.spacing.xl}>
        <Button
          onClick={() =>
            history.push(
              getInvoicesPageLink({
                currency: 'all',
                tab: 'outstanding',
                noSettingsRedirect: 'true',
              })
            )
          }
        >
          Go to invoices
        </Button>
        <Button
          variant="secondary"
          onClick={() => history.push(getDashboardPageLink())}
        >
          Go to dashboard
        </Button>
      </Col>
    </>
  );
};

export default StepCongrats;
