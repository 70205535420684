import { action, Action, thunk, Thunk } from 'easy-peasy';
import get from 'lodash.get';
import { Firebase } from 'services';
import { updateInvoice } from 'services/firebase/invoices';
import { IInvoice } from 'types';

import { Notify } from 'utils';

export interface InvoicesStateModel {
  setState: Action<InvoicesStateModel, [string, any]>;
  getInvoices: Thunk<InvoicesStateModel>;
  getInvoice: Thunk<InvoicesStateModel, Firebase.Invoices.GetInvoiceParams>;
  sortAndSetInvoices: Thunk<InvoicesStateModel, IInvoice[]>;
  subscribeToInvoice: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.SubscribeToInvoiceParams
  >;
  bindContractRateToInvoice: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.BindContractRateToInvoiceParams
  >;
  unbindContractRateToInvoice: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.BindContractRateToInvoiceParams
  >;
  cancelInvoiceContractRate: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.CancelInvoiceContractRateParams
  >;
  createInvoiceTracking: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.CreateInvoiceTrackingParams
  >;
  updateInvoiceTracking: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.UpdateInvoiceTrackingParams
  >;
  deleteInvoiceTracking: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.DeleteInvoiceTrackingParams
  >;
  getInvoiceTracking: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.GetInvoiceTrackingParams
  >;
  updateInvoice: Thunk<
    InvoicesStateModel,
    {
      invoiceId: string;
      data: Partial<IInvoice>;
    }
  >;
  deleteInvoice: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.DeleteInvoiceParams
  >;
  checkForInvoices: Thunk<InvoicesStateModel>;
  hasInvoices: boolean;
}

export const InvoicesState: InvoicesStateModel = {
  hasInvoices: false,
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  getInvoices: thunk(async (actions) => {
    try {
      const { data } = await Firebase.Invoices.getInvoices();

      if (data.success) {
        // @ts-expect-error TS(2339) FIXME: Property 'invoices' does not exist on type 'IRespo... Remove this comment to see the full error message
        actions.setState(['invoices', data.invoices]);
      } else {
        Notify.error(data.message ?? '');
      }
    } catch (error: any) {
      console.warn(error);
    }
  }),
  sortAndSetInvoices: thunk((actions, payload) => {
    const sortedInvoices = payload.sort((a, b) => {
      if (a._created < b._created) {
        return -1;
      } else if (a._created > b._created) {
        return 1;
      }
      return 0;
    });
    actions.setState(['invoices', sortedInvoices]);
    actions.setState(['isLoadingInvoices', false]);
  }),
  subscribeToInvoice: thunk((_, payload) => {
    try {
      const unsubscribe = Firebase.Invoices.subscribeToInvoice(payload);

      return unsubscribe;
    } catch (error: any) {
      console.warn(error);
    }
  }),
  getInvoice: thunk(async (_, payload) => {
    try {
      const invoice = await Firebase.Invoices.getInvoice(payload);

      return invoice;
    } catch (error: any) {
      console.warn(error);
    }
  }),
  bindContractRateToInvoice: thunk(async (_, payload) => {
    try {
      const result = await Firebase.Invoices.bindContractRateToInvoice(payload);

      if (!result.data.success) {
        Notify.error(
          result.data.message ??
            'Could not add this prebook rate to the invoice at the moment'
        );
      }

      return result;
    } catch (error: any) {
      Notify.error(
        'Could not add this prebook rate to the invoice at the moment'
      );
    }
  }),
  unbindContractRateToInvoice: thunk(async (_, payload) => {
    try {
      const invoice = await Firebase.Invoices.unbindContractRateToInvoice(
        payload
      );

      return invoice;
    } catch (error: any) {
      Notify.error(
        'Could not remove this prebook rate from the invoice at the moment'
      );
    }
  }),
  cancelInvoiceContractRate: thunk(async (_, payload) => {
    try {
      const response = await Firebase.Invoices.cancelInvoiceContractRate(
        payload
      );

      return response;
    } catch (error: any) {
      console.warn(error);
    }
  }),
  createInvoiceTracking: thunk(async (_, payload) => {
    try {
      await Firebase.Invoices.createInvoiceTracking(payload);
    } catch (error: any) {
      console.warn(error);
    }
  }),
  updateInvoiceTracking: thunk(async (_, payload) => {
    try {
      const response = await Firebase.Invoices.updateInvoiceTracking(payload);

      return response;
    } catch (error: any) {
      console.warn(error);
    }
  }),
  deleteInvoiceTracking: thunk(async (_, payload) => {
    try {
      await Firebase.Invoices.deleteInvoiceTracking(payload);
    } catch (error: any) {
      console.warn(error);
    }
  }),
  getInvoiceTracking: thunk(async (_, payload) => {
    try {
      const tracking = await Firebase.Invoices.getInvoiceTracking(payload);

      return tracking;
    } catch (error: any) {
      console.warn(error);
    }
  }),
  updateInvoice: thunk(async (_, payload) => {
    try {
      await updateInvoice(payload);
    } catch (error: any) {
      console.warn(error);
    }
  }),
  deleteInvoice: thunk(async (_, payload) => {
    try {
      const res = await Firebase.Invoices.deleteInvoice(payload);

      return res.data;
    } catch (error: any) {
      console.warn(error);
    }
  }),
  checkForInvoices: thunk(async (actions) => {
    try {
      const res = await Firebase.Invoices.getInvoicesCount();

      if (get(res, 'data.data.count', 0) > 0) {
        actions.setState(['hasInvoices', true]);
      }
    } catch (error: any) {
      console.warn(error);
    }
  }),
};
