import { CONTRACT_STATUS, ICurrency, TRANSFER_STATUS } from 'types';
import { BULK_PAYMENT_STATUS } from 'types/bulkPayments';
import { ITransaction } from 'types/transactions';
import { RouteComponentProps } from 'react-router';

export const getTransactionStatusDetails = (
  transactionStatus: ITransaction['status']
) => {
  switch (transactionStatus) {
    case TRANSFER_STATUS.initiated:
      return {
        text: 'Transfer initiated',
        color: '#E69138',
      };
    case TRANSFER_STATUS.awaitingPayment:
      return {
        text: 'Awaiting funds',
        color: '#E69138',
      };
    case TRANSFER_STATUS.funded:
    case TRANSFER_STATUS.approved:
      return {
        text: 'Funds received',
        color: '#E69138',
      };
    case TRANSFER_STATUS.processing:
      return {
        text: 'Being processed',
        color: '#000',
      };
    case TRANSFER_STATUS.paymentSent:
      return {
        text: 'Payment sent',
        color: '#39771D',
      };
    case TRANSFER_STATUS.paymentConfirmed:
      return {
        text: 'Payment confirmed',
        color: '#39771D',
      };
    case TRANSFER_STATUS.paymentCancelled:
      return {
        text: 'Payment cancelled',
        color: '#EC4B4B',
      };
    case TRANSFER_STATUS.paymentFailed:
      return {
        text: 'Payment failed',
        color: '#EC4B4B',
      };
    case TRANSFER_STATUS.timedOut:
      return {
        text: 'Timed out',
        color: '#EC4B4B',
      };
    case BULK_PAYMENT_STATUS.completed:
      return {
        text: 'Completed',
        color: '#39771D',
      };
    case BULK_PAYMENT_STATUS.cancelled:
      return {
        text: 'Cancelled',
        color: '#EC4B4B',
      };
    case CONTRACT_STATUS.awaitingPrepayment:
      return {
        text: 'Awaiting prepayment',
        color: '#E69138',
      };
    case CONTRACT_STATUS.prepaymentOverdue:
      return {
        text: 'Prepayment overdue',
        color: '#FF0102',
      };
    case CONTRACT_STATUS.readyToUse:
      return {
        text: 'Ready to use',
        color: '#39771D',
      };
    case CONTRACT_STATUS.used:
      return {
        text: 'Used',
        color: '#000',
      };
    case CONTRACT_STATUS.expired:
      return {
        text: 'Expired',
        color: '#FF0102',
      };
    case CONTRACT_STATUS.cancelled:
      return {
        text: 'Cancelled',
        color: '#000',
      };
    default:
      return null;
  }
};

export const getTransactionTypeTitle = (
  transactionType: ITransaction['type'],
  transactionDirection: ITransaction['direction']
) => {
  switch (transactionType) {
    case 'transfer':
      return 'Transfer';
    case 'bulkPayment':
      return 'Bulk';
    case 'conversion':
      return transactionDirection === 'in' ? 'Collection' : 'Exchange';
    case 'fxTransfer':
      return 'FX Transfer';
    case 'rateContract':
      return 'Prebooking';
    case 'funding':
      return 'Funding';
    default:
      return '';
  }
};

export const viewTransactionDetails = (
  url: string,
  history: RouteComponentProps['history'],
  transaction: { id: string; type: ITransaction['type'] }
) => {
  const { type } = transaction;

  // we do not have popup for conversions
  if (type === 'conversion') {
    return;
  }

  if (type === 'rateContract') {
    history.push(`${url}?contractId=${transaction.id}`);
    return;
  }

  if (type === 'bulkPayment') {
    history.push(`${url}?bulkPaymentId=${transaction.id}`);
    return;
  }

  history.push(`${url}?transferId=${transaction.id}`);
};

interface IDetectCurrenciesFromTransactionsParams {
  transactions: ITransaction[];
  currencies: ICurrency[];
}

export const detectCurrenciesFromTransactions = ({
  transactions,
  currencies,
}: IDetectCurrenciesFromTransactionsParams) => {
  const detectedCurrencies = new Set<string>();

  transactions.forEach((transaction) => {
    detectedCurrencies.add(transaction.buyCurrency);
  });

  return Array.from(detectedCurrencies).filter((currencyCode) =>
    currencies.some(
      (currency) => currency.code === currencyCode && currency.enabled
    )
  );
};
