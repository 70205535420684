import { FC } from 'react';
import Tabs from './components/Tabs/Tabs';
import Controls from './components/Controls/Controls';
import Tables from './components/Tables/Tables';
import useIsIntegratedWithAnything from 'hooks/useIsIntegratedWithAnything';
import { IntegrationWrapper } from './Invoices.styles';
import { StaleIntegrationSettings } from 'components';
import useDraftPaymentRun from './hooks/useDraftPaymentRun';
import { getInvoicesAsCsvFile } from 'services/firebase/invoices';
import TransferPrebookPopups from 'components/shared/TransferPrebookPopups/TransferPrebookPopups';
import useInvoicesPageCountsAndCurrenciesByTab from './hooks/useInvoicesPageTabsCountsAndCurrencies';
import { useStoreState } from 'state';

const Invoices: FC = () => {
  const isIntegratedWithAnything = useIsIntegratedWithAnything();
  const { counts, currenciesByTab } = useInvoicesPageCountsAndCurrenciesByTab();
  const { hasInvoices } = useStoreState(({ InvoicesState }) => InvoicesState);
  const {
    paymentRunId,
    getPaymentRunInvoicesAsCsv,
    onRemoveInvoice,
    paymentRunInvoices,
    isLoadingPaymentRunInvoices,
    isLoadingPaymentRunInvoicesCSV,
  } = useDraftPaymentRun();

  return (
    <>
      {!isIntegratedWithAnything && !hasInvoices ? (
        <IntegrationWrapper>
          <StaleIntegrationSettings />
        </IntegrationWrapper>
      ) : (
        <>
          <Tabs
            counts={{
              ...counts,
              paymentRun: paymentRunInvoices.length,
            }}
            paymentRunId={paymentRunId}
            getPaymentRunInvoicesAsCsv={getPaymentRunInvoicesAsCsv}
            getInvoicesAsCSV={getInvoicesAsCsvFile}
            isLoadingPaymentRunInvoicesCSV={isLoadingPaymentRunInvoicesCSV}
          />
          <Controls
            counts={{
              ...counts,
              paymentRun: paymentRunInvoices.length,
            }}
            currenciesByTab={currenciesByTab}
          />
          <Tables
            isLoadingPaymentRunInvoices={isLoadingPaymentRunInvoices}
            paymentRunInvoices={paymentRunInvoices}
            onRemoveInvoice={onRemoveInvoice}
            currenciesByTab={currenciesByTab}
          />
          <TransferPrebookPopups />
        </>
      )}
    </>
  );
};

export default Invoices;
