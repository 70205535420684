import { FC } from 'react';
import { IInvoice } from 'types';
import { getInvoiceTransferOrSimpleTransferLink } from 'utils/links';
import { useHistory, useLocation } from 'react-router-dom';
import useInvoiceRecord from 'hooks/useInvoiceRecord';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';
import StaleLimitedAccess from 'components/shared/StaleLimitedAccess/StaleLimitedAccess';
import { isCurrencyEnabledForBuying } from 'utils/currencies';
import { useStoreState } from 'state';
import useInvoicePrebookAndTransfer from '../../hooks/useInvoicePrebookAndTransfer';
import StaleTooltip from 'components/shared/StaleTooltip/StaleTooltip';

interface OwnProps {
  record: IInvoice;
}

const DoActionCell: FC<OwnProps> = ({ record }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const risks = pathname.includes('risks');
  const { currencies } = useStoreState((state) => state.CurrenciesState);
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const {
    showLimitedAccess,
    setShowLimitedAccessFalse,
    goToInvoicePrebook,
  } = useInvoicePrebookAndTransfer();
  const {
    isUpdatingInvoicesApprovalStatus,
    updateInvoicesApprovalStatus,
  } = useInvoicesApprovalStatus();
  const {
    isDisabled,
    isSameCurrency,
    isPayable,
    isCanBePaid,
    isApprovable,
    isApprovableByCurrentUser,
    isPrebookable,
    submittableForReview,
  } = useInvoiceRecord({ record });
  const isInvoiceCurrencyEnabledForBuying = isCurrencyEnabledForBuying({
    currencyCode: record.currency,
    currencies,
  });

  const renderContent = () => {
    if (isDisabled) {
      return null;
    }

    if (submittableForReview) {
      return (
        <ActionButton
          disabled={isUpdatingInvoicesApprovalStatus}
          onClick={(event) => {
            event.stopPropagation();
            updateInvoicesApprovalStatus({
              invoiceIds: [record.id],
              approvalStatus: 'submitted',
            });
          }}
        >
          Submit
        </ActionButton>
      );
    }

    if (isApprovable) {
      return (
        <StaleTooltip
          text="Awaiting 2nd Approval"
          placement="left"
          disabled={isApprovableByCurrentUser}
          strategy="absolute"
        >
          <ActionButton
            disabled={
              isUpdatingInvoicesApprovalStatus || !isApprovableByCurrentUser
            }
            onClick={(event) => {
              event.stopPropagation();
              updateInvoicesApprovalStatus({
                invoiceIds: [record.id],
                approvalStatus: 'approved',
              });
            }}
          >
            Approve
          </ActionButton>
        </StaleTooltip>
      );
    }

    if (isInvoiceCurrencyEnabledForBuying && isCanBePaid && isPayable) {
      return (
        <ActionButton
          disabled={isUpdatingInvoicesApprovalStatus}
          onClick={(event) => {
            event.stopPropagation();

            history.push(
              getInvoiceTransferOrSimpleTransferLink(record, entityCurrencyCode)
            );
          }}
        >
          Pay
        </ActionButton>
      );
    }

    if (
      !isSameCurrency &&
      isPrebookable &&
      !!risks &&
      isInvoiceCurrencyEnabledForBuying
    ) {
      return (
        <ActionButton
          disabled={isUpdatingInvoicesApprovalStatus}
          onClick={(event) => {
            event.stopPropagation();
            goToInvoicePrebook(record);
          }}
        >
          Book
        </ActionButton>
      );
    }

    return null;
  };

  return (
    <>
      {renderContent()}
      {showLimitedAccess && (
        <StaleLimitedAccess onClose={setShowLimitedAccessFalse} />
      )}
    </>
  );
};

export default DoActionCell;
