import { FC } from 'react';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { Paragraph } from '../Typography/Typography';

interface OwnProps {
  amount: number;
  currencySymbol?: string;
  title: string;
  isAbsolute?: boolean;
}

const ChartTooltipAmount: FC<OwnProps> = ({
  amount,
  currencySymbol,
  title,
  isAbsolute = false,
}) => (
  <Paragraph
    color="white"
    style={{ whiteSpace: 'nowrap' }}
  >{`${title} ${parseIntoCurrencyStringWithSymbol(
    isAbsolute ? Math.abs(amount) : amount,
    currencySymbol,
    0
  )}`}</Paragraph>
);

export default ChartTooltipAmount;
