import { Datum } from '@nivo/line';
import useChartTooltip from 'hooks/useChartTooltip';
import { FC } from 'react';
import ChartTooltip from 'components/shared/ChartTooltip/ChartTooltip';
import ChartPoint from 'components/shared/ChartPoint/ChartPoint.styles';
import { Paragraph } from 'components/shared/Typography/Typography';
import { parseIntoCurrencyStringWithSymbol } from 'utils';

interface OwnProps {
  data: Datum;
  activeMonth?: string | null;
  onClick?: (period: string, periodIndex: number) => void;
  cx: number;
  cy: number;
  color: string;
  index: number;
}

const ChartHedgeRatioPoint: FC<OwnProps> = ({
  data,
  activeMonth,
  onClick,
  cx,
  cy,
  color,
  index,
}) => {
  const { x, y } = data;
  const { tooltipRef, ...mouseEvents } = useChartTooltip();

  return (
    <g
      onClick={() => {
        if (typeof x === 'string' && onClick) {
          onClick(x, index);
        }
      }}
      {...mouseEvents}
    >
      <ChartPoint
        active={activeMonth === x}
        fill={color}
        r={6}
        cx={cx}
        cy={cy}
      />

      {typeof y === 'number' && (
        <ChartTooltip wrapperRef={tooltipRef}>
          <Paragraph color="white">{`Hedge Ratio: ${parseIntoCurrencyStringWithSymbol(
            y
          )}%`}</Paragraph>
        </ChartTooltip>
      )}
    </g>
  );
};

export default ChartHedgeRatioPoint;
