import {
  FC,
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Wrapper } from './Popups.styles';
import {
  Paragraph,
  StaleContainer,
  StaleBtnGroup,
  StaleTextHint,
  StalePopup,
  StaleInputConvert,
  StaleHexagonRating,
  Subtitle,
  StaleLoader,
  InlineLoader,
} from 'components';
import DecideConvertInfo from '../DecideConvertInfo/DecideConvertInfo';
import InfoCard from '../InfoCard/InfoCard';
import ReactSlider from 'react-slider';
import { useStoreActions, useStoreState } from 'state';
import { Firebase } from 'services';
import dayjs from 'dayjs';
import {
  Notify,
  parseIntoCurrencyStringWithSymbol,
  roundToPrecision,
} from 'utils';
import { IInvoice } from 'types';
import useCurrencyRate from 'hooks/useCurrencyRate';
import { getInvoiceNumber } from 'utils/invoices';
import { getInvoicePrebookLink } from 'utils/links';
import Button from 'components/shared/Button/Button';
import { DB_DATE_FORMAT } from 'variables';

const RATINGS_TEXT = [
  <>
    Rates are <strong>very expensive</strong> compared to the last 90 days.
  </>,
  <>
    Rates are <strong>expensive</strong> compared to the last 90 days.
  </>,
  <>
    Rates are <strong>close to the average</strong> of the last 90 days.
  </>,
  <>
    Rates are <strong>good</strong> compared to the last 90 days.
  </>,
  <>
    Rates are <strong>close to their best</strong> over the last 90 days.
  </>,
];

interface OwnProps {
  invoice: IInvoice;
  isEdit?: boolean;
  showAllFields?: boolean;
  onClose: () => void;
  setShowAllDecideFields?: Dispatch<SetStateAction<boolean>>;
}

const DecidePopup: FC<OwnProps> = ({
  invoice,
  isEdit,
  showAllFields,
  onClose,
  setShowAllDecideFields,
}) => {
  const history = useHistory();
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { sellCurrencies } = useStoreState((state) => state.CurrenciesState);

  const [isLoading, setIsLoading] = useState(false);
  const [targetAmount, setTargetAmount] = useState(0);
  const { rate } = useCurrencyRate({
    buyCurrency: invoice.currency,
    sellCurrency: entityCurrencyCode,
  });
  const [bookingFeeRate, setBookingFeeRate] = useState<number>(0);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { getRateAnalyses, getRatings } = useStoreActions(
    (actions) => actions.ReferenceDataState
  );
  const {
    createInvoiceTracking,
    updateInvoiceTracking,
    getInvoiceTracking,
  } = useStoreActions((actions) => actions.InvoicesState);
  const { rateAnalyses, ratings } = useStoreState(
    (state) => state.ReferenceDataState
  );

  const currency = currencyByCode(invoice.currency);
  const entityCurrency = currencyByCode(entityCurrencyCode);

  useEffect(() => {
    if (rate && !isEdit) {
      setTargetAmount(invoice.totalAmount / rate);
    }
  }, [rate, isEdit, invoice]);

  useEffect(() => {
    if (invoice && rate) {
      getRateAnalyses({
        buyCurrency: invoice.currency,
        sellCurrency: entityCurrencyCode,
        buyAmount: invoice.totalAmount,
        sellAmount: invoice.totalAmount / rate,
      });
    }
  }, [entityCurrencyCode, getRateAnalyses, invoice, rate]);

  useEffect(() => {
    if (rate && invoice && rate) {
      getRatings({
        buyCurrency: invoice.currency,
        sellCurrency: entityCurrencyCode,
        rate,
      });
    }
  }, [invoice, rate, getRatings, entityCurrencyCode]);

  // get fees based on date
  useEffect(() => {
    if (invoice) {
      Firebase.getForwardRateAndFees({
        sellCurrency: entityCurrencyCode,
        buyCurrency: invoice.currency,
        dateString: dayjs(invoice.dueDate).format(DB_DATE_FORMAT),
      })
        .then((response) => {
          if (response.data) {
            setBookingFeeRate(response.data.bookingFeeRate);
          }
        })
        .catch((error) => console.warn(error));
    }
  }, [entityCurrencyCode, invoice]);

  useEffect(() => {
    if (isEdit && invoice.trackingId) {
      getInvoiceTracking({
        trackingId: invoice.trackingId,
      }).then((data: any) => {
        if (data) {
          setTargetAmount(data.targetAmount);
        }
      });
    }
  }, [isEdit, getInvoiceTracking, invoice]);

  const activeRateAnalysesResult = useMemo(() => rateAnalyses?.data[2], [
    rateAnalyses,
  ]);

  const goToPrebook = () => {
    if (entityCurrencyCode) {
      history.push(getInvoicePrebookLink(invoice, entityCurrencyCode));
    }
  };

  const onCreateInvoiceTracking = async () => {
    await createInvoiceTracking({
      invoiceId: invoice.id,
      trackingData: {
        sellCurrency: entityCurrencyCode,
        buyCurrency: invoice.currency,
        sellAmount: invoice.totalAmount,
        targetAmount,
        targetRate: 0,
      },
    });

    onClose();
  };

  const onChangeTarget = async () => {
    if (!invoice.trackingId) {
      return;
    }

    setIsLoading(true);

    const response = await updateInvoiceTracking({
      trackingId: invoice.trackingId,
      trackingData: {
        sellCurrency: entityCurrencyCode,
        buyCurrency: invoice.currency,
        sellAmount: invoice.totalAmount,
        targetAmount,
        targetRate: 0,
      },
    });

    setIsLoading(false);

    if (response?.data?.success) {
      onClose();
    } else {
      Notify.error(response?.data?.message);
    }
  };

  return (
    <StalePopup
      title={`Invoice ${getInvoiceNumber(invoice)}`}
      theme="grey"
      width="439px"
      headContentAdditional={
        <Paragraph style={{ marginLeft: 'auto', marginRight: 12 }}>
          {`Due date ${dayjs(invoice.dueDate).format('D MMM, YYYY')}`}
        </Paragraph>
      }
      onClose={onClose}
    >
      <Wrapper>
        {ratings?.data?.rateRating ? (
          ratings.data.rateRating < 3 || isEdit || showAllFields ? (
            <>
              <StaleContainer>
                <InfoCard>
                  <div className="row rate-wrap" style={{ display: 'flex' }}>
                    <StaleHexagonRating
                      title="Rate"
                      rating={ratings.data.rateRating}
                    />

                    <StaleHexagonRating
                      title="Potential to save"
                      rating={ratings.data.rateRating}
                    />
                  </div>

                  <Paragraph>
                    {RATINGS_TEXT[ratings.data.rateRating - 1]}
                  </Paragraph>
                </InfoCard>

                <Paragraph>
                  You can track this rate changes and wait till it gets better.
                  Tell us the target so we can alert you if it gets close.
                </Paragraph>

                <DecideConvertInfo
                  invoice={invoice}
                  currency={currency}
                  rate={rate}
                />

                <InfoCard skin="border">
                  <Subtitle variant="bold">
                    {`What is your target amount in ${entityCurrencyCode}?`}
                  </Subtitle>

                  <div className="field">
                    <StaleInputConvert
                      readOnlyCurrency
                      value={targetAmount}
                      onChange={(value) => {
                        setTargetAmount(value);
                      }}
                      currencies={sellCurrencies}
                      selectedValue={currencyByCode(entityCurrencyCode)}
                      onSelect={() => {}}
                      onBlur={() => {}}
                      onFocus={() => {}}
                    />
                  </div>

                  <div className="tracked-table">
                    <div className="col">
                      <span>Lowest</span>

                      <p>
                        <span>{`${parseIntoCurrencyStringWithSymbol(
                          activeRateAnalysesResult?.lowest,
                          entityCurrency?.symbol,
                          entityCurrency?.precision
                        )}`}</span>
                      </p>
                    </div>
                    <div
                      className="col"
                      style={{
                        flex: '1 0 auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {rate ? (
                        <ReactSlider
                          value={[
                            targetAmount ?? 0,
                            invoice.totalAmount / rate,
                          ]}
                          min={activeRateAnalysesResult?.lowest}
                          max={activeRateAnalysesResult?.highest}
                          className="cost-range"
                          thumbClassName="example-thumb"
                          trackClassName="example-track"
                          disabled
                          renderThumb={(props) => {
                            if (props.key === 'example-thumb-1') {
                              return (
                                <div {...props}>
                                  <div className="tooltip">Current</div>
                                </div>
                              );
                            }

                            return (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  border: '2px dashed #8C9199',
                                }}
                              />
                            );
                          }}
                        />
                      ) : (
                        <InlineLoader />
                      )}
                    </div>
                    <div className="col">
                      <span>Highest</span>

                      <p>
                        <span>{`${parseIntoCurrencyStringWithSymbol(
                          activeRateAnalysesResult?.highest,
                          entityCurrency?.symbol,
                          entityCurrency?.precision
                        )}`}</span>
                      </p>
                    </div>
                  </div>

                  <StaleTextHint>Last 90 days rate changes</StaleTextHint>
                </InfoCard>
              </StaleContainer>
              {isEdit ? (
                <StaleBtnGroup container={false}>
                  <Button isLoading={isLoading} onClick={onChangeTarget}>
                    Change target amount
                  </Button>

                  <Button variant="link" onClick={goToPrebook}>
                    Prebook this rate
                  </Button>
                </StaleBtnGroup>
              ) : ratings.data.rateRating >= 3 ? (
                <StaleBtnGroup container={false}>
                  <Button onClick={goToPrebook}>Prebook this rate</Button>

                  <Button variant="link" onClick={onCreateInvoiceTracking}>
                    Track for now
                  </Button>
                </StaleBtnGroup>
              ) : (
                <StaleBtnGroup container={false}>
                  <Button onClick={onCreateInvoiceTracking}>
                    Start tracking
                  </Button>

                  <Button variant="link" onClick={goToPrebook}>
                    Prebook anyway
                  </Button>
                </StaleBtnGroup>
              )}
            </>
          ) : (
            <>
              <StaleContainer>
                <Paragraph>
                  Today’s rate is worth prebooking according to our analysis
                  algorithm. Go ahead and prebook it for further payment!
                </Paragraph>

                <DecideConvertInfo
                  invoice={invoice}
                  currency={currency}
                  rate={rate}
                />

                <InfoCard skin="green">
                  <div className="row rate-wrap">
                    <StaleHexagonRating
                      title="Rate"
                      rating={ratings.data.rateRating}
                    />

                    <StaleHexagonRating
                      title="Potential to save"
                      rating={ratings.data.rateRating}
                    />
                  </div>

                  <Paragraph>
                    {RATINGS_TEXT[ratings.data.rateRating - 1]}
                  </Paragraph>
                </InfoCard>

                <Paragraph>
                  {rate ? (
                    `Booking fee: ${parseIntoCurrencyStringWithSymbol(
                      roundToPrecision(
                        (invoice.totalAmount / rate) * bookingFeeRate
                      ),
                      entityCurrency?.symbol,
                      entityCurrency?.precision
                    )} to prebook this invoice with
                  Fully Flexible Guaranteed rate`
                  ) : (
                    <InlineLoader />
                  )}
                </Paragraph>
              </StaleContainer>

              <StaleBtnGroup container={false}>
                <Button onClick={goToPrebook}>Prebook this rate</Button>

                <Button
                  variant="link"
                  onClick={() => setShowAllDecideFields?.(true)}
                >
                  Track for now
                </Button>
              </StaleBtnGroup>
            </>
          )
        ) : (
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <StaleLoader />
          </div>
        )}
      </Wrapper>
    </StalePopup>
  );
};

export default DecidePopup;
