import { Dispatch, FC, SetStateAction } from 'react';
import CurrencyInfo from '../CurrencyInfo/CurrencyInfo';
import { Wrapper } from './CurrencyTiles.styles';
import { TReportsContentType } from 'pages/Reports/types';

interface OwnProps {
  entityId: string;
  activeCurrencyCode: string | undefined;
  setActiveCurrencyCode: Dispatch<SetStateAction<string>>;
  activeMonth: string;
  setActiveMonth: Dispatch<SetStateAction<string>>;
  contentType: TReportsContentType;
  setContentType: Dispatch<SetStateAction<TReportsContentType>>;
  currencyCodes: string[];
}

const CurrencyTiles: FC<OwnProps> = ({
  activeCurrencyCode,
  setActiveCurrencyCode,
  activeMonth,
  setActiveMonth,
  contentType,
  setContentType,
  currencyCodes,
}) => (
  <>
    {activeCurrencyCode && (
      <Wrapper>
        <CurrencyInfo
          activeMonth={activeMonth}
          setActiveMonth={setActiveMonth}
          contentType={contentType}
          setContentType={setContentType}
          currencyCodes={currencyCodes}
          setActiveCurrencyCode={setActiveCurrencyCode}
          activeCurrencyCode={activeCurrencyCode}
        />
      </Wrapper>
    )}
  </>
);

export default CurrencyTiles;
