import { css } from 'styled-components';

export const cardBaseCss = css`
  min-width: ${({ theme }) => theme.cardSizes.xs};

  @media (min-width: ${({ theme }) => theme.breakpoint.smallMin}px) {
    min-width: 98%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.mediumMin}px) {
    max-width: 49%;
    min-width: ${({ theme }) => theme.cardSizes.xs};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.extraLargeMin}px) {
    max-width: 32.7%;
  }
`;
