import { FC } from 'react';
import { IBulkPayment, IInvoice, isTransfer, ITransfer } from 'types';
import { parseIntoCurrencyString } from 'utils';
import { isOneCurrencyBulkPayment } from 'utils/bulkPayments';
import { Col } from '../Col/Col';
import { OverflowXWrapper } from '../OverflowXWrapper/OverflowXWrapper.styles';
import TransferInvoicesTable from '../TransferInvoicesTable/TransferInvoicesTable';
import { Paragraph, Title } from '../Typography/Typography';

interface OwnProps {
  selectedTransfer: ITransfer | IBulkPayment;
  relatedInvoices: IInvoice[];
}

const TransferOrBulkPaymentInnerInvoices: FC<OwnProps> = ({
  selectedTransfer,
  relatedInvoices,
}) => {
  const isSingleTransfer = isTransfer(selectedTransfer);

  return (
    <Col>
      <Title mb variant="h5">
        List of invoices covered by transfer
      </Title>

      {(isSingleTransfer || isOneCurrencyBulkPayment(selectedTransfer)) && (
        <Paragraph mb>
          Your transfer amount of
          <strong>
            {` ${parseIntoCurrencyString(
              isSingleTransfer
                ? selectedTransfer.buyAmount
                : selectedTransfer.buyAmountsBreakdown[0].amount
            )} ${
              isSingleTransfer
                ? selectedTransfer.buyCurrency
                : selectedTransfer.buyAmountsBreakdown[0].currency
            } `}
          </strong>
          covers the following invoices:
        </Paragraph>
      )}

      <OverflowXWrapper>
        <TransferInvoicesTable data={relatedInvoices} />
      </OverflowXWrapper>
    </Col>
  );
};

export default TransferOrBulkPaymentInnerInvoices;
