import { useMemo } from 'react';
import {
  addInvoicesToPaymentRun,
  getDraftPaymentRun,
} from 'services/paymentRuns';
import { useStoreState, useStoreActions } from 'state';
import { IInvoice } from 'types';
import { Notify } from 'utils';
import { errorHandler } from 'utils/errors';
import {
  isInvoiceApprovable,
  isInvoiceStatusInPayableState,
  isInvoiceSubmittableForReview,
  isPayableInvoice,
  isReceivableInvoice,
} from 'utils/invoices';

interface OwnProps {
  selectedInvoices: IInvoice[];
}

const useInvoicesFooterActions = ({ selectedInvoices }: OwnProps) => {
  const { isUserApprover, hasApprovalFlow, entityId } = useStoreState(
    (state) => state.UserState
  );
  const { setState } = useStoreActions((state) => state.PaymentRunsState);

  const data = useMemo(
    () =>
      selectedInvoices.reduce<{
        invoiceCurrencies: string[];
        payableInvoices: IInvoice[];
        invoicesForApproval: IInvoice[];
        invoicesForSubmission: IInvoice[];
        invoicesForPayment: IInvoice[];
        hasReceivableInvoices: boolean;
      }>(
        (acc, invoice) => {
          acc.invoiceCurrencies = [...acc.invoiceCurrencies, invoice.currency];

          if (isReceivableInvoice(invoice)) {
            acc.hasReceivableInvoices = true;
          }

          if (
            isPayableInvoice(invoice) &&
            isInvoiceStatusInPayableState(invoice) &&
            (!hasApprovalFlow ||
              (hasApprovalFlow && isUserApprover) ||
              (hasApprovalFlow &&
                !isUserApprover &&
                invoice.approval?.status === 'approved'))
          ) {
            acc.invoicesForPayment = [...acc.invoicesForPayment, invoice];
          }

          if (isInvoiceApprovable(invoice)) {
            acc.invoicesForApproval = [...acc.invoicesForApproval, invoice];
          }

          if (isInvoiceSubmittableForReview(invoice)) {
            acc.invoicesForSubmission = [...acc.invoicesForSubmission, invoice];
          }

          return acc;
        },
        {
          invoiceCurrencies: [],
          payableInvoices: [],
          invoicesForApproval: [],
          invoicesForSubmission: [],
          invoicesForPayment: [],
          hasReceivableInvoices: false,
        }
      ),
    [selectedInvoices, hasApprovalFlow, isUserApprover]
  );

  const onAddToPaymentRun = async () => {
    try {
      setState(['isUpdatingPaymentRun', true]);

      if (!entityId) {
        return;
      }
      // TODO: consider removing the need for payment run id from payment run invoices update, so we can avoid this call
      const { data: response } = await getDraftPaymentRun({ entityId });

      if (!response.data) {
        return;
      }

      await addInvoicesToPaymentRun({
        paymentRunId: response.data.id,
        invoiceIds: selectedInvoices.map((invoice) => invoice.id),
      });

      Notify.success('Invoices added to payment run');
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setState(['isUpdatingPaymentRun', false]);
    }
  };

  return {
    onAddToPaymentRun,
    ...data,
  };
};

export default useInvoicesFooterActions;
