import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';
import { TAccountingIntegration } from 'types';

export const SLIDER_DATA = [
  {
    id: 0,
    title: 'Prebook exchange',
    text:
      'Book your exchange in advance with a small prepayment and save time and money.',
    icon: 'prebook',
  },
  {
    id: 1,
    title: 'Follow currencies',
    text:
      'Add a currency pair to follow their rate changes. It may help you to pre-book a rate or make transfers when the rate is suitable.',
    icon: 'rates',
  },
  {
    id: 2,
    title: 'Transfer money',
    text: 'Pay securely in 38 currencies using current or prebooked rates.',
    icon: 'transfer',
  },
];

export const SLIDER_OPTIONS = {
  speed: 400,
  slidesToShow: 1,
  fade: true,
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 3000,
};

// in minutes
export const DEFAULT_SESSION_TIMEOUT = 20;

export const RECIPIENT_ROUTING_TYPES = {
  aba: 'ABA',
  sortCode: 'Sort code',
  bsbCode: 'BSB code',
  institutionNo: 'Institution number',
  bankCode: 'Bank code',
  branchCode: 'Branch code',
  clabe: 'CLABE',
  cnaps: 'CNAPS',
};

export const VALIDATION_MESSAGES = [
  {
    id: 0,
    text: 'At least 8 characters',
    typeId: 'minLength',
    validate: (value: string) => value?.length >= 8,
  },
  {
    id: 1,
    text: 'At least one number',
    typeId: 'oneNumber',
    validate: (value: string) => /(?=.*\d)/.test(value),
  },
  {
    id: 2,
    text: 'At least one upper case character',
    typeId: 'oneUppercase',
    validate: (value: string) => /(?=.*[A-Z])/.test(value),
  },
  {
    id: 3,
    text: 'At least one lower case character',
    typeId: 'oneLowercase',
    validate: (value: string) => value && /(?=.*[a-z])/.test(value),
  },
  {
    id: 4,
    text: 'At least one special character',
    typeId: 'oneSpecial',
    validate: (value: string) => /(?=.*[-+_!@#$%^&*.,?])/.test(value),
  },
];

export const ERROR_MESSAGES = {
  amountTooLow: 'Amount is too low',
  aboveCreditLimit:
    'Amount is too high. To increase your limit, please contact support.',
  requiredField: 'This field is required',
  incorrectDate: 'Date is incorrect',
  abovePrebookedAmount:
    'Exceeds prebooked amount. Reduce the amount, select a different prebooking, or use market rate.',
};

export const HELP_URL = 'https://help.hedgeflows.com';
export const SCAM_HELP_URL =
  'https://help.hedgeflows.com/how-to-avoid-fraudulent-payments';
export const SCHEDULE_MEET_URL = 'https://meetings.hubspot.com/alex2020';

export const DATE_FORMAT = 'DD MM YYYY';
export const DB_DATE_FORMAT = 'YYYY-MM-DD';
export const DB_MONTH_FORMAT = 'YYYY-MM';
export const MONTH_DATE_FORMAT = 'MMM YYYY';
export const QUARTER_DATE_FORMAT = '[Q]Q YYYY';
export const TIME_FORMAT = 'HH:mm';

export const RISK_APPETITE_SLIDER_DATA = [
  {
    id: 1,
    title: 'Risk tolerance',
    text:
      'We help you set up the risk tolerance metric and monitor your currency risks so that you don’t lose more that you can afford to',
    icon: 'past-performance',
  },
  {
    id: 2,
    title: 'Risk preferences',
    text:
      'There is more than one way to think about risks and you can adopt approach that suits your business',
    icon: 'transfer',
  },
  {
    id: 3,
    title: 'Risk planning',
    text:
      'If you care about your profit margins and cashflows, take time to understand how currencies affect them.',
    icon: 'prebook',
  },
];

export const COMMITTED_CURRENCY_QUESTIONS: ISelectItem[] = [
  {
    id: 'COMMITTED_1',
    value: 'WaitUntilFXRatesReachTarget',
    name: 'Wait until FX rates reach my targets',
  },
  {
    id: 'COMMITTED_2',
    value: 'SecureRatesWhenGood',
    name: 'Secure rates when they are good',
  },
  {
    id: 'COMMITTED_3',
    value: 'SecureRatesWhenExposureReachesLimit',
    name: 'Secure rates when exposure reaches our risk limit',
  },
  {
    id: 'COMMITTED_4',
    value: 'SecureRatesWhenGettingInvoices',
    name: 'Secure rates as soon as I get foreign invoices',
  },
];

export const FUTURE_CURRENCY_QUESTIONS: ISelectItem[] = [
  {
    id: 'FUTURE_1',
    value: 'NoVisibility',
    name: 'I have no visibility on future cashflows',
  },
  {
    id: 'FUTURE_2',
    value: 'DoNothing',
    name: 'Do nothing until invoices are accrued',
  },
  {
    id: 'FUTURE_3',
    value: 'SecureRatesWhenGood',
    name: 'Secure rates when they are good',
  },
  {
    id: 'FUTURE_4',
    value: 'SecureRatesToStayWithinRiskLimits',
    name: 'Secure rates to stay within my risk limit',
  },
];

export const mapAccountingIntegrationToIcon: Record<
  NonNullable<TAccountingIntegration>,
  string
> = {
  xero: 'xero-ico',
  sage50: 'sage50Xml-ico',
  quickBooks: 'quick-books-ico',
  dynamics: 'dynamics-365-ico',
  netSuite: 'netsuite-ico',
};

export const POPULAR_CURRENCIES_WITH_COLORS: Record<string, string> = {
  USD: '#F44336',
  EUR: '#9C27B0',
  JPY: '#673AB7',
  GBP: '#3F51B5',
  AUD: '#2196F3',
  CAD: '#03A9F4',
  CHF: '#00BCD4',
  CNY: '#009688',
  HKD: '#4CAF50',
  NZD: '#8BC34A',
  SEK: '#CDDC39',
  KRW: '#FFEB3B',
  SGD: '#FFC107',
  NOK: '#FF9800',
  MXN: '#FF5722',
  INR: '#795548',
  RUB: '#9E9E9E',
  ZAR: '#607D8B',
  TRY: '#E91E63',
  BRL: '#BA55D3',
  TWD: '#FF00FF',
  DKK: '#FF69B4',
  PLN: '#FF1493',
  THB: '#9400D3',
  MYR: '#4B0082',
  IDR: '#00FF7F',
  HUF: '#00FF00',
  CZK: '#00FFFF',
  ILS: '#00CED1',
  PHP: '#7FFF00',
  AED: '#808000',
  SAR: '#DC143C',
  KWD: '#FFC0CB',
};

export const LOOKUP = [
  { value: 1e9, symbol: 'B', digits: 1 },
  { value: 1e6, symbol: 'M', digits: 1 },
  { value: 1e3, symbol: 'K', digits: 1 },
  { value: 1, symbol: '', digits: 0 },
];
