import { IContact, IInvoiceFromSearch } from 'types';

const externalSystemIdTypes = [
  'externalRefsXeroId',
  'externalRefsCodatId',
] as const;
const externalSystemContactIdTypes = [
  'externalRefsXeroContactId',
  'externalRefsCodatContactId',
] as const;

const mapExternalSystemIdTypesToContactProperties: Record<
  typeof externalSystemIdTypes[number],
  typeof externalSystemContactIdTypes[number] | undefined
> = {
  externalRefsXeroId: 'externalRefsXeroContactId',
  externalRefsCodatId: 'externalRefsCodatContactId',
};

export const getExternalContactPropertyName = (invoice: IInvoiceFromSearch) => {
  for (const externalRefProperty of externalSystemIdTypes) {
    if (externalRefProperty in invoice) {
      const contactProp =
        mapExternalSystemIdTypesToContactProperties[externalRefProperty];
      if (contactProp) {
        return {
          prop: contactProp,
          value: invoice[contactProp],
        };
      }
    }
  }

  return false;
};

export const mapRecipientToIInvoiceSearch = (
  invoice: IInvoiceFromSearch,
  contact: IContact
) => {
  const invoiceToReturn = { ...invoice };

  invoiceToReturn.contactId = contact.id;
  invoiceToReturn.contactFirstName = contact.contactFirstName;
  invoiceToReturn.contactLastName = contact.contactLastName;
  invoiceToReturn.contactPhone = contact.contactPhone;
  invoiceToReturn.contactEnabled = contact.enabled;
  invoiceToReturn.contactRecipientName = contact.recipientName;

  if (contact.externalRefs) {
    if (contact.externalRefs.codatId) {
      invoiceToReturn.externalRefsCodatContactId = contact.externalRefs.codatId;
    } else if (contact.externalRefs.xeroId) {
      invoiceToReturn.externalRefsXeroContactId = contact.externalRefs.xeroId;
    }
  }

  return invoiceToReturn;
};
