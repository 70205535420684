import {
  AxiosPrivateFirebaseInstance,
  AxiosPublicFirebaseInstance,
  AxiosPublicWest3FirebaseInstance,
  AxiosWest3Instance,
} from 'settings';
import {
  ENTITY_TYPE,
  IEntityDetails,
  IInvite,
  IInvitePayload,
  IOwner,
  IResponse,
  IUser,
} from 'types';
import { Notify, openDoc } from 'utils';
import { asyncForEach } from 'utils';
import db from 'services/firestore';
import storage from 'services/storage';

const MISSING_DATA = 'Missing data';

export const generateVerificationCode = async () => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<IResponse>(
      '/code/generate'
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message ?? '');
      return undefined;
    }
  } catch (error: any) {
    Notify.error(error.message);
    return undefined;
  }
};

export interface VerifyVerificationCodeParams {
  code: string;
}

export const verifyVerificationCode = async ({
  code,
}: VerifyVerificationCodeParams) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<IResponse>(
      '/code/verify',
      {
        code,
      }
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message ?? '');
      return undefined;
    }
  } catch (error: any) {
    Notify.error(error.message);
    return undefined;
  }
};

export interface SearchCompanyPayload {
  searchString: string;
}

export const searchCompanies = async ({
  searchString,
}: SearchCompanyPayload) => {
  try {
    const { data } = await AxiosWest3Instance.get<IResponse>(
      `/registration/company?q=${searchString}`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message ?? '');
      return undefined;
    }
  } catch (error: any) {
    Notify.error(error.message);
    return undefined;
  }
};

export interface GetCompanyDetailsPayload {
  companyNumber: string;
}

export const getCompanyDetails = async ({
  companyNumber,
}: GetCompanyDetailsPayload) => {
  try {
    const { data } = await AxiosWest3Instance.get<IResponse>(
      `/registration/company/${companyNumber}`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message ?? '');
      return undefined;
    }
  } catch (error: any) {
    Notify.error(error.message);
    return undefined;
  }
};

export interface CheckVATNUmberPayload {
  number: string;
}

export const checkVATNumber = async ({ number }: CheckVATNUmberPayload) => {
  try {
    const { data } = await AxiosWest3Instance.get<IResponse>(
      `/registration/vat/${number}`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message ?? '');
      return undefined;
    }
  } catch (error: any) {
    Notify.error(error.message);
    return undefined;
  }
};

export const registerCompany = async (payload: {
  companyCountry: string; //2 character country code
  companyType: ENTITY_TYPE; //e.g. limitedCompany
  expectedAnnualTurnover: number;
  expectedFxTurnoverUpperLimit: number;
  name: string;
  website?: string;
  companyDetails: IEntityDetails;
  officers: IOwner[];
}) => {
  try {
    const { data } = await AxiosWest3Instance.post<IResponse>(
      `registration/company/register`,
      payload
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message ?? '');
      return undefined;
    }
  } catch (error: any) {
    Notify.error(error.message);
    return undefined;
  }
};

export const updateCompanyEidStatus = async () => {
  try {
    const { data } = await AxiosWest3Instance.post<IResponse>(
      `registration/company/register/eid`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message ?? '');
      return undefined;
    }
  } catch (error: any) {
    console.warn(error);
    return undefined;
  }
};

export interface IFileDetails {
  name: string;
  location: string;
  description?: string;
}

export interface UpdateCompanyFilesPayload {
  invoicesAndReceivesPayments: boolean;
  canProvideInvoicesAndBankStatements: boolean;
  fileDetails: IFileDetails[];
}

export const updateCompanyFilesStatus = async (
  payload: UpdateCompanyFilesPayload
) => {
  try {
    const { data } = await AxiosWest3Instance.post<IResponse>(
      `registration/company/register/qs`,
      payload
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message ?? '');
      return undefined;
    }
  } catch (error: any) {
    console.warn(error);
    return undefined;
  }
};

export interface UploadCompanyFilesPayload {
  userId: string;
  filesToUpload: File[];
}

export const uploadCompanyFiles = async ({
  userId,
  filesToUpload,
}: UploadCompanyFilesPayload) => {
  try {
    const downloadUrls: any[] = [];
    const filePathRef = storage.ref().child(`onboarding/${userId}`);

    await asyncForEach(filesToUpload, async (file: File) => {
      await filePathRef.child(file.name).put(file);

      const downloadUrl = await filePathRef.child(file.name).getDownloadURL();

      downloadUrls.push(downloadUrl);
    });

    return downloadUrls;
  } catch (error: any) {
    Notify.error(error);
    return undefined;
  }
};

export interface UploadCompanyFileParams {
  userId: string;
  entityId: string;
  fileToUpload: File;
}

export const uploadCompanyFile = async ({
  userId,
  entityId,
  fileToUpload,
}: UploadCompanyFileParams) => {
  try {
    const filePathRef = storage.ref().child(`onboarding/${entityId}/${userId}`);

    await filePathRef.child(fileToUpload.name).put(fileToUpload);

    const downloadUrl = await filePathRef
      .child(fileToUpload.name)
      .getDownloadURL();

    return downloadUrl;
  } catch (error: any) {
    console.log(error);
    return undefined;
  }
};

export interface RemoveCompanyFileParams {
  userId: string;
  entityId: string;
  filename: File['name'];
}

export const removeCompanyFile = async ({
  userId,
  entityId,
  filename,
}: RemoveCompanyFileParams) => {
  try {
    const filePathRef = storage.ref().child(`onboarding/${entityId}/${userId}`);

    await filePathRef.child(filename).delete();
  } catch (error: any) {
    Notify.error(error);
  }
};

// ONFIDO
export interface CreateOnfidoApplicantPayload {
  firstName: string;
  lastName: string;
}

export const onfidoCreateApplicant = async (
  payload: CreateOnfidoApplicantPayload
) => {
  try {
    const { data } = await AxiosWest3Instance.post<IResponse>(
      '/registration/eid/applicant',
      payload
    );

    return data;
  } catch (error: any) {
    Notify.error(error.message);
    return undefined;
  }
};

export interface GetOnfidoApplicantPayload {
  applicantId: string;
}

export const onfidoGetApplicant = async ({
  applicantId,
}: GetOnfidoApplicantPayload) => {
  try {
    const { data } = await AxiosWest3Instance.get<IResponse>(
      `/registration/eid/applicant/${applicantId}`
    );

    return data;
  } catch (error: any) {
    Notify.error(error.message);
  }
};

export interface OnfidoGenerateTokenPayload {
  applicant_id: string;
}

export const onfidoGenerateToken = async (
  payload: OnfidoGenerateTokenPayload
) => {
  try {
    const { data } = await AxiosWest3Instance.post<IResponse>(
      '/registration/eid/generate_token',
      payload
    );

    return data;
  } catch (error: any) {
    Notify.error(error.message);
    return undefined;
  }
};

export interface GetEntityOnboardingRecordParams {
  entityId: string;
  onboardingId: string;
}

export const getEntityOnboardingRecord = async ({
  entityId,
  onboardingId,
}: GetEntityOnboardingRecordParams) => {
  try {
    const data = await db
      .collection('entities')
      .doc(entityId)
      .collection('onboarding')
      .doc(onboardingId)
      .get()
      .then((doc) => openDoc(doc));

    return data;
  } catch (error: any) {
    Notify.error(error.message);
    return undefined;
  }
};

export interface SendResetPasswordLinkParams {
  email: string;
}

export const sendResetPasswordLink = async ({
  email,
}: SendResetPasswordLinkParams) => {
  try {
    const { data } = await AxiosPublicFirebaseInstance.post(
      `/pwreset/${email}`
    );

    return data;
  } catch (error: any) {
    Notify.error(error.message);
    return undefined;
  }
};

export const createInvitation = async ({
  entityId,
  ...payload
}: IInvitePayload) => {
  const response = await AxiosWest3Instance.post<IResponse>(
    `/registration/user/invite/${entityId}`,
    payload
  );

  return response;
};

export const getInvitation = async (token: string) => {
  const { data } = await AxiosPublicWest3FirebaseInstance.get<
    IResponse<IInvite>
  >(`/registration/user/invite/${token}`);

  if (!data.data) {
    throw new Error(MISSING_DATA);
  }

  return data.data;
};

/**
 * Sets an invitation status to either accepted or rejected
 */
export const updateInvitationAcceptance = async ({
  token,
  acceptance,
}: {
  token: string;
  acceptance: boolean;
}) => {
  try {
    const { data } = await AxiosPublicWest3FirebaseInstance.post<
      IResponse<IInvite>
    >(`/registration/user/invite/${token}/acceptance`, { acceptance });

    if (!data.data) {
      throw new Error(MISSING_DATA);
    }

    return data.data;
  } catch (error: any) {
    Notify.error(error.message);
    return undefined;
  }
};

/**
 * Creates a user given an invitation id and password.
 * This is used where the backend creates the auth record.
 *  */
export const createUnregisteredUser = async ({
  token,
  password,
}: {
  token: string;
  password: string;
}) => {
  try {
    const { data } = await AxiosPublicWest3FirebaseInstance.post<
      IResponse<IUser>
    >(`/registration/user/${token}`, { password });

    if (!data.data) {
      throw new Error(MISSING_DATA);
    }

    return data.data;
  } catch (error: any) {
    Notify.error(error.message);
    return undefined;
  }
};
