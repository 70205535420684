import { FC } from 'react';

import { StaleNotification, Paragraph } from 'components';
import useIsAwaitingReview from './useIsAwaitingReview';

interface Props {
  bgColor?: string;
  style?: object;
  title?: string;
  icon?: string;
}

const StaleAwaitingReviewNotification: FC<Props> = ({
  title = 'Full access required to continue',
  icon = 'look-smile',
  bgColor = '',
  style,
}) => {
  const isAwaitingReview = useIsAwaitingReview();

  return isAwaitingReview ? (
    <StaleNotification
      icon={icon}
      bgColor={bgColor}
      title={title}
      cross={false}
      style={style}
    >
      <Paragraph color="white">
        Your application is being reviewed. Once approved you will have full
        access to this feature. For now you can only play around with the
        screens.
      </Paragraph>
    </StaleNotification>
  ) : null;
};

export default StaleAwaitingReviewNotification;
