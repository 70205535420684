import useCodatIntegration from './useCodatIntegration';

const useDynamics365 = () => {
  const {
    onImportData: onImportDynamics365Data,
    isLoading: isLoadingDynamics365sData,
    isIntegrated: isIntegratedWithDynamics365,
    onLogin: onDynamics365Login,
    isImporting: isImportingDynamics365Data,
    queueNewCodatSync: queueNewDynamics365Sync,
    syncStatus: dynamics365SyncStatus,
  } = useCodatIntegration({
    platformNames: ['Dynamics 365 Business Central'],
  });

  return {
    isImportingDynamics365Data,
    isIntegratedWithDynamics365,
    isLoadingDynamics365sData,
    onImportDynamics365Data,
    onDynamics365Login,
    queueNewDynamics365Sync,
    dynamics365SyncStatus,
  };
};

export default useDynamics365;
