import { FC } from 'react';
import { ParagraphWithEllipsis } from 'components';
import { IInvoice, IInvoiceFromSearch } from 'types';
import { getInvoiceNumber } from 'utils/invoices';
import { Link } from 'react-router-dom';

interface OwnProps {
  record: IInvoice | IInvoiceFromSearch;
}

const InvoiceNumberCell: FC<OwnProps> = ({ record }) => {
  const value = getInvoiceNumber(record);

  return (
    <ParagraphWithEllipsis title={value}>
      <Link to={`/app/invoices/${record.id}`}>{value}</Link>
    </ParagraphWithEllipsis>
  );
};

export default InvoiceNumberCell;
