import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Column } from 'react-table';
import {
  Nullable,
  IRecipient,
  IInvoiceFromSearch,
  IContact,
  IPastPerformanceItemInvoice,
  TAccountingIntegration,
} from 'types';
import { isReceivableInvoice, getInvoiceRemainingAmount } from 'utils/invoices';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import AmountSellCurrencyCell from 'components/shared/AmountSellCurrencyCell/AmountSellCurrencyCell';
import ContactCellNew from 'components/shared/ContactCellNew/ContactCellNew';
import DateCell from 'components/shared/DateCell/DateCell';
import DirectionCell from 'components/shared/DirectionCell/DirectionCell';
import InvoiceNumberCell from 'components/shared/InvoiceNumberCell/InvoiceNumberCell';
import ProfitAndLossCellNew from '../ProfitAndLossCellNew/ProfitAndLossCellNew';
import {
  FlagCell,
  InvoiceFromSearchStatusCell,
} from './components/Cells/Cells';
import StatusIconCell from './components/StatusIconCell/StatusIconCell';
import InvoiceActionsMenu from './components/InvoiceActionsMenu/InvoiceActionsMenu';
import DoActionCell from './components/DoActionCell/DoActionCell';
import InvoiceSyncedToExternalSourceCell from './components/InvoiceSyncedToExternalSourceCell/InvoiceSyncedToExternalSourceCell';
import RealisedPnlCell from './components/RealisedPnlCell/RealisedPnlCell';

import Icon from '../Icon/Icon';
import { Row } from '../Row/Row';
import { mapAccountingIntegrationToIcon } from 'variables';
import { extractUnsavedRecipientFromIInvoiceFromSearch } from './utils';

export const generateTableColumns = ({
  recipientById,
  setCancelPrebookInvoice,
  setContactForEdit,
  setExistingInvoiceTracking,
  setExistingPrebookInvoice,
  setInvoiceDecide,
  setInvoiceForAddContact,
  setShowAllDecideFields,
  setShowInvoiceDelete,
  setShowPurchaseOrderPaymentsOnInvoice,
  renderActionCell,
  tab,
  pastPerformancePerRecordInvoices,
  isLoadingPastPerformancePerRecordInvoices,
  accountingIntegration,
  entityCurrencyCode,
}: {
  recipientById: (id: Nullable<string>) => IRecipient | undefined;
  setCancelPrebookInvoice: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  setContactForEdit: Dispatch<SetStateAction<IContact | null>>;
  setExistingInvoiceTracking: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
  setExistingPrebookInvoice: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
  setInvoiceDecide: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  setInvoiceForAddContact: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  setShowAllDecideFields: Dispatch<SetStateAction<boolean>>;
  setShowInvoiceDelete: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  setShowPurchaseOrderPaymentsOnInvoice: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
  renderActionCell?: (record: IInvoiceFromSearch) => ReactNode;
  tab: string | null;
  pastPerformancePerRecordInvoices?: IPastPerformanceItemInvoice[];
  isLoadingPastPerformancePerRecordInvoices?: boolean;
  accountingIntegration: TAccountingIntegration;
  entityCurrencyCode: Nullable<string>;
}): Column<IInvoiceFromSearch>[] => {
  const dateCells: Column<IInvoiceFromSearch>[] =
    tab !== 'paid'
      ? [
          {
            accessor: 'date',
            Header: 'Issued',
            Cell: ({ value }) => <DateCell value={value} />,
            width: 100,
            minWidth: 80,
          },
          {
            accessor: 'dueDate',
            Header: 'Due',
            Cell: ({ value }) => <DateCell withOverdueMarker value={value} />,
            width: 100,
            minWidth: 80,
          },
        ]
      : [
          {
            accessor: 'fullyPaidOnDate',
            Header: 'Payment Date',
            Cell: ({ value }) => <DateCell value={value} />,
            width: 100,
            minWidth: 80,
          },
        ];

  const pnlCells: Column<IInvoiceFromSearch>[] =
    tab !== 'paid'
      ? [
          {
            id: 'unrealisedPnl',
            accessor: 'profitAndLoss',
            Header: 'Unrealised P&L',
            disableSortBy: true,
            Cell: ({ row }) => (
              <ProfitAndLossCellNew
                record={row.original}
                profitAndLoss={row.original.profitAndLoss}
              />
            ),
            width: 120,
            minWidth: 100,
          },
        ]
      : [
          {
            id: 'realisedPnl',
            accessor: 'profitAndLoss',
            Header: 'Realised P&L',
            disableSortBy: true,
            Cell: ({
              row: {
                original: { id },
              },
            }) => {
              const pastPerformanceRecord = pastPerformancePerRecordInvoices?.find(
                ({ invoiceId }) => invoiceId === id
              );

              return (
                <RealisedPnlCell
                  pastPerformanceRecord={pastPerformanceRecord}
                  isLoadingPastPerformancePerRecordInvoices={
                    isLoadingPastPerformancePerRecordInvoices
                  }
                />
              );
            },
            width: 120,
            minWidth: 100,
          },
        ];

  const actionAndIconCells: Column<IInvoiceFromSearch>[] = [
    {
      id: 'toDo',
      disableSortBy: true,
      Cell: ({ row }: any) =>
        renderActionCell ? (
          renderActionCell(row.original)
        ) : (
          <DoActionCell record={row.original} />
        ),
      width: 80,
      minWidth: 60,
    },
    {
      id: 'icon',
      disableSortBy: true,
      Cell: ({ row }: any) => <StatusIconCell record={row.original} />,
      width: 48,
      minWidth: 48,
    },
  ];

  const invoiceSyncedToExternalSourceCell: Column<IInvoiceFromSearch>[] = [
    {
      id: 'invoiceSyncedToExternalSourceCell',
      Header: (
        <Row justifyContent="center">
          {accountingIntegration && (
            <Icon
              width="20px"
              height="20px"
              icon={mapAccountingIntegrationToIcon[accountingIntegration]}
            />
          )}
        </Row>
      ),
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <InvoiceSyncedToExternalSourceCell record={row.original} />
      ),
      width: 60,
      minWidth: 40,
    },
  ];

  return [
    {
      accessor: 'invoiceNumber',
      Header: 'Ref',
      Cell: ({ row }) => <InvoiceNumberCell record={row.original} />,
      width: 110,
    },
    {
      accessor: 'contactFirstName',
      Header: 'Name',
      Cell: ({ row, value }) => {
        const existingRecipient = recipientById(row.original.contactId);
        let recipientToUse = existingRecipient ?? value;

        if (!recipientToUse && row.original.contactRecipientName) {
          recipientToUse = extractUnsavedRecipientFromIInvoiceFromSearch(
            row.original
          );
        }

        return (
          <ContactCellNew
            invoice={row.original}
            recipientToUse={recipientToUse}
            setContactForEdit={setContactForEdit}
            setInvoiceForAddContact={setInvoiceForAddContact}
            withReviewStatus={tab !== 'paid'}
          />
        );
      },
      width: 180,
      minWidth: 150,
    },
    ...dateCells,
    {
      accessor: 'currency',
      disableSortBy: true,
      Cell: ({ value }) => <FlagCell currencyCode={value} />,
      width: 50,
      minWidth: 50,
    },
    {
      accessor: tab === 'paid' ? 'amountPaid' :'amountDue',
      Header: tab === 'paid' ? 'Paid Amount' : 'Amount Due',
      Cell: ({ row }: any) => (
        <>
          <DirectionCell
            withTitle={false}
            isReceivable={isReceivableInvoice(row.original)}
            currencyCode={row.original.currency}
          />
          <AmountCell
            amount={getInvoiceRemainingAmount(row.original)}
            currencyCode={row.original.currency}
          />
        </>
      ),
      filter: 'direction',
      width: 120,
      minWidth: 100,
    },
    {
      id: entityCurrencyCode ?? undefined,
      Header: `${entityCurrencyCode ?? ''} Value`,
      Cell: ({ row }: any) => <AmountSellCurrencyCell record={row.original} />,
      width: 120,
      minWidth: 100,
    },
    ...pnlCells,
    {
      accessor: 'status',
      Header: 'Status',
      Cell: ({ row }) => <InvoiceFromSearchStatusCell invoice={row.original} />,
      width: 110,
      minWidth: 90,
    },
    ...(tab !== 'paid' ? actionAndIconCells : []),
    ...(tab === 'paid' && !!accountingIntegration
      ? invoiceSyncedToExternalSourceCell
      : []),
    {
      id: 'dots',
      Header: () => null,
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <InvoiceActionsMenu
          record={row.original}
          setInvoiceDecide={setInvoiceDecide}
          setCancelPrebookInvoice={setCancelPrebookInvoice}
          setExistingInvoiceTracking={setExistingInvoiceTracking}
          setExistingPrebookInvoice={setExistingPrebookInvoice}
          setShowAllDecideFields={setShowAllDecideFields}
          setShowInvoiceDelete={setShowInvoiceDelete}
          setShowPurchaseOrderPaymentsOnInvoice={
            setShowPurchaseOrderPaymentsOnInvoice
          }
        />
      ),
      width: 60,
      minWidth: 55,
    },
  ];
};
