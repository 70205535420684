import { FC } from 'react';
import { Notify } from 'utils';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import StaleButtonCopy from 'components/shared/StaleButtonCopy/StaleButtonCopy';
import { Paragraph } from 'components/shared/Typography/Typography';

interface OwnProps {
  title: string;
  value?: string;
}

const CopyInfo: FC<OwnProps> = ({ title, value }) => (
  <RowInfo>
    <Paragraph>{title}</Paragraph>
    <StaleButtonCopy
      showIcon
      onClick={() => {
        navigator.clipboard.writeText(value ?? '');
        Notify.success(`Copied ${title} to clipboard!`);
      }}
    >
      <Paragraph variant="bold">{value}</Paragraph>
    </StaleButtonCopy>
  </RowInfo>
);

export default CopyInfo;
