import { useState, useEffect, useMemo } from 'react';
import { getInvoicesByIdArray } from 'services/firebase/invoices';
import { useStoreState } from 'state';
import {
  IBulkPayment,
  IInvoice,
  isTransfer,
  ITransfer,
  TRANSFER_TYPE,
} from 'types';
import { errorHandler } from 'utils/errors';

type ContentType = 'payment' | 'transfers' | 'invoices' | 'mt103' | null;

interface UseRelatedTransfersAndInvoicesValues {
  selectedTransferOrBulkPayment: ITransfer | IBulkPayment | null;
}

const useTransferOrBulkPaymentInner = ({
  selectedTransferOrBulkPayment,
}: UseRelatedTransfersAndInvoicesValues) => {
  const { transfersByBulkId } = useStoreState((state) => state.TransfersState);
  const [relatedInvoices, setRelatedInvoices] = useState<IInvoice[]>([]);
  const [contentType, setContentType] = useState<ContentType>(null);

  const isSingleTransfer =
    selectedTransferOrBulkPayment && isTransfer(selectedTransferOrBulkPayment);

  const relatedTransfers = useMemo(
    () =>
      !isSingleTransfer
        ? transfersByBulkId(selectedTransferOrBulkPayment?.id)
        : [],
    [transfersByBulkId, isSingleTransfer, selectedTransferOrBulkPayment]
  );

  const relatedInvoicesIds = useMemo(() => {
    const ids: string[] = [];

    relatedTransfers.forEach((transfer) => {
      if (!!transfer.invoiceId) {
        ids.push(transfer.invoiceId);
      }
      if (!!transfer.invoiceIds?.length) {
        ids.push(...transfer.invoiceIds);
      }
    });

    if (isSingleTransfer) {
      if (!!selectedTransferOrBulkPayment.invoiceId) {
        ids.push(selectedTransferOrBulkPayment.invoiceId);
      }
      if (!!selectedTransferOrBulkPayment.invoiceIds?.length) {
        ids.push(...selectedTransferOrBulkPayment.invoiceIds);
      }
    }

    return ids;
  }, [relatedTransfers, selectedTransferOrBulkPayment, isSingleTransfer]);

  useEffect(() => {
    if (!!relatedInvoicesIds.length) {
      const getRelatedInvoices = async () => {
        try {
          const result = await getInvoicesByIdArray(relatedInvoicesIds);

          setRelatedInvoices(result);
        } catch (error: any) {
          errorHandler(error);
        }
      };

      getRelatedInvoices();
    }
  }, [relatedInvoicesIds]);

  const tabs = useMemo(() => {
    if (!selectedTransferOrBulkPayment) {
      return [];
    }

    const tabsArray: Array<{ id: ContentType; title: string }> = [];

    if (
      selectedTransferOrBulkPayment.status === 'awaiting_payment' &&
      (!isSingleTransfer || !selectedTransferOrBulkPayment?.bulkPaymentId)
    ) {
      tabsArray.push({
        id: 'payment',
        title: 'Payment',
      });
    }

    if (relatedTransfers.length > 0) {
      tabsArray.push({
        id: 'transfers',
        title: 'Transfers',
      });
    }

    if (relatedInvoices.length > 0) {
      tabsArray.push({
        id: 'invoices',
        title: 'Invoices',
      });
    }

    if (
      isSingleTransfer &&
      selectedTransferOrBulkPayment.transferType === TRANSFER_TYPE.priority &&
      selectedTransferOrBulkPayment.externalRefs?.ccId
    ) {
      tabsArray.push({
        id: 'mt103',
        title: 'MT103',
      });
    }

    return tabsArray;
  }, [
    selectedTransferOrBulkPayment,
    isSingleTransfer,
    relatedTransfers,
    relatedInvoices,
  ]);

  useEffect(() => {
    if (!contentType && tabs.length) {
      setContentType(tabs[0].id);
    }
  }, [contentType, tabs]);

  return {
    contentType,
    setContentType,
    tabs,
    relatedInvoices,
    relatedTransfers,
  };
};

export default useTransferOrBulkPaymentInner;
