import { FC, useState } from 'react';

import {
  Title,
  StaleSubTitleBold,
  StaleCheckboxControlled,
  Paragraph,
} from 'components';
import { Wrapper } from './Review.styles';
import TransferSummary from '../TransferSummary/TransferSummary';
import {
  FirstStepValues,
  SecondStepValues,
} from 'pages/SimpleTransfer/SimpleTransfer';
import { Notify, parseIntoCurrencyString, roundToPrecision } from 'utils';
import { Row } from 'components/shared/Row/Row';
import { useForm } from 'react-hook-form';
import { PAYMENT_TYPE, RATE_TYPE, TRANSFER_TYPE } from 'types';
import { createSimpleTransfer, updateRecipient } from 'services/firebase';
import StaleReAuthenticate from 'components/shared/StaleReAuthenticate/StaleReAuthenticate';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import StaleInput from 'components/shared/StaleInput/StaleInput';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import { useReAuthenticate } from 'hooks';
import TooManyTimePasswordAttemptsPopup from 'components/shared/TooManyTimePasswordAttemptsPopup/TooManyTimePasswordAttemptsPopup';
import Button from 'components/shared/Button/Button';

interface FormValues {
  password: string;
  reference: string;
  isTrustedRecipient?: boolean;
}
interface OwnProps {
  onContinue: () => void;
  savedValues: FirstStepValues & SecondStepValues;
}

const Review: FC<OwnProps> = ({ onContinue, savedValues }) => {
  const { invoice, recipient, sellAmount, sellCurrency } = savedValues;

  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, control, errors } = useForm<FormValues>();

  const {
    onReAuthenticateClick,
    isTooManyPasswordAttemptsError,
    setIsTooManyPasswordAttemptsError,
  } = useReAuthenticate();

  const onConfirm = async ({ isTrustedRecipient, reference }: FormValues) => {
    try {
      setIsLoading(true);

      const response = await createSimpleTransfer({
        sellAmount: savedValues.sellAmount,
        sellCurrency: sellCurrency.code,
        buyAmount: savedValues.sellAmount,
        buyCurrency: sellCurrency.code,
        rate: 1,
        rateType: RATE_TYPE.market,
        payAmount: roundToPrecision(savedValues.sellAmount + savedValues.fee),
        transferType:
          recipient.paymentType === PAYMENT_TYPE.swift
            ? TRANSFER_TYPE.priority
            : TRANSFER_TYPE.regular,
        recipientId: recipient.id,
        priorityTransferFeeAmount: savedValues.fee,
        reference,
        invoiceId: invoice?.id,
      });

      if (response && response.success) {
        if (isTrustedRecipient) {
          updateRecipient({
            recipientId: recipient.id,
            recipientData: {
              ...recipient,
              isTrusted: true,
            },
          });
        }

        onContinue();
      }
    } catch (error: any) {
      Notify.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitHandler = async (values: any) => {
    try {
      if (!recipient.isTrusted) {
        await onReAuthenticateClick(values);
      }
      await onConfirm(values);
    } catch (error: any) {}
  };

  return (
    <>
      <FlowStepWrapper>
        <form
          id="simple-transfer-review-form"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <FlowStepContent>
            <FlowContentLeft>
              <Wrapper>
                <Title variant="h5">Recipient details</Title>

                <RowInfo>
                  <Paragraph variant="thin">Account name</Paragraph>
                  <Paragraph variant="bold">
                    {recipient.recipientName}
                  </Paragraph>
                </RowInfo>
                <RowInfo>
                  <Paragraph variant="thin">Account number</Paragraph>
                  <Paragraph variant="bold">
                    {recipient.accountNumber}
                  </Paragraph>
                </RowInfo>

                {!!recipient.bankName && (
                  <RowInfo>
                    <Paragraph variant="thin">Bank name</Paragraph>
                    <Paragraph variant="bold">{recipient.bankName}</Paragraph>
                  </RowInfo>
                )}
                {!!recipient.bicSwift && (
                  <RowInfo>
                    <Paragraph variant="thin">Bank code (SWIFT/BIC)</Paragraph>
                    <Paragraph variant="bold">{recipient.bicSwift}</Paragraph>
                  </RowInfo>
                )}

                <Title variant="h5" mt>
                  Transfer details
                </Title>

                <RowInfo>
                  <Paragraph variant="thin">Amount</Paragraph>
                  <Paragraph variant="bold">{`${
                    sellCurrency.code
                  } ${parseIntoCurrencyString(sellAmount)}`}</Paragraph>
                </RowInfo>

                <Title variant="h5" mt mb>
                  Add your reference
                </Title>
                <StaleInput
                  id="reference"
                  name="reference"
                  view="moving"
                  label="Your reference"
                  control={control}
                  defaultValue={recipient?.defaultReference}
                />
              </Wrapper>

              {!recipient.isTrusted && (
                <Row mt>
                  <Wrapper>
                    <StaleCheckboxControlled
                      id="isTrustedRecipient"
                      control={control}
                      name="isTrustedRecipient"
                      Label={
                        <Paragraph variant="bold">
                          Save as a Trusted Payee
                        </Paragraph>
                      }
                    />

                    <Paragraph style={{ marginTop: '28px' }}>
                      Future payments will not require a password. Please only
                      use it with Payees that you consider trustworthy.
                    </Paragraph>
                  </Wrapper>
                </Row>
              )}
            </FlowContentLeft>
            <FlowContentRight>
              <StaleSubTitleBold>Summary</StaleSubTitleBold>

              <TransferSummary {...savedValues} />
            </FlowContentRight>
          </FlowStepContent>

          <FlowStepFooter>
            {recipient.isTrusted ? (
              <Button
                isLoading={isLoading}
                form="simple-transfer-review-form"
                type="submit"
              >
                Confirm
              </Button>
            ) : (
              <StaleReAuthenticate control={control} errors={errors}>
                <Button
                  isLoading={isLoading}
                  form="simple-transfer-review-form"
                  type="submit"
                >
                  Confirm
                </Button>
              </StaleReAuthenticate>
            )}
          </FlowStepFooter>
        </form>
      </FlowStepWrapper>
      {isTooManyPasswordAttemptsError && (
        <TooManyTimePasswordAttemptsPopup
          onClose={() => setIsTooManyPasswordAttemptsError(false)}
        />
      )}
    </>
  );
};

export default Review;
