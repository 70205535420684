import { PropsWithChildren } from 'react';
import { Cell, Column, useTable } from 'react-table';
import {
  StyledTable,
  TableBody,
  TableDataCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from './TableHorizontal.styles';
import { TTableHorizontalCellTheme } from './types';

interface OwnProps<T extends object> {
  columns: Column<T>[];
  data: T[];
  isVirtualized?: boolean;
  getTdCellTheme?: (cell: Cell<T>) => TTableHorizontalCellTheme;
  getThCellTheme?: (columnId: string) => TTableHorizontalCellTheme;
}

const TableHorizontal = <T extends object>({
  columns,
  data,
  isVirtualized = false,
  getTdCellTheme = () => 'default',
  getThCellTheme = () => 'dark',
}: PropsWithChildren<OwnProps<T>>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <StyledTable isVirtualized={isVirtualized} {...getTableProps()} as="table">
      <TableHead as="thead">
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()} as="tr">
            {headerGroup.headers.map((column) => (
              <TableHeaderCell
                {...column.getHeaderProps()}
                as="th"
                colorTheme={getThCellTheme(column.id)}
              >
                {column.render('Header')}
              </TableHeaderCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()} as="tbody">
        {rows.map((row) => {
          prepareRow(row);

          return (
            <TableRow {...row.getRowProps()} as="tr">
              {row.cells.map((cell) => {
                return (
                  <TableDataCell
                    {...cell.getCellProps()}
                    as="td"
                    colorTheme={getTdCellTheme(cell)}
                  >
                    {cell.render('Cell')}
                  </TableDataCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

export default TableHorizontal;
