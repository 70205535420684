import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { StaleLoader, PrebookTable } from 'components';
import {
  appendRateAndProfitAndLossToInvoiceFromSearch,
  getInvoicesSortFields,
} from 'utils/invoices';
import { useStoreState } from 'state';
import useUrlValues from 'hooks/useUrlValues';
import Tabs from './components/Tabs/Tabs';
import { isExternalHedge } from 'components/shared/PrebookTable/utils';
import useInvoicesPastPerformanceData from 'hooks/useInvoicesPastPerformanceData';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { DB_MONTH_FORMAT } from 'variables';
import {
  getQuarterFromQuarterDateString,
  getYearFromQuarterDateString,
} from 'utils/dates';
import TransferPrebookPopups from 'components/shared/TransferPrebookPopups/TransferPrebookPopups';
import { getInvoicesPaginated } from 'services/firebase/invoices';
import { IGenerateSearchQueryParams, generateSearchQuery } from 'utils/search';
import { get, isEqual } from 'lodash';
import { IInvoiceFromSearch } from 'types';
import InvoicesTableNew from 'components/shared/InvoicesTableNew/InvoicesTableNew';
import { getInvoicesFilters, getRatesPerCurrency } from './utils';
import { SortingRule } from 'react-table';

dayjs.extend(quarterOfYear);

interface OwnProps {
  dateRange: string;
}

const DateRangeInfo: FC<OwnProps> = ({ dateRange }) => {
  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);
  const { currency, tab, period, isFirstPeriod, setUrlValue } = useUrlValues(
    'currency',
    'tab',
    'period',
    'isFirstPeriod'
  );

  const [invoices, setInvoices] = useState<IInvoiceFromSearch[]>([]);
  const [isLoadingMoreInvoices, setIsLoadingMoreInvoices] = useState(false);
  const [isLoadedFirstPage, setIsLoadedFirstPage] = useState(false);
  const detectedCurrencies = useMemo(() => [currency ?? ''], [currency]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreToLoad, setHasMoreToLoad] = useState(true);
  const [sortState, setSortState] = useState<SortingRule<IInvoiceFromSearch>[]>(
    []
  );
  const {
    data: pastPerformancePerRecordInvoices,
    isLoading: isLoadingPastPerformancePerRecordInvoices,
  } = useInvoicesPastPerformanceData({ currencies: detectedCurrencies });

  const fetchInvoices = useCallback(
    async ({
      dateRange,
      page,
      period,
      currency,
      entityCurrencyCode,
      isFirstPeriod,
      sortFields,
    }: {
      dateRange: string;
      page: number;
      entityCurrencyCode: string;
      period: string;
      currency: string;
      isFirstPeriod: boolean;
      sortFields?: IGenerateSearchQueryParams['sortFields'];
    }) => {
      try {
        let dateFrom = dayjs(dateRange, DB_MONTH_FORMAT).startOf('month');
        let dateTo = dayjs(dateRange, DB_MONTH_FORMAT).endOf('month');

        if (period === 'quarter') {
          const quarter = getQuarterFromQuarterDateString(dateRange);
          const year = getYearFromQuarterDateString(dateRange);

          if (quarter && year) {
            dateFrom = dayjs()
              .year(Number(year))
              .quarter(Number(quarter))
              .startOf('quarter');

            dateTo = dayjs()
              .year(Number(year))
              .quarter(Number(quarter))
              .endOf('quarter');
          }
        }

        const searchQueryParams = {
          filters: getInvoicesFilters({
            currency,
            dateFrom: isFirstPeriod ? '' : dateFrom.toISOString(),
            dateTo: dateTo.toISOString(),
          }),
          sortFields,
          page,
          size: 30,
        } as const;

        const query = generateSearchQuery(searchQueryParams);

        const result = await getInvoicesPaginated(query);

        const enabledCurrencies = [currency];

        const ratesPerCurrency = await getRatesPerCurrency(
          enabledCurrencies,
          entityCurrencyCode
        );

        const invoices = get(
          result,
          'data.data.results',
          []
        ) as IInvoiceFromSearch[];

        const invoicesWithProfitAndLoss = invoices.map((invoice) =>
          appendRateAndProfitAndLossToInvoiceFromSearch(
            invoice,
            ratesPerCurrency[invoice.currency]
          )
        );

        setInvoices((prevInvoices) => [
          ...prevInvoices,
          ...invoicesWithProfitAndLoss,
        ]);

        const currentPageFromResponse = result.data.data?.meta.page.current;
        const totalPagesFromResponse = result.data.data?.meta.page.total_pages;

        if (
          currentPageFromResponse &&
          currentPageFromResponse !== currentPage
        ) {
          setCurrentPage(currentPageFromResponse);
        }

        if (
          currentPageFromResponse &&
          totalPagesFromResponse &&
          currentPageFromResponse === totalPagesFromResponse
        ) {
          setHasMoreToLoad(false);
        }
      } catch (error) {
        console.error(`Error fetching invoices page ${page}:`, error);
      } finally {
        setIsLoadingMoreInvoices(false);
      }
    },
    [currentPage]
  );

  useEffect(() => {
    setInvoices([]);
    setCurrentPage(1);
  }, [currency, period, dateRange, sortState]);

  useEffect(() => {
    if (currency && period && currentPage === 1) {
      fetchInvoices({
        dateRange,
        page: currentPage,
        entityCurrencyCode,
        period,
        currency,
        isFirstPeriod: isFirstPeriod === 'true',
        sortFields: getInvoicesSortFields(sortState),
      }).finally(() => setIsLoadedFirstPage(true));
    }
  }, [
    currency,
    currentPage,
    dateRange,
    entityCurrencyCode,
    fetchInvoices,
    period,
    sortState,
    isFirstPeriod,
  ]);

  const onPrebookTableRowClick = useCallback(
    (record) =>
      setUrlValue({
        [isExternalHedge(record) ? 'hedgeId' : 'contractId']: record.id,
      }),
    [setUrlValue]
  );

  const handleSortChange = useCallback(
    (tableSortState: SortingRule<IInvoiceFromSearch>[]) => {
      if (isEqual(tableSortState, sortState)) {
        return;
      }

      setSortState(tableSortState);
    },
    [sortState]
  );

  const renderTables = () => {
    if (tab === 'invoices') {
      return !isLoadedFirstPage ? (
        <StaleLoader size="large" />
      ) : (
        <InvoicesTableNew
          isVirtualized
          data={invoices}
          autoResetSelectedRows
          pastPerformancePerRecordInvoices={pastPerformancePerRecordInvoices}
          isLoadingPastPerformancePerRecordInvoices={
            isLoadingPastPerformancePerRecordInvoices
          }
          isLoadingMoreItems={isLoadingMoreInvoices}
          loadingThreshold={10}
          withInfiniteLoading
          hasMoreToLoad={hasMoreToLoad}
          onLoadMoreItems={
            isLoadingMoreInvoices
              ? async () => {}
              : async () => {
                  if (period && currency) {
                    fetchInvoices({
                      dateRange,
                      page: currentPage + 1,
                      entityCurrencyCode,
                      period,
                      currency,
                      isFirstPeriod: isFirstPeriod === 'true',
                      sortFields: getInvoicesSortFields(sortState),
                    });
                  }
                }
          }
          onSort={handleSortChange}
          manualSortBy
        />
      );
    }

    if (tab === 'prebookings') {
      return (
        <PrebookTable
          isVirtualized
          currencyCode={currency ?? undefined}
          onRowClick={onPrebookTableRowClick}
        />
      );
    }

    return null;
  };

  return (
    <>
      <Tabs />
      {renderTables()}
      <TransferPrebookPopups />
    </>
  );
};

export default DateRangeInfo;
