import useCodatAccounts from 'hooks/useCodatAccounts';
import useXeroAccounts from 'hooks/useXeroAccounts';
import {
  TPlatformNames,
  isXeroPlatform,
  isCodatPlatform,
} from 'types/integrations';

/**
 * Fetches accounts from either Codat or Xero.
 *
 * Does not support OrderWise.
 */
const useIntegrationAccounts = (platformName: TPlatformNames) => {
  const fetchXeroAccounts = isXeroPlatform(platformName);
  const fetchCodatAccounts = isCodatPlatform(platformName);

  const {
    isLoadingAccounts: isLoadingCodatAccounts,
    hasEntityCurrencyAccount: hasEntityCurrencyAccountCodat,
    codatAccounts,
    bankFeesAccounts: codatBankFeesAccounts,
    defaultBankFeesAccount: defaultCodatBankFeesAccount,
  } = useCodatAccounts(fetchCodatAccounts);

  const {
    isLoadingAccounts: isLoadingXeroAccounts,
    hasEntityCurrencyAccount: hasEntityCurrencyAccountXero,
    xeroAccounts,
    bankFeesAccounts: xeroBankFeesAccounts,
    defaultBankFeesAccount404: defaultXeroBankFeesAccount404,
  } = useXeroAccounts(fetchXeroAccounts);

  return fetchXeroAccounts
    ? {
        isLoadingAccounts: isLoadingXeroAccounts,
        hasEntityCurrencyAccount: hasEntityCurrencyAccountXero,
        accounts: xeroAccounts,
        bankFeesAccounts: xeroBankFeesAccounts,
        defaultBankFeesAccount: defaultXeroBankFeesAccount404,
      }
    : {
        isLoadingAccounts: isLoadingCodatAccounts,
        hasEntityCurrencyAccount: hasEntityCurrencyAccountCodat,
        accounts: codatAccounts,
        bankFeesAccounts: codatBankFeesAccounts,
        defaultBankFeesAccount: defaultCodatBankFeesAccount,
      };
};

export default useIntegrationAccounts;
