import { useEffect, useMemo, useState } from 'react';
import { getInvoicesFilters } from '../utils';
import { generateSearchQuery } from 'utils/search';
import { getInvoicesPaginated } from 'services/firebase/invoices';
import { get } from 'lodash';
import { errorHandler } from 'utils/errors';
import { useStoreState } from 'state';
import {
  isRateContractAvailable,
  isRateContractExpired,
  isRateContractUsed,
  notUndefinedTypeGuard,
} from 'utils';
import useUrlValues from 'hooks/useUrlValues';
import { ICurrency } from 'types';

interface IInvoicesCountsPerTabOrFilter {
  paid: number;
  approved: number;
  submitted: number;
  payables: number;
  overdue: number;
  dueIn14Days: number;
  outstanding: number;
  [key: string]: number;
}

interface IInvoicesCurrenciesPerTab {
  outstanding: string[];
  submitted: string[];
  approved: string[];
  paid: string[];
  [key: string]: string[];
}

export interface ITabsCounts extends IInvoicesCountsPerTabOrFilter {
  available: number;
  expired: number;
  used: number;
  prebookedFx: number;
  paymentRun: number;
}

export interface ICurrenciesPerTab extends IInvoicesCurrenciesPerTab {
  paymentRun: string[];
  prebookings: string[];
}

const useInvoicesPageCountsAndCurrenciesByTab = () => {
  const { currency } = useUrlValues('currency');
  const { rateContracts } = useStoreState((state) => state.RateContractsState);
  const { externalHedges } = useStoreState(
    (state) => state.ExternalHedgesState
  );
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const [
    invoicesCounts,
    setInvoicesCounts,
  ] = useState<IInvoicesCountsPerTabOrFilter>({
    paid: 0,
    approved: 0,
    submitted: 0,
    payables: 0,
    overdue: 0,
    dueIn14Days: 0,
    outstanding: 0,
  });
  const [
    invoicesCurrenciesPerTab,
    setInvoicesCurrenciesPerTab,
  ] = useState<IInvoicesCurrenciesPerTab>({
    outstanding: [],
    submitted: [],
    approved: [],
    paid: [],
  });

  useEffect(() => {
    const getInvoicesCounts = async (currencyCode: string | null) => {
      const searchQueryParams = {
        outstanding: {
          size: 1,
          facets: {
            currency: {
              type: 'value',
              size: 100,
            },
          },
          filters: getInvoicesFilters('outstanding', null, currencyCode),
        },
        payables: {
          size: 1,
          facets: {
            currency: {
              type: 'value',
              size: 100,
            },
          },
          filters: getInvoicesFilters('outstanding', 'payables', currencyCode),
        },
        overdue: {
          size: 1,
          facets: {
            currency: {
              type: 'value',
              size: 100,
            },
          },
          filters: getInvoicesFilters('outstanding', 'overdue', currencyCode),
        },
        dueIn14Days: {
          size: 1,
          facets: {
            currency: {
              type: 'value',
              size: 100,
            },
          },
          filters: getInvoicesFilters(
            'outstanding',
            'dueIn14Days',
            currencyCode
          ),
        },
        paid: {
          size: 1,
          facets: {
            currency: {
              type: 'value',
              size: 100,
            },
          },
          filters: getInvoicesFilters('paid', null, currencyCode),
        },
        submitted: {
          size: 1,
          facets: {
            currency: {
              type: 'value',
              size: 100,
            },
          },
          filters: getInvoicesFilters('submitted', null, currencyCode),
        },
        approved: {
          size: 1,
          facets: {
            currency: {
              type: 'value',
              size: 100,
            },
          },
          filters: getInvoicesFilters('approved', null, currencyCode),
        },
      } as const;

      const invoicesCountsPerTabOrFilter = await Promise.all(
        Object.entries(searchQueryParams).map(async ([queryKey, value]) => {
          const results = await getInvoicesPaginated(
            generateSearchQuery(value)
          );
          const currencyFacets = get(
            results,
            'data.data.facets.currency[0].data',
            [] as Array<{ value: string; count: number }>
          );
          const totalCount = currencyFacets.reduce<number>(
            (acc, item) => acc + item.count,
            0
          );

          return [queryKey, totalCount] as const;
        })
      );

      return invoicesCountsPerTabOrFilter.reduce<IInvoicesCountsPerTabOrFilter>(
        (acc, [key, value]) => {
          acc[key] = value;

          return acc;
        },
        {
          paid: 0,
          approved: 0,
          submitted: 0,
          payables: 0,
          overdue: 0,
          dueIn14Days: 0,
          outstanding: 0,
        }
      );
    };

    getInvoicesCounts(currency).then(setInvoicesCounts).catch(errorHandler);
  }, [currency]);

  useEffect(() => {
    const getInvoicesCurrencies = async (
      currencyByCode: (currencyCode: string) => ICurrency | null
    ) => {
      const searchQueryParams = {
        outstanding: {
          size: 1,
          facets: {
            currency: {
              type: 'value',
              size: 100,
            },
          },
          filters: getInvoicesFilters('outstanding', null, null),
        },
        payables: {
          size: 1,
          facets: {
            currency: {
              type: 'value',
              size: 100,
            },
          },
          filters: getInvoicesFilters('outstanding', 'payables', null),
        },
        overdue: {
          size: 1,
          facets: {
            currency: {
              type: 'value',
              size: 100,
            },
          },
          filters: getInvoicesFilters('outstanding', 'overdue', null),
        },
        dueIn14Days: {
          size: 1,
          facets: {
            currency: {
              type: 'value',
              size: 100,
            },
          },
          filters: getInvoicesFilters('outstanding', 'dueIn14Days', null),
        },
        paid: {
          size: 1,
          facets: {
            currency: {
              type: 'value',
              size: 100,
            },
          },
          filters: getInvoicesFilters('paid', null, null),
        },
        submitted: {
          size: 1,
          facets: {
            currency: {
              type: 'value',
              size: 100,
            },
          },
          filters: getInvoicesFilters('submitted', null, null),
        },
        approved: {
          size: 1,
          facets: {
            currency: {
              type: 'value',
              size: 100,
            },
          },
          filters: getInvoicesFilters('approved', null, null),
        },
      } as const;

      const invoicesCurrenciesPerTab = await Promise.all(
        Object.entries(searchQueryParams).map(async ([queryKey, value]) => {
          const results = await getInvoicesPaginated(
            generateSearchQuery(value)
          );
          const currencyFacets = get(
            results,
            'data.data.facets.currency[0].data',
            [] as Array<{ value: string; count: number }>
          );
          const currenciesSet = currencyFacets.reduce<Set<string>>(
            (acc, item) => acc.add(item.value),
            new Set()
          );
          const currencies = Array.from(currenciesSet);
          const enabledCurrencies = currencies.filter(
            (currency) => currencyByCode(currency)?.enabled
          );

          return [queryKey, enabledCurrencies] as const;
        })
      );

      return invoicesCurrenciesPerTab.reduce<IInvoicesCurrenciesPerTab>(
        (acc, [key, value]) => {
          acc[key] = value;

          return acc;
        },
        {
          paid: [],
          approved: [],
          submitted: [],
          outstanding: [],
        }
      );
    };

    getInvoicesCurrencies(currencyByCode)
      .then(setInvoicesCurrenciesPerTab)
      .catch(errorHandler);
  }, [currencyByCode]);

  const prebookingsAndExternalHedgesCounts = useMemo(
    () =>
      [...rateContracts, ...externalHedges].reduce<{
        available: number;
        expired: number;
        used: number;
        prebookedFx: number;
      }>(
        (acc, item) => {
          if (currency && currency !== 'all' && currency !== item.buyCurrency) {
            return acc;
          }

          acc.prebookedFx += 1;

          if (isRateContractAvailable(item)) {
            acc.available += 1;
          }

          if (isRateContractExpired(item)) {
            acc.expired += 1;
          }

          if (isRateContractUsed(item)) {
            acc.used += 1;
          }

          return acc;
        },
        {
          available: 0,
          expired: 0,
          used: 0,
          prebookedFx: 0,
        }
      ),
    [currency, externalHedges, rateContracts]
  );

  const hedgesCurrencies = new Set(
    [...rateContracts, ...externalHedges]
      .map((item) =>
        !!currencyByCode(item.buyCurrency)?.enabled
          ? item.buyCurrency
          : undefined
      )
      .filter(notUndefinedTypeGuard)
  );

  return {
    currenciesByTab: {
      prebookings: Array.from(hedgesCurrencies),
      ...invoicesCurrenciesPerTab,
      paymentRun: [],
    },
    counts: {
      ...invoicesCounts,
      ...prebookingsAndExternalHedgesCounts,
    },
  };
};

export default useInvoicesPageCountsAndCurrenciesByTab;
