import { FC, KeyboardEventHandler, useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useTheme } from 'styled-components';
import {
  Label,
  CreatableSelectMenuWrapper,
  CreatableSelectErrorMessage,
} from './CreatableSelectMenu.styles';
import { createCreatableSelectMenuOption } from './utils';
import { OptionsType } from 'react-select';
import { CreatableSelectMenuOption } from './types';

interface OwnProps {
  data?: CreatableSelectMenuOption[];
  name?: string;
  label?: string;
  disabled?: boolean;
  onChange: (value: OptionsType<CreatableSelectMenuOption>) => void;
  error?: string;
  onBlur?: () => void;
  onFocus?: () => void;
}

const CreatableSelectMenu: FC<OwnProps> = ({
  data = [],
  name,
  label,
  disabled,
  onChange,
  error,
  onBlur,
  onFocus,
}) => {
  const theme = useTheme();
  const [focused, setFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) {
      return;
    }

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault();
        event.stopPropagation();
        const value = inputValue.replace(/ /g, '');

        onChange([...data, createCreatableSelectMenuOption(value)]);
        setInputValue('');
    }
  };

  const onFocusHandler = () => {
    setFocused(true);
    onFocus?.();
  };
  const onBlurHandler = () => {
    setFocused(false);
    onBlur?.();
  };

  useEffect(() => {
    if (data.length) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [data]);

  return (
    <CreatableSelectMenuWrapper
      filled={isFilled}
      focused={focused}
      disabled={disabled}
    >
      {label && (
        <Label filled={isFilled} focused={focused} disabled={disabled}>
          {label}
        </Label>
      )}

      <CreatableSelect
        name={name}
        components={{
          DropdownIndicator: null,
        }}
        isMulti
        value={data}
        inputValue={inputValue}
        menuIsOpen={false}
        classNamePrefix="select"
        placeholder="Type and press enter"
        onInputChange={setInputValue}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
      />

      {error && (
        <CreatableSelectErrorMessage mt mtValue={theme.spacing.xxs}>
          {error}
        </CreatableSelectErrorMessage>
      )}
    </CreatableSelectMenuWrapper>
  );
};
export default CreatableSelectMenu;
