import useXero from 'hooks/useXero';
import { useCallback, useState } from 'react';
import * as Firebase from 'services/firebase';
import {
  signUpDetails,
  DecodedToken,
  newClientWithXero,
  saveXeroPermissions,
  saveXeroSettings,
} from 'services/firebase';

import { useStoreActions, useStoreState } from 'state';
import { IIntegrationPermissions, IXeroSettings } from 'types';
import { Notify } from 'utils';

const exchangeCodeForToken = async () => {
  try {
    const response = await signUpDetails(window.location.href);
    if (response?.data?.success && response.data.data) {
      return response.data.data;
    } else {
      throw new Error(response?.data?.message ?? '');
    }
  } catch (error: any) {
    throw new Error(error.response?.data?.error);
  }
};

const createNewClient = async (token: DecodedToken) => {
  // 1. create user and entity and connect to Xero
  const response = await newClientWithXero(token.tokenSet);

  if (!response.data.data) {
    throw new Error(response?.data?.message ?? '');
  }

  return response.data.data;
};

const setDefaultPermissionsAndSettings = async (entityCurrency: string) => {
  try {
    // no write permissions as default
    const permissions: IIntegrationPermissions = {
      global: false,
      invoices: false,
      payments: false,
      purchaseOrders: false,
      bills: false,
      bankFees: false,
      importInvoices: true,
      importBills: true,
      importPayments: true,
      importContactDetails: true,
      importPurchaseAndSalesOrders: false,
      importBankBalances: false,
    };

    const settings: IXeroSettings = {
      settingsType: 'Xero',
      monthsInThePastToQueryFor: 24,
      excludedCurrencies: [entityCurrency],
    };

    await Promise.all([
      saveXeroSettings({ settings }),
      saveXeroPermissions({ permissions }),
    ]);
  } catch (error: any) {
    throw new Error(error?.response.data?.message ?? '');
  }
};

const useNewClientXero = () => {
  const [isCreatingEntity, setIsCreatingEntity] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [companyName, setCompanyName] = useState<string>();
  const { userId, entityCurrencyCode } = useStoreState(
    ({ UserState }) => UserState
  );
  const { getUser } = useStoreActions(({ UserState }) => UserState);

  const { onImportData: onImportXeroData } = useXero();

  const createNewClientEntity = useCallback(async () => {
    try {
      setIsCreatingEntity(true);

      // 1. Swap url/code for a decoded token
      const decodedToken = await exchangeCodeForToken();

      // 2. Create the entity
      const { companyName, entityId } = await createNewClient(decodedToken);

      // 3. Update entity on user so future calls work
      await Firebase.updateUser({
        user: {
          entityId,
        },
      });

      // 4. Update entity details stored in FE state
      if (userId) {
        await getUser({ id: userId });
      }

      // 5. Set default settings
      await setDefaultPermissionsAndSettings(entityCurrencyCode);

      // 6. Kick of a data sync
      await onImportXeroData();

      setIsCompleted(true);
      setIsCreatingEntity(false);
      setCompanyName(companyName);
    } catch (error: any) {
      Notify.error(error.response?.data?.error ?? error?.message ?? error);
      setIsCreatingEntity(false);
      setIsError(true);
    }
  }, [entityCurrencyCode, getUser, onImportXeroData, userId]);

  return {
    createNewClientEntity,
    companyName,
    isCompleted,
    isCreatingEntity,
    isError,
  };
};

export default useNewClientXero;
