import { FC } from 'react';
import { useStoreState } from 'state';
import { getCashflowsPerCurrencyValues } from 'utils/analysis';
import { ICurrency, IRiskDashboardItem, Nullable } from 'types';
import { Col, Paragraph, Row, StaleLoader } from 'components';
import DirectionIcon from 'components/shared/DirectionIcon/DirectionIcon';
import { parseIntoShortNumberWithSymbol } from 'utils';
import NetworthInfoRow from './components/NetworthInfoRow/NetworthInfoRow';
import { NetWrapper } from './Networth.styles';
import NetworthSection from './components/NetworthSection/NetworthSection';

interface OwnProps {
  cashflowRisksByCurrency?: IRiskDashboardItem;
  currency: Nullable<ICurrency>;
}

const Networth: FC<OwnProps> = ({ currency, cashflowRisksByCurrency }) => {
  const { userEntity } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const {
    netExposure,
    netExposureInSellCurrency,
    payables,
    receivables,
    prebooked,
    balances,
    externalBalances,
    externalHedges,
    saleOrders,
    purchaseOrders,
  } = getCashflowsPerCurrencyValues(cashflowRisksByCurrency);
  const entityCurrencySymbol = currencyByCode(userEntity?.entityCurrency)
    ?.symbol;
  const isEntityCurrency = currency?.code === userEntity?.entityCurrency;

  return (
    <Col alignSelf="stretch" justifyContent="flex-start" flex={0.4}>
      {!cashflowRisksByCurrency ? (
        <StaleLoader size="large" />
      ) : (
        <>
          <NetworthSection
            zIndex={4}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetworthInfoRow
                title="Cash balance"
                amount={balances + externalBalances}
                currencySymbol={currency?.symbol}
                showDirection={false}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
              />
            )}
            expansionContent={
              <>
                <NetworthInfoRow
                  title="with HedgeFlows"
                  amount={balances}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                  showDirection={false}
                />
                <NetworthInfoRow
                  title="with 3d parties"
                  amount={externalBalances}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                  showDirection={false}
                />
              </>
            }
          />
          <NetworthSection
            zIndex={3}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetworthInfoRow
                title="APs/ARs"
                amount={payables + receivables}
                currencySymbol={currency?.symbol}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
              />
            )}
            expansionContent={
              <>
                <NetworthInfoRow
                  title="Payables"
                  amount={payables}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
                <NetworthInfoRow
                  title="Receivables"
                  amount={receivables}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
              </>
            }
          />
          {!isEntityCurrency && (
            <NetworthSection
              zIndex={2}
              renderContent={(setIsExpanded, isExpanded) => (
                <NetworthInfoRow
                  title="Forecasted CFs"
                  amount={saleOrders + purchaseOrders}
                  currencySymbol={currency?.symbol}
                  onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                  rotateArrow={isExpanded}
                />
              )}
              expansionContent={
                <>
                  <NetworthInfoRow
                    title="Sale Orders"
                    amount={saleOrders}
                    variant="grey"
                    currencySymbol={currency?.symbol}
                  />
                  <NetworthInfoRow
                    title="Purchase Orders"
                    amount={purchaseOrders}
                    variant="grey"
                    currencySymbol={currency?.symbol}
                  />
                </>
              }
            />
          )}
          <NetworthSection
            zIndex={1}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetworthInfoRow
                title="FX Hedges"
                amount={prebooked + externalHedges}
                currencySymbol={currency?.symbol}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
              />
            )}
            expansionContent={
              <>
                <NetworthInfoRow
                  title="with HedgeFlows"
                  amount={prebooked}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
                <NetworthInfoRow
                  title="with 3d parties"
                  amount={externalHedges}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
              </>
            }
          />
          <NetworthSection
            zIndex={0}
            renderContent={() => (
              <NetWrapper>
                <Row>
                  <Paragraph variant="bold">Net balance:</Paragraph>

                  <Row>
                    <DirectionIcon
                      direction={netExposure >= 0 ? 'in' : 'out'}
                    />
                    <Paragraph variant="bold">
                      {parseIntoShortNumberWithSymbol(
                        netExposure,
                        currency?.symbol
                      )}
                    </Paragraph>
                  </Row>
                </Row>
                {!isEntityCurrency && (
                  <Row>
                    <Paragraph>{userEntity.entityCurrency} value</Paragraph>
                    <Paragraph>
                      {parseIntoShortNumberWithSymbol(
                        netExposureInSellCurrency,
                        entityCurrencySymbol
                      )}
                    </Paragraph>
                  </Row>
                )}
              </NetWrapper>
            )}
          />
        </>
      )}
    </Col>
  );
};

export default Networth;
