import { FC, useState, Dispatch, SetStateAction } from 'react';
import dayjs from 'dayjs';
import cx from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { isMobile } from 'react-device-detect';

import {
  Paragraph,
  StaleInfo,
  StaleParagraphBold,
  StaleParagraphMedium,
  StaleTextHint,
} from 'components';
import { RateSelectWrapper } from './RateSelect.styles';
import { ICurrency, IRateContract, Nullable, RATE_TYPE } from 'types';
import {
  canUseRateContract,
  getFlexCurrentBestRate,
  getCountryCodeByCurrency,
  parseIntoCurrencyString,
  parseRateWithPrecision,
} from 'utils';
import { useStoreState } from 'state';
import Rate from 'components/shared/Rate/Rate';
import { UseCurrencyRateReturnValues } from 'hooks/useCurrencyRate';
import { useAllocatePrebooksPermissionsCheck } from 'hooks/useSpecificPermissionsCheck';

interface OwnProps {
  rateContracts: IRateContract[];
  selectedRateContract: Nullable<IRateContract>;
  sellCurrency: ICurrency;
  buyCurrency: ICurrency;
  rate: UseCurrencyRateReturnValues['rate'];
  rateType: RATE_TYPE;
  setSelectedRateContract: Dispatch<SetStateAction<Nullable<IRateContract>>>;
  setRateType: Dispatch<SetStateAction<RATE_TYPE>>;
  isRateLoading: boolean;
  invoiceId?: string | null;
}

const RateSelect: FC<OwnProps> = ({
  rateContracts,
  selectedRateContract,
  sellCurrency,
  buyCurrency,
  rate,
  rateType,
  isRateLoading,
  invoiceId,
  setSelectedRateContract,
  setRateType,
}) => {
  const [show, setShow] = useState(false);

  const { currencies } = useStoreState((state) => state.CurrenciesState);

  const hasAllocatePrebooksPermission = useAllocatePrebooksPermissionsCheck();

  const bestAvailableRateContract = rateContracts[0];

  const renderRateContractValue = () => {
    if (!rateContracts.length) {
      return 'None available';
    }

    if (selectedRateContract) {
      return `1 ${sellCurrency?.code} = ${getFlexCurrentBestRate(
        selectedRateContract,
        rate
      )} ${buyCurrency?.code}`;
    }

    if (bestAvailableRateContract) {
      return `1 ${sellCurrency?.code} = ${getFlexCurrentBestRate(
        bestAvailableRateContract,
        rate
      )} ${buyCurrency?.code}`;
    }

    return '';
  };

  const onSelectRateContract = (prebookedRate: IRateContract | null) => {
    if (!prebookedRate) {
      return;
    }

    setSelectedRateContract(prebookedRate);
    setRateType(RATE_TYPE.prebooked);

    setTimeout(() => {
      setShow(false);
    }, 300);
  };

  return (
    <RateSelectWrapper className={cx(show && 'show')}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setShow(false);
        }}
      >
        <div
          className="wrap"
          style={show ? { position: isMobile ? 'fixed' : 'absolute' } : {}}
        >
          <div className="wrap-card">
            <div
              onClick={() => {
                if (rateContracts.length) {
                  onSelectRateContract(bestAvailableRateContract);
                }
              }}
              className={cx(
                'card',
                rateType === RATE_TYPE.prebooked && 'card_active'
              )}
            >
              <StaleParagraphBold>Prebooked rate</StaleParagraphBold>

              <StaleTextHint>{renderRateContractValue()}</StaleTextHint>

              {rateContracts.length ? (
                <svg
                  onClick={(event) => {
                    event.stopPropagation();

                    setShow(!show);
                  }}
                  width="24px"
                  height="24px"
                  className="i-arrow"
                >
                  <use xlinkHref="#arrow-down" />
                </svg>
              ) : (
                <StaleInfo placement="bottom">
                  <Paragraph color="white">None available</Paragraph>
                </StaleInfo>
              )}
            </div>
            <div
              className={cx(
                'card',
                rateType === RATE_TYPE.market && 'card_active'
              )}
              onClick={() => {
                setShow(false);
                setRateType(RATE_TYPE.market);
                setSelectedRateContract(null);
              }}
            >
              <Paragraph>Current rate</Paragraph>

              <StaleTextHint>
                {`1 ${sellCurrency?.code} = `}
                <Rate rate={rate} />
                {`${rate && !isRateLoading ? buyCurrency?.code : ''}`}
              </StaleTextHint>
            </div>
          </div>
          {show && (
            <div className="select">
              {rateContracts.map((item) => {
                const isSelected = selectedRateContract?.id === item.id;
                const isDisabled = !canUseRateContract({
                  rateContract: item,
                  invoiceId,
                  hasAllocatePrebooksPermission,
                });

                return (
                  <div
                    key={item.id}
                    className={cx(
                      'checkbox',
                      isSelected && 'checked',
                      isDisabled && 'disabled'
                    )}
                    onClick={() => onSelectRateContract(item)}
                  >
                    <StaleParagraphMedium>
                      <div className="icon">
                        <svg width="15px" height="15px" className="from">
                          <use
                            xlinkHref={`#${getCountryCodeByCurrency(
                              item.sellCurrency,
                              currencies
                            )}`}
                          />
                        </svg>
                        <svg width="15px" height="15px" className="to">
                          <use
                            xlinkHref={`#${getCountryCodeByCurrency(
                              item.buyCurrency,
                              currencies
                            )}`}
                          />
                        </svg>
                      </div>
                      {`1 ${item.sellCurrency} = ${getFlexCurrentBestRate(
                        item,
                        rate
                      )} ${item.buyCurrency}`}
                      {item.flexFeeAmount !== undefined &&
                        ` (FLEX ${parseRateWithPrecision(item.rate)})`}
                    </StaleParagraphMedium>
                    <div className="row">
                      <StaleTextHint>{`${
                        buyCurrency?.symbol
                      }${parseIntoCurrencyString(
                        item.remainingBuyAmount,
                        buyCurrency.precision
                      )} available`}</StaleTextHint>
                      <StaleTextHint>{`use by ${dayjs(item.expiryDate).format(
                        'D MMM YYYY'
                      )}`}</StaleTextHint>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </RateSelectWrapper>
  );
};

export default RateSelect;
