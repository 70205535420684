import { FC, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useStoreActions, useStoreState } from 'state';
import { getReduceRiskRecommendationsPageLink } from 'utils/links';
import { getRiskLevelRatingText } from 'utils/analysis';
import useUrlValues from 'hooks/useUrlValues';
import useRiskRating from 'hooks/useRiskRating';
import { Paragraph, StaleInputSelect } from 'components';
import HexagonRating from 'components/shared/HexagonRating/HexagonRating';
import { getHexagonsStatus } from 'components/shared/HexagonRating/utils';
import Button from 'components/shared/Button/Button';
import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';
import Networth from '../Networth/Networth';
import { StyledCard, StyledRow } from './CashflowAtRisk.styles';

const CashflowAtRisk: FC = () => {
  const history = useHistory();
  const { entityId, entityCurrencyCode } = useStoreState(
    (state) => state.UserState
  );
  const { currencyByCode, buyCurrencies } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { cashflowsRisks, isRefreshingCashflowsRisksData } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { refreshCashflowsRisksData } = useStoreActions(
    (actions) => actions.ReferenceDataState
  );
  const { currency: currencyCode, setUrlValue } = useUrlValues('currency');
  const currency = currencyByCode(currencyCode);

  useEffect(() => {
    if (!currency) {
      const initialCurrency = cashflowsRisks
        ? Object.keys(cashflowsRisks.currencyRisk)[0]
        : 'USD';

      setUrlValue({ currency: initialCurrency });
    }
  }, [cashflowsRisks, currency, setUrlValue]);

  const selectData = useMemo(
    () =>
      cashflowsRisks
        ? Object.keys(cashflowsRisks.currencyRisk).reduce<ISelectItem[]>(
            (acc, itemCurrencyCode) => {
              if (
                buyCurrencies.find((curr) => curr.code === itemCurrencyCode)
              ) {
                acc.push({
                  id: itemCurrencyCode,
                  name: itemCurrencyCode,
                  value: itemCurrencyCode,
                  icon: currencyByCode(itemCurrencyCode)?.countryCode,
                });
              }

              return acc;
            },
            []
          )
        : [],
    [buyCurrencies, cashflowsRisks, currencyByCode]
  );
  const cashflowRisksByCurrency = currencyCode
    ? cashflowsRisks?.currencyRisk[currencyCode]
    : undefined;
  const { riskRating } = useRiskRating(cashflowRisksByCurrency?.cashflowAtRisk);
  const isEntityCurrency = entityCurrencyCode === currencyCode;

  const onRecalculate = async () => {
    if (!entityId || !entityCurrencyCode) {
      return;
    }

    await refreshCashflowsRisksData({
      entityId,
      sellCurrency: entityCurrencyCode,
    });
  };

  return (
    <StyledCard>
      <StyledRow>
        <StaleInputSelect
          selected={currency}
          data={selectData}
          onSelect={(item) => setUrlValue({ currency: item.value })}
          id="invoices-currency"
          style={{ minWidth: '132px' }}
          strategy="fixed"
        />
        {!isEntityCurrency && (
          <HexagonRating
            rating={riskRating}
            status={getHexagonsStatus(true, 'asc', riskRating)}
            title={`Risk: ${getRiskLevelRatingText(riskRating)}`}
            description="We track for you how close your currency exposures are to the risk tolerance limits you've set."
          />
        )}
      </StyledRow>

      <Networth
        currency={currency}
        cashflowRisksByCurrency={cashflowRisksByCurrency}
      />

      <StyledRow>
        <Button
          onClick={() =>
            history.push(
              getReduceRiskRecommendationsPageLink({
                currency: currencyCode || undefined,
              })
            )
          }
        >
          Reduce risks
        </Button>
        <Button
          isLoading={isRefreshingCashflowsRisksData}
          onClick={onRecalculate}
          variant="link"
          icon="refresh"
        >
          <Paragraph>Recalculate</Paragraph>
        </Button>
      </StyledRow>
    </StyledCard>
  );
};

export default CashflowAtRisk;
