import {
  FC,
  useMemo,
  useCallback,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import Table from '../Table/Table';
import { useStoreState } from 'state';
import { IInvoiceForTable, TInvoiceTableFilterValue } from './types';
import { isInvoiceDisabled, isInvoiceRowSelectable } from 'utils/invoices';
import InvoicesTableFooterContent from './components/InvoicesTableFooterContent/InvoicesTableFooterContent';
import { filterTypes, generateTableColumns } from './tableColumnsGenerator';
import Popups from './components/Popups/Popups';
import useInvoicePopups from './hooks/useInvoicePopups';
import useUrlValues from 'hooks/useUrlValues';
import useTableFiltering from 'hooks/useTableFiltering';
import { IPastPerformanceItemInvoice } from 'types';
import useFindAccountingIntegration from 'hooks/useFindAccountingIntegration';
import { TableProps } from '../Table/types';

type ITablePropsToPick =
  | 'isVirtualized'
  | 'data'
  | 'renderFooterContent'
  | 'selectable'
  | 'autoResetSelectedRows';

interface OwnProps
  extends Pick<TableProps<IInvoiceForTable>, ITablePropsToPick> {
  showFooter?: boolean;
  renderActionCell?: (invoice: IInvoiceForTable) => ReactNode;
  pastPerformancePerRecordInvoices?: IPastPerformanceItemInvoice[];
  isLoadingPastPerformancePerRecordInvoices?: boolean;
}

const InvoicesTable: FC<OwnProps> = ({
  data,
  isVirtualized = true,
  autoResetSelectedRows = false,
  renderFooterContent,
  showFooter,
  selectable,
  renderActionCell,
  pastPerformancePerRecordInvoices,
  isLoadingPastPerformancePerRecordInvoices,
}) => {
  const invoicePopupsHandlers = useInvoicePopups();
  const { filter: filterFromUrl, currency, search, tab } = useUrlValues(
    'filter',
    'currency',
    'search',
    'tab'
  );
  const { setSearchValue, setFilter, tableRef } = useTableFiltering<
    TInvoiceTableFilterValue,
    IInvoiceForTable
  >({
    filterTypeName: 'direction',
    initialFilterValue:
      (filterFromUrl as TInvoiceTableFilterValue) ?? 'default',
  });
  const accountingIntegration = useFindAccountingIntegration();

  useEffect(() => {
    setFilter(filterFromUrl as TInvoiceTableFilterValue);
  }, [filterFromUrl, setFilter]);

  useEffect(() => {
    setSearchValue(search ?? '');
  }, [search, setSearchValue]);

  const {
    setExistingInvoiceTracking,
    setInvoiceDecide,
    setCancelPrebookInvoice,
    setExistingPrebookInvoice,
    setShowAllDecideFields,
    setContactForEdit,
    setInvoiceForAddContact,
    setShowInvoiceDelete,
    setShowPurchaseOrderPaymentsOnInvoice,
  } = invoicePopupsHandlers;

  const { recipientById } = useStoreState((state) => state.RecipientsState);
  const {
    isAutomationPackageEnabled,
    isFxManagementPackageEnabled,
    entityCurrencyCode,
  } = useStoreState(({ UserState }) => UserState);
  const [selectedInvoices, setSelectedInvoices] = useState<IInvoiceForTable[]>(
    []
  );
  const isPaymentRunsEnabled =
    isAutomationPackageEnabled || isFxManagementPackageEnabled;
  const onSelectInvoice = useCallback(
    (_, rows) => {
      const invoicesToSelect: IInvoiceForTable[] = rows.map(
        (row: any) => row.original
      );
      setSelectedInvoices(invoicesToSelect);
    },
    [setSelectedInvoices]
  );

  const showFooterInternal = showFooter ?? selectedInvoices.length > 0;

  const {
    selectedInvoicesTotalAmount,
    selectedInvoicesTotalBookingCost,
  } = useMemo(
    () =>
      selectedInvoices.reduce(
        (total, value) => {
          return {
            selectedInvoicesTotalAmount:
              total.selectedInvoicesTotalAmount + value.totalAmount,
            selectedInvoicesTotalBookingCost:
              total.selectedInvoicesTotalBookingCost + (value.prebookFee ?? 0),
          };
        },
        { selectedInvoicesTotalAmount: 0, selectedInvoicesTotalBookingCost: 0 }
      ),
    [selectedInvoices]
  );

  const tableColumns = useMemo(
    () =>
      generateTableColumns({
        setExistingInvoiceTracking,
        setInvoiceDecide,
        setShowAllDecideFields,
        setCancelPrebookInvoice,
        setExistingPrebookInvoice,
        setContactForEdit,
        recipientById,
        setInvoiceForAddContact,
        setShowInvoiceDelete,
        setShowPurchaseOrderPaymentsOnInvoice,
        renderActionCell,
        tab,
        pastPerformancePerRecordInvoices,
        isLoadingPastPerformancePerRecordInvoices,
        accountingIntegration,
        entityCurrencyCode,
      }),
    [
      recipientById,
      renderActionCell,
      setCancelPrebookInvoice,
      setContactForEdit,
      setExistingInvoiceTracking,
      setExistingPrebookInvoice,
      setInvoiceDecide,
      setInvoiceForAddContact,
      setShowAllDecideFields,
      setShowInvoiceDelete,
      setShowPurchaseOrderPaymentsOnInvoice,
      tab,
      pastPerformancePerRecordInvoices,
      isLoadingPastPerformancePerRecordInvoices,
      accountingIntegration,
      entityCurrencyCode,
    ]
  );

  return (
    <>
      <Table<IInvoiceForTable>
        autoResetGlobalFilter={false}
        autoResetSortBy={false}
        autoResetFilters={false}
        autoResetSelectedRows={autoResetSelectedRows}
        ref={tableRef}
        data={data}
        filterTypes={filterTypes}
        isRowDisabled={isInvoiceDisabled}
        initialState={{
          filters: [{ id: 'direction', value: filterFromUrl ?? 'default' }],
        }}
        sortable
        globalFilter="text"
        withSelectAll={isPaymentRunsEnabled}
        selectable={selectable ?? isPaymentRunsEnabled}
        isRowSelectable={({ row: { original: record } }) =>
          isInvoiceRowSelectable(record)
        }
        onSelect={onSelectInvoice}
        disabledCheckboxHint={`Invoice is disabled, receivable or has no valid contact`}
        columns={tableColumns}
        renderFooterContent={
          showFooterInternal
            ? renderFooterContent ?? (
                <InvoicesTableFooterContent
                  selectedInvoices={selectedInvoices}
                />
              )
            : null
        }
        isVirtualized={isVirtualized}
      />
      <Popups
        {...invoicePopupsHandlers}
        selectedInvoices={selectedInvoices}
        invoicesCurrency={currency ?? undefined}
        invoicesTotalAmount={selectedInvoicesTotalAmount}
        invoicesTotalBookingCost={selectedInvoicesTotalBookingCost}
      />
    </>
  );
};

export default InvoicesTable;
