import { useEffect, useState } from 'react';
import { Firebase } from 'services';
import { RECIPIENT_STATUS, IRecipient, IInvoiceFromSearch } from 'types';

const useGetXeroInvoiceFromSearchContact = (invoice?: IInvoiceFromSearch) => {
  const [
    recipientFromInvoice,
    setRecipientFromInvoice,
  ] = useState<IRecipient>();

  const [isLoadingXeroInvoice, setIsLoadingXeroInvoice] = useState(
    !!(
      !invoice?.contactId &&
      invoice?.externalRefsXeroContactId &&
      invoice.externalRefsXeroId
    )
  );

  useEffect(() => {
    const getXeroInvoiceContact = async () => {
      try {
        if (
          !invoice?.contactId &&
          invoice?.externalRefsXeroContactId &&
          invoice.externalRefsXeroId
        ) {
          setIsLoadingXeroInvoice(true);
          const invoiceFromXero = await Firebase.getXeroInvoice(
            invoice.externalRefsXeroId
          );
          const contact = {
            ...invoiceFromXero?.contact,
            status: RECIPIENT_STATUS.draft,
            externalRefs: { xeroId: invoice?.externalRefsXeroContactId },
          };

          // TODO: check types
          // @ts-expect-error TS(2345) FIXME: Argument of type '{ status: RECIPIENT_STATUS; exte... Remove this comment to see the full error message
          setRecipientFromInvoice(contact);
          setIsLoadingXeroInvoice(false);
        }
      } catch (error: any) {
        console.warn(error);
      } finally {
        setIsLoadingXeroInvoice(false);
      }
    };

    getXeroInvoiceContact();
  }, [invoice]);

  return {
    isLoadingXeroInvoice,
    recipientFromInvoice,
  };
};

export default useGetXeroInvoiceFromSearchContact;
