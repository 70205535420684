import { FC } from 'react';

import { StaleInputConvert, Subtitle } from 'components';
import TransferSummary from '../TransferSummary/TransferSummary';
import useCurrencies from 'hooks/useCurrencies';
import { useQuery } from 'hooks';
import useAmounts from 'hooks/useAmounts';
import { useStoreState } from 'state';
import { FirstStepValues } from 'pages/SimpleTransfer/SimpleTransfer';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import Button from 'components/shared/Button/Button';

interface OwnProps {
  onContinue: (values: FirstStepValues) => void;
}

const Amounts: FC<OwnProps> = ({ onContinue }) => {
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { sellCurrencies } = useStoreState((state) => state.CurrenciesState);
  const { balanceByCurrencyCode } = useStoreState(
    (state) => state.BalancesState
  );

  const URLQuery = useQuery();
  const predefinedCurrency = URLQuery.get('predefinedCurrency');
  const predefinedAmount = URLQuery.get('predefinedAmount');

  const { sellCurrency, updateSellCurrency } = useCurrencies({
    predefinedSellCurrency: predefinedCurrency,
    isForTransfer: true,
  });

  const sellCurrencyBalance =
    balanceByCurrencyCode(sellCurrency.code)?.amount ?? 0;

  const { sellAmountAsNumber, updateSellAmount } = useAmounts({
    rate: 1,
    predefinedSellAmount: predefinedAmount || sellCurrencyBalance.toString(),
    defaultAmount: systemVariables?.transferDefaultAmount,
  });

  const getErrorMessage = () => {
    if (sellAmountAsNumber === 0) {
      return 'Amount should be greater than 0';
    }

    if (sellAmountAsNumber > sellCurrencyBalance) {
      return 'Amount can not be greater than your balance';
    }
  };

  return (
    <FlowStepWrapper>
      <FlowStepContent>
        <FlowContentLeft>
          <Subtitle variant="bold" style={{ marginBottom: 6 }}>
            Amount
          </Subtitle>

          <StaleInputConvert
            id="sellAmount"
            value={sellAmountAsNumber}
            onChange={updateSellAmount}
            currencies={sellCurrencies}
            selectedValue={sellCurrency}
            onSelect={updateSellCurrency}
            error={getErrorMessage()}
          />
        </FlowContentLeft>

        <FlowContentRight>
          <Subtitle variant="bold">Summary</Subtitle>

          <TransferSummary
            sellCurrency={sellCurrency}
            sellAmount={sellAmountAsNumber}
            sellCurrencyBalance={sellCurrencyBalance}
          />
        </FlowContentRight>
      </FlowStepContent>

      <FlowStepFooter>
        <Button
          disabled={!!getErrorMessage()}
          onClick={() =>
            onContinue({
              sellAmount: sellAmountAsNumber,
              sellCurrency,
            })
          }
        >
          Continue
        </Button>
      </FlowStepFooter>
    </FlowStepWrapper>
  );
};

export default Amounts;
