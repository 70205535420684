// TDOO: rework this component and make logic more uniform, too many similar props now
import {
  AddContact,
  StaleButtonsPopup,
  CancellingBalance,
  StaleXeroReauthenticatePopup,
} from 'components';
import useXero from 'hooks/useXero';
import { IInvoicesByCurrency } from 'components/shared/InvoicesTableNew/types';
import { Dispatch, FC, SetStateAction } from 'react';
import { useStoreState, useStoreActions } from 'state';
import { IContact, IInvoiceFromSearch, TRANSFER_TYPE } from 'types';
import { Notify } from 'utils';
import PopupTracking from './PopupTracking/PopupTracking';
import UseExistingPrebook from './UseExistingPrebook/UseExistingPrebook';
import DecidePopup from './Decide';
import PrebookByIds from './PrebookByIds';
import PrePaymentsOnPurchaseOrder from './PrePaymentsOnPurchaseOrder/PrePaymentsOnPurchaseOrder';
import SelectExistingContact from './SelectExistingContact';

interface OwnProps {
  changeTargetInvoice: IInvoiceFromSearch | null;
  setChangeTargetInvoice: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  existingInvoiceTracking: IInvoiceFromSearch | null;
  setExistingInvoiceTracking: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
  invoiceDecide: IInvoiceFromSearch | null;
  setInvoiceDecide: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  showAllDecideFields: boolean;
  setShowAllDecideFields: Dispatch<SetStateAction<boolean>>;
  cancelPrebookInvoice: IInvoiceFromSearch | null;
  setCancelPrebookInvoice: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  existingPrebookInvoice: IInvoiceFromSearch | null;
  setExistingPrebookInvoice: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
  contactForEdit: IContact | null;
  setContactForEdit: Dispatch<SetStateAction<IContact | null>>;
  invoiceForAddContact: IInvoiceFromSearch | null;
  setInvoiceForAddContact: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  showInvoiceDelete: IInvoiceFromSearch | null;
  setShowInvoiceDelete: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  showPurchaseOrderPaymentsOnInvoice: IInvoiceFromSearch | null;
  setShowPurchaseOrderPaymentsOnInvoice: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
  showBulkPrebook: boolean;
  setShowBulkPrebook: Dispatch<SetStateAction<boolean>>;
  selectedInvoices?: IInvoiceFromSearch[];
  invoicesCurrency?: IInvoicesByCurrency['currencyCode'];
  invoicesTotalAmount?: number;
  invoicesTotalBookingCost?: number;
  updateInMemoryInvoices?: (invoiceId: string, contact: IContact) => void;
}

const Popups: FC<OwnProps> = ({
  changeTargetInvoice,
  setChangeTargetInvoice,
  existingInvoiceTracking,
  setExistingInvoiceTracking,
  invoiceDecide,
  setInvoiceDecide,
  showAllDecideFields,
  setShowAllDecideFields,
  cancelPrebookInvoice,
  setCancelPrebookInvoice,
  existingPrebookInvoice,
  setExistingPrebookInvoice,
  contactForEdit,
  setContactForEdit,
  invoiceForAddContact,
  setInvoiceForAddContact,
  showInvoiceDelete,
  setShowInvoiceDelete,
  showPurchaseOrderPaymentsOnInvoice,
  setShowPurchaseOrderPaymentsOnInvoice,
  showBulkPrebook,
  setShowBulkPrebook,
  selectedInvoices,
  invoicesCurrency,
  invoicesTotalAmount,
  invoicesTotalBookingCost,
  updateInMemoryInvoices,
}) => {
  const { reauthenticate } = useXero();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { deleteInvoice } = useStoreActions((state) => state.InvoicesState);

  const onDeleteInvoice = async () => {
    try {
      if (!showInvoiceDelete) {
        return;
      }

      const data = await deleteInvoice({
        invoiceId: showInvoiceDelete.id,
      });

      if (data && data.success) {
        Notify.success('Invoice deleted');
      } else {
        Notify.error(data?.message || 'Failed to delete Invoice');
      }
    } catch (error: any) {
      Notify.error('Failed to delete Invoice');
      console.warn(error);
    } finally {
      setShowInvoiceDelete(null);
    }
  };

  const initialCurrency = currencyByCode(contactForEdit?.currency);
  const sellCurrency = currencyByCode(systemVariables?.defaultSellCurrency);

  return (
    <>
      {!!contactForEdit && initialCurrency && sellCurrency && (
        <AddContact
          onClose={() => {
            setContactForEdit(null);
          }}
          withDraftWarning
          recipientForEdit={contactForEdit}
          initialCurrency={initialCurrency}
          sellCurrency={sellCurrency}
          initialTransferType={
            contactForEdit.paymentType === 'swift'
              ? TRANSFER_TYPE.priority
              : TRANSFER_TYPE.regular
          }
          onContinue={() => {
            setContactForEdit(null);
          }}
        />
      )}
      <StaleXeroReauthenticatePopup />
      {existingInvoiceTracking && !changeTargetInvoice && !reauthenticate && (
        <PopupTracking
          invoice={existingInvoiceTracking}
          onChangeTarget={setChangeTargetInvoice}
          onClose={() => setExistingInvoiceTracking(null)}
        />
      )}
      {changeTargetInvoice && !reauthenticate && (
        <DecidePopup
          invoice={changeTargetInvoice}
          isEdit
          onClose={() => setChangeTargetInvoice(null)}
        />
      )}
      {invoiceDecide && (
        <DecidePopup
          invoice={invoiceDecide}
          onClose={() => {
            setInvoiceDecide(null);
            setShowAllDecideFields(false);
          }}
          showAllFields={showAllDecideFields}
          setShowAllDecideFields={setShowAllDecideFields}
        />
      )}
      {cancelPrebookInvoice && cancelPrebookInvoice.contractId && (
        <CancellingBalance onClose={() => setCancelPrebookInvoice(null)} />
      )}
      {showPurchaseOrderPaymentsOnInvoice && (
        <PrePaymentsOnPurchaseOrder
          invoice={showPurchaseOrderPaymentsOnInvoice}
          onClose={() => setShowPurchaseOrderPaymentsOnInvoice(null)}
          // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<IContact | null>>' i... Remove this comment to see the full error message
          setContactForEdit={setContactForEdit}
          setInvoiceForAddContact={setInvoiceForAddContact}
        />
      )}

      {!!existingPrebookInvoice && (
        <UseExistingPrebook
          invoice={existingPrebookInvoice}
          sellCurrency={entityCurrencyCode}
          onClose={() => setExistingPrebookInvoice(null)}
        />
      )}

      {!!invoiceForAddContact && (
        <SelectExistingContact
          invoice={invoiceForAddContact}
          updateInMemoryInvoices={updateInMemoryInvoices}
          onClose={() => setInvoiceForAddContact(null)}
        />
      )}

      {!!showInvoiceDelete && (
        <StaleButtonsPopup
          title="Delete invoice?"
          text="Please confirm that you want to delete this invoice."
          mainButtonText="Yes, delete"
          secondaryButtonText="Cancel"
          width="347px"
          onClose={() => {
            setShowInvoiceDelete(null);
          }}
          onContinue={() => {
            onDeleteInvoice();
          }}
        />
      )}

      {showBulkPrebook && (
        <PrebookByIds
          selectedInvoices={selectedInvoices ?? []}
          invoicesCurrency={invoicesCurrency}
          invoicesTotalAmount={invoicesTotalAmount}
          invoicesTotalBookingCost={invoicesTotalBookingCost}
          onClose={() => {
            setShowBulkPrebook(false);
          }}
        />
      )}
    </>
  );
};

export default Popups;
