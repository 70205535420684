import { FC, useMemo, useState } from 'react';
import { Wrapper } from './InvoiceInfo.styles';
import {
  StaleTitleH3,
  StaleTitleH5,
  StaleSubTitleMedium,
  StaleParagraphMedium,
  StaleInput,
  Row,
  StalePrebookRateNotification,
  StaleLimitedAccess,
  Col,
  Paragraph,
} from 'components';
import { useForm } from 'react-hook-form';
import { useStoreState } from 'state';
import dayjs from 'dayjs';
import { firstCharacterToUppercase, parseIntoCurrencyString } from 'utils';
import { CONTRACT_STATUS, IInvoice, INVOICE_STATUSES } from 'types';
import _orderBy from 'lodash.orderby';
import useCurrencyRate from 'hooks/useCurrencyRate';
import InvoiceRate from './components/InvoiceRate/InvoiceRate';
import Attachments from './components/Attachments/Attachments';
import InvoiceLastSyncDetails from './components/InvoiceLastSyncDetails/InvoiceLastSyncDetails';
import useInvoiceRecord from 'hooks/useInvoiceRecord';
import { getInvoiceNumber, isPayableInvoice } from 'utils/invoices';
import { useTheme } from 'styled-components';
import useInvoicePrebookAndTransfer from 'components/shared/InvoicesTableNew/hooks/useInvoicePrebookAndTransfer';
import InvoiceDecide from 'components/shared/InvoicesTable/components/Popups/Decide';
import UseExistingPrebook from 'components/shared/InvoicesTable/components/Popups/UseExistingPrebook/UseExistingPrebook';
import Controls from './components/Controls/Controls';

interface OwnProps {
  invoice: IInvoice;
}

const InvoiceInfo: FC<OwnProps> = ({ invoice }) => {
  const theme = useTheme();
  const { isCanBePaid, isPayable } = useInvoiceRecord({
    record: invoice,
  });
  const { hasApprovalFlow, entityCurrencyCode } = useStoreState(
    ({ UserState }) => UserState
  );
  const { control } = useForm();
  const { rate } = useCurrencyRate({
    buyCurrency: isPayable ? invoice.currency : entityCurrencyCode,
    sellCurrency: isPayable ? entityCurrencyCode : invoice.currency,
  });
  const {
    showLimitedAccess,
    setShowLimitedAccessFalse,
  } = useInvoicePrebookAndTransfer();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { rateContractById, rateContractsByCurrencyPair } = useStoreState(
    (state) => state.RateContractsState
  );
  const { transferById } = useStoreState((state) => state.TransfersState);
  const [showInvoiceDecide, setShowInvoiceDecide] = useState(false);
  const [existingPrebookCurrency, setExistingPrebookCurrency] = useState<
    string | null
  >(null);
  const invoiceContact = invoice?.contact;
  const currency = currencyByCode(invoice.currency);
  const prebookedRateContract = rateContractById(invoice.contractId);
  const invoiceTransferIds = invoice.transferIds ?? [];
  const invoiceTransfers = invoiceTransferIds.map(transferById);
  const entityCurrency = currencyByCode(entityCurrencyCode);

  const availablePrebookedRate = useMemo(
    () =>
      _orderBy(
        rateContractsByCurrencyPair(
          entityCurrencyCode,
          invoice.currency
        ).filter(
          (item) =>
            item.remainingBuyAmount >= (invoice.amountDue ?? 0) &&
            item.status === CONTRACT_STATUS.readyToUse &&
            dayjs(item.expiryDate).isAfter(dayjs().subtract(1, 'day'))
        ),
        'rate',
        'desc'
      )[0],
    [
      entityCurrencyCode,
      invoice.amountDue,
      invoice.currency,
      rateContractsByCurrencyPair,
    ]
  );

  const getInvoiceContactBillingAddress = () => {
    let address = '';
    if (invoiceContact?.recipientAddress) {
      address += invoiceContact?.recipientAddress;
    }
    if (invoiceContact?.recipientPostcode) {
      address += ` ${invoiceContact?.recipientPostcode}`;
    }
    if (invoiceContact?.recipientCity) {
      address += ` ${invoiceContact?.recipientCity}`;
    }

    return `${address.trim()} `;
  };

  return (
    <>
      <Wrapper>
        <div className="head">
          <StaleTitleH3>Invoice {getInvoiceNumber(invoice)}</StaleTitleH3>

          <StaleSubTitleMedium className="status">
            {hasApprovalFlow &&
            invoice.status !== 'PAID' &&
            isPayableInvoice(invoice)
              ? invoice.approval?.status
                ? firstCharacterToUppercase(invoice.approval?.status)
                : 'Needs approval'
              : invoice.excludeFromRisk
              ? 'EXCLUDED'
              : invoice.status}
          </StaleSubTitleMedium>
        </div>

        <div className="content">
          <InvoiceLastSyncDetails invoice={invoice} />
          <Paragraph mb variant="bold">
            Bill to
          </Paragraph>

          <Row columnGap={theme.spacing.m}>
            <Col
              alignSelf="stretch"
              style={{
                width: 320,
              }}
            >
              <div className="field">
                <StaleInput
                  id="company"
                  name="company"
                  label="Company"
                  view="moving"
                  disabled
                  defaultValue={invoiceContact?.recipientName}
                  control={control}
                />
              </div>

              <div className="field">
                <StaleInput
                  id="email"
                  name="email"
                  label="Email"
                  view="moving"
                  disabled
                  defaultValue={
                    invoiceContact?.recipientEmail || 'Not specified'
                  }
                  control={control}
                />
              </div>

              <div className="field">
                <StaleInput
                  id="billingAddress"
                  name="billingAddress"
                  label="Billing address"
                  view="moving"
                  disabled
                  defaultValue={getInvoiceContactBillingAddress()}
                  control={control}
                />
              </div>
            </Col>

            <Col flex={1}>
              <Row
                style={{
                  flex: 1,
                  alignItems: 'flex-start',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 204,
                  }}
                >
                  <div className="field">
                    <StaleInput
                      id="invoiceDate"
                      name="invoiceDate"
                      label="Invoice Date"
                      view="moving"
                      disabled
                      defaultValue={dayjs(invoice.date).format('D MMM YYYY')}
                      control={control}
                    />
                  </div>
                  <div className="field">
                    <StaleInput
                      id="dueDate"
                      name="dueDate"
                      label="Due Date"
                      view="moving"
                      disabled
                      defaultValue={dayjs(invoice.dueDate).format('D MMM YYYY')}
                      control={control}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <div className="row-info">
                    <StaleParagraphMedium>
                      {`Invoice currency: `}
                      <svg
                        width="24px"
                        height="24px"
                        style={{ marginRight: '8px' }}
                      >
                        <use xlinkHref={`#${currency?.countryCode}`} />
                      </svg>
                      {invoice.currency}
                    </StaleParagraphMedium>
                  </div>

                  {invoiceTransfers.map((transfer) => (
                    <InvoiceRate
                      prebookedRateContract={prebookedRateContract}
                      currency={invoice.currency}
                      invoiceType={invoice.type}
                      transfer={transfer}
                      rate={rate}
                    />
                  ))}

                  {invoice?.status === INVOICE_STATUSES.partiallyPaid && (
                    <div className="row-info">
                      <StaleParagraphMedium>Due amount:</StaleParagraphMedium>
                      <StaleTitleH5>{` ${
                        currency?.symbol
                      }${parseIntoCurrencyString(
                        invoice.amountDue
                      )}`}</StaleTitleH5>
                    </div>
                  )}

                  <div className="row-info">
                    <StaleParagraphMedium>Total:</StaleParagraphMedium>
                    <StaleTitleH5>{` ${
                      currency?.symbol
                    }${parseIntoCurrencyString(
                      invoice.totalAmount
                    )}`}</StaleTitleH5>
                  </div>
                </div>
              </Row>

              {!invoice.contractId &&
                !!availablePrebookedRate &&
                isPayable &&
                isCanBePaid && (
                  <StalePrebookRateNotification
                    position="left"
                    amount={invoice.amountDue || invoice.totalAmount}
                    buyCurrency={currency}
                    sellCurrency={{
                      code: entityCurrencyCode,
                      symbol: entityCurrency?.symbol,
                    }}
                    onSubmit={() =>
                      setExistingPrebookCurrency(invoice.currency)
                    }
                  />
                )}
            </Col>
          </Row>

          <table className="invoice-table">
            <thead>
              <tr>
                <th>
                  <StaleParagraphMedium>#</StaleParagraphMedium>
                </th>
                <th>
                  <StaleParagraphMedium>Description</StaleParagraphMedium>
                </th>
                <th>
                  <StaleParagraphMedium>Quantity</StaleParagraphMedium>
                </th>
                <th>
                  <StaleParagraphMedium>Rate</StaleParagraphMedium>
                </th>
                <th>
                  <StaleParagraphMedium>Amount</StaleParagraphMedium>
                </th>
              </tr>
            </thead>

            <tbody>
              {invoice.lineItems?.map((item, index) => {
                return (
                  <tr key={item.itemCode}>
                    <td>
                      <div className="field-bg">
                        <StaleParagraphMedium>{index + 1}</StaleParagraphMedium>
                      </div>
                    </td>
                    <td>
                      <div className="field-bg">
                        <StaleParagraphMedium>
                          {item.description || item.name}
                        </StaleParagraphMedium>
                      </div>
                    </td>
                    <td>
                      <div className="field-bg">
                        <StaleParagraphMedium>
                          {item.quantity}
                        </StaleParagraphMedium>
                      </div>
                    </td>
                    <td>
                      <div className="field-bg">
                        <StaleParagraphMedium>
                          {parseIntoCurrencyString(
                            item.unitAmount,
                            currency?.precision
                          )}
                        </StaleParagraphMedium>
                      </div>
                    </td>
                    <td>
                      <StaleParagraphMedium>{`${
                        currency?.symbol
                      }${parseIntoCurrencyString(
                        item.lineAmount,
                        currency?.precision
                      )}`}</StaleParagraphMedium>
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td colSpan={1} rowSpan={2}>
                  <Attachments invoice={invoice} />
                </td>
                <td colSpan={3} style={{ paddingRight: '24px' }}>
                  <StaleParagraphMedium>Subtotal:</StaleParagraphMedium>
                </td>
                <td>
                  <StaleParagraphMedium>{`${
                    currency?.symbol
                  }${parseIntoCurrencyString(
                    invoice.subTotalAmount ?? invoice.totalAmount
                  )}`}</StaleParagraphMedium>
                </td>
              </tr>

              <tr>
                <td colSpan={3} style={{ paddingRight: '24px' }}>
                  <StaleParagraphMedium>Taxes:</StaleParagraphMedium>
                </td>
                <td>
                  <StaleParagraphMedium>{`${
                    currency?.symbol
                  }${parseIntoCurrencyString(
                    invoice.totalTaxAmount ?? 0
                  )}`}</StaleParagraphMedium>
                </td>
              </tr>
            </tbody>
          </table>

          <Controls invoice={invoice} rate={rate} />
        </div>
      </Wrapper>

      {showInvoiceDecide && (
        <InvoiceDecide
          invoice={invoice}
          onClose={() => setShowInvoiceDecide(false)}
          showAllFields={true}
        />
      )}

      {!!existingPrebookCurrency && (
        <UseExistingPrebook
          invoice={invoice}
          sellCurrency={entityCurrencyCode}
          onClose={() => setExistingPrebookCurrency(null)}
        />
      )}
      {showLimitedAccess && (
        <StaleLimitedAccess onClose={setShowLimitedAccessFalse} />
      )}
    </>
  );
};
export default InvoiceInfo;
