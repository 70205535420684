import { errorHandler } from './errors';

interface IFetchDataInsideUseEffectProps<T> {
  fetchData: () => Promise<T>;
  setData: (data: T) => void;
  setIsLoading: (loading: boolean) => void;
}

export const fetchDataInsideUseEffect = async <T extends any>({
  fetchData,
  setData,
  setIsLoading,
}: IFetchDataInsideUseEffectProps<T>) => {
  try {
    setIsLoading(true);
    const response = await fetchData();
    setData(response);
  } catch (error: any) {
    errorHandler(error.message);
  } finally {
    setIsLoading(false);
  }
};
