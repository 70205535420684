import { InlineLoader } from 'components';
import { useEffect, useState } from 'react';
import { getRatings } from 'services/firebase';
import { IRatings } from 'types';
import { UseCurrencyRateReturnValues } from './useCurrencyRate';

const RATINGS_TEXT = [
  <>
    Rates are <strong>very expensive</strong> compared to the last 90 days.
  </>,
  <>
    Rates are <strong>expensive</strong> compared to the last 90 days.
  </>,
  <>
    Rates are <strong>close to the average</strong> of the last 90 days.
  </>,
  <>
    Rates are <strong>good</strong> compared to the last 90 days.
  </>,
  <>
    Rates are <strong>close to their best</strong> over the last 90 days.
  </>,
];

interface UseRatingsParams {
  rate: UseCurrencyRateReturnValues['rate'];
  buyCurrencyCode: string;
  sellCurrencyCode: string;
}

const useRatings = ({
  rate,
  buyCurrencyCode,
  sellCurrencyCode,
}: UseRatingsParams) => {
  const [isLoading, setIsLoading] = useState(true);
  const [ratings, setRatings] = useState<IRatings>();

  useEffect(() => {
    if (rate && buyCurrencyCode && sellCurrencyCode) {
      getRatings({
        buyCurrency: buyCurrencyCode,
        sellCurrency: sellCurrencyCode,
        rate,
      })
        .then((data) => {
          // TODO: make sure response has correct types
          // @ts-expect-error TS(2345) FIXME: Argument of type 'IResponse<any> | undefined' is n... Remove this comment to see the full error message
          setRatings(data);
        })
        .catch((error) =>
          console.error(`Failed to fetch ratings. Error: ${error}`)
        )
        .finally(() => setIsLoading(false));
    }
  }, [buyCurrencyCode, rate, sellCurrencyCode]);

  const getRatingsText = () => {
    if (isLoading) {
      return <InlineLoader />;
    }

    if (!isLoading && !ratings) {
      return 'Failed to fetch ratings.';
    }

    return RATINGS_TEXT[
      ratings?.data?.rateRating ? ratings.data.rateRating - 1 : 0
    ];
  };

  return {
    rateRating: ratings?.data.rateRating,
    savingRating: ratings?.data.potentialSavingsRating,
    ratingText: getRatingsText(),
    isLoading,
  };
};

export default useRatings;
