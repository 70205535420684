import { FC } from 'react';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import useReduceRisks from './hooks/useReduceRisks';
import useUrlValues from 'hooks/useUrlValues';
import {
  Col,
  Row,
  Title,
  Subtitle,
  StaleLoader,
  InlineLoader,
} from 'components';
import ReduceRiskCard from './components/ReduceRiskCard/ReduceRiskCard';
import { getRiskCalculationsText } from './utils';

const ReduceRisks: FC = () => {
  const theme = useTheme();
  const { currency } = useUrlValues('currency');
  const { entityCurrencyCode, userEntity } = useStoreState(
    (state) => state.UserState
  );
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const entityCurrency = currencyByCode(entityCurrencyCode);

  const { cashflowRisksByCurrency, cards, isLoading } = useReduceRisks();

  return (
    <>
      <Col mb gap={theme.spacing.xs}>
        <Title variant="h4">Consider reducing risks</Title>
        <Subtitle color="greyDark">
          Your exposure to swings in {currency} exchange rates can result in
          potential currency losses of up to
          {isLoading && <InlineLoader ml />}
          {!isLoading && (
            <b>
              {getRiskCalculationsText({
                cashflowRisksByCurrency,
                riskTolerance: userEntity?.riskTolerance,
                symbol: entityCurrency?.symbol,
              })}
              your risk tolerance.
            </b>
          )}
        </Subtitle>
      </Col>

      <Title variant="h5">Below are some ways to reduce it:</Title>

      {isLoading && <StaleLoader size="large" />}
      {!isLoading && (
        <Row flexWrap="wrap" gap={theme.spacing.m} mt>
          {cards.map((card) => (
            <ReduceRiskCard key={card.id} {...card} />
          ))}
        </Row>
      )}
    </>
  );
};

export default ReduceRisks;
