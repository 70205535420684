import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useStoreState } from 'state';
import useUrlValues from 'hooks/useUrlValues';
import { getCashflowsPerCurrencyValues } from 'utils/analysis';
import {
  openInNewTab,
  parseIntoShortNumberString,
  roundToPrecision,
} from 'utils';
import { getReduceRiskRatingForHexagons } from '../utils';
import {
  getCurrencyExchangePageLink,
  getInvoicesPageLink,
  getPrebookPageLink,
} from 'utils/links';
import { SCHEDULE_MEET_URL } from 'variables';
import {
  useCreatePrebooksPermissionsCheck,
  useUpdateBalancesPermissionsCheck,
} from 'hooks/useSpecificPermissionsCheck';

const useReduceRisks = () => {
  const history = useHistory();
  const hasCreatePrebooksPermission = useCreatePrebooksPermissionsCheck();
  const hasUpdateBalancesPermission = useUpdateBalancesPermissionsCheck();
  const { cashflowsRisks } = useStoreState((state) => state.ReferenceDataState);
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { currency: currencyCode } = useUrlValues('currency');
  const currency = currencyByCode(currencyCode);
  const cashflowRisksByCurrency = currency
    ? cashflowsRisks?.currencyRisk[currency.code]
    : undefined;

  const cards = useMemo(() => {
    if (!cashflowRisksByCurrency) {
      return [];
    }

    const {
      cashflowAtRisk,
      cashflowAtRiskCash,
      cashflowAtRiskPayablesReceivables14,
      cashflowAtRiskPayablesReceivables14Plus,
      cashflowAtRiskPoSo,
    } = cashflowRisksByCurrency;

    const { balances, externalBalances } = getCashflowsPerCurrencyValues(
      cashflowRisksByCurrency
    );

    const cards = [
      {
        id: 1,
        icon: 'risk-convert-ico',
        title: 'Convert your cash balances',
        textOne:
          'Your account balances in foreign currencies could lose value in Pounds Sterling if exchange rates move.',
        textTwo: `Your future cashflows will further add to your balance. Convert up to [${currencyCode} ${parseIntoShortNumberString(
          balances + externalBalances
        )}] to reduce your risks.`,
        buttonText: 'Reduce balances',
        onClick: () =>
          history.push(
            getCurrencyExchangePageLink({
              predefinedBuyCurrency: currencyCode ?? undefined,
              step: '1',
            })
          ),
        showRisk: true,
        risk: getReduceRiskRatingForHexagons(
          cashflowAtRiskCash / cashflowAtRisk
        ),
        disabled: !hasUpdateBalancesPermission,
      },
      {
        id: 2,
        icon: 'risk-book-ico',
        title: 'Book FX for overdue/upcoming invoices',
        textOne: `You have overdue or upcoming invoices in ${currencyCode}.`,
        textTwo: `Booking FX for upcoming invoices turns them into fixed cashflows in ${entityCurrencyCode}${
          cashflowAtRiskPayablesReceivables14 > 0
            ? ` and can reduce risks by up to [${parseIntoShortNumberString(
                cashflowAtRiskPayablesReceivables14
              )}]`
            : ''
        }.`,
        buttonText: 'Convert upcoming invoices',
        onClick: () =>
          history.push(
            getPrebookPageLink({
              predefinedBuyCurrency: currencyCode ?? undefined,
            })
          ),
        showRisk: true,
        risk: getReduceRiskRatingForHexagons(
          cashflowAtRiskPayablesReceivables14 / cashflowAtRisk
        ),
        disabled: !hasCreatePrebooksPermission,
      },
      {
        id: 3,
        icon: 'risk-fix-fx-ico',
        title: 'Fix FX for net receivables/payables',
        textOne: `[${roundToPrecision(
          (cashflowAtRiskPayablesReceivables14Plus / cashflowAtRisk) * 100
        )}%] of your risks comes from future cashflows expected after 14 days.`,
        textTwo:
          'You can remove risks by booking current rates for the net exposure.',
        buttonText: 'Prebook for net AP/ARs ',
        onClick: () =>
          history.push(
            getPrebookPageLink({
              predefinedBuyCurrency: currencyCode ?? undefined,
            })
          ),
        showRisk: true,
        risk: getReduceRiskRatingForHexagons(
          cashflowAtRiskPayablesReceivables14Plus / cashflowAtRisk
        ),
        disabled: !hasCreatePrebooksPermission,
      },
      {
        id: 4,
        icon: 'search-ico',
        title: 'Choose specific cashflows to convert',
        textOne: 'Want more control -  fix rates for specific invoices.',
        textTwo:
          'Invoices totalling have an unrealised gain and contribute to current risks.',
        buttonText: 'Select invoices',
        onClick: () =>
          history.push(
            getInvoicesPageLink({
              currency: currencyCode ?? 'all',
              tab: 'outstanding',
            })
          ),
        showRisk: true,
        risk: getReduceRiskRatingForHexagons(
          (cashflowAtRiskPayablesReceivables14 +
            cashflowAtRiskPayablesReceivables14Plus) /
            cashflowAtRisk
        ),
      },
      {
        id: 5,
        icon: 'risk-forecasted-ico',
        title: 'Prebook for forecasted cashflows',
        textOne:
          'You may have currency risks from sales or purchase orders, if quotes in foreign currencies are binding.',
        textTwo:
          'Consider reducing risk by hedging some of these exposures and add predictability to your cashflows.',
        buttonText: 'Book cashflow hedges',
        onClick: () =>
          history.push(
            getPrebookPageLink({
              predefinedBuyCurrency: currencyCode ?? undefined,
            })
          ),
        showRisk: true,
        risk: getReduceRiskRatingForHexagons(
          cashflowAtRiskPoSo / cashflowAtRisk
        ),
        disabled: !hasCreatePrebooksPermission,
      },
      {
        id: 6,
        icon: 'support-ico',
        title: 'Schedule a consultation',
        textOne: 'Unsure what to do - talk to our experts.',
        textTwo:
          'We have helped 100s of blue-chip corporations worldwide before starting HedgeFlows.',
        buttonText: 'Schedule',
        onClick: () => openInNewTab(SCHEDULE_MEET_URL),
        showRisk: false,
      },
    ];

    return cards;
  }, [
    cashflowRisksByCurrency,
    currencyCode,
    entityCurrencyCode,
    hasCreatePrebooksPermission,
    hasUpdateBalancesPermission,
    history,
  ]);

  return {
    cards,
    isLoading: !cashflowRisksByCurrency,
    cashflowRisksByCurrency,
  };
};

export default useReduceRisks;
