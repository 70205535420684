import { useEffect } from 'react';
import auth from 'services/auth';
import { subscribeToUserEntityCodatIntegration } from 'services/firebase/invoices';
import { subscribeToCurrencyRates } from 'services/firebase/currencies';
import {
  subscribeToEntity,
  subscribeToUserEntityIntegrationsSummary,
} from 'services/firebase/user';
import { useStoreActions, useStoreState } from 'state';
import { Firebase } from 'services';
import { IIntegration } from 'types/integrations';
import useOnXeroConnection from 'hooks/useOnXeroConnection';
import { refreshBalances } from 'services/balances';
import useOnCodatConnection from 'hooks/useOnCodatConnection';
import { refreshCashflowSummaries } from 'services/cashflows';
import { errorHandler } from 'utils/errors';

const setVh = () => {
  document.documentElement.style.setProperty(
    '--vh',
    `${window.innerHeight * 0.01}px`
  );
};

const useBootstrap = () => {
  useOnXeroConnection();
  useOnCodatConnection();
  const {
    entityCurrencyCode,
    entityId,
    isEntityEnabled,
    isEntityOnboarded,
    userId,
  } = useStoreState(({ UserState }) => UserState);
  const {
    getEntityAccountDetails,
    getUser,
    getUserPermissionsOnEntity,
    setState: setUserState,
    setUser,
    setUserEntity,
    setUserEntityIntegrationCodat,
    setUserEntityIntegrations,
  } = useStoreActions(({ UserState }) => UserState);
  const { getCurrencies, subscribeToFollowedCurrencies } = useStoreActions(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { setCurrencyRates } = useStoreActions(
    ({ CurrencyRatesState }) => CurrencyRatesState
  );
  const { subscribeToTransfers } = useStoreActions(
    ({ TransfersState }) => TransfersState
  );
  const { subscribeToRateContracts } = useStoreActions(
    ({ RateContractsState }) => RateContractsState
  );
  const { subscribeToBulkPayments } = useStoreActions(
    ({ BulkPaymentsState }) => BulkPaymentsState
  );
  const { subscribeToRecipients } = useStoreActions(
    ({ RecipientsState }) => RecipientsState
  );
  const { subscribeToExternalPayments } = useStoreActions(
    ({ ExternalPaymentsState }) => ExternalPaymentsState
  );
  const { subscribeToExternalHedges } = useStoreActions(
    ({ ExternalHedgesState }) => ExternalHedgesState
  );
  const {
    subscribeToCashflowsRisks,
    refreshCashflowsRisksData,
    getIntegrations,
    getRiskContribution,
  } = useStoreActions(({ ReferenceDataState }) => ReferenceDataState);
  const {
    getSystemVariables,
    getCountries,
    getSwiftFees,
    getFundingAccounts,
    getCurrencyNews,
    setState: setReferenceDataState,
  } = useStoreActions(({ ReferenceDataState }) => ReferenceDataState);
  const { subscribeToBalances } = useStoreActions(
    ({ BalancesState }) => BalancesState
  );
  const { checkForInvoices } = useStoreActions(
    ({ InvoicesState }) => InvoicesState
  );
  const { getFeatureFlags } = useStoreActions(
    ({ FeatureFlagsState }) => FeatureFlagsState
  );

  useEffect(() => {
    if (entityId && entityCurrencyCode) {
      refreshCashflowsRisksData({
        entityId,
        sellCurrency: entityCurrencyCode,
      });
    }
  }, [entityCurrencyCode, entityId, refreshCashflowsRisksData]);

  useEffect(() => {
    if (isEntityOnboarded && isEntityEnabled) {
      refreshBalances();
    }
  }, [isEntityEnabled, isEntityOnboarded]);

  useEffect(() => {
    getSystemVariables();
    getCurrencies();
    getSwiftFees();
    getCountries();
    getFundingAccounts();
    getCurrencyNews();
    getIntegrations();
    getRiskContribution();
  }, [
    getSystemVariables,
    getCurrencies,
    getSwiftFees,
    getCountries,
    getFundingAccounts,
    getCurrencyNews,
    userId,
    getIntegrations,
    getRiskContribution,
    checkForInvoices,
  ]);

  useEffect(() => {
    setVh();
    window.addEventListener('resize', setVh);
    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);

  useEffect(() => {
    let unsubscribeTransfers: any;
    let unsubscribeRateContracts: any;
    let unsubscribeRecipients: any;
    let unsubscribeFromBulkPayments: any;
    let unsubscribeFromCashflowRisks: any;
    let unsubscribeFromPurchaseOrders: any;
    let unsubscribeFromIntegrationsSummary: any;
    let unsubscribeFromEntity: any;
    let unsubscribeFromCodatIntegration: any;
    let unsubscribeFromBalances: any;
    let unsubscribeFromExternalPayments: any;
    let unsubscribeFromExternalHedges: any;

    if (entityId) {
      // refetch feature flags when entity id is there
      getFeatureFlags();
      getEntityAccountDetails({ entityId });
      refreshCashflowSummaries({ entityId });
      checkForInvoices();
      getUserPermissionsOnEntity();
      unsubscribeTransfers = subscribeToTransfers({ entityId });
      unsubscribeRateContracts = subscribeToRateContracts({ entityId });
      unsubscribeRecipients = subscribeToRecipients({ entityId });
      unsubscribeFromCashflowRisks = subscribeToCashflowsRisks({ entityId });
      unsubscribeFromExternalPayments = subscribeToExternalPayments({
        entityId,
      });
      unsubscribeFromExternalHedges = subscribeToExternalHedges({
        entityId,
      });
      unsubscribeFromBulkPayments = subscribeToBulkPayments({ entityId });
      unsubscribeFromEntity = subscribeToEntity({
        entityId,
        callback: (entity) => {
          if (entity) {
            setUserEntity(entity);
          }
        },
      });
      unsubscribeFromIntegrationsSummary = subscribeToUserEntityIntegrationsSummary(
        {
          entityId,
          callback: (integrations: IIntegration | null) => {
            setUserEntityIntegrations(integrations ?? {});
          },
        }
      );
      unsubscribeFromCodatIntegration = subscribeToUserEntityCodatIntegration({
        entityId,
        callback: (entity) => {
          if (entity) {
            setUserEntityIntegrationCodat(entity);
          }
        },
      });

      unsubscribeFromBalances = subscribeToBalances({ entityId });
    } else {
      // feature flags required in any case, but we do not want to fetch them twice
      getFeatureFlags();
    }

    // TODO see with Oleksi if we can move this inside the above if block,
    // as it causing an uncaught error when visiting the login page
    const unsubscribeCurrencyRates = subscribeToCurrencyRates({
      callback: (data) => {
        if (data) {
          setCurrencyRates(data);
        }
      },
    });

    return () => {
      unsubscribeTransfers?.();
      unsubscribeRateContracts?.();
      unsubscribeRecipients?.();
      unsubscribeFromBulkPayments?.();
      unsubscribeCurrencyRates?.();
      unsubscribeFromCashflowRisks?.();
      unsubscribeFromPurchaseOrders?.();
      unsubscribeFromIntegrationsSummary?.();
      unsubscribeFromEntity?.();
      unsubscribeFromCodatIntegration?.();
      unsubscribeFromBalances?.();
      unsubscribeFromExternalPayments?.();
      unsubscribeFromExternalHedges?.();
    };
  }, [
    checkForInvoices,
    entityId,
    getEntityAccountDetails,
    getUserPermissionsOnEntity,
    setCurrencyRates,
    setUserEntity,
    setUserEntityIntegrationCodat,
    setUserEntityIntegrations,
    subscribeToBalances,
    subscribeToBulkPayments,
    subscribeToCashflowsRisks,
    subscribeToExternalHedges,
    subscribeToExternalPayments,
    subscribeToRateContracts,
    subscribeToRecipients,
    subscribeToTransfers,
    getFeatureFlags,
  ]);

  useEffect(() => {
    let unsubscribeFollowedCurrencies: any;

    if (userId) {
      unsubscribeFollowedCurrencies = subscribeToFollowedCurrencies({
        uid: userId,
      });
    }

    return () => {
      if (unsubscribeFollowedCurrencies) {
        unsubscribeFollowedCurrencies();
      }
    };
  }, [userId, subscribeToFollowedCurrencies]);

  useEffect(() => {
    // Subscribe to auth changes
    const authListener = auth.onAuthStateChanged(async (userFromAuth) => {
      try {
        if (userFromAuth) {
          Firebase.registerUserConnection({
            userId: userFromAuth.uid,
          });

          const { uid } = userFromAuth;

          await getUser({ id: uid });
        } else {
          setUser(null);
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setUserState(['isAuthLoading', false]);
      }
    });

    return () => {
      authListener();
    };
  }, [setUser, getUser, setReferenceDataState, setUserState, entityId]);
};

export default useBootstrap;
