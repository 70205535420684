import { Paragraph, ParagraphWithEllipsis, Row, Icon } from 'components';
import { IInvoiceFromSearch, INVOICE_STATUSES } from 'types';
import { firstCharacterToUppercase, parseIntoCurrencyString } from 'utils';
import { useStoreState } from 'state';
import { isPayableInvoice } from 'utils/invoices';

export const PaymentNumberCell: React.FC<{ value: string }> = ({ value }) => (
  <ParagraphWithEllipsis maxWidth="100px">{value}</ParagraphWithEllipsis>
);

export const PaymentAmountCell: React.FC<{
  payment: { currency: string; amount: number };
}> = ({ payment }) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(payment.currency);
  return (
    <Paragraph>
      {`${currency?.symbol}${parseIntoCurrencyString(
        payment.amount,
        currency?.precision
      )} `}
    </Paragraph>
  );
};

export const InvoiceFromSearchStatusCell: React.FC<{
  invoice: IInvoiceFromSearch;
}> = ({ invoice }) => {
  const { hasApprovalFlow } = useStoreState(({ UserState }) => UserState);

  return (
    <Row>
      {!!invoice.hasPayments &&
        invoice.status === INVOICE_STATUSES.purchaseOrder && (
          <Icon icon="link-ico" fill="green" style={{ marginRight: 10 }} />
        )}
      {hasApprovalFlow &&
      invoice.status !== 'PAID' &&
      isPayableInvoice(invoice) ? (
        <Paragraph>
          {invoice.approvalStatus
            ? firstCharacterToUppercase(invoice.approvalStatus)
            : 'Needs approval'}
        </Paragraph>
      ) : (
        <Paragraph>
          {invoice.excludeFromRisk ? 'EXCLUDED' : invoice.status}
        </Paragraph>
      )}
    </Row>
  );
};

export const RecipientNameCell: React.FC<{ recipientName: string }> = ({
  recipientName,
}) => (
  <Row justifyContent="flex-start">
    <Icon icon="warning-yellow-ico" mr />

    <ParagraphWithEllipsis maxWidth="100px">
      {recipientName}
    </ParagraphWithEllipsis>
  </Row>
);

export const FlagCell: React.FC<{ currencyCode: string }> = ({
  currencyCode,
}) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(currencyCode);

  if (!currency) {
    return null;
  }

  return (
    <Row justifyContent="center" alignSelf="stretch" flex={1}>
      <Icon icon={currency.countryCode} />
    </Row>
  );
};
