import { FC } from 'react';
import { InlineLoader, Paragraph } from 'components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { IPastPerformanceItemInvoice } from 'types/analyses';
import { useStoreState } from 'state';

interface OwnProps {
  pastPerformanceRecord?: IPastPerformanceItemInvoice;
  isLoadingPastPerformancePerRecordInvoices?: boolean;
}

const RealisedPnlCell: FC<OwnProps> = ({
  pastPerformanceRecord,
  isLoadingPastPerformancePerRecordInvoices,
}) => {
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(entityCurrencyCode);

  if (isLoadingPastPerformancePerRecordInvoices) {
    return <InlineLoader />;
  }

  if (!pastPerformanceRecord) {
    return null;
  }

  return (
    <Paragraph>
      {`${parseIntoCurrencyStringWithSymbol(
        pastPerformanceRecord.fxImpact + pastPerformanceRecord.fxCost,
        currency?.symbol,
        currency?.precision
      )}`}
    </Paragraph>
  );
};

export default RealisedPnlCell;
