import { Col, Paragraph, Row, Table } from 'components';
import Button from 'components/shared/Button/Button';
import dayjs from 'dayjs';
import { FC, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import {
  IPaymentRunItemSummary,
  IPaymentRunCurrencyTotal,
} from 'types/paymentRuns';
import { getDashboardPageLink, getInvoicesPageLink } from 'utils/links';
import { DATE_FORMAT } from 'variables';
import { generateConfirmationTableColumns } from '../../tableColumnsGenerator';
import FundsRequired from '../FundsRequired/FundsRequired';

interface OwnProps {
  paymentRunTotals: IPaymentRunCurrencyTotal[];
  data: IPaymentRunItemSummary[];
  paymentDate: string;
}

const ConfirmationStep: FC<OwnProps> = ({
  paymentRunTotals,
  data,
  paymentDate,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);

  const tableColumns = useMemo(
    () =>
      generateConfirmationTableColumns({
        currencyByCode,
        entityDefaultCurrencyCode: entityCurrencyCode,
      }),
    [currencyByCode, entityCurrencyCode]
  );

  return (
    <Col>
      <Table<IPaymentRunItemSummary>
        data={data}
        columns={tableColumns}
        defaultRowHeight={56}
        renderFooterContent={
          <Row alignItems="flex-end" gap={theme.spacing.m}>
            <Col flex={2.5}>
              <Paragraph mb>
                Your payment run has been booked. Please follow payment
                instructions provided and ensure sufficient funds are on your
                HedgeFlows account before 1pm on the Scheduled date.
              </Paragraph>
              <Row gap={theme.spacing.m} justifyContent="flex-start">
                <Button
                  onClick={() =>
                    history.replace(
                      getInvoicesPageLink({
                        currency: 'all',
                        tab: 'outstanding',
                      })
                    )
                  }
                >
                  Go to invoices
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => history.replace(getDashboardPageLink())}
                >
                  Go to dashboard
                </Button>
              </Row>
            </Col>

            <Col
              gap={theme.spacing.xs}
              alignItems="flex-start"
              justifyContent="space-between"
              alignSelf="stretch"
              flex={1}
            >
              <Row alignItems="flex-start" alignSelf="stretch">
                <Paragraph variant="bold">Payments scheduled for:</Paragraph>
                <Paragraph variant="bold">
                  {dayjs(paymentDate).format(DATE_FORMAT)}
                </Paragraph>
              </Row>
              <FundsRequired paymentRunTotals={paymentRunTotals} />
            </Col>
          </Row>
        }
        sortable
      />
    </Col>
  );
};

export default ConfirmationStep;
