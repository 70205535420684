import dayjs from 'dayjs';
import {
  codatPlatformNamesToLogsPaths,
  TCodatSettingsPaths,
  TLogsPaths,
  TSettingsPaths,
} from 'routes/IntegrationsRouteUtil';
import { IInvoice, IInvoiceFromSearch, TPlatformNames } from 'types';
import { getInvoiceRemainingAmount } from './invoices';

interface PrebookLinkParams {
  step?: string;
  invoiceId?: string;
  predefinedBuyCurrency?: string;
  predefinedSellCurrency?: string;
  predefinedBuyAmount?: string;
  predefinedSellAmount?: string;
  predefinedDate?: string;
}

interface CurrencyExchangeLinkParams {
  step?: string;
  invoiceId?: string;
  predefinedBuyCurrency?: string;
  predefinedSellCurrency?: string;
  predefinedBuyAmount?: string;
  predefinedSellAmount?: string;
  predefinedDate?: string;
  predefinedRateContractId?: string;
}

interface TransferLinkParams {
  predefinedRateContractId?: string;
  predefinedBuyCurrency?: string;
  predefinedSellCurrency?: string;
  predefinedBuyAmount?: string;
  invoiceId?: string;
  step?: string;
  bulkId?: string;
}

interface SimpleTransferLinkParams {
  invoiceId?: string;
  predefinedCurrency?: string;
  predefinedAmount?: string;
}

interface PrebooksOverviewLinkParams {
  currency?: string;
  contractId?: string;
  hedgeId?: string;
  cancelContractId?: string;
  deleteContractId?: string;
}

interface PaymentRunLinkParams {
  step: string;
}
interface InvoicesLinkParams {
  currency: string;
  tab: string;
  filter?: string;
  search?: string;
  contractId?: string;
  hedgeId?: string;
  cancelContractId?: string;
  deleteContractId?: string;
  noSettingsRedirect?: string;
}

interface ReduceRecommendationsRiskLinkParams {
  currency?: string;
}

interface TransactionsLinkParams {
  transferId?: string;
  contractId?: string;
  bulkPaymentId?: string;
}

interface IntegrationSettingsLinkParams {
  step?: string;
  firstIntegration?: boolean;
}

interface BulkUploadLinkParams {
  combineSameContacts?: boolean;
  invoicesIds?: string;
}

interface SettingsLinkParams {
  anchor?: string;
  tab?: string;
}

interface DashboardLinkParams {
  contractId?: string;
}

interface RisksLinkParams {
  currency: string;
}

interface RiskSettingsLinkParams {
  step?: string;
}

interface ManualUploadLinkParams {
  tab?: string;
  dataType?: string;
  /** used for manual hedge upload */
  buyCurrency?: string;
  /** used for manual invoice upload */
  currency?: string;
}

interface LinkParams extends TSettingLinkParams, TLogPathLinkParams {
  '/app/prebook': PrebookLinkParams;
  '/app/currency-exchange': CurrencyExchangeLinkParams;
  '/app/transfers': TransferLinkParams;
  '/app/simple-transfer': SimpleTransferLinkParams;
  '/app/invoices': InvoicesLinkParams;
  '/app/invoices/prebookings': PrebooksOverviewLinkParams;
  '/app/invoices/payment-run': PaymentRunLinkParams;
  '/app/risks/reduce-recommendations': ReduceRecommendationsRiskLinkParams;
  '/app/transactions': TransactionsLinkParams;
  '/app/settings': SettingsLinkParams;
  '/app/dashboard': DashboardLinkParams;
  '/app/settings/xero': IntegrationSettingsLinkParams;
  '/app/bulk-upload': BulkUploadLinkParams;
  '/app/logs/xero': {};
  '/app/new-client/xero': {};
  '/app/my-clients': {};
  '/app/switch-clients': {};
  '/login': {};
  '/app/company-registration': {};
  '/app/payment-runs': {};
  '/app/risks': RisksLinkParams;
  '/app/reports': {};
  '/app/risk-settings': RiskSettingsLinkParams;
  '/app/invoices/manual-upload': ManualUploadLinkParams;
}

type TSettingLinkParams = Record<TSettingsPaths, IntegrationSettingsLinkParams>;
type TLogPathLinkParams = Record<TLogsPaths, {}>;

export const getLink = <K extends keyof LinkParams, V extends LinkParams[K]>(
  baseUrl: K,
  params?: V,
  anchor?: string
) => {
  if (!params) {
    return baseUrl;
  }

  const queryParams = new URLSearchParams();

  Object.keys(params).forEach(
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    (key) => params[key] && queryParams.append(key, params[key])
  );

  return `${baseUrl}?${queryParams.toString()}${anchor ?? ''}`;
};

// page specific link getters
export const getPrebookPageLink = (args?: PrebookLinkParams) =>
  getLink('/app/prebook', args);

export const getTransactionPageLink = (args?: TransactionsLinkParams) =>
  getLink('/app/transactions', args);

export const getPaymentRunsPageLink = () => getLink('/app/payment-runs');

export const getXeroSettingsPageLink = (args?: IntegrationSettingsLinkParams) =>
  getLink('/app/settings/xero', args);

export const getSettingsPageLink = (
  args?: SettingsLinkParams,
  anchor?: string
) => getLink('/app/settings', args, anchor);

export const getQuickBooksSettingsPageLink = (
  args?: IntegrationSettingsLinkParams
) => getLink('/app/settings/quickbooks', args);

export const getCodatSettingsLink = (
  path: TCodatSettingsPaths,
  args?: IntegrationSettingsLinkParams
) => getLink(path, args);

export const getSageSettingsPageLink = (args?: IntegrationSettingsLinkParams) =>
  getLink('/app/settings/sage', args);

export const getXeroLogsPageLink = () => getLink('/app/logs/xero');

export const getCodatLogsPageLink = (platformName: TPlatformNames) =>
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  getLink(codatPlatformNamesToLogsPaths[platformName]);

export const getInvoicesPageLink = (args: InvoicesLinkParams) =>
  getLink('/app/invoices', args);

export const getPrebookingsPageLink = (args?: PrebooksOverviewLinkParams) =>
  getLink('/app/invoices/prebookings', args);

export const getCurrencyExchangePageLink = (args: CurrencyExchangeLinkParams) =>
  getLink('/app/currency-exchange', args);

export const getPaymentRunPageLink = (args?: PaymentRunLinkParams) =>
  getLink('/app/invoices/payment-run', args);

export const getReduceRiskRecommendationsPageLink = (
  args: ReduceRecommendationsRiskLinkParams
) => getLink('/app/risks/reduce-recommendations', args);

export const getDashboardPageLink = (args?: DashboardLinkParams) =>
  getLink('/app/dashboard', args);

export const getTransfersPageLink = (args?: TransferLinkParams) =>
  getLink('/app/transfers', args);

export const getXeroNewClientLink = () => getLink('/app/new-client/xero');
export const getMyClientsLink = () => getLink('/app/my-clients');
export const getSwitchClientsLink = () => getLink('/app/switch-clients');

export const getLoginPageLink = () => getLink('/login');
export const getCompanyRegistrationPageLink = () =>
  getLink('/app/company-registration');
export const getRisksPageLink = (args?: RisksLinkParams) =>
  getLink('/app/risks', args);
export const getReportsPageLink = () => getLink('/app/reports');

export const getInvoiceTransferLink = (
  invoice: IInvoice | IInvoiceFromSearch
) => {
  if (!invoice.id) {
    return '';
  }
  return getLink('/app/transfers', {
    step: '1',
    invoiceId: invoice.id,
    predefinedBuyCurrency: invoice.currency,
    predefinedBuyAmount: getInvoiceRemainingAmount(invoice).toString(),
    predefinedRateContractId: invoice.contractId,
  });
};

export const getInvoiceSimpleTransferLink = (
  invoice: IInvoice | IInvoiceFromSearch
) => {
  if (!invoice.id) {
    return '';
  }

  return getLink('/app/simple-transfer', {
    invoiceId: invoice.id,
    predefinedCurrency: invoice.currency,
    predefinedAmount: getInvoiceRemainingAmount(invoice).toString(),
  });
};

export const getInvoiceTransferOrSimpleTransferLink = (
  invoice: IInvoice | IInvoiceFromSearch,
  entityCurrencyCode: string
) =>
  entityCurrencyCode === invoice.currency
    ? getInvoiceSimpleTransferLink(invoice)
    : getInvoiceTransferLink(invoice);

export const getInvoicePrebookLink = (
  invoice: IInvoice | IInvoiceFromSearch,
  entityCurrency: string
) => {
  if (invoice.type === 'Receivable') {
    return getLink('/app/prebook', {
      step: '2',
      invoiceId: invoice.id,
      predefinedBuyCurrency: entityCurrency,
      predefinedSellCurrency: invoice.currency,
      predefinedSellAmount: getInvoiceRemainingAmount(invoice).toString(),
      predefinedDate: dayjs(invoice.dueDate).toISOString(),
    });
  }

  return getLink('/app/prebook', {
    step: '2',
    invoiceId: invoice.id,
    predefinedBuyCurrency: invoice.currency,
    predefinedBuyAmount: getInvoiceRemainingAmount(invoice).toString(),
    predefinedDate: dayjs(invoice.dueDate).toISOString(),
  });
};

export const getInvoiceInnerLink = (invoice: IInvoice) =>
  `/app/invoices/${invoice.id}`;

export const getInvoiceManualUploadLink = (args?: ManualUploadLinkParams) =>
  getLink('/app/invoices/manual-upload', args);

export const getRiskSettingsPageLink = (args?: RiskSettingsLinkParams) =>
  getLink('/app/risk-settings', args);

export const getSimpleTransferPageLink = (args?: SimpleTransferLinkParams) =>
  getLink('/app/simple-transfer', args);
