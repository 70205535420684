interface IBulkPaymentBase {
  paymentRunId?: string;
  scheduledPaymentDate?: string;
  fundedTimestamp: any;
  localCount?: number;
  swiftCount?: number;
  openBankingCount?: number;
  invoicesCount?: number;
  sellCurrency: string;
  sellAmount: number;
  paymentFee: number;
  payAmount: number;
  payByDate: string;
  payByTimeGMT: string;

  buyAmountsBreakdown: {
    currency: string;
    amount: number;
    rate: number;
    sellAmount: number;
  }[];
}

interface IBulkPaymentSystemFields {
  id: string;
  _created: any;
  _createdBy: string;
  _updated?: any;
  _updatedBy?: string;
  _version: number;
  _owner: string;
  status: BULK_PAYMENT_STATUS;
}

export interface IBulkPayment
  extends IBulkPaymentBase,
    IBulkPaymentSystemFields {}

export enum BULK_PAYMENT_STATUS {
  awaitingPayment = 'awaiting_payment',
  funded = 'funded',
  processing = 'processing',
  completed = 'completed',
  cancelled = 'cancelled',
  transfersFailed = 'transfers_failed',
}
