import { Column } from 'react-table';
import { IInvoice, IRecipient } from 'types';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import { SetStateAction, useMemo, Dispatch, FC } from 'react';
import { useStoreState } from 'state';
import { Table } from 'components';
import InvoiceNumberCell from 'components/shared/InvoiceNumberCell/InvoiceNumberCell';
import DateCell from 'components/shared/DateCell/DateCell';
import ContactCell from 'components/shared/ContactCell/ContactCell';
import { getInvoiceRemainingAmount } from 'utils/invoices';

const generateInvoiceTableColumns = ({
  recipientById,
  setContactForEdit,
  setInvoiceForAddContact,
}: {
  recipientById: (id?: string) => IRecipient | undefined;
  setContactForEdit: Dispatch<SetStateAction<any>>;
  setInvoiceForAddContact: Dispatch<SetStateAction<any>>;
}): Column<IInvoice>[] => {
  return [
    {
      Header: ` `,
      disableSortBy: true,
      width: 44,
      minWidth: 44,
    },
    {
      accessor: 'invoiceNumber',
      Header: 'PO',
      disableSortBy: true,
      Cell: ({ row }) => <InvoiceNumberCell record={row.original} />,
      width: 80,
    },
    {
      accessor: 'contact',
      Header: 'Name',
      Cell: ({ row, value }) => {
        const existingRecipient = recipientById(row.original.contactId);
        const recipientToUse = existingRecipient ?? value;

        return (
          <ContactCell
            invoice={row.original}
            recipientToUse={recipientToUse}
            setContactForEdit={setContactForEdit}
            setInvoiceForAddContact={setInvoiceForAddContact}
          />
        );
      },
      width: 120,
    },
    {
      accessor: 'dueDate',
      Header: 'Due date',
      Cell: ({ value }) => <DateCell value={value} />,
      width: 100,
    },

    {
      id: 'CCY',
      Header: 'Amount',
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <AmountCell
          amount={getInvoiceRemainingAmount(row.original)}
          currencyCode={row.original.currency}
        />
      ),
      width: 100,
    },
  ];
};

interface Props {
  invoice: IInvoice;
  setContactForEdit: Dispatch<SetStateAction<any>>;
  setInvoiceForAddContact: Dispatch<SetStateAction<any>>;
}
const InvoiceTable: FC<Props> = ({
  invoice,
  setContactForEdit,
  setInvoiceForAddContact,
}) => {
  const { recipientById } = useStoreState((state) => state.RecipientsState);
  const columns = useMemo(
    () =>
      generateInvoiceTableColumns({
        recipientById,
        setContactForEdit,
        setInvoiceForAddContact,
      }),
    [recipientById, setContactForEdit, setInvoiceForAddContact]
  );

  return <Table<IInvoice> columns={columns} data={[invoice]} />;
};

export default InvoiceTable;
