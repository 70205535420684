import { TCodatPlatformNames, TPlatformNames } from 'types/integrations';

/** Setting path helpers */
export type TCodatSettingsPaths =
  | '/app/settings/sage'
  | '/app/settings/quickbookssandbox'
  | '/app/settings/quickbooks'
  | '/app/settings/dynamics365'
  | '/app/settings/netsuite';

export type TSettingsPaths = TCodatSettingsPaths | '/app/settings/xero';

export const codatPlatformNamesToSettingsPaths: Record<
  TCodatPlatformNames,
  TCodatSettingsPaths
> = {
  'Sage 50 (UK)': '/app/settings/sage',
  'QuickBooks Online Sandbox': '/app/settings/quickbookssandbox',
  'QuickBooks Online': '/app/settings/quickbooks',
  'Dynamics 365 Business Central': '/app/settings/dynamics365',
  'Oracle NetSuite': '/app/settings/netsuite',
};

export const platformNamesToSettingsPaths: Record<
  TPlatformNames,
  TSettingsPaths
> = {
  ...codatPlatformNamesToSettingsPaths,
  Xero: '/app/settings/xero',
};

/** Log path helpers */
type TCodatLogsPaths =
  | '/app/logs/sage'
  | '/app/logs/quickbookssandbox'
  | '/app/logs/quickbooks'
  | '/app/logs/dynamics365'
  | '/app/logs/netsuite';

export type TLogsPaths = TCodatLogsPaths | '/app/logs/xero';

export const codatPlatformNamesToLogsPaths: Record<
  TCodatPlatformNames,
  TCodatLogsPaths
> = {
  'Sage 50 (UK)': '/app/logs/sage',
  'QuickBooks Online Sandbox': '/app/logs/quickbookssandbox',
  'QuickBooks Online': '/app/logs/quickbooks',
  'Dynamics 365 Business Central': '/app/logs/dynamics365',
  'Oracle NetSuite': '/app/logs/netsuite',
};

export const platformNamesToLogsPaths: Record<TPlatformNames, TLogsPaths> = {
  ...codatPlatformNamesToLogsPaths,
  Xero: '/app/logs/xero',
};

export const isStringSettingPath = (
  pathname: string | TSettingsPaths
): pathname is TSettingsPaths =>
  Object.values(platformNamesToSettingsPaths).includes(
    pathname as TSettingsPaths
  );

export const isStringLogsPath = (
  pathname: string | TLogsPaths
): pathname is TLogsPaths =>
  Object.values(platformNamesToLogsPaths).includes(pathname as TLogsPaths);

export const settingPathsToPlatformNames = Object.keys(
  platformNamesToSettingsPaths
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
).reduce((accumulator, platformName: TPlatformNames) => {
  const settingsPath: TSettingsPaths =
    platformNamesToSettingsPaths[platformName];
  accumulator[settingsPath] = platformName;
  return accumulator;
}, {} as Record<TSettingsPaths, keyof typeof platformNamesToSettingsPaths>);

export const logsPathsToPlatformNames = Object.keys(
  platformNamesToLogsPaths
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
).reduce((accumulator, platformName: TPlatformNames) => {
  const logsPaths: TLogsPaths = platformNamesToLogsPaths[platformName];
  accumulator[logsPaths] = platformName;
  return accumulator;
}, {} as Record<TLogsPaths, keyof typeof platformNamesToLogsPaths>);
