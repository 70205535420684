import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  appendRateAndProfitAndLossToInvoiceFromSearch,
  getInvoicesSortFields,
} from 'utils/invoices';
import InvoicesTableShared from 'components/shared/InvoicesTableNew/InvoicesTableNew';
import useUrlValues from 'hooks/useUrlValues';
import { StaleLoader } from 'components';
import { getInvoicesFilters } from 'pages/Invoices/utils';
import useRates from 'hooks/useRates';
import useInvoicesPastPerformanceData from 'hooks/useInvoicesPastPerformanceData';
import { IContact, IInvoiceFromSearch } from 'types';
import { IGenerateSearchQueryParams, generateSearchQuery } from 'utils/search';
import { getInvoicesPaginated } from 'services/firebase/invoices';
import {
  getExternalContactPropertyName,
  mapRecipientToIInvoiceSearch,
} from 'pages/Invoices/transformers';
import { SortingRule } from 'react-table';
import { isEqual } from 'lodash';
import { ExposedUseTableProps } from 'components/shared/Table/types';

interface OwnProps {
  currenciesByTab: Record<string, string[]>;
}

const InvoicesTable: FC<OwnProps> = ({ currenciesByTab }) => {
  const { currency, tab, search, filter } = useUrlValues(
    'currency',
    'tab',
    'search',
    'filter'
  );
  const [isLoadingFirstInvoicesPage, setIsLoadingFirstInvoicesPage] = useState(
    true
  );
  const [isLoadingMoreInvoices, setIsLoadingMoreInvoices] = useState(false);
  const [invoices, setInvoices] = useState<IInvoiceFromSearch[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreToLoad, setHasMoreToLoad] = useState(true);
  const [sortState, setSortState] = useState<SortingRule<IInvoiceFromSearch>[]>(
    []
  );
  const tableRef = useRef<ExposedUseTableProps<IInvoiceFromSearch>>(null);
  const isSetSortByForPaidTab = useRef(false);

  const fetchInvoices = useCallback(
    async ({
      page,
      searchQuery,
      filters,
      sortFields,
    }: {
      page: number;
      searchQuery: string;
      filters?: IGenerateSearchQueryParams['filters'];
      sortFields?: IGenerateSearchQueryParams['sortFields'];
    }) => {
      try {
        setIsLoadingMoreInvoices(true);

        const searchParams: IGenerateSearchQueryParams = {
          searchQuery,
          filters,
          sortFields,
          size: 30,
          page,
        };
        const query = generateSearchQuery(searchParams);

        const response = await getInvoicesPaginated(query);

        setInvoices((prevState) => [
          ...prevState,
          ...(response.data.data?.results ?? []),
        ]);

        const currentPageFromResponse = response.data.data?.meta.page.current;
        const totalPagesFromResponse =
          response.data.data?.meta.page.total_pages;

        if (
          currentPageFromResponse &&
          currentPageFromResponse !== currentPage
        ) {
          setCurrentPage(currentPageFromResponse);
        }

        if (
          currentPageFromResponse &&
          totalPagesFromResponse &&
          currentPageFromResponse === totalPagesFromResponse
        ) {
          setHasMoreToLoad(false);
        }
      } catch (error) {
        console.error(`Error fetching invoices page ${page}:`, error);
      } finally {
        setIsLoadingMoreInvoices(false);
      }
    },
    [currentPage]
  );

  useEffect(() => {
    if (tableRef.current && tab === 'paid' && !isSetSortByForPaidTab.current) {
      // we want that only on first render
      isSetSortByForPaidTab.current = true;

      tableRef.current.setSortBy([
        {
          id: 'fullyPaidOnDate',
          desc: true,
        },
      ]);
    }
  }, [sortState, tab]);

  useEffect(() => {
    setInvoices([]);
    setHasMoreToLoad(true);
    setCurrentPage(1);
  }, [tab, filter, currency, search, sortState]);

  useEffect(() => {
    if (currentPage === 1) {
      const filters = getInvoicesFilters(tab, filter, currency);
      const sortFields = getInvoicesSortFields(sortState);

      fetchInvoices({
        page: currentPage,
        searchQuery: search ?? '',
        filters,
        sortFields,
      }).finally(() => {
        setIsLoadingFirstInvoicesPage(false);
      });
    }
  }, [currentPage, fetchInvoices, filter, search, tab, currency, sortState]);

  const updateInMemoryInvoices = (invoiceId: string, contact: IContact) => {
    const invoice = invoices.find((invoice) => invoice.id === invoiceId);

    if (!invoice) {
      return undefined;
    }

    const externalRefIdDetails = getExternalContactPropertyName(invoice);
    if (!externalRefIdDetails) {
      return undefined;
    }

    const { prop, value } = externalRefIdDetails;

    const invoicesToUpdate = invoices.map((invoice) => {
      if (invoice[prop] === value) {
        invoice = mapRecipientToIInvoiceSearch(invoice, contact);
      }

      return invoice;
    });

    setInvoices(invoicesToUpdate);
  };

  const detectedCurrencies = useMemo(() => (tab ? currenciesByTab[tab] : []), [
    currenciesByTab,
    tab,
  ]);
  const { ratesByCurrency } = useRates({ currencies: detectedCurrencies });
  const {
    data: pastPerformancePerRecordInvoices,
    isLoading: isLoadingPastPerformancePerRecordInvoices,
  } = useInvoicesPastPerformanceData({ currencies: detectedCurrencies });

  // TODO: consider moving into BE query
  const invoicesForTable = useMemo(() => {
    const filteredInvoices = invoices.reduce<IInvoiceFromSearch[]>(
      (acc, invoice) => {
        const invoiceWithRateAndProfitAndLoss = appendRateAndProfitAndLossToInvoiceFromSearch(
          invoice,
          ratesByCurrency?.[invoice.currency]
        );

        acc.push(invoiceWithRateAndProfitAndLoss);

        return acc;
      },
      []
    );

    return filteredInvoices;
  }, [invoices, ratesByCurrency]);

  const handleSortChange = useCallback(
    (tableSortState: SortingRule<IInvoiceFromSearch>[]) => {
      if (isEqual(tableSortState, sortState)) {
        return;
      }

      setSortState(tableSortState);
    },
    [sortState]
  );

  if (isLoadingFirstInvoicesPage) {
    return <StaleLoader size="large" />;
  }

  return (
    <InvoicesTableShared
      tableRef={tableRef}
      isVirtualized
      data={invoicesForTable}
      autoResetSelectedRows
      pastPerformancePerRecordInvoices={pastPerformancePerRecordInvoices}
      isLoadingPastPerformancePerRecordInvoices={
        isLoadingPastPerformancePerRecordInvoices
      }
      withInfiniteLoading
      onLoadMoreItems={
        isLoadingMoreInvoices
          ? async () => {}
          : () =>
              fetchInvoices({
                page: currentPage + 1,
                searchQuery: search ?? '',
                filters: getInvoicesFilters(tab, filter, currency),
                sortFields: getInvoicesSortFields(sortState),
              })
      }
      loadingThreshold={10}
      isLoadingMoreItems={isLoadingMoreInvoices}
      itemsCount={invoicesForTable.length}
      hasMoreToLoad={hasMoreToLoad}
      updateInMemoryInvoices={updateInMemoryInvoices}
      manualSortBy
      onSort={handleSortChange}
    />
  );
};

export default InvoicesTable;
