import { Line, LineSvgProps } from '@nivo/line';
import { FC } from 'react';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import AutoSizer from 'react-virtualized-auto-sizer';

const ChartLine: FC<LineSvgProps> = (props) => {
  return (
    <AutoSizer>
      {({ height, width }: any) => (
        <Line height={height} width={width} {...props} />
      )}
    </AutoSizer>
  );
};

export default ChartLine;
