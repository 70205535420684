import {
  StaleBtnGroup,
  StaleContainer,
  Paragraph,
  StalePopup,
} from 'components';
import Button from 'components/shared/Button/Button';
import { InvoicesByCurrency } from 'components/shared/InvoicesTable/types';
import { FC, useState } from 'react';
import { useStoreActions, useStoreState } from 'state';
import { IInvoice } from 'types';

import { parseIntoCurrencyString } from 'utils';

interface OwnProps {
  onClose: () => void;
  selectedInvoices: IInvoice[];
  invoicesCurrency?: InvoicesByCurrency['currencyCode'];
  invoicesTotalAmount?: number;
  invoicesTotalBookingCost?: number;
}

const PrebookByIds: FC<OwnProps> = ({
  onClose,
  selectedInvoices,
  invoicesCurrency,
  invoicesTotalAmount,
  invoicesTotalBookingCost,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { prebookAllByIds } = useStoreActions(
    (actions) => actions.ReferenceDataState
  );
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);

  const onConfirm = async () => {
    setIsLoading(true);
    await prebookAllByIds({
      invoiceIds: selectedInvoices.map((invoice) => invoice.id),
    });
    onClose();
  };

  return (
    <StalePopup
      title="Confirm prebookings"
      theme="small"
      width="347px"
      minHeight="auto"
      onClose={onClose}
    >
      <StaleContainer style={{ padding: 0 }}>
        <Paragraph mb>
          You are about to prebook guaranteed exchange for{' '}
          {selectedInvoices?.length} invoices and the total of{' '}
          <strong>
            {invoicesCurrency} {parseIntoCurrencyString(invoicesTotalAmount)}
          </strong>
          .
        </Paragraph>
        <Paragraph>
          The total booking cost of{' '}
          <strong>
            {`${entityCurrencyCode} ${parseIntoCurrencyString(
              invoicesTotalBookingCost
            )}`}
          </strong>{' '}
          is included in guaranteed exchange rates.
        </Paragraph>
        <StaleBtnGroup horizontal container={false}>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onConfirm}
          >
            Confirm
          </Button>
          <Button disabled={isLoading} variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </StaleBtnGroup>
      </StaleContainer>
    </StalePopup>
  );
};

export default PrebookByIds;
