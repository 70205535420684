import { SliceTooltipProps } from '@nivo/line';
import { Paragraph, Span } from 'components';
import { StyledChartTooltip } from 'components/shared/ChartTooltip/ChartTooltip.styles';
import { PropsWithChildren } from 'react';
import { parseRate } from 'utils';
import { SERIE_ID_TO_TITLE } from './consts';

const SliceTooltip = ({ slice }: PropsWithChildren<SliceTooltipProps>) => {
  const { points } = slice;

  if (!points.length) {
    return null;
  }

  return (
    <StyledChartTooltip>
      <Paragraph
        style={{ whiteSpace: 'nowrap' }}
        color="white"
      >{`${points[0].data.xFormatted}: `}</Paragraph>

      {points.map((point) => {
        const { data, id, serieId, serieColor } = point;
        const { yFormatted } = data;
        return (
          <Paragraph style={{ whiteSpace: 'nowrap' }} key={id} color="white">
            <Span style={{ color: serieColor }}>-</Span>
            {` ${SERIE_ID_TO_TITLE[serieId]} ${parseRate(Number(yFormatted))}`}
          </Paragraph>
        );
      })}
    </StyledChartTooltip>
  );
};

export default SliceTooltip;
