// TODO: rework this components. make sure it follows all industry standards
import { ChangeEvent, FC } from 'react';
import { isMobile } from 'react-device-detect';
import OutsideClickHandler from 'react-outside-click-handler';
import cx from 'classnames';

import {
  StaleOverflowScroll,
  StaleBtnGroup,
  Title,
  InputSearch,
} from 'components';
import { Wrapper, InputSelectHead } from './StaleInputSelectList.styles';
import Button from '../Button/Button';

interface OwnProps {
  withSearch?: boolean;
  withClose?: boolean;
  searchValue?: string;
  onSearch?: (event: ChangeEvent<HTMLInputElement>) => any;
  onClose?: (...args: any) => any;
  onSelect?: (...args: any) => any;
  selectedValue?: any;
  data: any[];
  title?: string;
  theme?: 'theme-grey' | 'theme-dark';
}

const StaleInputSelectList: FC<OwnProps> = ({
  withSearch,
  withClose,
  searchValue,
  onSearch,
  onClose,
  onSelect,
  selectedValue,
  data,
  title = 'Select option',
  theme = 'theme-grey',
}) => {
  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <OutsideClickHandler onOutsideClick={onClose}>
      <Wrapper className={theme}>
        <InputSelectHead className={theme}>
          <Title variant="h3">{title}</Title>
          <svg width="24px" height="24px" onClick={onClose}>
            <use xlinkHref="#cross-ico" />
          </svg>
        </InputSelectHead>

        {withSearch && (
          <InputSearch
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={(event) => onSearch?.(event)}
            autoFocus={true}
          />
        )}

        <StaleOverflowScroll>
          <div className="list-wrap">
            {data.map((item, index) => (
              <div
                data-testid={`select-list-option-${index}`}
                onClick={() => {
                  onSelect?.(item);
                  onClose?.();
                }}
                key={item.id}
                className={cx(
                  'item',
                  item.id === selectedValue?.id && 'selected',
                  item.disabled && 'disabled'
                )}
              >
                {item.icon && (
                  <svg className="country">
                    <use xlinkHref={`#${item.icon}`} />
                  </svg>
                )}

                {(item.name || item.code) && (
                  <span>
                    {item.code && <strong>{item.code} </strong>}
                    {item.name}
                  </span>
                )}

                {item.id === selectedValue?.id && (
                  <svg width="24px" height="24px" className="tick">
                    <use xlinkHref="#tick-ico" />
                  </svg>
                )}
              </div>
            ))}
          </div>
        </StaleOverflowScroll>
        {withClose && isMobile && (
          <StaleBtnGroup>
            {theme === 'theme-grey' ? (
              <Button onClick={onClose}>Close</Button>
            ) : (
              <Button variant="secondary" onClick={onClose}>
                Close
              </Button>
            )}
          </StaleBtnGroup>
        )}
      </Wrapper>
    </OutsideClickHandler>
  );
};

export default StaleInputSelectList;
