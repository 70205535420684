import { FC, useState, useMemo, useCallback } from 'react';

import StaleAllAccountDetails from 'components/shared/StaleAllAccountDetails/StaleAllAccountDetails';
import { useStoreActions, useStoreState } from 'state';
import { StaleInfo, Paragraph, Icon, Row, ContextMenu } from '..';
import {
  ProfileNavWrapper,
  CompanyName,
  CompanyNameParagraph,
  CopySwift,
  UserCircle,
  UserCircleParagraph,
} from './ProfileNav.styles';
import { useTheme } from 'styled-components';
import { HELP_URL } from 'variables';
import { Link, useHistory } from 'react-router-dom';
import {
  copyTextToClipboard,
  getAccountDetailsForCopy,
  Notify,
  getSwiftAccountDetails,
} from 'utils';
import { getMyClientsLink, getSwitchClientsLink } from 'utils/links';
import { ContextMenuItem } from '../ContextMenu/ContextMenu';
import useDeviceWidth from 'hooks/useDeviceWidth';

const ProfileNav: FC = () => {
  const theme = useTheme();
  const { isMobile } = useDeviceWidth();
  const { signOut } = useStoreActions((actions) => actions.UserState);
  const history = useHistory();
  const {
    user,
    entityAccountDetails,
    userEntity,
    isEntityOnboarded,
    isAccountant,
  } = useStoreState((state) => state.UserState);
  const { countryByCode } = useStoreState((state) => state.ReferenceDataState);
  const [showAllAccountDetails, setShowAllAccountDetails] = useState(false);
  const swiftAccountDetails = getSwiftAccountDetails(entityAccountDetails);

  const userCircleText = useMemo(() => {
    if (!user) {
      return '';
    }

    if (user.firstName && user.lastName) {
      return user.firstName.slice(0, 1) + user.lastName.slice(0, 1);
    } else if (user.name.split(' ').length) {
      const nameSplit = user.name.split(' ');
      return nameSplit[0].slice(0, 1) + nameSplit[1]?.slice(0, 1);
    } else {
      return user.name.slice(0, 2);
    }
  }, [user]);

  const copySwiftDetails = () => {
    const text = getAccountDetailsForCopy(
      [
        ['Account holder', swiftAccountDetails.accountHolder],
        ['IBAN', swiftAccountDetails.iban],
        ['Bank', swiftAccountDetails.bankName],
        ['BIC', swiftAccountDetails.bic],
        ['Bank address', swiftAccountDetails.bankAddress],
        [
          'Bank country',
          countryByCode(swiftAccountDetails.bankCountry)?.name ??
            swiftAccountDetails.bankCountry,
        ],
      ],
      `For SWIFT Payments in USD, GBP, EUR or AUD`
    );
    copyTextToClipboard(text);
    Notify.success('Account details copied!');
  };

  const goToAccountsPage = useCallback(() => history.push(getMyClientsLink()), [
    history,
  ]);
  const goToSwitchClientsPage = useCallback(
    () => history.push(getSwitchClientsLink()),
    [history]
  );

  const contextMenuList: ContextMenuItem[] = useMemo(() => {
    const menuItemList = [
      {
        id: 1,
        title: 'Logout',
        icon: 'logout-ico',
        onClick: (e: any) => {
          e.stopPropagation();
          signOut();
        },
      },
    ];

    if (isAccountant) {
      menuItemList.unshift({
        id: 0,
        title: 'My Clients',
        icon: 'user-ico',
        onClick: goToAccountsPage,
      });
    } else {
      menuItemList.unshift({
        id: 0,
        title: 'Switch Clients',
        icon: 'users-group',
        onClick: (e: any) => {
          e.stopPropagation();
          goToSwitchClientsPage();
        },
      });
    }

    return menuItemList;
  }, [goToAccountsPage, isAccountant, signOut, goToSwitchClientsPage]);

  return (
    <>
      <ProfileNavWrapper>
        <StaleInfo
          infoSize="20px"
          mode="hover"
          strategy="fixed"
          portal
          placement="bottom"
          trigger={
            <Link to="/app/settings">
              <Icon
                width="20px"
                height="20px"
                fill={theme.color.greyDark}
                icon="settings-ico"
              />
            </Link>
          }
        >
          {!isMobile && <Paragraph color="white">View settings</Paragraph>}
        </StaleInfo>

        <StaleInfo
          infoSize="20px"
          mode="hover"
          strategy="fixed"
          portal
          placement="bottom"
          trigger={
            <a target="_blank" rel="noreferrer" href={HELP_URL}>
              <Icon
                width="20px"
                height="20px"
                fill={theme.color.greyDark}
                icon="question-ico"
              />
            </a>
          }
        >
          {!isMobile && <Paragraph color="white">Have a question?</Paragraph>}
        </StaleInfo>

        {isEntityOnboarded && (
          <Row gap="1px" mr mrValue={theme.spacing.xs}>
            {userEntity?.name && (
              <StaleInfo
                mode="hover"
                strategy="fixed"
                portal
                placement="bottom"
                infoSize="auto"
                trigger={
                  <CompanyName onClick={() => setShowAllAccountDetails(true)}>
                    <CompanyNameParagraph maxWidth="160px">
                      {userEntity?.name}
                    </CompanyNameParagraph>
                  </CompanyName>
                }
              >
                {!isMobile && (
                  <Paragraph color="white">View account details</Paragraph>
                )}
              </StaleInfo>
            )}

            <StaleInfo
              mode="hover"
              strategy="fixed"
              portal
              placement="bottom"
              infoSize="auto"
              trigger={
                <CopySwift onClick={copySwiftDetails}>
                  <Icon
                    width="20px"
                    height="20px"
                    fill={theme.color.greyDark}
                    icon="copy-ico"
                  />
                </CopySwift>
              }
            >
              {!isMobile && (
                <Paragraph color="white">Copy SWIFT details</Paragraph>
              )}
            </StaleInfo>
          </Row>
        )}

        <ContextMenu
          placement="bottom-end"
          portal
          list={contextMenuList}
          trigger={
            <StaleInfo
              mode="hover"
              strategy="fixed"
              portal
              placement="bottom"
              infoSize="auto"
              trigger={
                <UserCircle>
                  <UserCircleParagraph variant="bold">
                    {userCircleText}
                  </UserCircleParagraph>
                </UserCircle>
              }
            >
              {!isMobile && <Paragraph color="white">My profile</Paragraph>}
            </StaleInfo>
          }
        />
      </ProfileNavWrapper>

      {showAllAccountDetails && (
        <StaleAllAccountDetails
          onClose={() => setShowAllAccountDetails(false)}
        />
      )}
    </>
  );
};

export default ProfileNav;
