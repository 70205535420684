import { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';

import {
  StaleInput,
  Title,
  StaleTitleH5,
  StaleParagraphMedium,
  ButtonStyleLink,
  StaleCarousel,
  StaleCheckboxControlled,
  StaleOverflowScroll,
} from 'components';
import { HexagonLine, SignUpWrap, SignUpNav, SignUpContent } from '..';
import { useDebounce } from 'hooks';
import auth from 'services/auth';
import { regex } from 'utils';
import { ERROR_MESSAGES, VALIDATION_MESSAGES } from 'variables';
import { StyledButton } from 'components/shared/Button/Button.styles';

type Inputs = {
  email: string;
  password: string;
  repeatPassword: string;
  isPolicyAccepted: boolean;
};

interface OwnProps {
  savedValues: any;
  onSaveValues: any;
  onContinueHandler: () => void;
}

const StepOne: FC<OwnProps> = ({
  savedValues,
  onSaveValues,
  onContinueHandler,
}) => {
  const history = useHistory();

  const {
    control,
    watch,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<Inputs>({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const onSubmit = (values: any) => {
    onSaveValues(values);
    onContinueHandler();
  };

  const email = watch('email');
  const debounceEmail = useDebounce(email, 500);
  const [emailError, setEmailError] = useState('');
  const password = watch('password');
  const repeatPassword = watch('repeatPassword');

  useEffect(() => {
    if (debounceEmail && regex.email.test(debounceEmail)) {
      auth.fetchSignInMethodsForEmail(debounceEmail).then((data) => {
        if (data.length) {
          setEmailError('Email is already in use.');
        } else {
          setEmailError('');
        }
      });
    }
  }, [debounceEmail]);

  return (
    <SignUpWrap>
      <SignUpNav>
        {isMobile ? (
          <>
            <StaleTitleH5>Sign up</StaleTitleH5>

            <Link to="/" className="back">
              <svg width="24" height="24">
                <use xlinkHref="#cross-ico" />
              </svg>
            </Link>

            <div className="row">
              <div className="item current">Create account</div>
              <div className="item">Confirm email</div>
              <div className="item">Basic details</div>
            </div>
          </>
        ) : (
          <>
            <StaleTitleH5>Let’s sign you up to HedgeFlows</StaleTitleH5>

            <StaleParagraphMedium>
              You are about 10 minutes away from having access to our best
              features:{' '}
            </StaleParagraphMedium>

            <StaleCarousel />
          </>
        )}
      </SignUpNav>

      <SignUpContent className="rounded">
        <StaleOverflowScroll style={{ paddingBottom: 0 }}>
          <div className="block">
            {!isMobile && (
              <ButtonStyleLink
                className="cross"
                onClick={() => history.push('/')}
              >
                <svg width="24" height="24">
                  <use xlinkHref="#cross-ico" />
                </svg>
              </ButtonStyleLink>
            )}

            {isMobile ? (
              <Title variant="h5">Create your account:</Title>
            ) : (
              <Title>Enter your email and create the password</Title>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <StaleInput
                  id="email"
                  label="Email"
                  view="moving"
                  type="email"
                  name="email"
                  defaultValue={savedValues.email}
                  control={control}
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                  error={emailError || errors.email?.message}
                  autoFocus
                />
              </div>

              <div
                className="field"
                style={{
                  margin: '16px 0 8px',
                }}
              >
                <StaleInput
                  id="password"
                  label="Password"
                  view="moving"
                  type="password"
                  name="password"
                  defaultValue={savedValues.password}
                  control={control}
                  rules={{
                    validate: {
                      minLength: (value) => value.length >= 8,
                      oneUppercase: (value) => /(?=.*[A-Z])/.test(value),
                      oneLowercase: (value) => /(?=.*[a-z])/.test(value),
                      oneNumber: (value) => /(?=.*\d)/.test(value),
                      oneSpecial: (value) =>
                        /(?=.*[-+_!@#$%^&*.,?])/.test(value),
                    },
                  }}
                />
              </div>

              <ul>
                {VALIDATION_MESSAGES.map(({ id, text, validate }) => {
                  return (
                    <HexagonLine
                      key={id}
                      isValid={!!validate(password || savedValues.password)}
                      text={text}
                      hideWhenValid
                    />
                  );
                })}
              </ul>

              {(password || savedValues.password) && !errors.password && (
                <>
                  <div
                    className="field"
                    style={{
                      margin: '8px 0',
                    }}
                  >
                    <StaleInput
                      id="repeat-password"
                      label="Repeat password"
                      view="moving"
                      type="password"
                      name="repeatPassword"
                      defaultValue={savedValues.repeatPassword}
                      control={control}
                      rules={{
                        required: ERROR_MESSAGES.requiredField,
                        validate: {
                          matchesPasswordPassword: (value) => {
                            return (
                              password === value ||
                              savedValues.password === value ||
                              'Passwords have to match'
                            );
                          },
                        },
                      }}
                    />
                  </div>

                  <ul>
                    <HexagonLine
                      isValid={
                        (repeatPassword || savedValues.repeatPassword) ===
                        (password || savedValues.password)
                      }
                      text="Passwords have to match"
                      hideWhenValid
                    />
                  </ul>
                </>
              )}

              <div className="field">
                <StaleCheckboxControlled
                  id="isPolicyAccepted"
                  name="isPolicyAccepted"
                  defaultValue={savedValues.isPolicyAccepted}
                  control={control}
                  rules={{
                    validate: (value) => value,
                  }}
                  Label={
                    <>
                      I have read and agree to the HedgeFlows <br />
                      <a
                        href="https://www.hedgeflows.com/terms-and-conditions"
                        rel="noreferrer"
                        target="_blank"
                      >
                        {`Terms & Conditions`}
                      </a>
                      ,
                      <a
                        href="https://www.hedgeflows.com/terms-of-use"
                        rel="noreferrer"
                        target="_blank"
                      >
                        {` Terms of Use`}
                      </a>{' '}
                      and
                      <a
                        href="https://www.hedgeflows.com/privacy-policy"
                        rel="noreferrer"
                        target="_blank"
                      >
                        {` Privacy Policy`}
                      </a>
                    </>
                  }
                />
              </div>

              <StyledButton
                type="submit"
                disabled={!isValid || !!emailError}
                style={{ marginBottom: 0 }}
              >
                Continue
              </StyledButton>
            </form>

            <div className="bot">
              <StaleParagraphMedium>
                Already have an account?{' '}
                <ButtonStyleLink onClick={() => history.push('/login')}>
                  Log in
                </ButtonStyleLink>
              </StaleParagraphMedium>
            </div>
          </div>
        </StaleOverflowScroll>
      </SignUpContent>
    </SignUpWrap>
  );
};

export default StepOne;
