import styled from 'styled-components';
import ChartRect from 'components/shared/ChartRect/ChartRect.styles';

const ChartBarsGroupWrapper = styled.g`
  &:hover {
    ${ChartRect} {
      opacity: 1;
    }
  }
`;

export default ChartBarsGroupWrapper;
