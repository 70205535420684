import { TRANSFER_TYPE, PAYMENT_TYPE } from './transfers';

interface IRecipientFields {
  accountFields?: IRecipientFieldInstructions[];
  addressFields?: IRecipientFieldInstructions[];
  extraFields?: IRecipientFieldInstructions[];
  otherFields?: IRecipientFieldInstructions[];
  [key: string]: IRecipientFieldInstructions[] | undefined;
}

export interface IRecipientMeta {
  currency: string;
  country: string;
  company?: {
    priority?: IRecipientFields;
    regular?: IRecipientFields;
  };
  individual?: {
    priority?: IRecipientFields;
    regular?: IRecipientFields;
  };
}

export interface IRecipientFieldInstructions {
  // The name which this field is known as within HedgeFlows
  name: string;
  //The field title on forms and reports
  title: string;
  //Logical grouping of fields
  group?: string;
  //In some cases we want to allow tooltip style information for a field
  tooltip?: string;
  //If not a text field, then indicates what type of field to use
  type?:
    | 'IBAN'
    | 'BICSWIFT'
    | 'ROUTING'
    | 'ROUTING2'
    | 'RADIO'
    | 'COUNTRY'
    | 'EMAIL'
    | 'ABA'
    | 'CURRENCY'
    | 'SWIFTTYPE'
    | 'PURPOSE';
  //Example value for illustration
  example?: string;
  //Display and/or input format
  displayFormat?: string;
  //Logical order on the form within its group - Can be used to order fields
  order?: number;
  minLength?: number;
  maxLength?: number;
  //If required then returns either TRUE or the error text, e.g. "Field is required"
  required?: boolean | string;
  //Regular expression to apply during validation
  pattern?: RegExp;
  routingType?: string;
  /** Should not display the field if the value is empty or undefined */
  hideIfEmpty?: boolean;
  /** Read-only field */
  readOnly?: boolean;
}

export interface IRecipient {
  id: string;
  currency: string; // 3 char uppercase
  // CONTACT
  recipientEntityType: RECIPIENT_TYPE; // Individual or Company
  recipientCountry?: string; // 2 char uppercase
  recipientName: string; // 1-255 char
  // We use one field for the name
  // For entity_type=individual -> recipientName has to be two words or more
  //ACCOUNT
  accountCountry: string; // 2 char uppercase
  paymentType: PAYMENT_TYPE; // Local, IBAN or SWIFT
  //We need one of these 3, depending on the paymentType
  accountNumber: string; // Account Number or IBAN Number
  bicSwift?: string; // BIC/SWIFT Code
  routingType?: ROUTING_TYPE; // Local payment routing system (routing_code_type_1)
  routingNumber?: string; // (routing_code_value_1)
  routingType2?: ROUTING_TYPE; // Local payment routing system (routing_code_type_2)
  routingNumber2?: string; // (routing_code_value_2)
  //REQUIRED FOR PRIORITY PAYMENTS | paymentType === PAYMENT_TYPE.priority
  recipientAddress?: string;
  recipientCity?: string;
  //OPTIONAL
  recipientEmail?: string;
  recipientPostcode?: string;
  bankName?: string;
  recipientStateOrProvince?: string;
  enabled: boolean;
  status?: RECIPIENT_STATUS;
  swiftType?: SWIFT_TYPE;
  transferType?: TRANSFER_TYPE;
  externalRefs?: {
    ccId?: string;
    xeroId?: string;
    codatId?: string;
    orderWiseId?: string;
  };
  isTrusted?: boolean;
  scamAlert?: {
    answer: TScamQuestions;
    date: string; // DD MM YYYY
  };
}

export type TScamQuestions =
  | 'SuccessfullyTransferredToThisAccountBefore'
  | 'VerifiedByCallingRelevantGenuineNumber'
  | 'VerifiedDetailsViaAlternativeSecureMethod'
  | 'InternalAccountVerificationProcess';

export enum RECIPIENT_STATUS {
  draft = 'draft',
  initiated = 'initiated',
  requiresReview = 'requires_review',
  approved = 'approved',
  rejected = 'rejected',
  deleted = 'deleted',
}

export enum RECIPIENT_TYPE {
  individual = 'individual',
  company = 'company',
}

enum ROUTING_TYPE {
  /** UK sort code */
  sortCode = 'sortCode',
  /** US ABA */
  aba = 'aba',
  /** Australian BSB code */
  bsbCode = 'bsbCode',
  /** Canadian Institution Number */
  institutionNo = 'institutionNo',
  /** Bank Code, e.g. used in Canada */
  bankCode = 'bankCode',
  /** Branch Code, e.g. used in Canada */
  branchCode = 'branchCode',
  /** Mexican CLABE */
  clabe = 'clabe',
  /** Chinese CNAPS */
  cnaps = 'cnaps',
  /** Indian Financial System Code */
  ifsc = 'ifsc',
}

export enum SWIFT_TYPE {
  shared = 'shared',
  ours = 'ours',
}

type TContactReviewalStatus = 'new' | 'updated' | 'reviewed';

export interface IContact extends IRecipient {
  companyRegistrationNumber?: string;
  vatNumber?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactPhone?: string;
  iban?: string;
  isSupplier?: boolean;
  isCustomer?: boolean;
  phoneNumber?: string;
  shouldSendRemittance?: boolean;
  purpose?: IPurpose;
  defaultReference?: string;
  transferType?: TRANSFER_TYPE;
  isTrusted?: boolean;
  reviewalStatus?: TContactReviewalStatus;
}

export interface IPurpose {
  code: string;
  description: string;
}
