import { FC, useMemo, useEffect, useState } from 'react';
import { useStoreState } from 'state';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import { getInvoiceManualUploadLink } from 'utils/links';
import useCodat from 'hooks/useCodat';
import useUrlValues from 'hooks/useUrlValues';
import useXero from 'hooks/useXero';
import { PermissionsChecker, Row } from 'components';
import Button from 'components/shared/Button/Button';
import TabsShared, { ITab } from 'components/shared/Tabs/Tabs';
import { TInvoicePageTabs } from 'pages/Invoices/types';
import { TableHeader } from 'components/shared/TableHeader/TableHeader.styles';
import { createAndOpenDownloadLinkToInMemoryFileData } from 'utils';
import { errorHandler } from 'utils/errors';
import { AxiosResponse } from 'axios';
import { ITabsCounts } from 'pages/Invoices/hooks/useInvoicesPageTabsCountsAndCurrencies';

interface OwnProps {
  counts: ITabsCounts;
  getInvoicesAsCSV: (
    tab: 'outstanding' | 'paid'
  ) => Promise<AxiosResponse<string>>;
  getPaymentRunInvoicesAsCsv: () => void;
  isLoadingPaymentRunInvoicesCSV: boolean;
  paymentRunId: string;
}

const Tabs: FC<OwnProps> = ({
  counts,
  getInvoicesAsCSV,
  getPaymentRunInvoicesAsCsv,
  isLoadingPaymentRunInvoicesCSV,
  paymentRunId,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { setUrlValue, tab } = useUrlValues('tab');
  const {
    hasApprovalFlow,
    isAutomationPackageEnabled,
    isFxManagementPackageEnabled,
  } = useStoreState(({ UserState }) => UserState);
  const {
    onImportData: onImportXeroData,
    isImporting: isImportingXeroData,
    isIntegrated: isIntegratedWithXero,
  } = useXero();
  const {
    onImportCodatData,
    isImportingFromCodat,
    isIntegratedViaCodat,
  } = useCodat();

  const [isDownloadingCSVFile, setIsDownloadingCSVFile] = useState(false);

  const downloadInvoicesAsCSV = async (
    tab: Parameters<typeof getInvoicesAsCSV>[0]
  ) => {
    try {
      setIsDownloadingCSVFile(true);
      const response = await getInvoicesAsCSV(tab);
      createAndOpenDownloadLinkToInMemoryFileData(
        response.data,
        `invoices_${tab}.csv`,
        'text/csv'
      );
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsDownloadingCSVFile(false);
    }
  };

  const tabs = useMemo(
    () =>
      [
        { id: 'outstanding', title: `Outstanding (${counts.outstanding})` },
        ...(hasApprovalFlow
          ? [
              {
                id: 'submitted',
                title: `To approve (${counts.submitted})`,
              },
              {
                id: 'approved',
                title: `Approved (${counts.approved})`,
              },
            ]
          : []),
        ...(isAutomationPackageEnabled || isFxManagementPackageEnabled
          ? [{ id: 'paymentRun', title: `Payment run (${counts.paymentRun})` }]
          : []),
        { id: 'paid', title: `Paid (${counts.paid})` },
        {
          id: 'prebookings',
          title: `Prebooked FX (${counts.prebookedFx})`,
        },
      ] as ITab<TInvoicePageTabs>[],
    [
      hasApprovalFlow,
      counts,
      isAutomationPackageEnabled,
      isFxManagementPackageEnabled,
    ]
  );

  // TODO: Remove after refactor of useUrlValues (add initial values for props to avoid useEffect)
  useEffect(() => {
    if (!tab) {
      setUrlValue({ tab: 'outstanding' });
    }
  }, [tab, setUrlValue]);

  return (
    <TableHeader>
      <Row>
        <TabsShared<TInvoicePageTabs>
          data={tabs}
          activeTab={(tab as TInvoicePageTabs) ?? tabs[0].id}
          setActiveTab={({ id }) =>
            setUrlValue({ tab: id, filter: '', currency: 'all' })
          }
          whiteSpace="nowrap"
        />
      </Row>
      <Row>
        {isIntegratedWithXero && (
          <Button
            disabled={isImportingXeroData}
            onClick={onImportXeroData}
            variant="link"
            isLoading={isImportingXeroData}
            icon="refresh"
          >
            Refresh
          </Button>
        )}

        {isIntegratedViaCodat && (
          <Button
            disabled={isImportingFromCodat}
            onClick={onImportCodatData}
            variant="link"
            isLoading={isImportingFromCodat}
            icon="refresh"
          >
            Refresh
          </Button>
        )}

        <PermissionsChecker action="create" resource="cashflows_manual">
          <Button
            onClick={() => history.push(getInvoiceManualUploadLink())}
            variant="link"
            ml
            mlValue={theme.spacing.xs}
            icon="import-ico"
          >
            Manual upload
          </Button>
        </PermissionsChecker>

        {tab === 'paymentRun' && !!paymentRunId && (
          <Button
            onClick={getPaymentRunInvoicesAsCsv}
            variant="link"
            ml
            mlValue={theme.spacing.xs}
            isLoading={isLoadingPaymentRunInvoicesCSV}
          >
            Download CSV
          </Button>
        )}
        {(tab === 'outstanding' || tab === 'paid') && (
          <Button
            onClick={() => downloadInvoicesAsCSV(tab)}
            variant="link"
            ml
            mlValue={theme.spacing.xs}
            isLoading={isDownloadingCSVFile}
          >
            Download CSV
          </Button>
        )}
      </Row>
    </TableHeader>
  );
};

export default Tabs;
