import { TRiskSettingHedgeRatio, TRiskSettingHedgeRatioKeys } from 'state/user';
import { formatCashflowsAtRiskValuesPerMonth } from 'utils/analysis';

export const enrichValuesForContributorsChart = (
  values: ReturnType<typeof formatCashflowsAtRiskValuesPerMonth>
) =>
  values.map((value) => ({
    ...value,
    prebookedAndHedged: value.prebooked + value.externalHedges,
    payables: Math.abs(value.payables),
    saleOrders: Math.abs(value.saleOrders),
    purchaseOrders: Math.abs(value.purchaseOrders),
  }));

export type THedgeRatiosFilledData = Record<
  string,
  { min: number; max: number }
>;

export const fillGapsInData = (
  hedgeRatios?: TRiskSettingHedgeRatio,
  isQuarterFormat = false
): THedgeRatiosFilledData => {
  if (!hedgeRatios) {
    return {};
  }

  const filledData: THedgeRatiosFilledData = {};
  const totalPeriods = 12;

  const definedPeriods = Object.keys(hedgeRatios)
    .map(Number)
    .sort((a, b) => a - b);

  for (let period = 1; period <= totalPeriods; period++) {
    const periodString = period.toString();
    const scaledPeriod = isQuarterFormat ? period * 3 : period;

    const nextDefinedPeriod =
      definedPeriods.find((definedPeriod) => definedPeriod >= scaledPeriod) ||
      definedPeriods[definedPeriods.length - 1];

    filledData[periodString] =
      hedgeRatios[nextDefinedPeriod.toString() as TRiskSettingHedgeRatioKeys];
  }

  return filledData;
};
