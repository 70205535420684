import { useState } from 'react';
import { Title, Icon, Paragraph, Col, Row } from 'components';
import { useStoreState } from 'state';
import { ICurrencyInput, MakeOptional } from 'types';
import { IAccountData, TXeroSettingsInput } from 'types/integrations';
import { Notify } from 'utils';
import TitleLoader from '../TitleLoader/TitleLoader';
import {
  saveXeroPermissions,
  createXeroAccount,
  saveXeroSettings,
} from 'services/firebase';
import { useTheme } from 'styled-components';
import { StepAccountsSecondNotification } from 'components/shared/XeroNotification/XeroNotification.styles';
import useIntegrationAccounts from 'hooks/useIntegrationAccounts';
import UnorderedList from '../UnorderedList/UnorderedList';
import Pill from '../Pill/Pill';
import Button from '../Button/Button';

interface Inputs {
  paymentsAndConversions: boolean;
  invoicesAndBills: boolean;
  withBankFees: boolean;
  withExistingAccount: boolean;
  mainSellCurrencyAccount: IAccountData | null;
  createNewAccount: boolean;
  newAccountCurrencies: ICurrencyInput[];
}

interface ISavedValues extends MakeOptional<Inputs> {
  bankFeesAccount?: IAccountData | null;
}

interface OwnProps {
  savedValues?: ISavedValues;
  onContinue: () => void;
}

const StepAccountsSecondXero = ({ savedValues, onContinue }: OwnProps) => {
  const theme = useTheme();
  const { xero } = useStoreState(
    ({ UserState }) => UserState.integrationsSummary
  );
  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);
  const {
    hasEntityCurrencyAccount,
    isLoadingAccounts,
    accounts,
  } = useIntegrationAccounts('Xero');
  const [isLoading, setIsLoading] = useState(false);

  const savedXeroSettings = xero?.settings;

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const permissions = {
        global: false,
        invoices: savedValues?.invoicesAndBills,
        payments: savedValues?.paymentsAndConversions,
        purchaseOrders: savedValues?.invoicesAndBills,
        bills: savedValues?.invoicesAndBills,
        bankFees: !!savedValues?.bankFeesAccount,
        accounts: savedValues?.paymentsAndConversions,
      };

      const settings = {
        ...savedXeroSettings,
        settingsType: 'Xero',
        bankFeesAccount: savedValues?.bankFeesAccount?.value,
      } as TXeroSettingsInput;

      const [permissionsResponse, settingsResponse] = await Promise.all([
        saveXeroPermissions({ permissions }),
        saveXeroSettings({ settings }),
      ]);

      // creating accounts is currently a Xero only feature
      if (!hasEntityCurrencyAccount) {
        await createXeroAccount({ currency: entityCurrencyCode });
      }

      if (permissionsResponse.data?.success && settingsResponse.data?.success) {
        onContinue();
      } else {
        Notify.error(
          permissionsResponse?.data?.message ||
            settingsResponse?.data?.message ||
            ''
        );
        setIsLoading(false);
      }
    } catch (error: any) {
      Notify.error(error.response?.data?.error);
      setIsLoading(false);
    }
  };

  if (isLoadingAccounts) {
    return <TitleLoader title={`Getting Xero accounts.`} />;
  }

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        Required HedgeFlows accounts in Xero
      </Title>

      <Col mb gap={theme.spacing.xs}>
        <Paragraph>
          We automatically create accounts in Xero to record your payments and
          collections:
        </Paragraph>

        <UnorderedList
          data={[
            'Invoices in foreign currencies paid using Pounds Sterling funds are processed via “HedgeFlows GBP”',
            'Invoices issued and paid using funds in the same currency are process via a relevant HedgeFlows currency account.',
          ]}
        />
      </Col>

      {accounts.length > 0 && (
        <Col mb gap={theme.spacing.xs}>
          <Paragraph>The following accounts already exist in Xero: </Paragraph>
          <Row
            flexWrap="wrap"
            gap={theme.spacing.s}
            justifyContent="flex-start"
          >
            {accounts.map(({ name }) => (
              <Pill text={name} />
            ))}
          </Row>
        </Col>
      )}

      <StepAccountsSecondNotification>
        <Icon icon="alert-ico" />
        <Paragraph ml>
          By pressing Confirm you confirm your permission for HedgeFlows to
          update Xero on your behalf. We will automatically record in Xero
          transactions that you process via HedgeFlows.
        </Paragraph>
      </StepAccountsSecondNotification>

      <Button
        isLoading={isLoading}
        disabled={isLoading}
        mt
        mtValue={theme.spacing.xl}
        onClick={onSubmit}
      >
        Confirm
      </Button>
    </>
  );
};

export default StepAccountsSecondXero;
