import {
  TPastPerformanceItem,
  isPastPerformanceItemInvoice,
  isPastPerformanceItemTransfer,
} from 'types';

export const getName = (item: TPastPerformanceItem) => {
  const isInvoice = isPastPerformanceItemInvoice(item);
  const isTransfer = isPastPerformanceItemTransfer(item);

  if (isInvoice) {
    return item.contactName ?? 'Contact is missing';
  }

  if (isTransfer) {
    return 'TRANSFER';
  }

  return '';
};
