import { IAttachment, IInvoice } from 'types';
import { Firebase } from 'services';
import { useEffect, useRef, useState } from 'react';
import { Notify } from 'utils';

export const useGetXeroInvoiceAttachments = (xeroInvoice?: IInvoice) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAttachment, setIsLoadingAttachment] = useState<string>();
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const isInvoiceRerendered = useRef<boolean>(false);

  useEffect(() => {
    const fetchInvoiceAttachments = async () => {
      try {
        if (isInvoiceRerendered.current) {
          return;
        }

        let shouldSave = false;
        setIsLoading(true);
        let invoice: IInvoice | undefined = xeroInvoice;
        if (!invoice?.externalRefs?.xeroId || !xeroInvoice?.id) {
          return;
        }

        if (
          invoice?.hasAttachments === undefined ||
          (invoice.hasAttachments === true &&
            invoice?.attachments === undefined)
        ) {
          invoice = await Firebase.getXeroInvoice(invoice.externalRefs?.xeroId);
          shouldSave = true;
        }

        if (!invoice) {
          return;
        }

        if (
          invoice.hasAttachments === true &&
          Array.isArray(invoice?.attachments)
        ) {
          setAttachments(invoice.attachments);
        }

        if (shouldSave) {
          // save fetched attachments and hasAttachments properties
          await Firebase.Invoices.updateInvoice({
            invoiceId: xeroInvoice?.id,
            data: {
              hasAttachments: !!invoice.hasAttachments,
              attachments: invoice.attachments,
            },
          });

          isInvoiceRerendered.current = true;
        }

        return;
      } catch (error: any) {
        Notify.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInvoiceAttachments();
  }, [xeroInvoice]);

  const onFileClickHandler = async (args: {
    xeroInvoiceId: string;
    fileName: string;
    mimeType: string;
  }) => {
    setIsLoadingAttachment(args.fileName);
    try {
      const base64EncodedString = await Firebase.getXeroInvoiceAttachment(args);
      if (base64EncodedString) {
        // convert buffer to file data
        const link = document.createElement('a');

        link.setAttribute('href', base64EncodedString);
        link.setAttribute('download', args.fileName);
        link.setAttribute('id', 'id-download-attachment');

        // download
        link.click();

        // clean up
        const element = document.getElementById('id-download-attachment');
        element?.remove();
      }
    } catch (error: any) {
      Notify.error('Failed to get attachment');
      console.error(error);
    } finally {
      setIsLoadingAttachment(undefined);
    }
  };

  return {
    isLoading,
    attachments,
    onFileClickHandler,
    isLoadingAttachment,
  };
};
