import styled, { CSSProperties, DefaultThemeColorKey } from 'styled-components';
import { Col } from '../Col/Col';

const Card = styled(Col)<{
  color?: DefaultThemeColorKey;
  padding?: CSSProperties['padding'];
  position?: CSSProperties['position'];
}>`
  background: ${({ theme, color = 'white' }) => theme.color[color]};
  padding: ${({ theme, padding = theme.spacing.xl }) => padding};
  box-shadow: ${({ theme }) => theme.shadow};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  flex-direction: ${({ flexDirection }) => flexDirection};
  position: ${({ position }) => position};
`;

export default Card;
