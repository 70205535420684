import { FC } from 'react';
import { DefaultThemeColorKey, useTheme } from 'styled-components';
import { Col } from '../Col/Col';
import Icon from '../Icon/Icon';
import { Row } from '../Row/Row';
import StaleInfo from '../StaleInfo/StaleInfo';
import { Paragraph, StaleTextHint } from '../Typography/Typography';
import { getColorKey } from './utils';

export type RatingStatus = 'good' | 'bad' | 'neutral';
type RatingSize = 'small' | 'medium' | 'large';

interface OwnProps {
  title?: string;
  titleColor?: DefaultThemeColorKey;
  rating?: number;
  status?: RatingStatus;
  size?: RatingSize;
  description?: string;
  statusText?: string;
  disabled?: boolean;
}

const RATING_SIZES = {
  small: '12px',
  medium: '16px',
  large: '20px',
};

const HexagonRating: FC<OwnProps> = ({
  title = '',
  titleColor,
  size = 'medium',
  status,
  rating = 0,
  description,
  statusText,
  disabled = false,
}) => {
  const theme = useTheme();

  return (
    <Col>
      {(!!description || !!title) && (
        <Row mb mbValue={theme.spacing.xxs} justifyContent="flex-start">
          {!!title && (
            <StaleTextHint
              color={titleColor}
              style={{ marginRight: theme.spacing.xxs }}
            >
              {title}
            </StaleTextHint>
          )}

          {!!description && (
            <StaleInfo infoSize="12px" mode="hover" strategy="fixed" portal>
              <Paragraph color="white">{description}</Paragraph>
            </StaleInfo>
          )}
        </Row>
      )}

      <Row justifyContent="start">
        {Array.from(Array(5).keys()).map((value, index) => (
          <Icon
            key={value}
            width={RATING_SIZES[size]}
            height={RATING_SIZES[size]}
            icon="rate-ico"
            stroke={
              disabled
                ? theme.color.greyLight_1
                : theme.color[getColorKey(status)]
            }
            fill={
              !disabled && index < rating
                ? theme.color[getColorKey(status)]
                : 'none'
            }
            style={{ marginRight: 2 }}
          />
        ))}
      </Row>

      {!!statusText && (
        <Paragraph mt mtValue={theme.spacing.xxs} color={getColorKey(status)}>
          {statusText}
        </Paragraph>
      )}
    </Col>
  );
};

export default HexagonRating;
