import dayjs from 'dayjs';
import { FC } from 'react';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ChartLine from 'components/shared/ChartLine/ChartLine';
import ChartHedgeRatioLinesLayer from './components/ChartHedgeRatioLinesLayer/ChartHedgeRatioLinesLayer';
import { parseIntoShortNumberString } from 'utils';
import { LineSvgProps } from '@nivo/line';
import { dateStringToNumber } from 'utils/dates';

dayjs.extend(customParseFormat);

interface OwnProps extends Pick<LineSvgProps, 'data'> {
  isMonthly?: boolean;
  activeDate?: string | null;
  onLinePointClick?: (period: string, periodIndex: number) => void;
  withStaticColorPerCurrency?: boolean;
}

const ChartHedgeRatio: FC<OwnProps> = ({
  data,
  activeDate,
  isMonthly = true,
  onLinePointClick,
  withStaticColorPerCurrency,
}) => {
  const minDate = data.reduce(
    (_, { data: serie }) =>
      Math.min(
        ...serie.map((serieData) => {
          if (typeof serieData.x === 'string') {
            return dateStringToNumber(serieData.x, isMonthly);
          }

          return 0;
        })
      ),
    0
  );

  const maxDate = data.reduce(
    (total, { data: serie }) =>
      Math.max(
        total,
        ...serie.map((serieData) => {
          if (typeof serieData.x === 'string') {
            return dateStringToNumber(serieData.x, isMonthly);
          }

          return 0;
        })
      ),
    0
  );

  return (
    <ChartLine
      data={data}
      margin={{
        right: 30,
        bottom: 40,
        top: 20,
        left: 42,
      }}
      yScale={{
        type: 'linear',
        min: 0,
        max: 100,
      }}
      lineWidth={0.4}
      axisTop={null}
      axisRight={null}
      enableGridX={false}
      enablePoints={false}
      axisBottom={{
        tickPadding: 16,
        tickSize: 0,
        format: (value) => value,
        tickValues:
          data.find((item) => item.id === 'min')?.data.map((item) => item.x) ||
          [],
      }}
      axisLeft={{
        format: (value) => {
          return `${parseIntoShortNumberString(value)}%`;
        },
      }}
      layers={[
        'grid',
        'markers',
        'areas',
        (layerData) => (
          <ChartHedgeRatioLinesLayer
            minValue={0}
            maxValue={100}
            minDate={minDate}
            maxDate={maxDate}
            customLayerProps={layerData}
            activeMonth={activeDate}
            onClick={onLinePointClick}
            isMonthly={isMonthly}
            withStaticColorPerCurrency={withStaticColorPerCurrency}
          />
        ),
        'slices',
        'axes',
        'points',
      ]}
    />
  );
};

export default ChartHedgeRatio;
