import { AxisTickProps } from '@nivo/axes';
import dayjs from 'dayjs';
import { DB_MONTH_FORMAT } from 'variables';

const ChartTick = (tick: AxisTickProps<any>) => {
  const { x, y, value } = tick;

  return (
    <g transform={`translate(${x},${y + 16})`}>
      <text
        style={{
          fontSize: 12,
        }}
        textAnchor="middle"
      >
        {dayjs(value, DB_MONTH_FORMAT).format('MMM')}
      </text>

      {(dayjs(tick.value, 'YYYY-MM')
        .subtract(1, 'month')
        .isBefore(dayjs(tick.value, 'YYYY-MM').startOf('year')) ||
        tick.tickIndex === 0) && (
        <text
          transform={`translate(0, ${24})`}
          style={{
            fontSize: 12,
          }}
          textAnchor="middle"
        >
          {dayjs(tick.value, 'YYYY-MM').format('YYYY')}
        </text>
      )}
    </g>
  );
};

export default ChartTick;
