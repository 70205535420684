import { FC, useState, useMemo, useEffect, useCallback } from 'react';
import EntityUserPopup from './components/EntityUserPopup/EntityUserPopup';
import Button from 'components/shared/Button/Button';
import { useStoreState } from 'state';
import { PermissionsChecker, Row, StaleLoader, Table } from 'components';
import { IEntityUser } from 'state/user';

import { IPermitRoleV2 } from 'types/permissions';
import { Notify } from 'utils';
import { Firebase } from 'services';
import Placeholder from 'components/shared/Placeholder/Placeholder';
import {
  SettingsContent,
  SettingsFooter,
} from 'pages/Settings/Settings.styles';
import { generateEntityUserTableColumns } from './tableColumnsGenerator';
import { useTheme } from 'styled-components';

const TeamSettings: FC = () => {
  const theme = useTheme();
  const { userId, entityId } = useStoreState(({ UserState }) => UserState);
  const [users, setUsers] = useState<IEntityUser[]>([]);
  const [roles, setRoles] = useState<IPermitRoleV2[]>([]);
  const [packageSpecificRoles, setPackageSpecificRoles] = useState<
    IPermitRoleV2[]
  >([]);
  const [userForEdit, setUserForEdit] = useState<IEntityUser | null>(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);

  const { featureFlagById } = useStoreState(
    ({ FeatureFlagsState }) => FeatureFlagsState
  );

  const isPermitFeatureFlagEnabled = featureFlagById('permit');
  const [
    isUserEditOrInvitePopupOpen,
    setIsUserEditOrInvitePopupOpen,
  ] = useState(false);

  const getEntityUsers = useCallback(async () => {
    if (!entityId) {
      return;
    }

    try {
      setIsLoadingUsers(true);
      const response = await Firebase.getEntityUsers({ entityId });

      if (response?.data?.success && response.data.data) {
        setUsers(response.data.data);
      } else {
        Notify.error(response?.data?.message ?? '');
      }
    } catch (error: any) {
      Notify.error(error.message);
    } finally {
      setIsLoadingUsers(false);
    }
  }, [entityId]);

  const getEntityRoles = useCallback(async () => {
    if (!entityId) {
      return;
    }

    try {
      setIsLoadingRoles(true);
      const response = await Firebase.getEntityRoles(entityId);

      if (response?.data?.success && response.data?.data) {
        setRoles(response.data.data.filter((role) => !role.packageSpecific));
        setPackageSpecificRoles(
          response.data.data.filter((role) => !!role.packageSpecific)
        );
      } else {
        Notify.error(response?.data?.message ?? '');
      }
    } catch (error: any) {
      Notify.error(error.message);
    } finally {
      setIsLoadingRoles(false);
    }
  }, [entityId]);

  const usersTableColumns = useMemo(
    () =>
      generateEntityUserTableColumns({
        isLoadingRoles,
        isPermitFeatureFlagEnabled,
        onEditEntityUser: (user) => {
          setUserForEdit(user);
          setIsUserEditOrInvitePopupOpen(true);
        },
        theme,
        userId,
      }),
    [userId, isLoadingRoles, theme, isPermitFeatureFlagEnabled]
  );

  useEffect(() => {
    getEntityRoles();
    getEntityUsers();
  }, [getEntityRoles, getEntityUsers]);

  if (isLoadingUsers) {
    return (
      <SettingsContent>
        <StaleLoader size="large" />
      </SettingsContent>
    );
  }

  return (
    <>
      <SettingsContent>
        {!!users.length ? (
          <Table<IEntityUser>
            data={users}
            columns={usersTableColumns}
            sortable
            defaultRowHeight={56}
            minRowHeight={56}
          />
        ) : (
          <Row>
            <Placeholder
              fullWidth
              description="You will see your team members here when you have some."
            />
          </Row>
        )}
      </SettingsContent>

      <SettingsFooter>
        <PermissionsChecker action="update" resource="settings">
          <Row>
            <Button
              variant="secondary"
              disabled={false}
              isLoading={false}
              onClick={() => setIsUserEditOrInvitePopupOpen(true)}
            >
              + Add new
            </Button>
          </Row>
        </PermissionsChecker>
      </SettingsFooter>
      {isUserEditOrInvitePopupOpen && (
        <EntityUserPopup
          userForEdit={userForEdit}
          roles={roles}
          getEntityUsers={getEntityUsers}
          packageSpecificRoles={packageSpecificRoles}
          onClose={() => {
            setUserForEdit(null);
            setIsUserEditOrInvitePopupOpen(false);
          }}
        />
      )}
    </>
  );
};

export default TeamSettings;
