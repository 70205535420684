import { FC, Dispatch, SetStateAction, useState } from 'react';
import { Row } from 'components';
import { FilterButton } from 'components/shared/FilterButton/FilterButton.styles';
import CounterTitle from 'components/shared/CounterTitle/CounterTitle';
import { TReportsContentType, TReportsTableFilterValue } from '../../types';
import { useTheme } from 'styled-components';
import Button from 'components/shared/Button/Button';
import { errorHandler } from 'utils/errors';
import { createAndOpenDownloadLinkToInMemoryFileData } from 'utils';
import { getPastPerformanceDataAsCsvFile } from 'services/firebase/analysis';
import { useStoreState } from 'state';

interface IProps {
  invoiceCount: number;
  filter?: TReportsTableFilterValue;
  setFilter: Dispatch<SetStateAction<TReportsTableFilterValue | undefined>>;
  activeMonth: string;
  activeCurrencyCode?: string;
  contentType: TReportsContentType;
}

const TableControls: FC<IProps> = ({
  invoiceCount,
  filter,
  setFilter,
  activeMonth,
  activeCurrencyCode,
  contentType,
}) => {
  const theme = useTheme();
  const { entityId } = useStoreState((state) => state.UserState);
  const [isDownloadingCSVFile, setIsDownloadingCSVFile] = useState(false);

  const downloadPastPerformanceDataAsCSV = async () => {
    if (!activeCurrencyCode || !entityId) {
      return;
    }

    try {
      setIsDownloadingCSVFile(true);
      const response = await getPastPerformanceDataAsCsvFile({
        currency: activeCurrencyCode,
        tab: contentType,
        activeMonth,
        entityId,
      });
      createAndOpenDownloadLinkToInMemoryFileData(
        response.data,
        `past_performance_${activeMonth}.csv`,
        'text/csv'
      );
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsDownloadingCSVFile(false);
    }
  };

  return (
    <Row mr mb>
      <Row gap={theme.spacing.m}>
        <CounterTitle title="Contributors" count={invoiceCount} />

        <FilterButton
          onClick={() =>
            setFilter(filter === 'payables' ? 'default' : 'payables')
          }
          active={filter === 'payables'}
        >
          Payables
        </FilterButton>

        <FilterButton
          onClick={() =>
            setFilter(filter === 'receivables' ? 'default' : 'receivables')
          }
          active={filter === 'receivables'}
        >
          Receivables
        </FilterButton>

        <FilterButton
          onClick={() =>
            setFilter(filter === 'transfers' ? 'default' : 'transfers')
          }
          active={filter === 'transfers'}
        >
          Transfers
        </FilterButton>
      </Row>

      <Button
        onClick={() => downloadPastPerformanceDataAsCSV()}
        variant="link"
        ml
        mlValue={theme.spacing.xs}
        isLoading={isDownloadingCSVFile}
        disabled={isDownloadingCSVFile || !invoiceCount}
      >
        Download CSV
      </Button>
    </Row>
  );
};

export default TableControls;
