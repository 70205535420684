import useUrlValues from 'hooks/useUrlValues';
import { FC, useMemo } from 'react';
import { generateTableColumns } from './tableColumnsGenerator';
import DateRangeInfo from './components/DateRangeInfo/DateRangeInfo';
import TableHorizontal from 'components/shared/TableHorizontal/TableHorizontal';
import { TCashflowAtRiskNew } from 'types';
import { getTdCellTheme, getThCellTheme } from './utils';
import { StaleLoader } from 'components';

interface OwnProps {
  cashflowsRisksData: TCashflowAtRiskNew[];
  isLoadingCashflowsRisksData: boolean;
  isLoadingAndHaveData: boolean;
  getCashflowsRisksPerCurrency: (
    currencyCode: string,
    period: 'month' | 'quarter'
  ) => Promise<void>;
}

const DataTable: FC<OwnProps> = ({
  cashflowsRisksData,
  isLoadingCashflowsRisksData,
  isLoadingAndHaveData,
  getCashflowsRisksPerCurrency,
}) => {
  const { currency, dateRange } = useUrlValues('currency', 'dateRange');

  const columns = useMemo(
    () =>
      currency
        ? generateTableColumns({
            currencyCode: currency,
            cashflowsRisksData,
            getCashflowsRisksPerCurrency,
          })
        : [],
    [cashflowsRisksData, currency, getCashflowsRisksPerCurrency]
  );

  if (isLoadingCashflowsRisksData && !isLoadingAndHaveData) {
    return <StaleLoader size="large" style={{ alignSelf: 'center' }} />;
  }

  if (dateRange) {
    return <DateRangeInfo dateRange={dateRange} />;
  }

  return (
    <TableHorizontal<TCashflowAtRiskNew>
      getTdCellTheme={getTdCellTheme}
      getThCellTheme={getThCellTheme}
      columns={columns}
      data={cashflowsRisksData}
    />
  );
};

export default DataTable;
