import { FC } from 'react';
import { useTheme } from 'styled-components';
import { IInvoice } from 'types';

interface OwnProps {
  record: IInvoice;
}

const StatusIconCell: FC<OwnProps> = ({ record }) => {
  const theme = useTheme();

  return (
    <>
      {record.trackingId && (
        <svg width="24px" height="24px" fill={theme.color.blue}>
          <use xlinkHref="#eye-table-ico" />
        </svg>
      )}

      {record.contractId && (
        <svg width="24px" height="24px" fill={theme.color.coral}>
          <use xlinkHref="#lock-ico" />
        </svg>
      )}
    </>
  );
};

export default StatusIconCell;
