import ChartMarketImpact from 'pages/Reports/components/ChartMarketImpact/ChartMarketImpact';
import ChartNetOutstanding from 'pages/Reports/components/ChartNetOutstanding/ChartNetOutstanding';
import ChartProfitAndLoss from 'pages/Reports/components/CurrencyInfo/components/Charts/components/ChartProfitAndLoss/ChartProfitAndLoss';
import ChartRealised from 'pages/Reports/components/ChartRealised/ChartRealised';
import { TReportsContentType } from 'pages/Reports/types';
import { FC } from 'react';
import {
  IPnlDataPerMonth,
  TPastPerformanceDataFromExternalSource,
} from 'types';

interface OwnProps {
  contentType: TReportsContentType;
  data: IPnlDataPerMonth[];
  dataPerCurrency: Record<string, IPnlDataPerMonth[]>;
  externalData: TPastPerformanceDataFromExternalSource[];
  activeMonth: string;
  setActiveMonth: (value: string) => void;
}

const Charts: FC<OwnProps> = ({
  contentType,
  data,
  dataPerCurrency,
  externalData,
  activeMonth,
  setActiveMonth,
}) => (
  <>
    {contentType === 'realised' && (
      <ChartRealised
        data={data}
        onChartBarClick={setActiveMonth}
        activeMonth={activeMonth}
      />
    )}
    {contentType === 'volumes' && (
      <ChartNetOutstanding
        data={data}
        onLinePointClick={setActiveMonth}
        activeMonth={activeMonth}
      />
    )}
    {contentType === 'marketImpact' && (
      <ChartMarketImpact
        data={data}
        onChartBarClick={setActiveMonth}
        activeMonth={activeMonth}
      />
    )}
    {contentType === 'pnl' && (
      <ChartProfitAndLoss
        data={dataPerCurrency}
        externalData={externalData}
        onChartPeriodClick={setActiveMonth}
      />
    )}
  </>
);

export default Charts;
