import { CustomLayerProps, Datum } from '@nivo/line';
import { line, curveMonotoneX } from 'd3-shape';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import ChartNetOutstandingBalancePoint from '../ChartNetOutstandingBalancePoint/ChartNetOutstandingBalancePoint';
import ChartNetOutstandingTurnoverPoint from '../ChartNetOutstandingTurnoverPoint/ChartNetOutstandingTurnoverPoint';

interface OwnProps {
  customLayerProps: CustomLayerProps;
  activeMonth: string;
  onClick: (month: string) => void;
}

const ChartNetOutstandingLinesLayer: FC<OwnProps> = ({
  customLayerProps,
  activeMonth,
  onClick,
}) => {
  const { color } = useTheme();

  const turnoverData = customLayerProps.data.find(
    (serie) => serie.id === 'turnover'
  );
  const averageOutstandingBalanceData = customLayerProps.data.find(
    (serie) => serie.id === 'averageOutstandingBalance'
  );
  const lineGenerator = line<Datum>()
    // @ts-expect-error
    .x((dataItem) => {
      // @ts-expect-error TS(2349) FIXME: This expression is not callable.
      return customLayerProps.xScale(dataItem.x);
    })
    // @ts-expect-error
    .y((dataItem) => {
      // @ts-expect-error TS(2349) FIXME: This expression is not callable.
      return customLayerProps.yScale(dataItem.y);
    })
    .curve(curveMonotoneX);

  return (
    <>
      {!!turnoverData && (
        <path
          d={lineGenerator(turnoverData.data) || undefined}
          fill="none"
          stroke={color.green}
          style={{ pointerEvents: 'none' }}
        />
      )}
      {turnoverData?.data.map((data) => (
        <ChartNetOutstandingTurnoverPoint
          data={data}
          activeMonth={activeMonth}
          onClick={onClick}
          // @ts-expect-error TS(2349) FIXME: This expression is not callable.
          cx={customLayerProps.xScale(data.x)}
          // @ts-expect-error TS(2349) FIXME: This expression is not callable.
          cy={customLayerProps.yScale(data.y)}
        />
      ))}
      {!!averageOutstandingBalanceData && (
        <path
          d={lineGenerator(averageOutstandingBalanceData.data) || undefined}
          fill="none"
          stroke={color.blueLight}
          style={{ pointerEvents: 'none' }}
        />
      )}

      {averageOutstandingBalanceData?.data.map((data) => (
        <ChartNetOutstandingBalancePoint
          data={data}
          activeMonth={activeMonth}
          onClick={onClick}
          // @ts-expect-error TS(2349) FIXME: This expression is not callable.
          cx={customLayerProps.xScale(data.x)}
          // @ts-expect-error TS(2349) FIXME: This expression is not callable.
          cy={customLayerProps.yScale(data.y)}
        />
      ))}
    </>
  );
};

export default ChartNetOutstandingLinesLayer;
