import { FilterTypes } from 'react-table';
import { INVOICE_STATUSES } from 'types';
import {
  isPastPerformanceItemInvoice,
  isPastPerformanceItemTransfer,
  TPastPerformanceItem,
} from 'types/analyses';
import { TReportsContentType } from './types';

export const filterTypes: FilterTypes<TPastPerformanceItem> = {
  direction: (rows, _, filterValue) => {
    if (filterValue === 'default') {
      return rows;
    }

    if (filterValue === 'transfers') {
      return rows.filter((row) => {
        const value = row.original;

        return value.recordType === 'bankTransfer';
      });
    }

    if (filterValue === 'payables') {
      return rows.filter((row) => {
        const value = row.original;

        if (isPastPerformanceItemInvoice(value)) {
          return value.type === 'Payable';
        } else {
          return value.status === 'TRANSFER OUT';
        }
      });
    }

    if (filterValue === 'receivables') {
      return rows.filter((row) => {
        const value = row.original;

        if (isPastPerformanceItemInvoice(value)) {
          return value.type === 'Receivable';
        } else {
          return value.status === 'TRANSFER IN';
        }
      });
    }

    return rows;
  },
  text: (rows, _, filterValue) => {
    if (!filterValue) {
      return rows;
    }

    return rows.filter((row) => {
      const value = row.original;

      if (
        isPastPerformanceItemInvoice(value) &&
        value.invoiceId.includes(filterValue)
      ) {
        return true;
      }

      if (
        isPastPerformanceItemTransfer(value) &&
        value.bankTransferId.includes(filterValue)
      ) {
        return true;
      }
      if (
        value.contactName?.toLowerCase().includes(filterValue.toLowerCase())
      ) {
        return true;
      }

      return false;
    });
  },
};

export const filterPastPerformanceItem = (
  pastPerformanceItem: TPastPerformanceItem,
  contentType: TReportsContentType,
  activeMonth: string
) => {
  if (
    contentType === 'realised' &&
    pastPerformanceItem.status === INVOICE_STATUSES.authorised
  ) {
    return false;
  }

  if (pastPerformanceItem.recordType === 'bankTransfer') {
    return pastPerformanceItem.paidDateMonth === activeMonth;
  }

  if (
    contentType === 'marketImpact' &&
    pastPerformanceItem.monthlyImpactContributions
  ) {
    return Object.keys(pastPerformanceItem.monthlyImpactContributions).some(
      (monthlyContributionKey) => monthlyContributionKey === activeMonth
    );
  }

  return pastPerformanceItem.paidDateMonth === activeMonth;
};
