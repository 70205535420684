import {
  Col,
  InlineLoader,
  Paragraph,
  RedHint,
  Row,
  StaleLoader,
  StaleNotification,
  StaleSwitch,
  Table,
} from 'components';
import Button from 'components/shared/Button/Button';
import Field from 'components/shared/Field/Field.styles';
import InputDateUncontrolled from 'components/shared/InputDateUncontrolled/InputDateUncontrolled';
import StaleReAuthenticate from 'components/shared/StaleReAuthenticate/StaleReAuthenticate';
import TooManyTimePasswordAttemptsPopup from 'components/shared/TooManyTimePasswordAttemptsPopup/TooManyTimePasswordAttemptsPopup';
import dayjs from 'dayjs';
import { useReAuthenticate } from 'hooks';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Firebase } from 'services';
import {
  confirmPaymentRun,
  updatePaymentRun,
  updatePaymentRunItemSummary,
} from 'services/paymentRuns';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import {
  IPaymentRun,
  IPaymentRunItemSummary,
  IPaymentRunCurrencyTotal,
} from 'types/paymentRuns';
import { getFirstValidDay } from 'utils/dates';
import { errorHandler } from 'utils/errors';
import { DATE_FORMAT, DB_DATE_FORMAT, ERROR_MESSAGES } from 'variables';
import { generateAuthoriseTableColumns } from '../../tableColumnsGenerator';
import FundsRequired from '../FundsRequired/FundsRequired';

interface OwnProps {
  paymentRunId: string;
  paymentRunTotals: IPaymentRunCurrencyTotal[];
  isPaymentRunValid: boolean;
  data: IPaymentRunItemSummary[];
  initialDate: string;
  setPaymentRun: Dispatch<SetStateAction<IPaymentRun>>;
  onContinue: () => void;
  error?: string;
}

const AuthoriseStep: FC<OwnProps> = ({
  paymentRunId,
  paymentRunTotals,
  data,
  isPaymentRunValid,
  initialDate,
  setPaymentRun,
  onContinue,
  error,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);
  const { control, errors, handleSubmit, watch } = useForm<{
    password: string;
    date: string;
  }>({
    defaultValues: {
      date: initialDate ? dayjs(initialDate).format(DATE_FORMAT) : '',
    },
  });
  const date = watch('date');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBuyFxAll, setIsLoadingBuyFxAll] = useState(false);
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);
  const {
    onReAuthenticateClick,
    isTooManyPasswordAttemptsError,
    setIsTooManyPasswordAttemptsError,
  } = useReAuthenticate();
  const [nonTradingDays, setNonTradingDays] = useState<string[]>([]);
  const nonTradingDaysToUse = new Set(nonTradingDays);

  useEffect(() => {
    const getNonTradingDays = async () => {
      const detectedCurrencies = new Set<string>();

      data.forEach(({ invoicesCurrency }) =>
        detectedCurrencies.add(invoicesCurrency)
      );

      const bulkNonTradingDaysResponse = await Promise.all(
        Array.from(detectedCurrencies).map((currencyCode) =>
          Firebase.getNonTradingDays({
            ccyPair: `${currencyCode}${entityCurrencyCode}`,
          })
        )
      );

      bulkNonTradingDaysResponse.forEach((response) => {
        if (response?.success && response?.data) {
          setNonTradingDays((prevState) => [...prevState, ...response.data]);
        }
      });
    };

    getNonTradingDays();
  }, [data, entityCurrencyCode]);

  // calender props
  const minDate = getFirstValidDay(
    new Date(),
    0,
    Array.from(nonTradingDaysToUse)
  );
  const defaultActiveStartDate = initialDate
    ? dayjs(initialDate).toDate()
    : dayjs().add(7, 'days').toDate();

  useEffect(() => {
    if (
      dayjs(date, DATE_FORMAT).format(DB_DATE_FORMAT) !== initialDate &&
      dayjs(date, DATE_FORMAT).isValid()
    ) {
      const updatePaymentRunPaymentDate = async () => {
        try {
          setIsLoading(true);
          const { data: response } = await updatePaymentRun({
            paymentRunId,
            paymentDate: dayjs(date, DATE_FORMAT).format(DB_DATE_FORMAT),
          });

          if (response.data) {
            setPaymentRun(response.data);
          }
        } catch (error: any) {
          errorHandler(error);
        } finally {
          setIsLoading(false);
        }
      };

      updatePaymentRunPaymentDate();
    }
  }, [date, initialDate, paymentRunId, setPaymentRun]);

  const onEdit = useCallback(
    async (recordId: string, updatedData: IPaymentRunItemSummary) => {
      try {
        setIsLoading(true);
        const { data: response } = await updatePaymentRunItemSummary({
          paymentRunId,
          summaryItemId: recordId,
          buyFx: updatedData.buyFx,
        });

        if (response.data) {
          setPaymentRun(response.data);
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    },
    [paymentRunId, setPaymentRun]
  );

  const onBuyFxAll = async () => {
    try {
      const currentValue = !data.some(
        (item) => item.canChooseBuyFx && !item.buyFx
      );

      setIsLoadingBuyFxAll(true);
      const { data: response } = await updatePaymentRunItemSummary({
        paymentRunId,
        summaryItemId: 'all',
        buyFx: !currentValue,
      });

      if (response.data) {
        setPaymentRun(response.data);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoadingBuyFxAll(false);
    }
  };

  const tableColumns = useMemo(
    () =>
      generateAuthoriseTableColumns({
        // @ts-expect-error TS(2322) FIXME: Type '(recordId: string, updatedData: IPaymentRunI... Remove this comment to see the full error message
        onEdit,
        currencyByCode,
        entityDefaultCurrencyCode: entityCurrencyCode,
        isLoadingBuyFxAll,
      }),
    [currencyByCode, entityCurrencyCode, isLoadingBuyFxAll, onEdit]
  );

  const onSubmit = async (values: any) => {
    try {
      setIsLoadingSubmission(true);

      await onReAuthenticateClick(values);

      await confirmPaymentRun({ paymentRunId, paymentRunTotals });

      onContinue();
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoadingSubmission(false);
    }
  };

  const canSelectBuyFx = data.some(({ canChooseBuyFx }) => canChooseBuyFx);

  return (
    <>
      <Col style={{ position: 'relative' }}>
        {(isLoading || isLoadingBuyFxAll || isLoadingSubmission) && (
          <StaleLoader
            withBackdrop
            size="large"
            style={{ position: 'absolute', inset: 0, zIndex: 3 }}
          />
        )}
        <Table<IPaymentRunItemSummary>
          data={data}
          columns={tableColumns}
          isRowDisabled={(record) => !record.valid}
          defaultRowHeight={56}
          renderFooterContent={
            <Col>
              {error && (
                <Row>
                  <StaleNotification
                    title="Issue with the transfers"
                    cross={false}
                    bgColor={theme.color.red}
                    style={{ maxWidth: 'unset' }}
                  >
                    {error}
                  </StaleNotification>
                </Row>
              )}
              <Row gap={theme.spacing.m} justifyContent="space-between">
                <form
                  id="payment-run-authorise-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Col alignItems="flex-start">
                    <Row mb>
                      <Field mr>
                        <Controller
                          id="date"
                          name="date"
                          control={control}
                          rules={{
                            required: ERROR_MESSAGES.requiredField,
                          }}
                          error={errors?.date?.message}
                          render={({ onChange, value, name }) => {
                            return (
                              <InputDateUncontrolled
                                id={name}
                                view="moving"
                                label="Date to schedule payment"
                                error={errors?.date?.message}
                                value={value}
                                calendarProps={{
                                  defaultActiveStartDate,
                                  minDate,
                                  minDetail: 'month',
                                }}
                                onChange={onChange}
                                disabledDates={Array.from(nonTradingDaysToUse)}
                              />
                            );
                          }}
                        />
                      </Field>
                      {canSelectBuyFx && (
                        <Row>
                          {isLoading || isLoadingBuyFxAll ? (
                            <Row style={{ width: 46 }}>
                              <InlineLoader height="24px" />
                            </Row>
                          ) : (
                            <StaleSwitch
                              id="all-payment-run-summary-items"
                              isOn={
                                !data.some(
                                  (item) => item.canChooseBuyFx && !item.buyFx
                                )
                              }
                              handleToggle={onBuyFxAll}
                            />
                          )}
                          <Paragraph ml>
                            Book required currency conversions
                          </Paragraph>
                        </Row>
                      )}
                    </Row>

                    {isPaymentRunValid ? (
                      <StaleReAuthenticate
                        control={control}
                        errors={errors}
                        withLabel={false}
                      >
                        <Button type="submit" ml>
                          Authorize payments
                        </Button>
                      </StaleReAuthenticate>
                    ) : (
                      <Button onClick={history.goBack} type="button">
                        Back to review
                      </Button>
                    )}
                  </Col>
                </form>

                <Col
                  gap={theme.spacing.xs}
                  alignItems="flex-start"
                  justifyContent="space-between"
                  alignSelf="stretch"
                >
                  <FundsRequired paymentRunTotals={paymentRunTotals} />
                  <RedHint>
                    You can purchase FX now or anytime prior to the payment date
                  </RedHint>
                </Col>
              </Row>
            </Col>
          }
          sortable
        />
      </Col>
      {isTooManyPasswordAttemptsError && (
        <TooManyTimePasswordAttemptsPopup
          onClose={() => setIsTooManyPasswordAttemptsError(false)}
        />
      )}
    </>
  );
};

export default AuthoriseStep;
