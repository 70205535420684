import { StaleLoader } from 'components';
import { FC, useMemo } from 'react';
import { TMonthlyBreakdownContentType } from '../../types';
import ChartContributors from './components/ChartContributors/ChartContributors';
import { useStoreState } from 'state';
import {
  fillValuesForGivenPeriodWith,
  formatCashflowsAtRiskValuesPerMonth,
  formatCashflowsAtRiskValuesPerQuarter,
  formatValuesForHedgeRatioLineChart,
  hedgeRatiosToHedgeRatioLineChartData,
} from 'utils/analysis';
import useUrlValues from 'hooks/useUrlValues';
import { enrichValuesForContributorsChart, fillGapsInData } from './utils';
import ChartHedgeRatio from 'components/shared/ChartHedgeRatio/ChartHedgeRatio';
import ChartRates from './components/ChartRates/ChartRates';
import { TCashflowAtRiskNew } from 'types';
import ChartBalances from './components/ChartBalances/ChartBalances';

interface OwnProps {
  cashflowsRisksData: TCashflowAtRiskNew[];
  contentType: TMonthlyBreakdownContentType;
  activeDate: string | null;
  onChartPeriodClick: (period: string, periodIndex: number) => void;
}

const Charts: FC<OwnProps> = ({
  cashflowsRisksData,
  contentType,
  activeDate,
  onChartPeriodClick,
}) => {
  const { cashflowsRisks } = useStoreState((state) => state.ReferenceDataState);
  const { userEntity } = useStoreState((state) => state.UserState);
  const { currency, period } = useUrlValues('currency', 'period', 'dateRange');
  const currencyRiskPerCurrency = currency
    ? cashflowsRisks?.currencyRisk[currency]
    : undefined;
  const isEntityCurrency = currency === userEntity?.entityCurrency;

  const chartData = useMemo(() => {
    let dataToUse = currencyRiskPerCurrency
      ? formatCashflowsAtRiskValuesPerMonth(currencyRiskPerCurrency.perMonth)
      : [];

    if (period === 'quarter') {
      dataToUse = formatCashflowsAtRiskValuesPerQuarter(dataToUse);
    }

    return enrichValuesForContributorsChart(
      fillValuesForGivenPeriodWith({
        existingValues: dataToUse,
        periodType: period === 'quarter' ? period : 'month',
        fillWith: (period) => ({
          date: period,
          netExposure: 0,
          payables: 0,
          receivables: 0,
          purchaseOrders: 0,
          saleOrders: 0,
          prebooked: 0,
          externalHedges: 0,
          netExposureInSellCurrency: 0,
        }),
      }).slice(0, 6)
    );
  }, [currencyRiskPerCurrency, period]);

  const { minValues, maxValues } = hedgeRatiosToHedgeRatioLineChartData({
    hedgeRatios: fillGapsInData(
      userEntity.riskSettingHedgeRatio,
      period === 'quarter'
    ),
    isQuarterFormat: period === 'quarter',
  });

  const hedgeRatioChartData = currencyRiskPerCurrency
    ? [
        formatValuesForHedgeRatioLineChart(
          currencyRiskPerCurrency,
          period === 'quarter'
        ),
        {
          id: 'min',
          data: minValues,
        },
        {
          id: 'max',
          data: maxValues,
        },
      ]
    : [];

  if (!currencyRiskPerCurrency) {
    return <StaleLoader size="large" />;
  }

  if (contentType === 'hedgeRatio' && !isEntityCurrency) {
    return (
      <ChartHedgeRatio
        data={hedgeRatioChartData}
        onLinePointClick={onChartPeriodClick}
        activeDate={activeDate}
        isMonthly={period === 'month'}
      />
    );
  }

  if (contentType === 'balances') {
    return (
      <ChartBalances
        data={cashflowsRisksData}
        onChartPeriodClick={onChartPeriodClick}
      />
    );
  }

  if (contentType === 'contributors') {
    return (
      <ChartContributors
        data={chartData}
        onChartBarClick={onChartPeriodClick}
        activeDate={activeDate}
      />
    );
  }

  if (contentType === 'rates' && !isEntityCurrency) {
    return <ChartRates data={cashflowsRisksData} />;
  }

  return null;
};

export default Charts;
