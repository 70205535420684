import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { StaleParagraphMedium } from '../..';

export const Wrapper = styled.div<{ color: string; duration: number }>(
  ({ theme, color, duration }) => css`
    padding: 12px;
    border-radius: 8px;
    color: ${theme.color.white};
    background-color: ${color};
    width: ${isMobile ? '344px' : '400px'};
    min-height: 64px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    opacity: 0;
    animation: ${`show ${duration}ms`};
    z-index: 13;

    ${StaleParagraphMedium} {
      color: ${theme.color.white};
      flex: 1;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }

    @keyframes show {
      0% {
        opacity: 0;
        top: 0;
      }

      20% {
        opacity: 1;
        top: 80px;
      }

      80% {
        opacity: 1;
        top: 80px;
      }

      100% {
        opacity: 0;
        top: 0;
      }
    }
  `
);
