import { FC } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Icon, PermissionsChecker, Row, StaleLoader } from 'components';
import Button from 'components/shared/Button/Button';
import Card from 'components/shared/Card/Card.styles';
import CounterTitle from 'components/shared/CounterTitle/CounterTitle';
import useSwitchClients from 'hooks/useSwitchClients';
import { getXeroNewClientLink } from 'utils/links';

import { Flex } from 'components/shared/Flex/Flex.styles';

const SwitchClients: FC<RouteComponentProps> = () => {
  const theme = useTheme();
  const history = useHistory();

  const { clients, isLoadingClients, onSwitchAccount } = useSwitchClients();

  return (
    <div style={{ backgroundColor: '#fr2f2f2' }}>
      {isLoadingClients && <StaleLoader size="large" />}
      {!isLoadingClients && clients?.length && (
        <>
          <Row>
            <CounterTitle
              mr
              title="Clients"
              count={!!clients.length ? clients.length : undefined}
            />

            <PermissionsChecker action="create" resource="entities">
              <Button
                variant="link"
                onClick={() => history.push(getXeroNewClientLink())}
              >
                <Icon width="16px" height="16px" icon="show-more-ico" />
                Add from Xero
                <Icon ml width="16px" height="16px" icon="xero-ico" />
              </Button>
            </PermissionsChecker>
          </Row>

          {clients.map((entity) => (
            <Card key={entity.name} mt mtValue={theme.spacing.m}>
              <Row justifyContent="space-between">
                {entity.name}
                <Flex flexBasis="20%">
                  <Button onClick={() => onSwitchAccount(entity.id)}>
                    Switch Client
                  </Button>
                </Flex>
              </Row>
            </Card>
          ))}
        </>
      )}
    </div>
  );
};

export default SwitchClients;
