import { mergeWith, zipWith } from 'lodash';
import { useEffect, useState } from 'react';
import {
  getPastPerformanceDataFromExternalSource,
  getPastPerformanceDataPerMonthByCurrency,
} from 'services/firebase/analysis';
import { useStoreState } from 'state';
import {
  IPnlDataPerMonth,
  TPastPerformanceDataFromExternalSource,
} from 'types/analyses';

const usePastPerformanceDataPerMonth = ({
  currencyCodes,
  currencyCode,
  from,
}: {
  currencyCodes?: string[];
  currencyCode: string;
  from: string;
}) => {
  const { entityId } = useStoreState((state) => state.UserState);
  const [data, setData] = useState<IPnlDataPerMonth[]>([]);
  const [dataPerCurrency, setDataPerCurrency] = useState<
    Record<string, IPnlDataPerMonth[]>
  >({});
  const [externalData, setExternalData] = useState<
    TPastPerformanceDataFromExternalSource[]
  >([]);

  useEffect(() => {
    if (entityId) {
      const fetchPastPerformanceDataPerMonth = async () => {
        if (currencyCode === 'all' && currencyCodes) {
          const promises = currencyCodes.map((currency) =>
            getPastPerformanceDataPerMonthByCurrency({
              entityId,
              currency,
              from,
            })
          );

          const results = await Promise.all(promises);

          const resultsPerCurrency: Record<string, IPnlDataPerMonth[]> = {};

          results.forEach((result, index) => {
            resultsPerCurrency[currencyCodes[index]] = result;
          });

          const mergeCustomizer = (objValue: any, srcValue: any) => {
            if (typeof objValue === 'number' && typeof srcValue === 'number') {
              return objValue + srcValue;
            }

            return undefined;
          };
          // Merge the arrays into a single array of objects with summed values
          const mergedArray = zipWith(...results, (...objs) =>
            mergeWith({}, ...objs, mergeCustomizer)
          );

          const externalDataResult = await getPastPerformanceDataFromExternalSource(
            {
              entityId,
              from,
            }
          );

          setExternalData(externalDataResult);
          setData(mergedArray);
          setDataPerCurrency(resultsPerCurrency);
        } else {
          const result = await getPastPerformanceDataPerMonthByCurrency({
            entityId,
            currency: currencyCode,
            from,
          });

          setData(result);
        }
      };

      fetchPastPerformanceDataPerMonth();
    }
  }, [currencyCode, currencyCodes, entityId, from]);

  const calculatedData = data.reduce(
    (
      {
        totalTurnover,
        totalRealisedImpact,
        totalUnrealisedImpact,
        averageNetOutstanding,
        totalFxCost,
        totalTransactionCost,
        totalGainLoss,
      },
      item
    ) => ({
      totalTurnover: totalTurnover + item.turnover,
      totalRealisedImpact: totalRealisedImpact + item.totalFxImpactForPaid,
      totalUnrealisedImpact:
        totalUnrealisedImpact +
        (item.fxImpactTotal ? item.fxImpactTotal - item.fxImpactForPaid : 0),
      averageNetOutstanding:
        averageNetOutstanding + item.averageOutstandingBalance,
      totalFxCost: totalFxCost + item.fxCost,
      totalTransactionCost: totalTransactionCost + item.transactionCost,
      totalGainLoss:
        totalGainLoss +
        item.totalFxImpactForPaid +
        item.transactionCost +
        (item.fxImpactTotal ? item.fxImpactTotal - item.fxImpactForPaid : 0),
    }),
    {
      totalRealisedImpact: 0,
      totalUnrealisedImpact: 0,
      averageNetOutstanding: 0,
      totalTurnover: 0,
      totalFxCost: 0,
      totalTransactionCost: 0,
      totalGainLoss: 0,
    }
  );

  const calculatedExternalData = externalData.reduce(
    (acc, item) => {
      return {
        totalExternalGainLoss: acc.totalExternalGainLoss + item.pnl,
      };
    },
    {
      totalExternalGainLoss: 0,
    }
  );

  return {
    data,
    dataPerCurrency,
    externalData,
    averageMonthlyRealisedImpact:
      calculatedData.totalRealisedImpact / data.length,
    averageMonthlyUnrealisedImpact:
      calculatedData.totalUnrealisedImpact / data.length,
    averageTurnover: calculatedData.totalTurnover / data.length,
    averageMonthlyFxCost: calculatedData.totalFxCost / data.length,
    averageTransactionCost: calculatedData.totalTransactionCost / data.length,
    averageGainLoss: calculatedData.totalGainLoss / data.length,
    averageExternalGainLoss:
      calculatedExternalData.totalExternalGainLoss / externalData.length,
    ...calculatedData,
    ...calculatedExternalData,
  };
};

export default usePastPerformanceDataPerMonth;
