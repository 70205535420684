import { useEffect, useState } from 'react';
import { getPastPerformanceDataPerRecordByCurrency } from 'services/firebase/analysis';
import { useStoreState } from 'state';
import {
  IPastPerformanceItemInvoice,
  isPastPerformanceItemInvoice,
  TPastPerformanceItem,
} from 'types';
import { fetchDataInsideUseEffect } from 'utils/fetchers';

const useInvoicesPastPerformanceData = ({
  currencies,
}: {
  currencies: string[];
}) => {
  const { entityId } = useStoreState((state) => state.UserState);
  const [data, setData] = useState<IPastPerformanceItemInvoice[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPastPerformanceData = async () => {
      if (!entityId) {
        return;
      }

      await fetchDataInsideUseEffect<TPastPerformanceItem[][]>({
        fetchData: () =>
          Promise.all(
            currencies.map(async (currencyCode) =>
              getPastPerformanceDataPerRecordByCurrency({
                entityId,
                currency: currencyCode,
              })
            )
          ),
        setData: (response) =>
          setData(response.flat().filter(isPastPerformanceItemInvoice)),
        setIsLoading: setIsLoading,
      });
    };

    getPastPerformanceData();
  }, [entityId, currencies]);

  return {
    data,
    isLoading,
  };
};

export default useInvoicesPastPerformanceData;
