import useCodatIntegration from './useCodatIntegration';

const useNetSuite = () => {
  const {
    onImportData: onImportNetSuiteData,
    isLoading: isLoadingNetSuitesData,
    isIntegrated: isIntegratedWithNetSuite,
    onLogin: onNetSuitesLogin,
    isImporting: isImportingNetSuiteData,
    queueNewCodatSync: queueNewNetSuiteSync,
    syncStatus: netSuiteSyncStatus,
  } = useCodatIntegration({
    platformNames: ['Oracle NetSuite'],
  });

  return {
    isImportingNetSuiteData,
    isIntegratedWithNetSuite,
    isLoadingNetSuitesData,
    onImportNetSuiteData,
    onNetSuitesLogin,
    queueNewNetSuiteSync,
    netSuiteSyncStatus,
  };
};

export default useNetSuite;
