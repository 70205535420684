import { Bar, BarDatum, BarSvgProps } from '@nivo/bar';

// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import AutoSizer from 'react-virtualized-auto-sizer';

const ChartBar = <T extends BarDatum>(
  props: Omit<BarSvgProps<T>, 'height' | 'width'>
) => {
  return (
    <AutoSizer>
      {({
        height,
        width
      }: any) => <Bar height={height} width={width} {...props} />}
    </AutoSizer>
  );
};

export default ChartBar;
