import { Paragraph, Row, StaleInputSelect, Col } from 'components';
import { Dispatch, FC, SetStateAction, useState } from 'react';

import {
  CurrencyInfoTableWrapper,
  CurrencyInfoWrapper,
} from './CurrencyInfo.styles';

import { useStoreState } from 'state';
import usePastPerformanceDataPerMonth from 'pages/Reports/hooks/usePastPerformanceDataPerMonth';

import { useTheme } from 'styled-components';
import { StepperItem } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import dayjs from 'dayjs';
import { DB_MONTH_FORMAT } from 'variables';
import { TReportsContentType } from 'pages/Reports/types';
import { AccountsRow } from 'components/shared/AccountsRow/AccountsRow.styles';
import Field from 'components/shared/Field/Field.styles';
import InfoValue from '../InfoValue/InfoValue';
import InfoTitle from './components/InfoTitle/InfoTitle';
import Charts from './components/Charts/Charts';
import { TNumberOFMonthsType } from './types';
import { PERIOD_VALUES, TABS, TABS_FOR_ALL_CURRENCIES } from './consts';
import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';
import { getCurrencyDropdownValues } from './utils';
import useXero from 'hooks/useXero';

interface OwnProps {
  contentType: TReportsContentType;
  setContentType: Dispatch<SetStateAction<TReportsContentType>>;
  activeMonth: string;
  setActiveMonth: Dispatch<SetStateAction<string>>;
  currencyCodes: string[];
  setActiveCurrencyCode: (code: string) => void;
  activeCurrencyCode: string;
}

const CurrencyInfo: FC<OwnProps> = ({
  setContentType,
  contentType,
  activeMonth,
  setActiveMonth,
  currencyCodes,
  setActiveCurrencyCode,
  activeCurrencyCode,
}) => {
  const theme = useTheme();
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { isIntegrated: isIntegratedWithXero } = useXero();
  const [months, setMonths] = useState<TNumberOFMonthsType>(6);
  const entityCurrency = currencyByCode(entityCurrencyCode);

  const {
    averageMonthlyRealisedImpact,
    averageMonthlyUnrealisedImpact,
    totalTurnover,
    averageTurnover,
    totalRealisedImpact,
    totalUnrealisedImpact,
    data,
    dataPerCurrency,
    externalData,
    totalGainLoss,
    totalTransactionCost,
    averageGainLoss,
    averageTransactionCost,
    totalExternalGainLoss,
    averageExternalGainLoss,
  } = usePastPerformanceDataPerMonth({
    currencyCodes,
    currencyCode: activeCurrencyCode,
    from: dayjs()
      .subtract(months - 1, 'months')
      .format(DB_MONTH_FORMAT),
  });

  const dropdownValues = getCurrencyDropdownValues({
    currencyCodes,
    currencyByCode,
    isIntegratedWithXero: !!isIntegratedWithXero,
  });

  const onSelectCurrency = (item: ISelectItem) => {
    if (activeCurrencyCode !== 'all' && item.value === 'all') {
      setContentType('pnl');
    }

    if (activeCurrencyCode === 'all' && item.value !== 'all') {
      setContentType('volumes');
    }

    setActiveCurrencyCode(item.value);
  };

  return (
    <Row flex={1} columnGap={theme.spacing.s}>
      <CurrencyInfoWrapper>
        <AccountsRow columnGap={theme.spacing.xxl}>
          <Field fluid flex={1}>
            <StaleInputSelect
              selected={activeCurrencyCode}
              data={dropdownValues}
              onSelect={onSelectCurrency}
              id="backtester-currency"
              style={{ width: '100%' }}
            />
          </Field>
          <Field fluid flex={1}>
            <StaleInputSelect
              selected={months}
              data={PERIOD_VALUES}
              onSelect={(item) => setMonths(item.value)}
              id="backtester-data-duration"
              style={{ width: '100%' }}
            />
          </Field>
        </AccountsRow>

        <Col>
          <AccountsRow flex={1}>
            <Row flex={1.2} justifyContent="flex-start">
              <Paragraph variant="thin">Metrics</Paragraph>
            </Row>
            <Row flex={1} justifyContent="flex-end">
              <Paragraph variant="thin">Total</Paragraph>
            </Row>
            <Row flex={1} justifyContent="flex-end">
              <Paragraph variant="thin">Monthly</Paragraph>
            </Row>
          </AccountsRow>
          {activeCurrencyCode !== 'all' && (
            <AccountsRow flex={1}>
              <InfoTitle
                title="FX Turnover"
                info="Total turnover (in your main currency) of foreign currency
                  cashflows paid or received over the period."
              />
              <InfoValue
                value={totalTurnover}
                currencySymbol={entityCurrency?.symbol}
              />
              <InfoValue
                value={averageTurnover}
                currencySymbol={entityCurrency?.symbol}
              />
            </AccountsRow>
          )}

          {activeCurrencyCode === 'all' && (
            <AccountsRow flex={1}>
              <InfoTitle
                title="FX Gains & Losses (Xero)"
                info="FX Gains & Losses (in your main currency) from Xero."
              />
              <InfoValue
                value={totalExternalGainLoss}
                currencySymbol={entityCurrency?.symbol}
              />
              <InfoValue
                value={averageExternalGainLoss}
                currencySymbol={entityCurrency?.symbol}
              />
            </AccountsRow>
          )}

          <AccountsRow flex={1}>
            <InfoTitle
              title="Realised impact from invoices"
              info="Changes in the value of foreign currency cashflows due to
                  exchange rate fluctuations between cashflow date and payment
                  date for each cashflow."
            />
            <InfoValue
              value={totalRealisedImpact}
              currencySymbol={entityCurrency?.symbol}
            />
            <InfoValue
              value={averageMonthlyRealisedImpact}
              currencySymbol={entityCurrency?.symbol}
            />
          </AccountsRow>
          <AccountsRow flex={1}>
            <InfoTitle
              title="Transaction cost"
              info="Estimated costs of exchanging currencies based on the
                  difference between the official FX rates (Bank of England
                  data) on each payment date and actual rates used for each
                  cashflow payment."
            />
            <InfoValue
              value={totalTransactionCost}
              currencySymbol={entityCurrency?.symbol}
            />
            <InfoValue
              value={averageTransactionCost}
              currencySymbol={entityCurrency?.symbol}
            />
          </AccountsRow>
          <AccountsRow flex={1}>
            <InfoTitle
              title="Unrealised Impact"
              info="Changes in the value of foreign currency cashflows due to
                  exchange rate fluctuations between cashflow date and last
                  available rate."
            />
            <InfoValue
              value={totalUnrealisedImpact}
              currencySymbol={entityCurrency?.symbol}
            />
            <InfoValue
              value={averageMonthlyUnrealisedImpact}
              currencySymbol={entityCurrency?.symbol}
            />
          </AccountsRow>
          <AccountsRow flex={1}>
            <InfoTitle
              title="Explained gains & losses"
              info="Sum of market impact and transaction costs for a given period."
            />
            <InfoValue
              value={totalGainLoss}
              currencySymbol={entityCurrency?.symbol}
            />
            <InfoValue
              value={averageGainLoss}
              currencySymbol={entityCurrency?.symbol}
            />
          </AccountsRow>
        </Col>
      </CurrencyInfoWrapper>

      <CurrencyInfoTableWrapper>
        <Row justifyContent="flex-start">
          {(activeCurrencyCode === 'all' ? TABS_FOR_ALL_CURRENCIES : TABS).map(
            ({ title, id }) => (
              <StepperItem
                onClick={() => setContentType(id)}
                key={id}
                current={id === contentType}
              >
                {title}
              </StepperItem>
            )
          )}
        </Row>

        <Row alignItems="stretch" flex={1}>
          <Charts
            data={data}
            dataPerCurrency={dataPerCurrency}
            activeMonth={activeMonth}
            setActiveMonth={setActiveMonth}
            contentType={contentType}
            externalData={externalData}
          />
        </Row>
      </CurrencyInfoTableWrapper>
    </Row>
  );
};

export default CurrencyInfo;
