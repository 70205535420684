import { DefaultTheme } from 'styled-components';
import { InlineLoader, Paragraph, PermissionsChecker, Row } from 'components';
import { Column } from 'react-table';
import { Nullable } from 'types';
import { IEntityUser } from 'state/user';
import Button from 'components/shared/Button/Button';
import Pill from 'components/shared/Pill/Pill';
import {
  filterOutNonPackageSpecificRoles,
  filterOutPackageSpecificRoles,
} from './utils';
import { IPermitRoleV2 } from 'types/permissions';

interface OwnProps {
  userId: Nullable<string>;
  onEditEntityUser: (member: IEntityUser) => void;
  isLoadingRoles: boolean;
  theme: DefaultTheme;
  isPermitFeatureFlagEnabled: boolean;
}

export const generateEntityUserTableColumns = ({
  isLoadingRoles,
  onEditEntityUser,
  isPermitFeatureFlagEnabled,
  theme,
  userId,
}: OwnProps): Column<IEntityUser>[] => {
  const columns: Column<IEntityUser>[] = [
    {
      accessor: 'name',
      Header: 'Name',
      Cell: ({ row, value }) => (
        <Paragraph
          variant="bold"
          color={userId === row.original.id ? 'emeraldDark' : 'dark'}
        >
          {value}
        </Paragraph>
      ),
      width: 100,
    },
    {
      accessor: 'email',
      Header: 'Mail',
      Cell: ({ value }) => <Paragraph>{value}</Paragraph>,
      width: 100,
    },
    {
      accessor: 'roles',
      Header: `Roles (${isPermitFeatureFlagEnabled ? 'Beta' : 'coming soon'})`,
      disableSortBy: true,
      Cell: ({ value }) => (
        <Row
          flex={1}
          flexWrap="wrap"
          gap={theme.spacing.s}
          justifyContent="flex-start"
        >
          {filterOutPackageSpecificRoles(value ?? []).map(
            (role: IPermitRoleV2) => (
              <Pill text={role.role ?? ''} />
            )
          )}
        </Row>
      ),
      width: 120,
    },
    {
      Header: `Package roles (${
        isPermitFeatureFlagEnabled ? 'Beta' : 'coming soon'
      })`,
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <Row flexWrap="wrap" gap={theme.spacing.m} justifyContent="flex-start">
          {filterOutNonPackageSpecificRoles(row?.original?.roles ?? []).map(
            (role: IPermitRoleV2) => (
              <Pill text={role.role ?? ''} />
            )
          )}
        </Row>
      ),
      width: 120,
    },
    {
      id: 'id',
      accessor: 'id',
      disableSortBy: true,
      Header: () => null,
      Cell: ({ row }) => {
        return (
          <Row flex={1} justifyContent="flex-end">
            <PermissionsChecker action="update" resource="settings">
              {isLoadingRoles && <InlineLoader />}
              {!isLoadingRoles && (
                <Button
                  onClick={() => onEditEntityUser(row.original)}
                  variant="link"
                  disabled={!isPermitFeatureFlagEnabled}
                  icon="edit-ico"
                />
              )}
            </PermissionsChecker>
          </Row>
        );
      },
      width: 60,
      minWidth: 55,
    },
  ];

  return columns;
};
