import { FC, useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useQuery } from 'hooks';
import { getGoToStepQuery } from 'utils';
import ReviewStep from './components/ReviewStep/ReviewStep';
import AuthoriseStep from './components/AuthoriseStep/AuthoriseStep';
import ConfirmationStep from './components/ConfirmationStep/ConfirmationStep';
import HorizontalStepper from 'pages/PaymentRun/components/HorizontalStepper/HorizontalStepper';
import { getDraftPaymentRun } from 'services/paymentRuns';
import { IPaymentRun } from 'types/paymentRuns';
import { StaleLoader } from 'components';
import { errorHandler } from 'utils/errors';
import { getInvoicesPageLink } from 'utils/links';
import { useStoreState } from 'state';

const PaymentRun: FC = () => {
  const history = useHistory<{ invoiceIds: string[] } | undefined>();
  const { entityId } = useStoreState(({ UserState }) => UserState);
  const URLQuery = useQuery();
  const { url } = useRouteMatch();
  const activeStep = URLQuery.get('step');

  const [paymentRun, setPaymentRun] = useState<IPaymentRun>();
  const [isLoading, setIsLoading] = useState(true);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (entityId && !paymentRun) {
      const getPaymentRunHandler = async () => {
        try {
          const { data } = await getDraftPaymentRun({
            entityId,
            generate: true,
          });

          setPaymentRun(data.data);
          setIsLoading(false);
        } catch (error: any) {
          errorHandler(error);
          history.goBack();
        }
      };

      getPaymentRunHandler();
    }
  }, [entityId, history, paymentRun]);

  useEffect(() => {
    if (
      activeStep &&
      ((Number(activeStep) > 1 && !paymentRun) ||
        (Number(activeStep) !== 3 && isComplete))
    ) {
      history.replace(
        getInvoicesPageLink({
          currency: 'all',
          tab: 'outstanding',
        })
      );
    }
  }, [activeStep, history, isComplete, paymentRun]);

  const goToStep = (stepNumber: number) =>
    history.push(getGoToStepQuery(url, URLQuery, stepNumber));

  const renderSteps = (step: string) => {
    return (
      <HorizontalStepper
        activeStep={parseInt(step)}
        data={[
          {
            title: 'Review',
            onClick: () => goToStep(1),
            disabled: parseInt(step) === 3,
          },
          {
            title: 'Authorise',
            onClick: () => goToStep(2),
            disabled: parseInt(step) === 3,
          },
          {
            title: 'Confirmation',
            onClick: () => goToStep(3),
            disabled: parseInt(step) === 3,
          },
        ]}
      >
        {renderContent()}
      </HorizontalStepper>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return <StaleLoader size="large" />;
    }

    if (!activeStep || !paymentRun) {
      return null;
    }

    switch (parseInt(activeStep)) {
      case 1:
        return (
          <ReviewStep
            paymentRunId={paymentRun.id}
            isCombineSameContacts={!!paymentRun.instructions.combine}
            // @ts-expect-error TS(2740) FIXME: Type 'IPaymentRunItemSummary' is missing the follo... Remove this comment to see the full error message
            data={paymentRun.paymentRunItemSummary.reduce(
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              (acc, item) => [...acc, ...item.breakdownByRecipient],
              []
            )}
            localCurrencyCode={paymentRun.localCurrency}
            // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<IPaymentRun | undefi... Remove this comment to see the full error message
            setPaymentRun={setPaymentRun}
            onContinue={() => goToStep(2)}
            error={paymentRun.error}
          />
        );
      case 2:
        return (
          <AuthoriseStep
            paymentRunId={paymentRun.id}
            paymentRunTotals={paymentRun.paymentRunTotals}
            isPaymentRunValid={paymentRun.valid}
            initialDate={paymentRun.instructions.paymentDate ?? ''}
            data={paymentRun.paymentRunItemSummary || []}
            // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<IPaymentRun | undefi... Remove this comment to see the full error message
            setPaymentRun={setPaymentRun}
            onContinue={() => {
              goToStep(3);
              setIsComplete(true);
            }}
            error={paymentRun.error}
          />
        );
      case 3:
        return (
          <ConfirmationStep
            paymentRunTotals={paymentRun.paymentRunTotals}
            paymentDate={paymentRun.instructions.paymentDate ?? ''}
            data={paymentRun.paymentRunItemSummary || []}
          />
        );
      default:
        return null;
    }
  };

  if (!activeStep) {
    return null;
  }

  return renderSteps(activeStep);
};

export default PaymentRun;
