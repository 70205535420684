import { CustomLayerProps, Datum } from '@nivo/line';
import { line } from 'd3-shape';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { FC } from 'react';
import ChartHedgeRatioPoint from './components/ChartHedgeRatioPoint/ChartHedgeRatioPoint';
import dayjs from 'dayjs';
import { MONTH_DATE_FORMAT, POPULAR_CURRENCIES_WITH_COLORS } from 'variables';
import { getQuarterDateToUse, getScaleX, getScaleY } from './utils';

interface OwnProps {
  customLayerProps: CustomLayerProps;
  activeMonth?: string | null;
  onClick?: (period: string, periodIndex: number) => void;
  minValue: number;
  maxValue: number;
  minDate: number;
  maxDate: number;
  isMonthly: boolean;
  withStaticColorPerCurrency?: boolean;
}

const ChartHedgeRatioLinesLayer: FC<OwnProps> = ({
  customLayerProps,
  activeMonth,
  onClick,
  minValue,
  maxValue,
  minDate,
  maxDate,
  isMonthly,
  withStaticColorPerCurrency = false,
}) => {
  const lineGenerator = line<Datum>()
    .x((dataItem) => {
      if (typeof dataItem.x === 'string') {
        const scaleX = getScaleX({
          minDate,
          maxDate,
          width: customLayerProps.innerWidth,
        });

        let dateToUse = dayjs(dataItem.x, MONTH_DATE_FORMAT);

        if (!isMonthly) {
          dateToUse = getQuarterDateToUse(dataItem.x);
        }

        return scaleX(dateToUse.toDate().getTime());
      }

      return 0;
    })
    .y((dataItem) => {
      if (typeof dataItem.y === 'number') {
        const scaleY = getScaleY({
          height: customLayerProps.innerHeight,
          minValue,
          maxValue,
        });

        return scaleY(dataItem.y);
      }

      return 0;
    });

  return (
    <>
      {customLayerProps.data.map((serie, index) => {
        const colorToUse =
          withStaticColorPerCurrency && serie.id !== 'min' && serie.id !== 'max'
            ? POPULAR_CURRENCIES_WITH_COLORS[serie.id]
            : schemeCategory10[index];

        return (
          <>
            <path
              d={lineGenerator(serie.data) || undefined}
              fill="none"
              stroke={colorToUse}
              style={{ pointerEvents: 'none' }}
            />

            {serie.data.map((data, index) => {
              const { x, y, buyCurrency, sellCurrency } = data;

              // min max values do not have currencies
              if (!buyCurrency || !sellCurrency) {
                return null;
              }

              const scaleX = getScaleX({
                minDate,
                maxDate,
                width: customLayerProps.innerWidth,
              });
              const scaleY = getScaleY({
                height: customLayerProps.innerHeight,
                minValue,
                maxValue,
              });
              let dateToUse = dayjs(x, MONTH_DATE_FORMAT);

              if (!isMonthly && typeof x === 'string') {
                dateToUse = getQuarterDateToUse(x);
              }

              return (
                <ChartHedgeRatioPoint
                  key={x?.toString()}
                  data={data as Datum}
                  activeMonth={activeMonth}
                  onClick={onClick}
                  cx={scaleX(dateToUse.toDate().getTime())}
                  cy={scaleY(Number(y))}
                  color={colorToUse}
                  index={index}
                />
              );
            })}
          </>
        );
      })}
    </>
  );
};

export default ChartHedgeRatioLinesLayer;
