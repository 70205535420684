import styled from 'styled-components';
import { Paragraph } from 'components/shared/Typography/Typography';
import { Row } from 'components/shared/Row/Row';

export const FinalStepInfoWrapper = styled.div`
  ${Row} {
    margin-top: 12px;

    &:first-child {
      margin-top: 16px;
    }

    ${Paragraph} {
      display: flex;
      align-items: center;
    }
  }
`;

export const RedHint = styled(Paragraph)`
  color: ${({ theme }) => theme.color.red};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    text-align: right;
  }
`;
