import { BarCustomLayerProps, BarDatum } from '@nivo/bar';
import { FC } from 'react';
import { useTheme } from 'styled-components';

interface OwnProps {
  data: BarCustomLayerProps<BarDatum>;
}

const ChartContributorsLegendsLayer: FC<OwnProps> = ({ data }) => {
  const { color } = useTheme();

  return (
    <g>
      <rect
        fill={color.red}
        x={0}
        y={data.height - 40}
        style={{
          width: 12,
          height: 12,
        }}
      />

      <text
        y={data.height - 29}
        x={18}
        style={{
          fontSize: 12,
        }}
      >
        Payables
      </text>

      <rect
        fill={color.emeraldDark}
        x={85}
        y={data.height - 40}
        style={{
          width: 12,
          height: 12,
        }}
      />

      <text
        y={data.height - 29}
        x={103}
        style={{
          fontSize: 12,
        }}
      >
        Receivables
      </text>

      <rect
        fill={color.blueLight}
        x={186}
        y={data.height - 40}
        style={{
          width: 12,
          height: 12,
        }}
      />

      <text
        y={data.height - 29}
        x={204}
        style={{
          fontSize: 12,
        }}
      >
        Prebooked
      </text>

      <rect
        fill={color.redDark}
        x={276}
        y={data.height - 40}
        style={{
          width: 12,
          height: 12,
        }}
      />

      <text
        y={data.height - 29}
        x={296}
        style={{
          fontSize: 12,
        }}
      >
        Purchase orders
      </text>

      <rect
        fill={color.green}
        x={396}
        y={data.height - 40}
        style={{
          width: 12,
          height: 12,
        }}
      />

      <text
        y={data.height - 29}
        x={416}
        style={{
          fontSize: 12,
        }}
      >
        Sale orders
      </text>
    </g>
  );
};

export default ChartContributorsLegendsLayer;
