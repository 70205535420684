import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { Col, StaleInputRadioNew, StaleSubTitleBold, Title } from 'components';
import ExchangeSummary from '../ExchangeSummary/ExchangeSummary';
import { Notify } from 'utils';
import { exchangeCurrency } from 'services/firebase/conversions';
import { IConversion } from 'types/conversions';
import StaleInfoBox from 'components/shared/StaleInfoBox/StaleInfoBox';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import { UseExchangeDetailsReturnValues } from 'pages/CurrencyExchange/hooks/useExchangeDetails';
import Button from 'components/shared/Button/Button';
import { Controller, useForm } from 'react-hook-form7';

interface OwnProps {
  onContinue: (conversionData: IConversion) => void;
  savedValues: UseExchangeDetailsReturnValues;
}

interface IExchangeFunding {
  delayedFunding: boolean;
}

const Review: FC<OwnProps> = ({ onContinue, savedValues }) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values: IExchangeFunding) => {
    try {
      setIsLoading(true);

      const {
        sellAmountAsNumber: sellAmount,
        buyAmountAsNumber: buyAmount,
        sellCurrency,
        buyCurrency,
        rateToUse,
        rateType,
        selectedRateContract,
        conversionFeeRate,
        invoiceId,
      } = savedValues;

      const { delayedFunding } = values;

      if (!rateToUse) {
        return;
      }

      const { data } = await exchangeCurrency({
        sellAmount,
        sellCurrency: sellCurrency.code,
        buyCurrency: buyCurrency.code,
        buyAmount,
        rate: rateToUse,
        rateType,
        contractId: selectedRateContract?.id,
        conversionFeeRate,
        invoiceId: invoiceId || undefined,
        delayedFunding,
      });

      if (data.success && data.data) {
        onContinue(data.data);
      } else {
        Notify.error(data.message ?? '');
      }
    } catch (error: any) {
      console.warn('Failed to exchange currency. Error: ', error);
      Notify.error(error.response?.data?.error);
    } finally {
      setIsLoading(false);
    }
  };

  const {
    sellAmountAsNumber: sellAmount,
    buyAmountAsNumber: buyAmount,
    sellCurrency,
    buyCurrency,
    rateToUse,
    conversionFeeRate,
    activeBalance,
    sellAmountAsNumber,
  } = savedValues;

  const { control, handleSubmit } = useForm<IExchangeFunding>({
    defaultValues: {
      delayedFunding:
        !!activeBalance && activeBalance.amount < sellAmountAsNumber,
    },
  });

  return (
    <FlowStepWrapper>
      <FlowStepContent>
        <FlowContentLeft>
          <Title variant="h3">How will you fund the exchange?</Title>

          <Col
            as="form"
            id="create-exchange-form"
            onSubmit={handleSubmit(onSubmit)}
            mt
            mb
            gap={theme.spacing.m}
          >
            <Controller
              name="delayedFunding"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <StaleInputRadioNew
                    id="accountBalance"
                    label="Use the existing account balance"
                    checked={!value}
                    onChange={() => onChange(false)}
                    disabled={
                      activeBalance && activeBalance.amount < sellAmountAsNumber
                    }
                  />
                  <StaleInputRadioNew
                    id="bankAccount"
                    label="Transfer funds from my bank account"
                    checked={value}
                    onChange={() => onChange(true)}
                  />
                </>
              )}
            />
          </Col>

          <StaleInfoBox text="Check details & confirm" icon="alert-ico" />
        </FlowContentLeft>

        <FlowContentRight>
          <StaleSubTitleBold>Summary</StaleSubTitleBold>
          <ExchangeSummary
            sellCurrency={sellCurrency}
            buyCurrency={buyCurrency}
            buyAmountAsNumber={buyAmount}
            sellAmountAsNumber={sellAmount}
            rate={rateToUse}
            conversionFeeRate={conversionFeeRate}
          />
        </FlowContentRight>
      </FlowStepContent>

      <FlowStepFooter>
        <Button form="create-exchange-form" isLoading={isLoading}>
          Confirm
        </Button>
      </FlowStepFooter>
    </FlowStepWrapper>
  );
};

export default Review;
