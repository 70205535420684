import { useState } from 'react';
import { requestFreeTrial } from 'services/firebase';
import { useStoreState } from 'state';
import { Notify } from 'utils';
import { TEntityPackageKeys } from 'types/permissions';

const useRequestFreeTrial = () => {
  const { entityId } = useStoreState(({ UserState }) => UserState);
  const [isLoading, setIsLoading] = useState(false);

  const onRequestFreeTrial = async (
    trialPackage: TEntityPackageKeys,
    onFinish?: () => void
  ) => {
    if (!entityId) {
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await requestFreeTrial(entityId, trialPackage);

      if (data.success) {
        Notify.success('Your free trial will be activated soon.');
      } else if (data?.message) {
        Notify.error(data.message);
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        Notify.error(error.response.data.error);
      }
    } finally {
      onFinish?.();
      setIsLoading(false);
    }
  };

  return {
    onRequestFreeTrial,
    isLoading,
  };
};

export default useRequestFreeTrial;
