import {
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  ReactNode,
  RefObject,
  useState,
} from 'react';
import { useTheme } from 'styled-components';
import Icon from '../Icon/Icon';
import {
  Input,
  InputRightButton,
  InputBaseWrapper,
  MovingLabel,
} from './InputBase.styles';
import { InputErrorMessage } from '../InputErrorMessage/InputErrorMessage.styles';

interface OwnProps
  extends Pick<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    | 'value'
    | 'onChange'
    | 'placeholder'
    | 'type'
    | 'id'
    | 'autoFocus'
    | 'disabled'
    | 'min'
    | 'max'
  > {
  label?: string;
  wrapperRef?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null;
  icon?: string;
  error?: string;
  showSpinButtons?: boolean;
  renderInputComponent?: (params: {
    onFocus: () => void;
    onBlur: () => void;
    isFocused: boolean;
  }) => ReactNode;
  onRightButtonClick?: () => unknown;
  min?: number;
  max?: number;
}

const InputBase: FC<OwnProps> = ({
  value,
  onChange,
  placeholder,
  type,
  id,
  autoFocus,
  error,
  label,
  icon,
  wrapperRef,
  renderInputComponent,
  onRightButtonClick,
  disabled,
  showSpinButtons,
  min,
  max,
}) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <InputBaseWrapper
        focused={isFocused}
        error={!!error}
        ref={wrapperRef}
        disabled={disabled}
      >
        {!!label && (
          <MovingLabel moved={isFocused || !!value} disabled={disabled}>
            {label}
          </MovingLabel>
        )}
        {renderInputComponent ? (
          renderInputComponent({
            onFocus: () => setIsFocused(true),
            onBlur: () => setIsFocused(false),
            isFocused,
          })
        ) : (
          <Input
            value={value}
            id={id}
            type={type}
            placeholder={placeholder}
            autoFocus={autoFocus}
            autoComplete="off"
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            disabled={disabled}
            withLabel={!!label}
            showSpinButtons={showSpinButtons}
            min={min}
            max={max}
          />
        )}

        {!!icon && (
          <InputRightButton type="button" onClick={onRightButtonClick}>
            <Icon icon={icon} />
          </InputRightButton>
        )}
      </InputBaseWrapper>

      {error && (
        <InputErrorMessage mt mtValue={theme.spacing.xxs}>
          {error}
        </InputErrorMessage>
      )}
    </>
  );
};

export default InputBase;
