import { useMemo } from 'react';
import { useStoreState } from 'state';
import { transformCurrencyToSelectOption } from 'utils';
import _orderBy from 'lodash.orderby';

const useIntegrationDefaultValues = () => {
  const { currencies } = useStoreState((state) => state.CurrenciesState);
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);

  const defaultCurrencies = useMemo(() => {
    const currency = currencies.find(
      (item) => item.code === entityCurrencyCode
    );
    return currency ? [transformCurrencyToSelectOption(currency)] : [];
  }, [currencies, entityCurrencyCode]);

  const parsedCurrencies = useMemo(
    () => _orderBy(currencies.map(transformCurrencyToSelectOption), 'label'),
    [currencies]
  );

  return {
    defaultCurrencies,
    parsedCurrencies,
  };
};

export default useIntegrationDefaultValues;
