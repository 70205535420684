import { useTheme } from 'styled-components';
import { roundToPrecision, parseIntoCurrencyString } from 'utils';

const ChartContributorsChartLabel = ({ data, variant }: any) => {
  const theme = useTheme();

  return (
    <tspan
      textAnchor={variant === 'left' ? 'right' : 'middle'}
      dominantBaseline={variant === 'left' ? 'right' : 'central'}
      style={{
        fontSize: theme.fontSize.xs,
        fill: theme.color.greyDark,
        letterSpacing: '0.002em',
      }}
    >
      {variant === 'left'
        ? parseIntoCurrencyString(roundToPrecision(Number(data), 0), 0)
        : data}
    </tspan>
  );
};

export default ChartContributorsChartLabel;
