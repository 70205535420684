import {
  IInvoice,
  IResponse,
  IInvoiceApproval,
  INVOICE_STATUSES,
  IInvoiceFromSearch,
  IRecipient,
  TInvoiceAggregationsForBalances,
} from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import { openDoc, openQuery } from 'utils';
import db from 'services/firestore';
import { GetUserEntityParams } from '.';
import { IEntityIntegrations } from 'state/user';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import _chunk from 'lodash.chunk';
import firebase from 'firebase/app';
import { ISearchResponse } from 'types/search';

export const getInvoices = async () => {
  return AxiosPrivateFirebaseInstance.get<IResponse>('/xero/import');
};

export interface GetInvoiceParams {
  invoiceId: string;
}

export const getInvoice = async ({ invoiceId }: GetInvoiceParams) => {
  const data = await db
    .collection('invoices')
    .doc(invoiceId)
    .get()
    .then((doc) => openDoc<IInvoice>(doc));

  return data;
};

export interface SetXeroPermissionsParams extends GetInvoiceParams {
  permission: boolean;
}

export interface SubscribeToInvoiceParams {
  invoiceId: string;
  callback: (invoice: IInvoice) => void;
}

export const subscribeToInvoice = ({
  invoiceId,
  callback,
}: SubscribeToInvoiceParams) => {
  return db
    .collection('invoices')
    .doc(invoiceId)
    .onSnapshot((doc) => callback(openDoc(doc) as IInvoice));
};

export interface SubscribeToInvoicesParams {
  entityId: string;
  callback: (invoices: IInvoice[]) => void;
}

/** Returns all AUTHORISED and DRAFT invoices. */
export const subscribeToInvoicesUnpaid = ({
  entityId,
  callback,
}: SubscribeToInvoicesParams) => {
  return db
    .collection('invoices')
    .where('_owner', '==', entityId)
    .where('status', 'in', [
      INVOICE_STATUSES.draft,
      INVOICE_STATUSES.submitted,
      INVOICE_STATUSES.authorised,
      INVOICE_STATUSES.paymentScheduled,
      INVOICE_STATUSES.partiallyPaid,
      INVOICE_STATUSES.processingPayment,
      INVOICE_STATUSES.purchaseOrder,
      INVOICE_STATUSES.salesOrder,
    ])
    .onSnapshot(
      (query) => {
        callback(openQuery(query));
      },
      (error) => console.log('Failed to subscribe to invoices. Error: ', error)
    );
};

/** Returns PAID invoices with a value in the transferId field. */
export const subscribeToInvoicesPaidViaHf = ({
  entityId,
  callback,
}: SubscribeToInvoicesParams) => {
  return db
    .collection('invoices')
    .where('_owner', '==', entityId)
    .where('status', '==', INVOICE_STATUSES.paid)
    .where('transferId', '>', '')
    .orderBy('transferId')
    .orderBy('fullyPaidOnDate', 'desc')
    .limit(250)
    .onSnapshot(
      (query) => {
        callback(openQuery(query));
      },
      (error) => console.log('Failed to subscribe to invoices. Error: ', error)
    );
};

export const importXeroData = async () => {
  await AxiosPrivateFirebaseInstance.post<IResponse>('/xero/import');
};

export interface BindContractRateToInvoiceParams {
  contractId: string;
  payload: {
    invoiceId: string;
  };
}

export const bindContractRateToInvoice = async ({
  contractId,
  payload,
}: BindContractRateToInvoiceParams) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/contracts/${contractId}/usepartial`,
    payload
  );
};

export const unbindContractRateToInvoice = async ({
  contractId,
  payload,
}: BindContractRateToInvoiceParams) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/contracts/${contractId}/releasepartial`,
    payload
  );
};

export interface CancelInvoiceContractRateParams {
  contractId: string;
  payload: {
    cancelValue: number;
  };
}

export const cancelInvoiceContractRate = async ({
  contractId,
  payload,
}: CancelInvoiceContractRateParams) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/contracts/${contractId}/cancel`,
    payload
  );
};

export interface GetContractRateCancelValueParams {
  contractId: string;
}

export const getContractRateCancelValue = async ({
  contractId,
}: GetContractRateCancelValueParams) => {
  return AxiosPrivateFirebaseInstance.get<IResponse>(
    `/contracts/${contractId}/cancelvalue`
  );
};

export interface CreateInvoiceTrackingParams {
  invoiceId: string;
  trackingData: {
    sellCurrency: string;
    buyCurrency: string;
    sellAmount: number;
    targetAmount: number;
    targetRate: number;
  };
}

export const createInvoiceTracking = async ({
  invoiceId,
  trackingData,
}: CreateInvoiceTrackingParams) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/tracking/${invoiceId}`,
    trackingData
  );
};

export interface DeleteInvoiceTrackingParams {
  trackingId: string;
}

export const deleteInvoiceTracking = async ({
  trackingId,
}: DeleteInvoiceTrackingParams) => {
  return AxiosPrivateFirebaseInstance.delete<IResponse>(
    `/tracking/${trackingId}`
  );
};

export interface DeleteInvoiceParams {
  invoiceId: string;
}

export const deleteInvoice = async ({ invoiceId }: DeleteInvoiceParams) => {
  return AxiosPrivateFirebaseInstance.delete<IResponse>(
    `/invoices/${invoiceId}`
  );
};

export const getInvoicesAsCsvFile = async (tab: 'paid' | 'outstanding') =>
  AxiosPrivateFirebaseInstance.get<string>(`/invoices/csv?status=${tab}`);

export interface UpdateInvoiceTrackingParams {
  trackingId: string;
  trackingData: {
    sellCurrency: string;
    buyCurrency: string;
    sellAmount: number;
    targetAmount: number;
    targetRate: number;
  };
}

export const updateInvoiceTracking = async ({
  trackingId,
  trackingData,
}: UpdateInvoiceTrackingParams) => {
  return AxiosPrivateFirebaseInstance.put<IResponse>(
    `/tracking/${trackingId}`,
    trackingData
  );
};
export interface GetInvoiceTrackingParams {
  trackingId: string;
}

export const getInvoiceTracking = async ({
  trackingId,
}: GetInvoiceTrackingParams) => {
  return db
    .collection('tracking')
    .doc(trackingId)
    .get()
    .then((doc) => openDoc(doc));
};

export const bulkSage50InvoicesUpload = async (payload: any) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/invoices/import/sage50`,
    payload
  );
};

export const bulkCsvInvoicesUpload = async (payload: any) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/invoices/import`,
    payload
  );
};

export const bulkHedgesUpload = async (payload: any) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/invoices/import/hedges`,
    payload
  );
};

export interface UpdateInvoiceParams {
  invoiceId: string;
  data: Partial<IInvoice>;
  updateForSameContact?: boolean;
}

export const updateInvoice = async ({
  invoiceId,
  data,
  updateForSameContact = false,
}: UpdateInvoiceParams) => {
  return AxiosPrivateFirebaseInstance.put<IResponse<IRecipient>>(
    `/invoices/${invoiceId}?update_for_same_contact=${updateForSameContact}`,
    data
  );
};

export interface UpdateInvoiceApprovalStatusParams {
  invoiceIds: string[];
  approvalStatus: IInvoiceApproval['status'];
}

export const updateInvoicesApprovalStatus = async ({
  invoiceIds,
  approvalStatus,
}: UpdateInvoiceApprovalStatusParams) => {
  return AxiosPrivateFirebaseInstance.put<IResponse>(
    '/invoices/approval/update',
    {
      approvalStatus,
      invoiceIds,
    }
  );
};

export interface ISubscribeToUserEntityCodatIntegrationParams
  extends GetUserEntityParams {
  callback: (entity: IEntityIntegrations['codat'] | null) => void;
}

export const subscribeToUserEntityCodatIntegration = ({
  entityId,
  callback,
}: ISubscribeToUserEntityCodatIntegrationParams) => {
  return db
    .collection('entities')
    .doc(entityId)
    .collection('integrations')
    .doc('codat')
    .onSnapshot((query) =>
      callback(openDoc<IEntityIntegrations['codat']>(query))
    );
};

export const getInvoicesByIdArray = async (
  payload?: string[]
): Promise<IInvoice[]> => {
  if (!payload || payload.length === 0) {
    return [];
  }
  if (payload.length > 10) {
    const result = await Promise.all<IInvoice[]>(
      _chunk(payload, 10).map((arr: any) =>
        db
          .collection('invoices')
          .where(firebase.firestore.FieldPath.documentId(), 'in', arr)
          .get()
          .then((query) => openQuery(query))
      )
    );
    return result.flat();
  } else {
    return db
      .collection('invoices')
      .where(firebase.firestore.FieldPath.documentId(), 'in', payload)
      .get()
      .then((query) => openQuery(query));
  }
};

export const getInvoicesPaginated = async (query: string) =>
  AxiosPrivateFirebaseInstance.get<
    IResponse<ISearchResponse<IInvoiceFromSearch>>
  >(`/invoices${query}`);

export const getInvoicesAggregationsPerCurrencyCode = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<TInvoiceAggregationsForBalances>>(
    `/invoices/aggregations/balances`
  );

export const getInvoicesCount = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<{ count: number }>>(
    `/invoices/aggregations/count`
  );
