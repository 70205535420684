import { useEffect, useState } from 'react';
import { getXeroAccountDetails } from 'services/firebase';
import { useStoreState } from 'state';
import { IAccountData, IXeroAccount } from 'types/integrations';
import { Notify } from 'utils';
import { generateXeroBankFeesAccountData } from 'utils/integrations';

interface IFilteredAccounts {
  xeroAccounts: IAccountData[];
  bankFeesAccounts: IAccountData[];
}

const useXeroAccounts = (fetchAccounts = false) => {
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [hasEntityCurrencyAccount, setHasEntityCurrencyAccount] = useState(
    false
  );
  const [
    defaultBankFeesAccount404,
    setDefaultBankFeesAccount404,
  ] = useState<IXeroAccount | null>(null);
  const [xeroAccounts, setXeroAccounts] = useState<IAccountData[]>([]);
  const [bankFeesAccounts, setBankFeesAccounts] = useState<IAccountData[]>([]);
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);

  useEffect(() => {
    const fetchXeroAccounts = async () => {
      try {
        setIsLoadingAccounts(true);
        const response = await getXeroAccountDetails();
        if (response.data?.success) {
          const accounts = response.data.data;
          const foundEntityCurrencyAccount = accounts?.some(
            (account) => account.name === `HedgeFlows ${entityCurrencyCode}`
          );

          if (foundEntityCurrencyAccount) {
            setHasEntityCurrencyAccount(foundEntityCurrencyAccount);
          }

          const findBankFeesAccount = accounts?.find(
            (account) => account.code === '404'
          );

          if (findBankFeesAccount) {
            setDefaultBankFeesAccount404(findBankFeesAccount);
          }

          const filteredAccounts = accounts?.reduce<IFilteredAccounts>(
            (total, el) => {
              if (
                el.type === 'BANK' &&
                el.currencyCode === entityCurrencyCode
              ) {
                total.xeroAccounts = [
                  ...total?.xeroAccounts,
                  generateXeroBankFeesAccountData(el),
                ];
              }
              if (el._class === 'EXPENSE') {
                total.bankFeesAccounts = [
                  ...total?.bankFeesAccounts,
                  generateXeroBankFeesAccountData(el),
                ];
              }
              return total;
            },
            { xeroAccounts: [], bankFeesAccounts: [] }
          );

          if (filteredAccounts?.xeroAccounts?.length) {
            setXeroAccounts(filteredAccounts.xeroAccounts);
          }
          if (filteredAccounts?.bankFeesAccounts?.length) {
            setBankFeesAccounts(filteredAccounts.bankFeesAccounts);
          }
        }
      } catch (error: any) {
        Notify.error(error.message);
      } finally {
        setIsLoadingAccounts(false);
      }
    };

    if (fetchAccounts) {
      fetchXeroAccounts();
    }
  }, [entityCurrencyCode, fetchAccounts]);

  return {
    isLoadingAccounts,
    hasEntityCurrencyAccount,
    xeroAccounts,
    bankFeesAccounts,
    defaultBankFeesAccount404,
  };
};

export default useXeroAccounts;
