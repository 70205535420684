import { Paragraph, ParagraphWithEllipsis, StaleInfo } from 'components';
import { FC } from 'react';
import { TPastPerformanceItem, isPastPerformanceItemTransfer } from 'types';
import { getName } from './utils';

interface OwnProps {
  item: TPastPerformanceItem;
}

const NameCell: FC<OwnProps> = ({ item }) => {
  const isTransfer = isPastPerformanceItemTransfer(item);

  return (
    <>
      <ParagraphWithEllipsis maxWidth="100px">
        {getName(item)}
      </ParagraphWithEllipsis>

      {isTransfer && (
        <StaleInfo mode="hover" strategy="fixed">
          <Paragraph color="white">
            Account from: {item.fromAccountName}
          </Paragraph>
          <Paragraph color="white">Account to: {item.toAccountName}</Paragraph>
        </StaleInfo>
      )}
    </>
  );
};

export default NameCell;
