import React, { useState, useEffect, FC, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  StaleBtnGroup,
  StaleTitleH5,
  StaleParagraphMedium,
  StaleSwitch,
  StaleInput,
  ButtonStyleLink,
  StaleTitleH1,
  StaleLoadPlaceholder,
  Row,
  StaleOverflowScroll,
  Subtitle,
} from 'components';
import { Firebase } from 'services';
import { useStoreActions, useStoreState } from 'state';
import { SignUpContent } from '..';
import { useHistory } from 'react-router-dom';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { IOwner } from 'types';
import shortid from 'shortid';
import { useTheme } from 'styled-components';
import { ERROR_MESSAGES } from 'variables';
import { getLink } from 'utils/links';
import Button from 'components/shared/Button/Button';
dayjs.extend(customParseFormat);

type Inputs = {
  companyDetails: any;
};

interface OwnProps {
  onContinue: () => void;
  // TODO: add correct interface
  stepOneValues: any;
  stepTwoValues: any;
}

const StepThree: FC<OwnProps> = ({
  onContinue,
  stepOneValues,
  stepTwoValues,
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyDirectors, setCompanyDirectors] = useState<any[]>([]);
  const [companyDetails, setCompanyDetails] = useState<Record<string, any>>({});
  const [companyPscs, setCompanyPscs] = useState<any[]>([]);
  const [newShareholder, setNewShareholder] = useState<any[]>([]);

  const { user, userEntity } = useStoreState((state) => state.UserState);
  const { getUserEntity, getUser } = useStoreActions(
    (actions) => actions.UserState
  );
  const { currencies } = useStoreState((state) => state.CurrenciesState);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const yourselfValue: any = watch('yourself');
  const anotherDirectorValue: any = watch('anotherDirector');
  const shareholder: any = watch('shareholder');
  const history = useHistory();

  useEffect(() => {
    if (stepTwoValues) {
      setSelectedCompany(stepTwoValues?.values?.companyName);

      setCompanyDetails(stepTwoValues?.companyDetails);

      const filteredDirectors = stepTwoValues?.companyDetails?.officers
        .filter(
          (item: any) =>
            (item.role === 'director' || item.role === 'secretary') &&
            !item.resigned_on
        )
        .map((item: any, index: number) => ({
          id: `director_${index}`,
          data: item,
        }));

      setCompanyDirectors(filteredDirectors);

      const pscs = stepTwoValues?.companyDetails?.officers.filter(
        (item: any) => item.isPsc
      );

      setCompanyPscs(pscs);
    }
  }, [stepTwoValues]);

  let filteredPscs = useMemo(
    () =>
      companyPscs.filter(
        (item) =>
          item.name !== yourselfValue?.directorData?.name &&
          item.name !== anotherDirectorValue?.data?.directorData?.name
      ),
    [companyPscs, yourselfValue, anotherDirectorValue]
  );

  const onSubmit = async (values: any) => {
    if (!user || !userEntity) return;

    const entityCurrency = currencies.find(
      (item) => item.countryCode === userEntity.companyCountry
    );

    if (!entityCurrency) return;

    // step two form values
    const { isVatNumber, VAT, socialMediaLink } = stepTwoValues.values;

    // step three form values
    const { yourself, yourselfEmail, anotherDirector, pscEmail = [] } = values;

    const officersData: IOwner[] = [
      {
        // Yourself data
        address: { ...yourself.directorData.address },
        name: yourself.directorData.name,
        email: yourselfEmail,
        isUser: true,
        isRepresentingEntity: true,
        isPsc: yourself.directorData.isPsc,
        isCompany: yourself.directorData.isCompany,
        pscNaturesOfControl: yourself.directorData.pscNaturesOfControl,
        role: yourself.directorData.role,
      },
    ];

    if (anotherDirector) {
      const anotherDirectorData = {
        address: { ...anotherDirector.data.directorData.address },
        name: anotherDirector.data.directorData.name,
        email: anotherDirector.email,
        role: anotherDirector.data.directorData.role,
        isPsc: anotherDirector.data.directorData.isPsc,
        isCompany: anotherDirector.data.directorData.isCompany,
        pscNaturesOfControl:
          anotherDirector.data.directorData.pscNaturesOfControl,
        isRepresentingEntity: true,
        isUser: false,
      };

      officersData.push(anotherDirectorData);
    }

    if (filteredPscs.length) {
      filteredPscs.forEach((item, index) => {
        const pscData = {
          address: { ...item.address },
          name: item.name,
          role: item.role,
          isPsc: item.isPsc,
          isCompany: item.isCompany,
          pscNaturesOfControl: item.pscNaturesOfControl,
          isRepresentingEntity: false,
          isUser: false,
        };

        if (pscEmail[index]) {
          // @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type '{ address... Remove this comment to see the full error message
          pscData.email = pscEmail[index];
        }

        officersData.push(pscData);
      });
    }

    if (shareholder) {
      Object.keys(shareholder).forEach((item) => {
        if (shareholder[item].name && shareholder[item].email) {
          const shareholderData = {
            name: shareholder[item].name,
            email: shareholder[item].email,
            role: 'individual-person-with-significant-control',
            isRepresentingEntity: false,
            isUser: false,
            isPsc: true,
          };
          officersData.push(shareholderData);
        }
      });
    }

    setIsLoading(true);

    const response = await Firebase.registerCompany({
      companyCountry: companyDetails.companyCountry,
      companyType: companyDetails.companyType,
      expectedAnnualTurnover: stepOneValues.expectedAnnualTurnover,
      expectedFxTurnoverUpperLimit:
        stepOneValues.expectedMonthlyForeignCurrencyTurnover.value
          .expectedFxTurnoverUpperLimit,
      name: companyDetails.name,
      website: socialMediaLink,
      companyDetails: {
        address: companyDetails.address,
        addressCorrespondence: {
          addressLine1: companyDetails.address.addressLine1,
          addressLine2: companyDetails.address?.addressLine2,
          postalCode: companyDetails.address.postalCode,
          city: companyDetails.address.city,
          country: companyDetails.address.country,
          stateOrProvince: companyDetails.address.stateOrProvince,
        },
        companyRegistrarId: companyDetails.companyRegistrarId,
        countriesSendingMoneyTo: stepOneValues.countries.map(
          (item: any) => item.value.alpha2
        ),
        natureOfBusiness:
          stepOneValues.businessNature.code === 'other'
            ? stepOneValues.otherNatureOfBusinessLabel
            : stepOneValues.businessNature.label,
        natureOfBusinessCode: stepOneValues.businessNature.code,
        natureOfBusinessSicFromCh: companyDetails.natureOfBusinessSicFromCh,
        purposeOfAccount: stepOneValues.accountPurpose,
        vat: isVatNumber === 'yes' ? true : false,
        vatNumber: isVatNumber === 'yes' ? VAT : '',
      },
      officers: officersData,
    });

    if (response?.success) {
      // TODO: remove later when we get entity from the egistration response
      await getUserEntity({
        entityId: response.id ?? '',
      });
      await getUser({
        id: user.id,
      });

      setIsLoading(false);
      onContinue();
      return;
    }

    setIsLoading(false);
  };

  const onError = () => {
    // TODO: discuss how to handle errors
  };

  const showShareholders = useMemo(() => {
    return (
      (yourselfValue &&
        anotherDirectorValue &&
        anotherDirectorValue?.data !== null &&
        companyDirectors.length > 1) ||
      (yourselfValue && companyDirectors.length <= 1)
    );
  }, [yourselfValue, companyDirectors, anotherDirectorValue]);

  const moreThanOneDirector = companyDirectors?.length > 1;

  return (
    <SignUpContent>
      <ButtonStyleLink
        className="cross"
        onClick={() => history.push(getLink('/app/dashboard'))}
      >
        <svg width="24" height="24">
          <use xlinkHref="#cross-ico" />
        </svg>
      </ButtonStyleLink>

      <StaleOverflowScroll>
        <div className="block">
          <ButtonStyleLink onClick={history.goBack} className="back">
            <svg width="24" height="24">
              <use xlinkHref="#arrow-left" />
            </svg>
            Back
          </ButtonStyleLink>

          <StaleTitleH1>
            {moreThanOneDirector
              ? 'Add directors & owners info'
              : 'Add director & owners info'}
          </StaleTitleH1>

          <br />

          <form>
            <div className="additional" style={{ marginBottom: 16 }}>
              {selectedCompany && (
                <>
                  <StaleParagraphMedium>
                    {moreThanOneDirector
                      ? 'Select two directors from the list to authorize this registration:'
                      : 'Select a director from the list to authorize this registration:'}
                  </StaleParagraphMedium>

                  {isLoading ? (
                    <>
                      <StaleLoadPlaceholder height="48px" />
                    </>
                  ) : null}

                  <div className="additional" style={{ marginBottom: 16 }}>
                    <Controller
                      name="yourself"
                      control={control}
                      defaultValue={null}
                      rules={{
                        required: ERROR_MESSAGES.requiredField,
                      }}
                      render={({ onChange, value }) => {
                        return (
                          <>
                            {companyDirectors.map((item, index) => {
                              return yourselfValue?.id === item.id ||
                                !yourselfValue ? (
                                <React.Fragment key={item.id}>
                                  <Row
                                    mtValue={theme.spacing.xs}
                                    mt
                                    justifyContent="flex-start"
                                  >
                                    <StaleSwitch
                                      id={`yourself_${index}`}
                                      isOn={value?.id === item.id}
                                      handleToggle={() => {
                                        if (value && value.id === item.id) {
                                          onChange(null);
                                        } else {
                                          onChange({
                                            id: item.id,
                                            directorData: item.data,
                                          });
                                        }
                                      }}
                                    />

                                    <Subtitle ml>{item.data.name}</Subtitle>
                                  </Row>
                                </React.Fragment>
                              ) : null;
                            })}
                          </>
                        );
                      }}
                    />

                    {yourselfValue && (
                      <div className="field">
                        <StaleInput
                          id="yourselfEmail"
                          label="Email"
                          view="moving"
                          type="email"
                          control={control}
                          name="yourselfEmail"
                          rules={{
                            required: ERROR_MESSAGES.requiredField,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}

              {yourselfValue && companyDirectors.length > 1 && (
                <>
                  <div className="additional" style={{ marginBottom: 16 }}>
                    {companyDirectors
                      .filter((item) => item.id !== (yourselfValue as any)?.id)
                      .map((item, index) => {
                        const isSelected =
                          anotherDirectorValue?.data?.id === item.id;

                        return (
                          <React.Fragment key={item.id}>
                            <Row
                              mtValue={theme.spacing.xs}
                              mt
                              justifyContent="flex-start"
                            >
                              <Controller
                                name="anotherDirector.data"
                                control={control}
                                defaultValue={null}
                                rules={{
                                  required: ERROR_MESSAGES.requiredField,
                                }}
                                render={({ onChange, value }) => {
                                  return (
                                    <StaleSwitch
                                      id={`anotherDirector.data_${index}`}
                                      isOn={value?.id === item.id}
                                      disabled={
                                        anotherDirectorValue?.data &&
                                        !isSelected
                                      }
                                      handleToggle={() => {
                                        if (value && value.id === item.id) {
                                          onChange(null);
                                        } else {
                                          onChange({
                                            id: item.id,
                                            directorData: item.data,
                                          });
                                        }
                                      }}
                                    />
                                  );
                                }}
                              />

                              <Subtitle ml>{item.data.name}</Subtitle>
                            </Row>

                            {isSelected && (
                              <>
                                <div className="field">
                                  <StaleInput
                                    id="anotherDirector.email"
                                    label="Email"
                                    view="moving"
                                    type="email"
                                    control={control}
                                    name="anotherDirector.email"
                                    rules={{
                                      required: ERROR_MESSAGES.requiredField,
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </div>
                </>
              )}
            </div>

            {showShareholders && (
              <>
                <hr />

                <StaleTitleH5>Confirm shareholders</StaleTitleH5>

                <StaleParagraphMedium>
                  Check and add names of each individual who owns or controls{' '}
                  <b>more than 25%</b> of the company and add their contact
                  details
                </StaleParagraphMedium>

                {filteredPscs.map((item, index) => {
                  return (
                    <div
                      className="field"
                      style={{
                        marginBottom: 8,
                      }}
                      key={item.name}
                    >
                      <Row
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <StaleParagraphMedium>{item.name}</StaleParagraphMedium>

                        <ButtonStyleLink
                          className="button-link"
                          onClick={(event) => {
                            event.preventDefault();
                            setCompanyPscs(
                              companyPscs.filter((el) => el.name !== item.name)
                            );
                          }}
                        >
                          <svg width="20" height="20">
                            <use xlinkHref="#remove-ico" />
                          </svg>
                          Remove
                        </ButtonStyleLink>
                      </Row>
                      {item.isPsc && item.isCompany ? null : (
                        <StaleInput
                          id={`pscEmail.${index}`}
                          label="Email"
                          view="moving"
                          type="email"
                          control={control}
                          name={`pscEmail.${index}`}
                          rules={{
                            required: ERROR_MESSAGES.requiredField,
                          }}
                          // @ts-expect-error TS(2339) FIXME: Property 'pscEmail' does not exist on type 'DeepMa... Remove this comment to see the full error message
                          error={errors.pscEmail?.[index]?.message}
                        />
                      )}
                    </div>
                  );
                })}

                {newShareholder.length > 0 && (
                  <div style={{ marginTop: '32px' }} className="field">
                    <StaleTitleH5 style={{ fontWeight: 'bold' }}>
                      Add one more shareholder
                    </StaleTitleH5>
                    {newShareholder.map((generatedId) => {
                      return (
                        <div
                          className="field"
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            flexDirection: 'column',
                            width: '100%',
                          }}
                          key={generatedId}
                        >
                          <ButtonStyleLink
                            className="button-link"
                            onClick={(event) => {
                              event.preventDefault();
                              setNewShareholder((prevState) =>
                                prevState.filter((el) => el !== generatedId)
                              );
                            }}
                          >
                            <svg width="20" height="20">
                              <use xlinkHref="#remove-ico" />
                            </svg>
                            Remove
                          </ButtonStyleLink>

                          <Row
                            style={{
                              width: '100%',
                              gap: 20,
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <StaleInput
                                id={`shareholder.${generatedId}.name`}
                                label="Name"
                                view="moving"
                                type="name"
                                control={control}
                                name={`shareholder.${generatedId}.name`}
                                rules={{
                                  required: ERROR_MESSAGES.requiredField,
                                }}
                                error={
                                  // @ts-expect-error TS(2339) FIXME: Property 'shareholder' does not exist on type 'Dee... Remove this comment to see the full error message
                                  errors.shareholder?.[generatedId]?.name
                                    ?.message
                                }
                              />
                            </div>
                            <div style={{ flex: 1 }}>
                              <StaleInput
                                id={`shareholder.${generatedId}.email`}
                                label="Email"
                                view="moving"
                                type="email"
                                control={control}
                                name={`shareholder.${generatedId}.email`}
                                rules={{
                                  required: ERROR_MESSAGES.requiredField,
                                }}
                                error={
                                  // @ts-expect-error TS(2339) FIXME: Property 'shareholder' does not exist on type 'Dee... Remove this comment to see the full error message
                                  errors.shareholder?.[generatedId]?.email
                                    ?.message
                                }
                              />
                            </div>
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                )}

                <ButtonStyleLink
                  className="shareholder-link"
                  onClick={(event) => {
                    event.preventDefault();
                    setNewShareholder((prevState) => [
                      ...prevState,
                      shortid.generate(),
                    ]);
                  }}
                >
                  <svg width="20" height="20">
                    <use xlinkHref="#round-plus-ico" />
                  </svg>
                  Add another shareholder
                </ButtonStyleLink>
              </>
            )}
          </form>

          <StaleBtnGroup>
            <Button onClick={handleSubmit(onSubmit, onError)}>Continue</Button>
          </StaleBtnGroup>
        </div>
      </StaleOverflowScroll>
    </SignUpContent>
  );
};

export default StepThree;
