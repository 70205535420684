import { FC, RefObject } from 'react';
import { createPortal } from 'react-dom';
import { StyledChartTooltipForPortal } from './ChartTooltip.styles';

interface OwnProps {
  wrapperRef: RefObject<HTMLDivElement>;
}

const ChartTooltip: FC<OwnProps> = ({ children, wrapperRef }) => {
  return createPortal(
    <StyledChartTooltipForPortal ref={wrapperRef}>
      {children}
    </StyledChartTooltipForPortal>,
    document.body
  );
};

export default ChartTooltip;
