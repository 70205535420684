import { FC, useState, useMemo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form7';
import orderBy from 'lodash.orderby';
import { isMobile } from 'react-device-detect';

import {
  StaleTitleH5,
  StaleParagraphMedium,
  Paragraph,
  StaleBtnGroup,
  ButtonStyleLink,
  StaleInputSelect,
  StaleCarousel,
  StaleTitleH1,
  StaleOverflowScroll,
  Row,
  StaleRadioButton,
} from 'components';
import { SignUpWrap, SignUpNav, SignUpContent } from '..';
import { useStoreState, useStoreActions } from 'state';
import { RECIPIENT_TYPE } from 'types';
import { ERROR_MESSAGES } from 'variables';
import { Inputs } from './types';
import { BUSINESS_TYPES } from './variables';
import InputBase from 'components/shared/InputBase/InputBase';
import Field from 'components/shared/Field/Field.styles';
import Button from 'components/shared/Button/Button';

interface OwnProps {
  onContinueHandler: (values: {
    firstName: string;
    lastName: string;
    companyCountry: string;
    companyType: RECIPIENT_TYPE;
    companyName: string;
    isAccountant: boolean;
  }) => void;
  onNavItemClick: (stepNumber?: number) => void;
  onCloseHandler: () => void;
}

const StepThree: FC<OwnProps> = ({
  onContinueHandler,
  onNavItemClick,
  onCloseHandler,
}) => {
  const { handleSubmit, control, watch } = useForm<Inputs>({
    defaultValues: {
      isAccountant: undefined,
      isCompany: undefined,
      firstName: '',
      lastName: '',
      country: undefined,
      businessType: undefined,
    },
  });

  const { countries } = useStoreState((state) => state.ReferenceDataState);
  const { getCountries } = useStoreActions(
    (actions) => actions.ReferenceDataState
  );

  const isCompanyWatch = watch('isCompany');
  const isCompanyAnswered = isCompanyWatch !== undefined;

  const isAccountantWatch = watch('isAccountant');
  const isAccountantAnswered = isAccountantWatch !== undefined;

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  const [companiesSearchValue, setCompaniesSearchValue] = useState('');

  const orderedCountries = useMemo(
    () =>
      orderBy(countries, 'name', 'asc').filter((item) => item.alpha2 !== 'gb'),
    [countries]
  );

  const onSubmit = async ({
    firstName,
    lastName,
    country,
    businessType,
    companyName,
    isAccountant,
    isCompany,
  }: any) => {
    onContinueHandler({
      firstName,
      lastName,
      companyCountry: isCompany ? 'gb' : country.alpha2,
      companyType: isCompany ? 'limitedCompany' : businessType,
      isAccountant,
      companyName,
    });
  };

  return (
    <SignUpWrap>
      <SignUpNav>
        {isMobile ? (
          <>
            <StaleTitleH5>Sign up</StaleTitleH5>

            <ButtonStyleLink className="back " onClick={onCloseHandler}>
              <svg width="24px" height="24px">
                <use xlinkHref="#cross-ico" />
              </svg>
            </ButtonStyleLink>

            <div className="row">
              <div className="item passed" onClick={() => onNavItemClick()}>
                Create account
              </div>
              <div className="item passed" onClick={() => onNavItemClick(2)}>
                Confirm email
              </div>
              <div className="item current">Basic details</div>
            </div>
          </>
        ) : (
          <>
            <StaleTitleH5>Let’s sign you up to HedgeFlows</StaleTitleH5>

            <StaleParagraphMedium>
              You are about 10 minutes away from having access to our best
              features:
            </StaleParagraphMedium>

            <StaleCarousel />
          </>
        )}
      </SignUpNav>

      <SignUpContent className="rounded">
        <StaleOverflowScroll>
          <div className="block">
            {!isMobile && (
              <ButtonStyleLink className="cross" onClick={onCloseHandler}>
                <svg width="24" height="24">
                  <use xlinkHref="#cross-ico" />
                </svg>
              </ButtonStyleLink>
            )}

            {isMobile ? (
              <StaleTitleH5>Basic info</StaleTitleH5>
            ) : (
              <StaleTitleH1 style={{ marginBottom: 24 }}>
                Basic info
              </StaleTitleH1>
            )}

            <form id="basic-info-form" onSubmit={handleSubmit(onSubmit)}>
              <Paragraph>What is your name?</Paragraph>
              <Row mt mb>
                <Field fluid mr flexDirection="column">
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: ERROR_MESSAGES.requiredField,
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputBase
                        label="First name"
                        type="text"
                        autoFocus
                        error={error?.message}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Field>

                <Field fluid flexDirection="column">
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: ERROR_MESSAGES.requiredField,
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputBase
                        label="Last name"
                        type="text"
                        error={error?.message}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Field>
              </Row>

              <Paragraph>
                What's the name of the business you represent?
              </Paragraph>
              <Field fluid mt flexDirection="column">
                <Controller
                  name="companyName"
                  control={control}
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <InputBase
                      label="Business name"
                      type="text"
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Field>

              <Paragraph mt mb>
                Are you representing a Limited Liability company registered in
                UK?
              </Paragraph>

              <Field fluid>
                <Controller
                  name="isCompany"
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <StaleRadioButton
                      onChange={(item: any) => onChange(item.value)}
                      list={[
                        {
                          id: 'isCompany-yes',
                          value: true,
                          checked: value === true,
                          name: <p>Yes</p>,
                        },
                        {
                          id: 'isCompany-no',
                          value: false,
                          checked: value === false,
                          name: <p>No</p>,
                        },
                      ]}
                      name={name}
                      cardCol
                    />
                  )}
                />
              </Field>

              {isCompanyAnswered && !isCompanyWatch && (
                <Row>
                  {!!orderedCountries.length && (
                    <Field mr>
                      <Controller
                        control={control}
                        name="country"
                        rules={{ required: ERROR_MESSAGES.requiredField }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <StaleInputSelect
                              id="country"
                              label="Country of business registration"
                              view="moving"
                              data={[
                                countries.find((item) => item.id === 'GB'),
                                ...orderedCountries,
                              ]
                                .filter((item) =>
                                  item?.name
                                    .toLowerCase()
                                    .trim()
                                    .includes(
                                      companiesSearchValue.toLowerCase().trim()
                                    )
                                )
                                .map((item) => ({
                                  name: item?.name ?? '',
                                  id: item?.name,
                                  icon: item?.alpha2.toLowerCase(),
                                  value: { ...item, id: item?.name },
                                }))}
                              selected={value}
                              onSelect={(item) => {
                                onChange(item.value);
                              }}
                              withSearch
                              searchValue={companiesSearchValue}
                              onSearch={(e) =>
                                setCompaniesSearchValue(e.currentTarget.value)
                              }
                              onClose={() => setCompaniesSearchValue('')}
                              error={error?.message}
                              style={{
                                flex: 1,
                              }}
                            />
                          );
                        }}
                      />
                    </Field>
                  )}

                  <Field>
                    <Controller
                      control={control}
                      name="businessType"
                      rules={{ required: ERROR_MESSAGES.requiredField }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <StaleInputSelect
                            id="businessType"
                            label="Type of business"
                            view="moving"
                            data={BUSINESS_TYPES}
                            selected={value}
                            onSelect={(item) => onChange(item.value)}
                            error={error?.message}
                            style={{
                              flex: 1,
                            }}
                          />
                        );
                      }}
                    />
                  </Field>
                </Row>
              )}

              <Paragraph mt mb>
                Are you an external accountant or part-time CFO?
              </Paragraph>

              <Field fluid>
                <Controller
                  name="isAccountant"
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <StaleRadioButton
                      onChange={(item: any) => onChange(item.value)}
                      list={[
                        {
                          id: 'isAccountant-yes',
                          value: true,
                          checked: value === true,
                          name: <p>Yes</p>,
                        },
                        {
                          id: 'isAccountant-no',
                          value: false,
                          checked: value === false,
                          name: <p>No</p>,
                        },
                      ]}
                      name={name}
                      cardCol
                    />
                  )}
                />
              </Field>
            </form>

            <StaleBtnGroup>
              <Button
                disabled={!isCompanyAnswered || !isAccountantAnswered}
                form="basic-info-form"
              >
                Continue
              </Button>
            </StaleBtnGroup>
          </div>
        </StaleOverflowScroll>
      </SignUpContent>
    </SignUpWrap>
  );
};

export default StepThree;
