import { Defs } from '@nivo/core';
import { CustomLayerProps } from '@nivo/line';
import { area, curveMonotoneX } from 'd3-shape';
import { FC } from 'react';
import { useTheme } from 'styled-components';

interface DataPoint {
  x: number;
  y0: number | null;
  y1: number | null;
}

const definedSegment = (d: DataPoint) => {
  return d.y0 !== null && d.y1 !== null;
};

interface OwnProps {
  effectiveRateData: { x: number; y0: number | null; y1: number | null }[];
}

const ChartRatesArea: FC<OwnProps & CustomLayerProps> = ({
  xScale,
  yScale,
  innerHeight,
  effectiveRateData,
}) => {
  const theme = useTheme();

  const areaGenerator = area<DataPoint>()
    .x((d) => xScale(d.x) as number)
    .y0((d) => (d.y0 !== null ? Math.min(innerHeight, yScale(d.y0) as number) : 0))
    .y1((d) => (d.y1 !== null ? yScale(d.y1) as number : 0))
    .defined(definedSegment)
    .curve(curveMonotoneX);

  return (
    <>
      <Defs
        defs={[
          {
            id: 'pattern',
            type: 'patternLines',
            background: 'transparent',
            color: theme.color.greyLight_1,
            lineWidth: 1,
            spacing: 6,
            rotation: -45,
          },
        ]}
      />
      <path
        d={areaGenerator(effectiveRateData) ?? undefined}
        fill="url(#pattern)"
        stroke={theme.color.greyLight_1}
        strokeWidth={2}
      />
    </>
  );
};

export default ChartRatesArea;
