import { FC, useState } from 'react';

import { StaleHexagonRating, StaleHowRatesNow, Expansion } from 'components';
import { ICurrency } from 'types';
import { Wrapper, WrapperContent } from './StaleRateMore.styles';
import useRatings from 'hooks/useRatings';
import StaleLoader from '../StaleLoader/StaleLoader';
import { Paragraph } from '../Typography/Typography';
import Icon from '../Icon/Icon';
import { UseCurrencyRateReturnValues } from 'hooks/useCurrencyRate';
import { useStoreState } from 'state';
import Button from '../Button/Button';

interface OwnProps {
  months: number;
  sellCurrency: ICurrency;
  buyCurrency: ICurrency;
  sellAmountAsNumber: number;
  buyAmountAsNumber: number;
  rate: UseCurrencyRateReturnValues['rate'];
}

const StaleRateMore: FC<OwnProps> = ({
  buyAmountAsNumber,
  buyCurrency,
  sellAmountAsNumber,
  sellCurrency,
  rate,
}) => {
  const [showMore, setShowMore] = useState(false);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  const { rateRating, savingRating, isLoading, ratingText } = useRatings({
    rate,
    buyCurrencyCode: buyCurrency.code,
    sellCurrencyCode: sellCurrency.code,
  });

  return (
    <>
      <Wrapper showMore={showMore}>
        {isLoading ? (
          <StaleLoader />
        ) : (
          <>
            {!showMore && <Paragraph>{ratingText}</Paragraph>}

            <Button variant="link" onClick={() => setShowMore(!showMore)}>
              {showMore ? 'Hide' : 'More'}

              <Icon
                icon="arrow-down"
                style={{ transform: showMore ? 'rotate(180deg)' : '' }}
              />
            </Button>

            <div className="bottom">
              <StaleHexagonRating title="Rate" rating={rateRating} />
              {currencyByCode(buyCurrency.code)?.tier1 && (
                <StaleHexagonRating
                  title="Potential to move"
                  rating={savingRating}
                />
              )}
            </div>
          </>
        )}
      </Wrapper>

      <Expansion isOpen={showMore}>
        <WrapperContent showMore={showMore}>
          <StaleHowRatesNow
            buyAmount={buyAmountAsNumber}
            sellAmount={sellAmountAsNumber}
            sellCurrencyCode={sellCurrency?.code}
            buyCurrencyCode={buyCurrency?.code}
            sellCurrencySymbol={sellCurrency?.symbol}
            buyCurrencySymbol={buyCurrency?.symbol}
            rate={rate}
          />
        </WrapperContent>
      </Expansion>
    </>
  );
};

export default StaleRateMore;
