import { FC } from 'react';
import { Paragraph } from 'components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { IClient } from 'state/user';
import { useStoreState } from 'state';

interface OwnProps {
  data: IClient;
  value: number;
}

const NumberCell: FC<OwnProps> = ({ data, value }) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { entityCurrency } = data;

  return (
    <Paragraph>
      {parseIntoCurrencyStringWithSymbol(
        value,
        currencyByCode(entityCurrency)?.symbol,
        0
      )}
    </Paragraph>
  );
};

export default NumberCell;
