import firebase from 'firebase/app';
import db from 'services/firestore';
import dayjs from 'dayjs';

const getUniqueDecreasingNumber = (inject?: string) => {
  const dateAsNumber = dayjs().diff(dayjs('1970-01-01'));
  const id = (9999999999999 - dateAsNumber).toString();
  return generateId(id, inject);
};

const generateId = (prefix: string, inject?: string) => {
  const postfix = Math.floor(100000 + Math.random() * 900000).toString();
  if (inject) {
    return `${prefix}-${inject}-${postfix}`;
  }
  return `${prefix}-${postfix}`;
};

export interface CreateUserLogParams {
  userId: string;
  type: 'info' | 'warn' | 'error';
  triggeredBy: string;
  url?: string;
  message: string;
  uiComponentStack?: string;
}

// TODO: consider moving to the BE
export const createUserLog = async (payload: CreateUserLogParams) => {
  try {
    await db
      .collection('userLogs')
      .doc(getUniqueDecreasingNumber())
      .set({
        ...payload,
        _createdAt: firebase.firestore.Timestamp.now(),
      });
  } catch (error: any) {
    console.warn(error.message);
  }
};
