import styled from 'styled-components';

export const StyledChartTooltip = styled.div`
  background-color: ${({ theme }) => theme.color.dark};
  box-shadow: ${({ theme }) => theme.shadow};
  padding: ${({ theme }) => theme.spacing.xs};
  border-radius: ${({ theme }) => theme.borderRadius.s};
`;

// TODO: now we can use StyledChartTooltip with our charts using tooltip prop, 
// so we can get rid of this one once we use StyledChartTooltip everywhere
export const StyledChartTooltipForPortal = styled(StyledChartTooltip)`
  position: absolute;
  display: none;
  z-index: ${({ theme }) => theme.zIndex.chartTooltip};
`;
