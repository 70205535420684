import { useEffect, useState } from 'react';
import { getCodatAccounts } from 'services/firebase';
import { useStoreState } from 'state';
import { IAccountData, ICodatAccount } from 'types/integrations';
import { Notify } from 'utils';
import { generateCodatBankFeesAccountData } from 'utils/integrations';

interface IFilteredAccounts {
  codatAccounts: IAccountData[];
  bankFeesAccounts: IAccountData[];
}

const useCodatAccounts = (fetchAccounts = false) => {
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [hasEntityCurrencyAccount, setHasEntityCurrencyAccount] = useState(
    false
  );
  const [
    defaultBankFeesAccount,
    setDefaultBankFeesAccount,
  ] = useState<ICodatAccount | null>(null);
  const [codatAccounts, setCodatAccounts] = useState<IAccountData[]>([]);
  const [bankFeesAccounts, setBankFeesAccounts] = useState<IAccountData[]>([]);
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);

  useEffect(() => {
    const fetchCodatAccounts = async () => {
      try {
        setIsLoadingAccounts(true);
        const response = await getCodatAccounts();
        if (response.data?.success) {
          const accounts = response.data.data;
          const foundEntityCurrencyAccount = accounts?.some(
            (account) => account.name === `HedgeFlows ${entityCurrencyCode}}`
          );

          if (foundEntityCurrencyAccount) {
            setHasEntityCurrencyAccount(foundEntityCurrencyAccount);
          }

          const findBankFeesAccount = accounts?.find(
            (account) => account.id === '27'
          );
          if (findBankFeesAccount) {
            setDefaultBankFeesAccount(findBankFeesAccount);
          }

          const filteredAssetAndExpenseAccounts = accounts?.reduce<IFilteredAccounts>(
            (total, el) => {
              if (el.type === 'Asset') {
                total.codatAccounts = [
                  ...total?.codatAccounts,
                  generateCodatBankFeesAccountData(el),
                ];
              }
              if (el.type === 'Expense') {
                total.bankFeesAccounts = [
                  ...total?.bankFeesAccounts,
                  generateCodatBankFeesAccountData(el),
                ];
              }
              return total;
            },
            { codatAccounts: [], bankFeesAccounts: [] }
          );

          if (filteredAssetAndExpenseAccounts?.codatAccounts?.length) {
            setCodatAccounts(filteredAssetAndExpenseAccounts.codatAccounts);
          }
          if (filteredAssetAndExpenseAccounts?.bankFeesAccounts?.length) {
            setBankFeesAccounts(
              filteredAssetAndExpenseAccounts.bankFeesAccounts
            );
          }
        }
      } catch (error: any) {
        Notify.error(error.message);
      } finally {
        setIsLoadingAccounts(false);
      }
    };

    if (fetchAccounts) {
      fetchCodatAccounts();
    }
  }, [entityCurrencyCode, fetchAccounts]);

  return {
    isLoadingAccounts,
    hasEntityCurrencyAccount,
    codatAccounts,
    bankFeesAccounts,
    defaultBankFeesAccount,
  };
};

export default useCodatAccounts;
