import firebase from 'firebase/app';
import { IResponse } from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import firestore from 'services/firestore';

import { openDoc, openQuery } from 'utils';
import {
  CashflowsRisksData,
  TPastPerformanceItem,
  IPnlDataPerMonth,
} from 'types/analyses';
import { TReportsContentType } from 'pages/Reports/types';

export interface RefreshCashflowsRisksParams {
  entityId: string;
  sellCurrency: string;
}

export const refreshCashflowRisks = async ({
  entityId,
  sellCurrency,
}: RefreshCashflowsRisksParams) => {
  return AxiosPrivateFirebaseInstance.get<IResponse>(
    `analysis/cashflow_risk/${entityId}/${sellCurrency}/refresh`
  );
};

export interface SubscribeToCashflowsRisksParams {
  entityId: string;
  callback: (cashflowsRisksData: CashflowsRisksData | null) => void;
}

export const subscribeToCashflowsRisks = ({
  entityId,
  callback,
}: SubscribeToCashflowsRisksParams) =>
  firestore
    .collection('entities')
    .doc(entityId)
    .collection('analyses')
    .doc('cashFlowRiskAnalysis')
    .onSnapshot(
      (doc) => {
        callback(openDoc(doc));
      },
      (error) =>
        console.log(
          `Failed to subscribe to cashflows risks for entity ID ${entityId}). Error: ${error}`
        )
    );

interface GetPastPerformanceCurrenciesParams {
  entityId: string;
}

export const getPastPerformanceCurrencies = ({
  entityId,
}: GetPastPerformanceCurrenciesParams) =>
  firestore
    .collection('entities')
    .doc(entityId)
    .collection('pastPerformanceAnalysis')
    .where(firebase.firestore.FieldPath.documentId(), '!=', 'status')
    .get()
    .then(openQuery);

export type ReportCalculationStatus = 'inProgress' | 'complete';
interface SubscribeToPastPerformanceStatus {
  entityId: string;
  callback: (status: ReportCalculationStatus) => void;
}
export const subscribeToPastPerformanceStatus = ({
  entityId,
  callback,
}: SubscribeToPastPerformanceStatus) =>
  firestore
    .collection('entities')
    .doc(entityId)
    .collection('pastPerformanceAnalysis')
    .doc('status')
    .onSnapshot(
      (query) => {
        const statusDoc = openDoc<{ status: ReportCalculationStatus }>(query);
        if (statusDoc?.status) {
          callback(statusDoc?.status);
        }
      },
      (error) =>
        console.log(
          `Failed to subscribe to past performance status for entity ID ${entityId}). Error: ${error}`
        )
    );

interface GetPastPerformanceDataForEntityParams {
  entityId: string;
}

export interface PastPerformanceDataForEntityResponse {
  worstCumulativeImpact: number;
  worstPotentialLoss: number;
}

export const getPastPerformanceDataForEntity = async ({
  entityId,
}: GetPastPerformanceDataForEntityParams) => {
  return AxiosPrivateFirebaseInstance.get<
    IResponse<PastPerformanceDataForEntityResponse>
  >(`analysis/past_performance/${entityId}`);
};

export const getPastPerformanceDataPerRecordByCurrency = async ({
  entityId,
  currency,
}: any): Promise<TPastPerformanceItem[]> =>
  firestore
    .collection('entities')
    .doc(entityId)
    .collection('pastPerformanceAnalysis')
    .doc(currency)
    .collection('perRecord')
    .get()
    .then((query) => openQuery(query));

interface GetPastPerformancePerMonthByCurrencyParams {
  entityId: string;
  currency: string;
  from: string;
}

export const getPastPerformanceDataPerMonthByCurrency = async ({
  entityId,
  currency,
  from,
}: GetPastPerformancePerMonthByCurrencyParams): Promise<IPnlDataPerMonth[]> =>
  firestore
    .collection('entities')
    .doc(entityId)
    .collection('pastPerformanceAnalysis')
    .doc(currency)
    .collection('perMonth')
    .where(firebase.firestore.FieldPath.documentId(), '>=', from)
    .get()
    .then(openQuery);

interface IGetPastPerformanceDataAsCsvFileParams {
  entityId: string;
  currency: string;
  activeMonth: string;
  tab: TReportsContentType;
}

export const getPastPerformanceDataAsCsvFile = async ({
  activeMonth,
  currency,
  tab,
  entityId,
}: IGetPastPerformanceDataAsCsvFileParams) =>
  AxiosPrivateFirebaseInstance.get<string>(
    `analysis/past_performance/csv/${entityId}/${currency}/${tab}/${activeMonth}`
  );

interface IGetCashflowsRisksPerCurrencyParams {
  currencyCode: string;
  period: 'month' | 'quarter';
}

export const getCashflowsRisksPerCurrency = async ({
  currencyCode,
  period,
}: IGetCashflowsRisksPerCurrencyParams) => {
  return AxiosPrivateFirebaseInstance.get<IResponse>(
    `analysis/cashflow_risk/${currencyCode}/${period}`
  );
};

interface IUpdateEntityBudgetRatesParams {
  currencyCode: string;
  date: string; // YYYY-MM
  budgetRate: number;
}

export const updateEntityBudgetRates = async ({
  currencyCode,
  date,
  budgetRate,
}: IUpdateEntityBudgetRatesParams) => {
  return AxiosPrivateFirebaseInstance.put<IResponse>(
    `analysis/budget-rates/${currencyCode}/${date}`,
    {
      budgetRate,
    }
  );
};

interface GetPastPerformanceDataFromExternalSourceParams {
  entityId: string;
  from: string;
}

export const getPastPerformanceDataFromExternalSource = async ({
  entityId,
  from,
}: GetPastPerformanceDataFromExternalSourceParams) =>
  firestore
    .collection('entities')
    .doc(entityId)
    .collection('analyses')
    .doc('externalPastPerformance')
    .collection('perMonth')
    .where(firebase.firestore.FieldPath.documentId(), '>=', from)
    .get()
    .then(openQuery);
