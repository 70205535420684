import {
  IInvoice,
  IInvoiceApproval,
  IInvoiceFromSearch,
  INVOICE_STATUSES,
  ISourceSyncData,
  Nullable,
} from 'types';
import dayjs from 'dayjs';
import { IInvoiceForTable } from 'components/shared/InvoicesTableNew/types';
import { isDateOverdue } from './dates';
import { SortingRule } from 'react-table';

// invoices from search
export const isInvoiceFromSearchNotPaid = (invoice: IInvoiceFromSearch) =>
  invoice.status !== INVOICE_STATUSES.paid;

export const isInvoiceFromSearchFromXero = (invoice: IInvoiceFromSearch) =>
  !!invoice.externalRefsXeroId;

const isInvoiceFromSearchFromCodat = (invoice: IInvoiceFromSearch) =>
  !!invoice.externalRefsCodatId;

export const isInvoiceFromSearchHasValidContact = (
  invoice: IInvoiceFromSearch
) => !!invoice.contactId && !!invoice.contactEnabled;

export const isInvoiceFromSearchApprovable = (invoice: IInvoiceFromSearch) =>
  isPayableInvoice(invoice) &&
  isInvoiceFromSearchHasValidContact(invoice) &&
  isInvoiceStatusInPayableState(invoice) &&
  invoice.approvalStatus === 'submitted';

export const isInvoiceFromSearchSubmittableForReview = (
  invoice: IInvoiceFromSearch
) =>
  isPayableInvoice(invoice) &&
  isInvoiceFromSearchHasValidContact(invoice) &&
  isInvoiceStatusInPayableState(invoice) &&
  !invoice.approvalStatus;

export const isInvoiceFromSearchRowSelectable = (invoice: IInvoiceFromSearch) =>
  invoice.status !== INVOICE_STATUSES.paid &&
  !isInvoiceDisabled(invoice) &&
  isInvoiceFromSearchHasValidContact(invoice) &&
  !isReceivableInvoice(invoice) &&
  isInvoiceStatusInPayableState(invoice);

export const isInvoiceRowSelectable = (invoice: IInvoice) =>
  invoice.status !== INVOICE_STATUSES.paid &&
  !isInvoiceDisabled(invoice) &&
  isInvoiceHasValidContact(invoice) &&
  !isReceivableInvoice(invoice) &&
  isInvoiceStatusInPayableState(invoice);

export const appendRateAndProfitAndLossToInvoiceFromSearch = (
  invoice: IInvoiceFromSearch,
  rateToUse?: number
): IInvoiceFromSearch => {
  const invoiceRate = getInvoiceFromSearchRate(invoice, rateToUse);
  const profitAndLoss = generateProfitAndLoss(invoice, invoiceRate, rateToUse);

  return { ...invoice, profitAndLoss, invoiceRate };
};

const getInvoiceFromSearchRate = (
  record: IInvoiceFromSearch,
  rateToUse?: number
) => {
  if (!record.externalCurrencyRate) {
    return rateToUse ?? null;
  }

  if (isInvoiceFromSearchFromCodat(record) && isReceivableInvoice(record)) {
    return 1 / record.externalCurrencyRate;
  }

  return record.externalCurrencyRate;
};

interface IIsApprovalFlowAllowToPayInvoiceFromSearchParams {
  hasApprovalFlow: boolean;
  record: IInvoiceFromSearch;
}

export const isApprovalFlowAllowToPayInvoiceFromSearch = ({
  hasApprovalFlow,
  record,
}: IIsApprovalFlowAllowToPayInvoiceFromSearchParams) =>
  !hasApprovalFlow || (hasApprovalFlow && record.approvalStatus === 'approved');

export const isInvoiceFromSearchApprovableByUser = (
  invoice: IInvoiceFromSearch,
  userId: Nullable<string>
) =>
  isInvoiceFromSearchApprovable(invoice) &&
  !!userId &&
  invoice.approvedBy !== userId;

// invoices from firestore
export const isInvoiceHasValidContact = (invoice: IInvoice) =>
  !!invoice.contact && !!invoice.contactId && !!invoice.contact.enabled;

export const isInvoiceApprovable = (invoice: IInvoice) =>
  isPayableInvoice(invoice) &&
  isInvoiceHasValidContact(invoice) &&
  isInvoiceStatusInPayableState(invoice) &&
  invoice.approval?.status === 'submitted';

export const isInvoiceApprovableByUser = (
  invoice: IInvoice,
  userId: Nullable<string>
) =>
  isInvoiceApprovable(invoice) &&
  !!userId &&
  invoice.approval?.approvedBy !== userId;

export const isInvoiceSubmittableForReview = (invoice: IInvoice) =>
  isPayableInvoice(invoice) &&
  isInvoiceHasValidContact(invoice) &&
  isInvoiceStatusInPayableState(invoice) &&
  !invoice.approval?.status;

export const appendRateAndProfitAndLossToInvoice = (
  invoice: IInvoice,
  rateToUse?: number
): IInvoiceForTable => {
  const invoiceRate = getInvoiceRate(invoice, rateToUse);
  const profitAndLoss = generateProfitAndLoss(invoice, invoiceRate, rateToUse);

  return { ...invoice, profitAndLoss, invoiceRate };
};

const isInvoiceFromCodat = (invoice: IInvoice) =>
  !!invoice.externalRefs?.codatId;

const getInvoiceRate = (record: IInvoice, rateToUse?: number) => {
  if (!record.externalCurrencyRate) {
    return rateToUse ?? null;
  }

  if (isInvoiceFromCodat(record) && isReceivableInvoice(record)) {
    return 1 / record.externalCurrencyRate;
  }

  return record.externalCurrencyRate;
};

export const isInvoiceOverdue = (invoice: IInvoice) =>
  isDateOverdue(invoice.dueDate) &&
  isPayableInvoice(invoice) &&
  invoice.status !== INVOICE_STATUSES.paid;

interface IIsApprovalFlowAllowToPayInvoiceParams {
  hasApprovalFlow: boolean;
  record: IInvoice;
  isUserApprover: boolean;
}

export const isApprovalFlowAllowToPayInvoice = ({
  hasApprovalFlow,
  record,
}: IIsApprovalFlowAllowToPayInvoiceParams) =>
  !hasApprovalFlow ||
  (hasApprovalFlow && record.approval?.status === 'approved');

export const isInvoiceDueIn14Days = (invoice: IInvoice) => {
  const daysLeft = getInvoiceDaysLeft(invoice);

  return (
    daysLeft <= 14 &&
    daysLeft > 0 &&
    isPayableInvoice(invoice) &&
    invoice.status !== INVOICE_STATUSES.paid
  );
};

export const isInvoiceNotPaid = (invoice: IInvoice) =>
  invoice.status !== INVOICE_STATUSES.paid;

export const isInvoiceFromXero = (invoice: IInvoice) =>
  !!invoice.externalRefs?.xeroId;

// both
export const isInvoiceDeletable = (invoice: IInvoice | IInvoiceFromSearch) =>
  !invoice.contractId && !invoice.transferId;

export const getInvoiceDaysLeft = (invoice: IInvoice | IInvoiceFromSearch) =>
  dayjs(invoice.dueDate).endOf('day').diff(dayjs().startOf('day'), 'days');

export const isReceivableInvoice = (invoice: IInvoice | IInvoiceFromSearch) =>
  invoice.type === 'Receivable';

export const isPayableInvoice = (invoice: IInvoice | IInvoiceFromSearch) =>
  invoice.type === 'Payable';

export const isInvoiceStatusInPayableState = (
  invoice: IInvoice | IInvoiceFromSearch
) =>
  invoice.status === INVOICE_STATUSES.authorised ||
  invoice.status === INVOICE_STATUSES.submitted ||
  invoice.status === INVOICE_STATUSES.partiallyPaid;

export const isInvoiceDisabled = (invoice: IInvoice | IInvoiceFromSearch) =>
  invoice.status === INVOICE_STATUSES.voided ||
  invoice.status === INVOICE_STATUSES.deleted ||
  !!invoice.excludeFromRisk;

export const isInvoicePrebookable = (invoice: IInvoice | IInvoiceFromSearch) =>
  !invoice?.contractId &&
  !invoice.trackingId &&
  ((isPayableInvoice(invoice) && isInvoiceStatusInPayableState(invoice)) ||
    isReceivableInvoice(invoice));

export const isInvoiceTrackable = (invoice: IInvoice | IInvoiceFromSearch) =>
  invoice.status !== INVOICE_STATUSES.paymentScheduled;

export const getInvoiceRemainingAmount = (
  invoice: IInvoice | IInvoiceFromSearch
) => invoice.amountDue || invoice.totalAmount;

export const getInvoiceNumber = (invoice: IInvoice | IInvoiceFromSearch) =>
  invoice?.invoiceNumber || '"no ref"';

export const getInvoiceApprovalNotificationMessage = (
  approvalStatus: IInvoiceApproval['status'],
  isMoreThenOneInvoice: boolean
) => {
  const invoiceMsg = isMoreThenOneInvoice ? 'Invoices have' : 'Invoice has';

  if (approvalStatus === 'submitted') {
    return `${invoiceMsg} been submitted for approval`;
  }
  if (approvalStatus === 'approved') {
    return `${invoiceMsg} been approved`;
  }

  return `${invoiceMsg} been rejected`;
};

const generateProfitAndLoss = (
  record: IInvoice | IInvoiceFromSearch,
  invoiceRate: number | null,
  rateToUse?: number
) => {
  if (!invoiceRate || !rateToUse) {
    return null;
  }
  const profitAndLoss =
    record.totalAmount / invoiceRate - record.totalAmount / rateToUse;
  return isReceivableInvoice(record) ? -1 * profitAndLoss : profitAndLoss;
};

interface IGetInvoiceSyncedToExternalSourceStatusDetailsProps {
  lastSyncToExternalSource?: ISourceSyncData;
}

export const getInvoiceSyncedToExternalSourceStatusDetails = ({
  lastSyncToExternalSource,
}: IGetInvoiceSyncedToExternalSourceStatusDetailsProps) => {
  if (!lastSyncToExternalSource) {
    return null;
  }

  const { status, success } = lastSyncToExternalSource;

  if (status === 'success' || success) {
    return {
      icon: 'done-ico',
      color: 'emeraldDark',
    };
  }
  if (status === 'error' || success === false) {
    return {
      icon: 'failed-ico',
      color: 'red',
    };
  }
  if (status === 'queued') {
    return {
      icon: 'clock-ico',
      color: 'yellow',
    };
  }
  return null;
};

export const getInvoiceFromSearchSyncedToExternalSourceStatusDetails = ({
  lastSyncToExternalSourceStatus,
}: {
  lastSyncToExternalSourceStatus?: ISourceSyncData['status'];
}) => {
  if (!lastSyncToExternalSourceStatus) {
    return null;
  }

  if (lastSyncToExternalSourceStatus === 'success') {
    return {
      icon: 'done-ico',
      color: 'emeraldDark',
    };
  }
  if (lastSyncToExternalSourceStatus === 'error') {
    return {
      icon: 'failed-ico',
      color: 'red',
    };
  }
  if (lastSyncToExternalSourceStatus === 'queued') {
    return {
      icon: 'clock-ico',
      color: 'yellow',
    };
  }
  return null;
};

export const getInvoicesSortFields = (
  sortState: SortingRule<IInvoiceFromSearch>[]
) =>
  sortState.map(
    (sortItem) => `${sortItem.id}:${sortItem.desc ? 'desc' : 'asc'}`
  );
