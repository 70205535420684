import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  IntegrationTileWrapper,
  IntegrationSvg,
  IntegrationTextHint,
  IntegrationParagraph,
  IntegrationRow,
  ComingSoon,
  CSVRow,
} from './IntegrationTile.styles';
import { Col, PermissionsChecker, StaleBetaTag } from 'components';
import { Notify } from 'utils';
import OrderWiseLoginPopup from '../OrderWiseLoginPopup/OrderWiseLoginPopup';
import { useHistory } from 'react-router-dom';
import { IntegrateWithOrderWiseParams } from 'services/firebase/integrations';
import { INTEGRATION_TYPE } from 'types';
import useIntegration from './useIntegration';
import { IAvailableIntegration } from 'types/integrations';
import { getInvoiceManualUploadLink } from 'utils/links';
import Button from 'components/shared/Button/Button';
import { formatTimeFormat, formatDateFormat } from 'utils/dates';
import { useTheme } from 'styled-components';

interface ExportedDataProps {
  onLogin?: () => Promise<void>;
  onImportData?: () => Promise<void>;
  onDisconnect?: () => Promise<void>;
  isLoading?: boolean;
  isImporting?: boolean;
  isDisconnecting?: boolean;
  isIntegrated?: boolean;
  onManageSettings?: () => void;
  connectionName?: string;
  isLoginOrderWise?: boolean;
  setIsLoginOrderWise?: Dispatch<SetStateAction<boolean>>;
  onLoginOrderWise?: (values: IntegrateWithOrderWiseParams) => Promise<void>;
  onManageLogs?: () => void;
  lastSuccessfulSync?: string;
  reauthenticate?: boolean;
}

interface Props {
  integration: IAvailableIntegration;
}
const IntegrationTile: React.FC<Props> = ({ integration }) => {
  const theme = useTheme();
  const history = useHistory();
  const { id, name, img, type, available, isBeta } = integration;

  const {
    onLogin,
    onImportData,
    isImporting,
    isDisconnecting,
    onDisconnect,
    isLoading,
    isIntegrated,
    connectionName,
    onManageSettings,
    isLoginOrderWise,
    setIsLoginOrderWise,
    onLoginOrderWise,
    onManageLogs,
    lastSuccessfulSync,
    reauthenticate,
  }: ExportedDataProps = useIntegration({
    withStartAction: true,
    variant: id,
    codatShortCode: integration.codatShortCode,
    platformNames: integration.platformNames,
  });

  const connect = async () => {
    try {
      await onLogin?.();
    } catch (error: any) {
      Notify.error(error);
    }
  };

  const disconnect = async () => {
    try {
      await onDisconnect?.();
    } catch (error: any) {
      Notify.error(error);
    }
  };

  const additionalText = useMemo(() => {
    if (isIntegrated && !!connectionName) {
      return connectionName;
    } else {
      return type === INTEGRATION_TYPE.business
        ? 'Business system'
        : 'Accounting system';
    }
  }, [connectionName, isIntegrated, type]);

  return (
    <>
      <IntegrationTileWrapper>
        {id !== 'csv' && (
          <>
            <IntegrationSvg>
              <use xlinkHref={img} />
            </IntegrationSvg>
            <Col>
              <IntegrationParagraph variant="bold">
                {name}
                {isBeta && <StaleBetaTag />}
              </IntegrationParagraph>
              <IntegrationTextHint color="grey">
                {additionalText}
              </IntegrationTextHint>
              {isIntegrated && lastSuccessfulSync && (
                <IntegrationTextHint>
                  Last sync: {formatTimeFormat(lastSuccessfulSync)} on{' '}
                  {formatDateFormat(lastSuccessfulSync)}
                </IntegrationTextHint>
              )}
            </Col>
          </>
        )}

        <IntegrationRow>
          {isIntegrated && id === 'orderWise' && (
            <Button ml variant="link" onClick={connect} disabled={isLoading}>
              Reconnect
            </Button>
          )}

          {id === 'csv' && (
            <PermissionsChecker action="create" resource="cashflows_manual">
              <CSVRow>
                Or manually
                <Button
                  ml
                  mlValue={theme.spacing.xs}
                  variant="link"
                  onClick={() => history.push(getInvoiceManualUploadLink())}
                >
                  upload CSV invoices
                </Button>
              </CSVRow>
            </PermissionsChecker>
          )}

          {isIntegrated ? (
            <>
              {!!onImportData && (
                <Button
                  variant="link"
                  onClick={onImportData}
                  disabled={isImporting || reauthenticate}
                  isLoading={isImporting}
                >
                  Refresh data
                </Button>
              )}
              {!!onManageLogs && (
                <Button
                  ml
                  variant="link"
                  disabled={reauthenticate}
                  onClick={onManageLogs}
                >
                  View logs
                </Button>
              )}
              {!!onManageSettings && (
                <PermissionsChecker action="update" resource="integrations">
                  <Button
                    ml
                    variant="link"
                    disabled={reauthenticate}
                    onClick={onManageSettings}
                  >
                    Settings
                  </Button>
                </PermissionsChecker>
              )}

              {!!onLogin && reauthenticate && (
                <PermissionsChecker action="update" resource="integrations">
                  <Button
                    ml
                    variant="link"
                    onClick={connect}
                    disabled={isLoading}
                  >
                    Reauthenticate
                  </Button>
                </PermissionsChecker>
              )}
              <PermissionsChecker action="update" resource="integrations">
                <Button
                  ml
                  variant="link"
                  onClick={!!onDisconnect ? disconnect : undefined}
                  disabled={!!onDisconnect ? isDisconnecting : true}
                >
                  Disconnect {!onDisconnect && '(coming soon)'}
                </Button>
              </PermissionsChecker>
            </>
          ) : (
            !!onLogin && (
              <PermissionsChecker action="update" resource="integrations">
                <Button
                  ml
                  variant="link"
                  onClick={connect}
                  disabled={isLoading}
                >
                  Connect
                </Button>
              </PermissionsChecker>
            )
          )}

          {!available && <ComingSoon>Coming soon</ComingSoon>}
        </IntegrationRow>
      </IntegrationTileWrapper>

      {isLoginOrderWise && (
        <OrderWiseLoginPopup
          onClose={() => setIsLoginOrderWise?.(false)}
          onSubmit={onLoginOrderWise}
          isIntegrated={!!isIntegrated}
        />
      )}
    </>
  );
};

export default IntegrationTile;
