import { FC, MouseEvent } from 'react';
import { Icon, Row } from 'components';
import { IClient } from 'state/user';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router';
import { getRisksPageLink } from 'utils/links';
import { ICurrency } from 'types';

interface OwnProps {
  data: IClient;
  detectedCurrencies: string[];
}

const FlagCell: FC<OwnProps> = ({ data, detectedCurrencies }) => {
  const theme = useTheme();
  const history = useHistory();
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { topCurrenciesByCashflowRisk } = data;

  const onClickHandler = ({
    event,
    currency,
  }: {
    event: MouseEvent<HTMLButtonElement>;
    currency: ICurrency;
  }) => {
    event.stopPropagation();
    history.push(getRisksPageLink({ currency: currency.code }), {
      detectedCurrencies,
    });
  };

  return (
    <Row gap={theme.spacing.xs}>
      {topCurrenciesByCashflowRisk?.map((currencyCode) => {
        const currency = currencyByCode(currencyCode);

        if (currency) {
          return (
            <button onClick={(event) => onClickHandler({ event, currency })}>
              <Icon icon={currency.countryCode} />
            </button>
          );
        }

        return null;
      })}
    </Row>
  );
};

export default FlagCell;
