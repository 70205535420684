import { ComputedBarDatum } from '@nivo/bar';
import { Col, Paragraph } from 'components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import ChartBarRealised from '../ChartBarRealised/ChartBarRealised';
import ChartTooltip from 'components/shared/ChartTooltip/ChartTooltip';
import useChartTooltip from 'hooks/useChartTooltip';
import ChartBarsGroupWrapper from '../ChartBarsGroupWrapper/ChartBarsGroupWrapper.styles';
import { useStoreState } from 'state';

const ChartBarsGroup = <T extends any>({
  groupId,
  barsGroup,
  barExtraProps,
  onClick,
}: {
  groupId: string;
  barsGroup: ComputedBarDatum<T>[];
  barExtraProps: any;
  onClick: any;
}) => {
  const { tooltipRef, ...mouseEvents } = useChartTooltip();
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(entityCurrencyCode);

  return (
    <ChartBarsGroupWrapper onClick={() => onClick(groupId)} {...mouseEvents}>
      {barsGroup.map((bar) => (
        <ChartBarRealised
          key={bar.data.id}
          bar={bar as any}
          activeMonth={barExtraProps.activeMonth}
        />
      ))}

      <ChartTooltip wrapperRef={tooltipRef}>
        <Col>
          <Paragraph color="white">{`Realised impact: ${parseIntoCurrencyStringWithSymbol(
            barsGroup[0].data.value,
            currency?.symbol,
            0
          )}`}</Paragraph>
          <Paragraph color="white">{`Transaction costs: ${parseIntoCurrencyStringWithSymbol(
            barsGroup[1].data.value,
            currency?.symbol,
            0
          )}`}</Paragraph>
          <Paragraph color="white">{`Total: ${parseIntoCurrencyStringWithSymbol(
            (barsGroup[1].data.value ?? 0) + (barsGroup[0].data.value ?? 0),
            currency?.symbol,
            0
          )}`}</Paragraph>
        </Col>
      </ChartTooltip>
    </ChartBarsGroupWrapper>
  );
};

export default ChartBarsGroup;
