import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import {
  StaleTitleH3,
  StaleTitleH5,
  StaleTextHint,
  StaleSubTitleMedium,
} from 'components';

export const Wrapper = styled.div<{}>(
  ({ theme }) => css`
    background: ${theme.color.dark};
    padding: 8px 16px;
    color: ${theme.color.white};
    min-height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 auto;

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      padding: 120px 70px;
      flex: 0 0 50%;
      max-width: 50%;
      justify-content: center;
    }

    @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
      padding: 160px 172px 160px 160px;
    }

    .icon {
      margin: 68px auto 65px;
      fill: ${theme.color.white};
    }

    .slick-slider {
      @media (max-width: ${theme.breakpoint.largeMax}px) {
        display: none;
      }

      .wrap {
        display: flex;
        flex-direction: column;
      }
    }

    ${StaleTitleH3} {
      margin-bottom: 9px;
    }

    ${StaleTitleH5} {
      @media (max-width: ${theme.breakpoint.largeMax}px) {
        text-align: center;
      }
      @media (min-width: ${theme.breakpoint.largeMin}px) {
        font-size: 36px;
        line-height: 42.2px;
        background: -webkit-linear-gradient(
          360deg,
          #cfeeea 1.31%,
          #e7edd0 27.56%,
          #facba0 70.76%,
          #ffb8bd 98.26%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 30px;
      }
    }

    ${StaleSubTitleMedium} {
      margin-bottom: 16px;

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        margin-bottom: 24px;
      }
    }

    .back {
      position: absolute;
      right: 16px;
      top: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      svg {
        fill: ${theme.color.white};
      }

      &.previous {
        display: flex;
        align-items: center;
        left: 12px;
        right: inherit;
        right: initial;

        svg {
          margin-right: 4px;
        }
      }
    }
  `
);

export const MenuItem = styled.div<{ passed: boolean; current: boolean }>(
  ({ theme, passed, current }) => css`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${passed
      ? theme.color.white
      : current
      ? theme.color.emeraldDark
      : theme.color.grey};
    position: relative;
    padding: ${isMobile ? '6px 24px 2px 0' : '0 34px 16px 0'};

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      font-size: 16px;
      margin-bottom: 2px;
    }

    &:not(:last-of-type):after {
      content: '';
    }

    &:last-of-type {
      padding-right: 0;
    }

    &:after {
      width: 0;
      height: 0;
      border-top: ${isMobile && '4.5px solid transparent'};
      border-left: ${isMobile && `6px solid ${theme.color.grey}`};
      border-bottom: ${isMobile && '4.5px solid transparent'};
      position: absolute;
      top: ${isMobile && '3px'};
      right: ${isMobile && '8px'};
      left: ${!isMobile && '46px'};
      bottom: 1px;
      margin: auto;
      border-left-color: ${passed && theme.color.white};
    }

    span {
      border-color: ${passed
        ? theme.color.white
        : current && theme.color.emeraldDark};
    }

    ${StaleTextHint} {
      color: ${current ? theme.color.white : 'inherit'};
    }

    ${!isMobile &&
    css`
      padding-left: 72px;

      span {
        position: absolute;
        top: 0;
        border-right: 2px solid
          ${passed ? theme.color.white : current && theme.color.emeraldDark};
        padding-right: 10px;
        left: 17px;
        width: 30px;
        text-align: right;
      }

      &:after {
        width: 1px;
        height: calc(100% - 24px);
        background: ${theme.color.grey};
      }
    `}
  `
);
