import dayjs from 'dayjs';
import { TCashflowAtRiskNew } from 'types';
import { DB_MONTH_FORMAT, MONTH_DATE_FORMAT } from 'variables';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { notNullTypeGuard } from 'utils';

dayjs.extend(customParseFormat);

export const reduceDataToChartValues = (
  data: TCashflowAtRiskNew[],
  isMonthly: boolean
) => {
  return [
    {
      id: 'closingBalance',
      data: data.map((item) => {
        const dateStringToUse = isMonthly
          ? dayjs(item.date, DB_MONTH_FORMAT).format(MONTH_DATE_FORMAT)
          : item.date;

        return {
          x: dateStringToUse,
          y: item.closingBalance,
          userData: item,
        };
      }),
    },
  ];
};

export const getMinMaxValues = (data: TCashflowAtRiskNew[]) => {
  const closingBalances = data
    .map((item) => item.closingBalance)
    .filter(notNullTypeGuard);
  const min = Math.min(0, ...closingBalances);
  const max = Math.max(0, ...closingBalances);

  return { min, max };
};
