import { FC } from 'react';

import { Paragraph, StaleHint, Row, Icon, Col } from 'components';
import { parseIntoCurrencyString, parseRateWithPrecision } from 'utils';
import InlineLoader from 'components/shared/InlineLoader/InlineLoader';
import { ICurrency } from 'types';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import { UseCurrencyRateReturnValues } from 'hooks/useCurrencyRate';

interface ExchangeSummaryProps {
  sellCurrency: ICurrency;
  buyCurrency: ICurrency;
  buyAmountAsNumber: number;
  sellAmountAsNumber: number;
  rate: UseCurrencyRateReturnValues['rate'];
  conversionFeeRate: number;
  isRateLoading?: boolean;
  isFwdRateLoading?: boolean;
  isFeesLoading?: boolean;
}

const ExchangeSummary: FC<ExchangeSummaryProps> = ({
  sellCurrency,
  buyCurrency,
  buyAmountAsNumber,
  sellAmountAsNumber,
  rate,
  conversionFeeRate,
  isRateLoading,
  isFwdRateLoading,
  isFeesLoading,
}) => {
  const isLoading = isRateLoading || isFeesLoading || isFwdRateLoading;

  const {
    code: sellCurrencyCode,
    precision: sellCurrencyPrecision,
    countryCode: sellCurrencyCountryCode,
    symbol: sellCurrencySymbol,
  } = sellCurrency;
  const {
    code: buyCurrencyCode,
    precision: buyCurrencyPrecision,
    countryCode: buyCurrencyCountryCode,
  } = buyCurrency;

  return (
    <Col>
      <RowInfo>
        <Paragraph>You convert</Paragraph>
        <Row>
          <Paragraph variant="bold" mr>
            {`${sellCurrencyCode} ${parseIntoCurrencyString(
              sellAmountAsNumber,
              sellCurrencyPrecision
            )}
          `}
          </Paragraph>
          <Icon icon={sellCurrencyCountryCode} />
        </Row>
      </RowInfo>
      <StaleHint>
        Includes our conversion fee{' '}
        {isLoading ? (
          <InlineLoader />
        ) : (
          `${sellCurrencySymbol} ${parseIntoCurrencyString(
            sellAmountAsNumber * conversionFeeRate,
            sellCurrencyPrecision
          )}`
        )}
      </StaleHint>
      <RowInfo>
        <Paragraph>You get</Paragraph>
        <Row>
          <Paragraph variant="bold" mr>
            {`${buyCurrencyCode} ${parseIntoCurrencyString(
              buyAmountAsNumber,
              buyCurrencyPrecision
            )}`}
          </Paragraph>
          <Icon icon={buyCurrencyCountryCode} />
        </Row>
      </RowInfo>

      <RowInfo>
        <Paragraph>Exchange rate</Paragraph>

        <Row>
          <Paragraph variant="bold" mr>
            {`1 ${sellCurrencyCode} = `}
            {isLoading || !rate ? (
              <InlineLoader />
            ) : (
              `${parseRateWithPrecision(rate)} ${buyCurrencyCode}`
            )}
          </Paragraph>
          <Icon icon="convert-ico" />
        </Row>
      </RowInfo>
    </Col>
  );
};

export default ExchangeSummary;
