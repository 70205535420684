import { useState, FC, useEffect } from 'react';
import {
  RouteComponentProps,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { ChangePassword } from './components';
import { StaleIntegrationSettings, StaleLoader, Row, Col } from 'components';
import { SETTINGS_TABS } from './constants';
import { WhiteCenteredContainer } from 'components/shared/WhiteCenteredContainer/WhiteCenteredContainer.styles';
import { SettingsHeader } from './Settings.styles';
import { useStoreState } from 'state';
import NotificationSettings from './components/NotificationSettings/NotificationSettings';
import RiskSettings from './components/RiskSettings/RiskSettings';
import UserSettings from './components/UserSettings/UserSettings';
import TeamSettings from './components/TeamSettings/TeamSettings';
import Packages from './components/Packages/Packages';
import Subscriptions from './components/Subscriptions/Subscriptions';
import { StepperItem } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import { useQuery } from 'hooks';

const Settings: FC<RouteComponentProps> = () => {
  const history = useHistory();
  const URLQuery = useQuery();
  const tabFromQuery = URLQuery.get('tab');
  const { url } = useRouteMatch();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const { userEntity, user } = useStoreState((state) => state.UserState);
  const [selectedTab, setSelectedTab] = useState(
    tabFromQuery ?? SETTINGS_TABS[0].id
  );

  const settingsTabsToUse = !!user?.isSuperAdmin
    ? SETTINGS_TABS
    : SETTINGS_TABS.filter((tab) => tab.id !== 'packages');

  useEffect(() => {
    if (tabFromQuery !== selectedTab) {
      URLQuery.set('tab', selectedTab);
      history.push(`${url}?${URLQuery.toString()}`);
    }
  }, [selectedTab, URLQuery, url, history, tabFromQuery]);

  const renderSettingsContent = (tab: any) => {
    switch (tab) {
      case 'general':
        return <UserSettings setShowChangePassword={setShowChangePassword} />;
      case 'notifications':
        return !!userEntity ? <NotificationSettings /> : <StaleLoader />;
      case 'risk':
        return !!userEntity ? <RiskSettings /> : <StaleLoader />;
      case 'integrations':
        return <StaleIntegrationSettings />;
      case 'packages':
        return <Packages />;
      case 'subscriptions':
        return <Subscriptions />;
      case 'team':
        return <TeamSettings />;
      default:
        return null;
    }
  };

  return (
    <>
      <WhiteCenteredContainer>
        <SettingsHeader>
          <Row justifyContent="flex-start">
            {settingsTabsToUse.map(({ title, id }) => (
              <StepperItem
                key={id}
                current={id === selectedTab}
                onClick={() => setSelectedTab(id)}
              >
                {title}
              </StepperItem>
            ))}
          </Row>
        </SettingsHeader>
        <Col>{renderSettingsContent(selectedTab)}</Col>
      </WhiteCenteredContainer>

      {showChangePassword && (
        <ChangePassword setShowChangePassword={setShowChangePassword} />
      )}
    </>
  );
};

export default Settings;
