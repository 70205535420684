import { FC } from 'react';
import { InlineLoader, Paragraph } from 'components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { useStoreState } from 'state';
import { IInvoiceFromSearch } from 'types';
import useInvoiceFromSearchRecord from 'hooks/useInvoiceFromSearchRecord';

interface OwnProps {
  record: IInvoiceFromSearch;
  profitAndLoss: number | null;
}

const ProfitAndLossCellNew: FC<OwnProps> = ({ record, profitAndLoss }) => {
  const { isSameCurrency } = useInvoiceFromSearchRecord({ record });
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(entityCurrencyCode);

  if (isSameCurrency) {
    return null;
  }

  if (!record.invoiceRate) {
    return <InlineLoader />;
  }

  return (
    <>
      {profitAndLoss && (
        <Paragraph ml color={profitAndLoss >= 0 ? 'green' : 'red'}>
          {`${parseIntoCurrencyStringWithSymbol(
            profitAndLoss,
            currency?.symbol,
            currency?.precision
          )}`}
        </Paragraph>
      )}
    </>
  );
};

export default ProfitAndLossCellNew;
