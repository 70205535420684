import { Datum } from '@nivo/line';
import { Col, Paragraph } from 'components';
import useChartTooltip from 'hooks/useChartTooltip';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import ChartPoint from 'components/shared/ChartPoint/ChartPoint.styles';
import ChartTooltip from 'components/shared/ChartTooltip/ChartTooltip';
import { useStoreState } from 'state';

interface OwnProps {
  data: Datum;
  activeMonth: string;
  onClick: (month: string) => void;
  cx: number;
  cy: number;
}

const ChartNetOutstandingTurnoverPoint: FC<OwnProps> = ({
  data,
  activeMonth,
  onClick,
  cx,
  cy,
}) => {
  const { color } = useTheme();
  const { x, y } = data;
  const { tooltipRef, ...mouseEvents } = useChartTooltip();
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(entityCurrencyCode);

  return (
    <g
      onClick={() => {
        if (typeof x === 'string') {
          onClick(x);
        }
      }}
      {...mouseEvents}
    >
      <ChartPoint
        active={activeMonth === x}
        fill={color.green}
        r={6}
        cx={cx}
        cy={cy}
      />

      {typeof y === 'number' && (
        <ChartTooltip wrapperRef={tooltipRef}>
          <Col>
            <Paragraph color="white">{`Turnover: ${parseIntoCurrencyStringWithSymbol(
              y,
              currency?.symbol,
              0
            )}`}</Paragraph>
          </Col>
        </ChartTooltip>
      )}
    </g>
  );
};

export default ChartNetOutstandingTurnoverPoint;
