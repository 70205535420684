import {
  ICodatStatus,
  isCodatPlatform,
  isXeroPlatform,
  IXeroStatus,
  TPlatformNames,
} from 'types';

export const IMPORT_DATA = [
  {
    id: 'importInvoices',
    disabled: true,
    title: 'Invoices',
  },
  {
    id: 'importBills',
    disabled: true,
    title: 'Bills',
  },
  {
    id: 'importPayments',
    disabled: true,
    title: 'Payments',
  },
  {
    id: 'importContactDetails',
    disabled: true,
    title: 'Contact details',
  },
  {
    id: 'importBankBalances',
    disabled: false,
    title: 'Bank balances',
  },
  {
    id: 'importPurchaseAndSalesOrders',
    disabled: true,
    title: 'Purchase and sales orders (coming soon)',
  },
] as const;

export const getDefaultImportPermissionValues = ({
  platformName,
  xeroData,
  codatData,
}: {
  platformName: TPlatformNames;
  xeroData?: IXeroStatus;
  codatData?: ICodatStatus;
}) => {
  let values = {
    importInvoices: true,
    importBills: true,
    importPayments: true,
    importContactDetails: true,
    importPurchaseAndSalesOrders: false,
    importBankBalances: false,
  };

  if (isXeroPlatform(platformName) && xeroData?.permissions) {
    values = {
      ...values,
      ...xeroData.permissions,
    };
  }

  if (isCodatPlatform(platformName) && codatData?.permissions) {
    values = {
      ...values,
      ...codatData.permissions,
    };
  }

  return values;
};
