import { IInvoice, INVOICE_STATUSES } from 'types';
import dayjs from 'dayjs';

export const detectCurrenciesFromInvoices = (
  invoices: IInvoice[],
  entityCurrencyCode?: string | null
) => {
  const currencies = new Set<string>(
    new Set(invoices.map((invoice) => invoice.currency))
  );

  if (entityCurrencyCode && currencies.has(entityCurrencyCode)) {
    currencies.delete(entityCurrencyCode);
  }

  return Array.from(currencies);
};

export const getInvoicesFilters = (
  tab: string | null,
  filterName: string | null,
  currency: string | null
) => {
  /** Refer to https://www.elastic.co/guide/en/app-search/current/filters.html */
  const filters: any = {
    all: [],
  };

  if (tab === 'outstanding') {
    filters.all.push({
      any: [
        {
          status: [
            INVOICE_STATUSES.partiallyPaid,
            INVOICE_STATUSES.authorised,
            INVOICE_STATUSES.paymentScheduled,
            INVOICE_STATUSES.processingPayment,
          ],
        },
      ],
    });
  }

  if (tab === 'paid') {
    filters.all.push({ status: INVOICE_STATUSES.paid });
  }

  if (tab === 'approved') {
    filters.all.push({
      all: [
        { approval_status: 'approved' },
        {
          status: [INVOICE_STATUSES.authorised, INVOICE_STATUSES.partiallyPaid],
        },
      ],
    });
  }

  if (tab === 'submitted') {
    filters.all.push({
      all: [
        { approval_status: 'submitted' },
        {
          status: [INVOICE_STATUSES.authorised, INVOICE_STATUSES.partiallyPaid],
        },
      ],
    });
  }

  if (filterName === 'overdue') {
    filters.all.push({
      all: [
        {
          type: 'Payable',
        },
        {
          due_date: {
            to: dayjs().toISOString(),
          },
        },
        {
          status: [INVOICE_STATUSES.authorised, INVOICE_STATUSES.partiallyPaid],
        },
      ],
    });
  }

  if (filterName === 'payables') {
    filters.all.push({
      all: [
        {
          type: 'Payable',
        },
        {
          status: [INVOICE_STATUSES.authorised, INVOICE_STATUSES.partiallyPaid],
        },
      ],
    });
  }

  if (filterName === 'dueIn14Days') {
    filters.all.push({
      all: [
        {
          type: 'Payable',
        },
        {
          due_date: {
            from: dayjs().toISOString(),
            to: dayjs().add(14, 'days').toISOString(),
          },
        },
        {
          status: [INVOICE_STATUSES.authorised, INVOICE_STATUSES.partiallyPaid],
        },
      ],
    });
  }

  if (currency && currency !== 'all') {
    filters.all.push({
      currency,
    });
  }

  return filters;
};
