import { FC, useState } from 'react';
import { Row, ContextMenu, PermissionsChecker } from 'components';
import { ContextMenuItem } from 'components/shared/ContextMenu/ContextMenu';
import { useTheme } from 'styled-components';
import { getInvoiceManualUploadLink } from 'utils';
import { useHistory } from 'react-router';
import BudgetRatePopup from '../BudgetRatePopup/BudgetRatePopup';
import { TCashflowAtRiskNew } from 'types';
import useUrlValues from 'hooks/useUrlValues';

interface OwnProps {
  period: string;
  currencyCode: string;
  cashflowsRisksData: TCashflowAtRiskNew[];
  getCashflowsRisksPerCurrency: (
    currencyCode: string,
    period: 'month' | 'quarter'
  ) => Promise<void>;
}

const DateThreeDotsMenu: FC<OwnProps> = ({
  period,
  currencyCode,
  cashflowsRisksData,
  getCashflowsRisksPerCurrency,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const [showAddBudgetRate, setShowAddBudgetRate] = useState(false);
  const { setUrlValue } = useUrlValues();

  const list: ContextMenuItem[] = [
    {
      id: 'showContributors',
      title: 'Show contributors',
      onClick: () =>
        setUrlValue({
          dateRange: period,
        }),
    },
    {
      id: 'payable',
      title: 'Add payable',
      onClick: () =>
        history.push(
          getInvoiceManualUploadLink({
            tab: 'manual',
            dataType: 'Bill',
            currency: currencyCode,
          })
        ),
    },
    {
      id: 'receivable',
      title: 'Add receivable',
      onClick: () =>
        history.push(
          getInvoiceManualUploadLink({
            tab: 'manual',
            dataType: 'Invoice',
            currency: currencyCode,
          })
        ),
    },
    {
      id: 'po',
      title: 'Add PO',
      onClick: () =>
        history.push(
          getInvoiceManualUploadLink({
            tab: 'manual',
            dataType: 'PO',
            currency: currencyCode,
          })
        ),
    },
    {
      id: 'hedge',
      title: 'Add hedge',
      onClick: () =>
        history.push(
          getInvoiceManualUploadLink({
            tab: 'hedges',
            buyCurrency: currencyCode,
          })
        ),
    },
    {
      id: 'budgetRate',
      title: 'Add/edit budget rate',
      onClick: () => setShowAddBudgetRate(true),
    },
  ];

  return (
    <>
      <Row gap={theme.spacing.m} style={{ maxHeight: 20 }}>
        <PermissionsChecker action="create" resource="cashflows_manual">
          <ContextMenu list={list} strategy="fixed" portal />
        </PermissionsChecker>
      </Row>

      {showAddBudgetRate && (
        <BudgetRatePopup
          onClose={() => setShowAddBudgetRate(false)}
          currencyCode={currencyCode}
          cashflowsRisksData={cashflowsRisksData}
          getCashflowsRisksPerCurrency={getCashflowsRisksPerCurrency}
        />
      )}
    </>
  );
};
export default DateThreeDotsMenu;
