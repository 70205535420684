import { Notify, openQueryWithTimestamp } from 'utils';
import {
  ITransfer,
  IResponse,
  ITransferInput,
  ICurrency,
  ICountry,
} from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import db from 'services/firestore';

export const getTransfers = async () => {
  try {
    const data = await db.collection('transfers').get();

    return openQueryWithTimestamp(data);
  } catch (error: any) {
    console.warn(error.message);
  }
};

export interface SubscribeToTransfersParams {
  entityId: string;
  callback: (transfers: ITransfer[]) => void;
}

export const subscribeToTransfers = ({
  entityId,
  callback,
}: SubscribeToTransfersParams) => {
  try {
    return db
      .collection('transfers')
      .where('_owner', '==', entityId)
      .onSnapshot((query) => callback(openQueryWithTimestamp(query)));
  } catch (error: any) {
    console.warn(error);
  }
};

export const createTransfer = async (payload: ITransferInput) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<
      IResponse<ITransfer>
    >(`${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/transfers`, payload);

    if (data.success) {
      return data;
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      Notify.error(data.message);
    }
  } catch (error: any) {
    console.warn(error);
  }
};

export const createSimpleTransfer = async (payload: ITransferInput) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<
      IResponse<ITransfer>
    >(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/transfers/simple`,
      payload
    );

    if (data.success) {
      return data;
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      Notify.error(data.message);
    }
  } catch (error: any) {
    console.warn(error);
  }
};

export interface GetTransferPurposesParams {
  currency: ICurrency['code'];
  accountCountry?: ICountry['alpha2'];
}

export const getTransferPurposes = async ({
  currency,
  accountCountry,
}: GetTransferPurposesParams) => {
  return AxiosPrivateFirebaseInstance.get<IResponse>(
    `/transfers/purpose/${currency}/${accountCountry}`
  );
};

export const updateTransferPaymentSubmissionDetails = async (
  transferId: string
) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/transfers/mt103/${transferId}`
  );
};
