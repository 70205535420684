import dayjs from 'dayjs';
import { Column, FilterTypes } from 'react-table';
import { useTheme } from 'styled-components';

import { Paragraph, Row } from 'components';
import { BULK_PAYMENT_STATUS, IBulkPayment } from 'types';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import NumberCircle from 'components/shared/NumberCircle/NumberCircle';
import ActionCell from './components/ActionCell/ActionCell';
import CurrenciesCell from './components/CurrenciesCell/CurrenciesCell';

const BulkPaymentStatusToText: Record<`${BULK_PAYMENT_STATUS}`, string> = {
  awaiting_payment: 'Scheduled',
  processing: 'Processing',
  completed: 'Completed',
  cancelled: 'Cancelled',
  transfers_failed: 'Transfers Failed',
  funded: 'Funded',
};

const isScheduled = (bulkPayment: IBulkPayment) =>
  bulkPayment.status === BULK_PAYMENT_STATUS.awaitingPayment;

const isFailed = (bulkPayment: IBulkPayment) =>
  bulkPayment.status === BULK_PAYMENT_STATUS.transfersFailed;

const isRecent = (bulkPayment: IBulkPayment) =>
  dayjs(bulkPayment._created).isAfter(dayjs().subtract(7, 'days'));

export const paymentRunsTableTileFilterTypes: FilterTypes<IBulkPayment> = {
  buyCurrency: (rows, _, filterValue) => {
    if (filterValue === 'scheduled') {
      return rows.filter((row) => isScheduled(row.original));
    }

    if (filterValue === 'failed') {
      return rows.filter((row) => isFailed(row.original));
    }

    if (filterValue === 'recent') {
      return rows.filter((row) => isRecent(row.original));
    }

    return rows;
  },
};

export const generatePaymentRunTableTileColumns = ({
  onCancelBulkPaymentClick,
}: {
  onCancelBulkPaymentClick: (bulkPaymentId: string) => void;
}): Column<IBulkPayment>[] => [
  {
    id: 'buyAmountsBreakdown',
    Header: 'Currency',
    Cell: ({ row }: any) => (
      <CurrenciesCell buyAmountsBreakdown={row.original.buyAmountsBreakdown} />
    ),
    disableSortBy: true,
    width: 220,
    minWidth: 220,
    filter: 'buyAmountsBreakdown',
  },
  {
    accessor: 'payAmount',
    Header: 'Funding amount',
    Cell: ({ row, value }) => (
      <AmountCell
        size="large"
        amount={value}
        currencyCode={row.original.sellCurrency}
      />
    ),
    width: 160,
    minWidth: 160,
  },
  {
    Header: 'Transfers',
    disableSortBy: true,
    Cell: ({ row }: any) => {
      const theme = useTheme();

      return (
        <Row gap={theme.spacing.xs}>
          {!!row.original.localCount && (
            <Row columnGap={theme.spacing.xs}>
              <Paragraph>Local</Paragraph>
              <NumberCircle value={row.original.localCount} />
            </Row>
          )}
          {!!row.original.swiftCount && (
            <Row columnGap={theme.spacing.xs}>
              <Paragraph>SWIFT</Paragraph>
              <NumberCircle value={row.original.swiftCount} />
            </Row>
          )}
        </Row>
      );
    },
    width: 160,
    minWidth: 160,
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }) => (
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      <Paragraph>{BulkPaymentStatusToText[value]}</Paragraph>
    ),
    width: 120,
    minWidth: 100,
    disableSortBy: true,
  },
  {
    accessor: 'fundedTimestamp',
    Header: 'Funding Details',
    Cell: ({ value, row }) => {
      const isAwaitingPayment =
        row.original.status === BULK_PAYMENT_STATUS.awaitingPayment;

      return (
        <Paragraph>
          {isAwaitingPayment ? (
            <>
              {row.original.payByDate && (
                <>
                  Fund by {dayjs(row.original.payByDate).format('DD MMM YYYY')}{' '}
                  @ {dayjs(row.original.payByTimeGMT, 'HH:mm').format('HH:mm')}
                </>
              )}
            </>
          ) : (
            <>{value && <>Funded on {dayjs(value).format('DD MMM YYYY')}</>}</>
          )}
        </Paragraph>
      );
    },
    width: 200,
    minWidth: 200,
  },
  {
    id: 'dots',
    Header: () => null,
    disableSortBy: true,
    Cell: ({ row }: any) => (
      <ActionCell
        status={row.original.status}
        bulkPaymentId={row.original.id}
        onCancelClick={onCancelBulkPaymentClick}
      />
    ),
    width: 60,
    minWidth: 55,
  },
];
