import { ContextMenu, Paragraph, Row } from 'components';
import { Column } from 'react-table';
import { Nullable } from 'types';
import { IClient } from 'state/user';
import HexagonRating from 'components/shared/HexagonRating/HexagonRating';
import { getHexagonsStatus } from 'components/shared/HexagonRating/utils';
import NumberCell from '../components/NumberCell/NumberCell';
import FlagCell from '../components/FlagCell/FlagCell';
import FxGainLossCell from '../components/FxGainLossCell/FxGainLossCell';

interface OwnProps {
  entityId: Nullable<string>;
  onSwitchAccount: (entityId: string) => void;
  detectedCurrencies: string[];
}

export const generateClientTableColumns = ({
  entityId,
  onSwitchAccount,
  detectedCurrencies,
}: OwnProps): Column<IClient>[] => {
  return [
    {
      id: 'name',
      accessor: 'name',
      Header: 'Client name',
      Cell: ({ row }) => {
        const { name, id } = row.original;

        return (
          <Paragraph
            variant="bold"
            color={entityId === id ? 'emeraldDark' : 'dark'}
          >
            {name}
          </Paragraph>
        );
      },
      width: 100,
    },
    {
      id: 'worstRiskRating',
      accessor: 'worstRiskRatingStars',
      Header: 'Risk Level',
      Cell: ({ value }) => (
        <HexagonRating
          rating={value}
          status={getHexagonsStatus(true, 'asc', value)}
        />
      ),
      width: 100,
    },
    {
      id: 'topCurrenciesByCashflowRisk',
      Header: 'Top 3',
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <FlagCell data={row.original} detectedCurrencies={detectedCurrencies} />
      ),
      width: 100,
    },
    {
      id: 'netOutstandingTotalInEntityCurrency',
      accessor: 'netOutstandingTotalInEntityCurrency',
      Header: 'Net FX AP/ARs',
      Cell: ({ value, row }) => (
        <NumberCell value={value} data={row.original} />
      ),
      width: 100,
    },
    {
      id: 'accountBalancesInEntityCurrencySum',
      accessor: 'accountBalancesInEntityCurrencySum',
      Header: 'FX Balances',
      Cell: ({ value, row }) => (
        <NumberCell value={value} data={row.original} />
      ),
      width: 100,
    },
    {
      id: 'fxGainLossSum',
      accessor: 'fxGainLossSum',
      Header: 'FX Gain/Losses (3m)',
      Cell: ({ value, row }) => (
        <FxGainLossCell value={value} data={row.original} />
      ),
      width: 100,
    },
    {
      id: 'id',
      accessor: 'id',
      disableSortBy: true,
      Header: () => null,
      Cell: ({ value }) => (
        <Row flex={1} justifyContent="flex-end">
          <ContextMenu
            list={[
              {
                id: 1,
                title: 'Select',
                onClick: (e) => {
                  e.stopPropagation();
                  onSwitchAccount(value);
                },
              },
            ]}
          />
        </Row>
      ),
      width: 60,
      minWidth: 55,
    },
  ];
};

type ClientsSettled = PromiseSettledResult<IClient>;

type ClientsFulfilled = PromiseFulfilledResult<IClient>;

export const checkIfClientsFulfilled = (
  item: ClientsSettled
): item is ClientsFulfilled => {
  return (item as ClientsFulfilled)?.value !== undefined;
};
