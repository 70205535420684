import { Row, Col, Subtitle, Icon } from 'components';
import { FC } from 'react';
import { useStoreState } from 'state';
import { IPaymentRunCurrencyTotal } from 'types/paymentRuns';
import { parseIntoCurrencyStringWithSymbol } from 'utils';

interface OwnProps {
  paymentRunTotals: IPaymentRunCurrencyTotal[];
}

const FundsRequired: FC<OwnProps> = ({ paymentRunTotals }) => {
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );

  return (
    <Row alignItems="flex-start" alignSelf="stretch">
      <Subtitle variant="bold">Funds required:</Subtitle>

      <Col alignItems="flex-end" ml>
        {paymentRunTotals.map(({ currency, amount }) => {
          const totalCurrency = currencyByCode(currency);

          return (
            <Row justifyContent="flex-end">
              <Subtitle variant="bold">
                {parseIntoCurrencyStringWithSymbol(
                  amount,
                  totalCurrency?.symbol,
                  totalCurrency?.precision
                )}
              </Subtitle>
              {!!totalCurrency && <Icon icon={totalCurrency.countryCode} ml />}
            </Row>
          );
        })}
      </Col>
    </Row>
  );
};

export default FundsRequired;
