import { FC } from 'react';
import { Icon, Row } from 'components';
import { IInvoice } from 'types';
import { useTheme } from 'styled-components';
import { useInvoiceLastSyncDetails } from 'hooks/useInvoiceLastSyncDetails';
import { getInvoiceSyncedToExternalSourceStatusDetails } from 'utils/invoices';

interface OwnProps {
  record: IInvoice;
}

const InvoiceSyncedToExternalSourceCell: FC<OwnProps> = ({ record }) => {
  const theme = useTheme();
  const { lastSyncToExternalSource } = useInvoiceLastSyncDetails(record);
  const statusDetails = getInvoiceSyncedToExternalSourceStatusDetails({
    lastSyncToExternalSource,
  });

  if (!statusDetails) {
    return null;
  }

  const { icon, color } = statusDetails;

  return (
    <Row flex={1} justifyContent="center">
      <Icon icon={icon} fill={theme.color[color]} />
    </Row>
  );
};

export default InvoiceSyncedToExternalSourceCell;
