import dayjs from 'dayjs';
import { useStoreState } from 'state';
import { IInvoiceFromSearch } from 'types';
import {
  isInvoiceDisabled,
  isInvoiceStatusInPayableState,
  isPayableInvoice,
  isReceivableInvoice,
  isInvoicePrebookable,
  isApprovalFlowAllowToPayInvoiceFromSearch,
  isInvoiceFromSearchHasValidContact,
  isInvoiceFromSearchSubmittableForReview,
  isInvoiceFromSearchApprovable,
  isInvoiceFromSearchApprovableByUser,
} from 'utils/invoices';

interface UseInvoiceFromSearchRecordParams {
  record: IInvoiceFromSearch;
}

const useInvoiceFromSearchRecord = ({
  record,
}: UseInvoiceFromSearchRecordParams) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const {
    hasApprovalFlow,
    isUserApprover,
    isUserSubmitter,
    entityCurrencyCode,
    userId,
  } = useStoreState((state) => state.UserState);

  const currency = currencyByCode(record.currency);
  const isDisabled = isInvoiceDisabled(record);
  const isSameCurrency = record.currency === entityCurrencyCode;
  const daysUntilDueDate = dayjs(record.dueDate).diff(dayjs(), 'days');
  const isPayable = isPayableInvoice(record);
  const isReceivable = isReceivableInvoice(record);
  const isCanBePaid =
    isInvoiceStatusInPayableState(record) &&
    isApprovalFlowAllowToPayInvoiceFromSearch({
      hasApprovalFlow,
      record,
    });
  const isPrebookable = isInvoicePrebookable(record);
  const hasTracking = !!record.trackingId;
  const hasPrebook = !!record.contractId;
  const hasValidContact = isInvoiceFromSearchHasValidContact(record);
  const submittableForReview =
    isUserSubmitter &&
    hasApprovalFlow &&
    isInvoiceFromSearchSubmittableForReview(record);
  const isApprovable =
    isInvoiceFromSearchApprovable(record) && hasApprovalFlow && isUserApprover;
  const isApprovableByCurrentUser =
    isApprovable && isInvoiceFromSearchApprovableByUser(record, userId);
  const isInvoiceInEntityCurrency = record.currency === entityCurrencyCode;

  return {
    currency,
    isDisabled: !!isDisabled,
    isSameCurrency,
    daysUntilDueDate,
    isPayable,
    isReceivable,
    hasTracking,
    hasPrebook,
    hasValidContact,
    isCanBePaid,
    isPrebookable,
    submittableForReview,
    isApprovable,
    isApprovableByCurrentUser,
    isInvoiceInEntityCurrency,
  };
};

export default useInvoiceFromSearchRecord;
