import { lazy } from 'react';

import Login from './Login/Login';
import ResetPassword from './ResetPassword/ResetPassword';
import ResetPasswordConfirm from './ResetPasswordConfirm/ResetPasswordConfirm';
import SignUp from './SignUp/SignUp';
import CompanyRegistration from './CompanyRegistration/CompanyRegistration';
import SignUpSoleTrader from './SignUpSoleTrader/SignUpSoleTrader';
import Settings from './Settings/Settings';
import Invoices from './Invoices/Invoices';
import InvoiceInner from './InvoiceInner/InvoiceInner';
import Contacts from './Contacts/Contacts';
import IdVerification from './IdVerification/IdVerification';
import PageNotFound from './Error/PageNotFound';
import NewClientXero from 'pages/NewClientXero/NewClientXero';
import SwitchClients from './SwitchClients/SwitchClients';

const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'));
const Prebook = lazy(() => import('pages/Prebook/Prebook'));
const IntegrationSettings = lazy(
  () => import('pages/IntegrationSettings/IntegrationSettings')
);
const IntegrationLogs = lazy(
  () => import('pages/IntegrationLogs/IntegrationLogs')
);
const Transfers = lazy(() => import('pages/Transfer/Transfer'));
const BulkUploadContacts = lazy(
  () => import('pages/BulkUpload/BulkUploadContacts')
);
const BulkUploadContactsSage = lazy(
  () => import('pages/BulkUpload/BulkUploadContactsSage')
);
const BulkUploadInvoicesSage = lazy(
  () => import('pages/BulkUpload/BulkUploadSage50XmlInvoices')
);
const ManualUpload = lazy(
  () => import('pages/ManualUpload/ManualUpload')
);
const Transactions = lazy(() => import('pages/Transactions/Transactions'));

const MyClients = lazy(() => import('pages/MyClients/MyClients'));

const UserInvite = lazy(() => import('pages/UserInvite/UserInvite'));

export {
  BulkUploadContacts,
  BulkUploadContactsSage,
  ManualUpload,
  BulkUploadInvoicesSage,
  CompanyRegistration,
  Contacts,
  Dashboard,
  IdVerification,
  IntegrationLogs,
  IntegrationSettings,
  InvoiceInner,
  Invoices,
  Login,
  MyClients,
  NewClientXero,
  PageNotFound,
  Prebook,
  ResetPassword,
  ResetPasswordConfirm,
  Settings,
  SignUp,
  SignUpSoleTrader,
  SwitchClients,
  Transactions,
  Transfers,
  UserInvite,
};
