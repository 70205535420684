import { IRecipient } from './../types/recipients';
import { IInvoice, IInvoiceFromSearch } from 'types';
import {
  IAccountData,
  ICodatAccount,
  TIntegrationDocIds,
  TPlatformNames,
  IXeroAccount,
} from 'types/integrations';

export const generateCodatBankFeesAccountData = (
  account: ICodatAccount
): IAccountData => {
  const { name, id } = account;
  const nameValue = `${name} (${id})`;
  return {
    id: nameValue,
    value: account,
    name: nameValue,
  };
};

export const generateXeroBankFeesAccountData = (
  account: IXeroAccount
): IAccountData => {
  const { name, code } = account;
  const nameValue = `${name} (${code})`;
  return {
    id: nameValue,
    value: account,
    name: nameValue,
  };
};

export const platformNamesToIntegrationDocIds: Record<
  TPlatformNames,
  TIntegrationDocIds
> = {
  Xero: 'xero',
  'Sage 50 (UK)': 'codat',
  'QuickBooks Online': 'codat',
  'QuickBooks Online Sandbox': 'codat',
  'Dynamics 365 Business Central': 'codat',
  'Oracle NetSuite': 'codat',
};

interface IIsContactIdsMatchWithInvoiceFromSearchParams {
  invoice: IInvoiceFromSearch;
  recipient: IRecipient;
}
export const isXeroContactIdsNotMatchWithInvoiceFromSearch = ({
  invoice,
  recipient,
}: IIsContactIdsMatchWithInvoiceFromSearchParams) =>
  invoice.externalRefsXeroContactId &&
  invoice.externalRefsXeroContactId !== recipient.externalRefs?.xeroId;

export const isCodatContactIdsNotMatchWithInvoiceFromSearch = ({
  invoice,
  recipient,
}: IIsContactIdsMatchWithInvoiceFromSearchParams) =>
  invoice.externalRefsCodatContactId &&
  invoice.externalRefsCodatContactId !== recipient.externalRefs?.codatId;

interface IIsContactIdsMatchWithInvoiceParams {
  invoice: IInvoice;
  recipient: IRecipient;
}
export const isXeroContactIdsNotMatchWithInvoice = ({
  invoice,
  recipient,
}: IIsContactIdsMatchWithInvoiceParams) =>
  invoice.externalRefs?.xeroContactId &&
  invoice.externalRefs.xeroContactId !== recipient.externalRefs?.xeroId;

export const isCodatContactIdsNotMatchWithInvoice = ({
  invoice,
  recipient,
}: IIsContactIdsMatchWithInvoiceParams) =>
  invoice.externalRefs?.codatContactId &&
  invoice.externalRefs.codatContactId !== recipient.externalRefs?.codatId;
