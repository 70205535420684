import { FC, useMemo } from 'react';
import { useStoreState } from 'state';
import { appendRateAndProfitAndLossToInvoice } from 'utils/invoices';
import { IInvoiceForTable } from 'components/shared/InvoicesTableNew/types';
import { PermissionsChecker, Row, StaleLoader } from 'components';
import { detectCurrenciesFromInvoices } from 'pages/Invoices/utils';
import Button from 'components/shared/Button/Button';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import { useHistory } from 'react-router';
import { getPaymentRunPageLink } from 'utils/links';
import useRates from 'hooks/useRates';
import useUrlValues from 'hooks/useUrlValues';
import { IInvoice } from 'types';
import InvoicesTable from 'components/shared/InvoicesTable/InvoicesTable';

interface OwnProps {
  isLoadingPaymentRunInvoices: boolean;
  paymentRunInvoices: IInvoice[];
  onRemoveInvoice: (id: string) => void;
}
const PaymentRunInvoicesTable: FC<OwnProps> = ({
  isLoadingPaymentRunInvoices,
  paymentRunInvoices,
  onRemoveInvoice,
}) => {
  const history = useHistory();
  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);
  const { isUpdatingPaymentRun } = useStoreState(
    (state) => state.PaymentRunsState
  );

  const detectedCurrencies = useMemo(
    () => detectCurrenciesFromInvoices(paymentRunInvoices, entityCurrencyCode),
    [entityCurrencyCode, paymentRunInvoices]
  );
  const { ratesByCurrency } = useRates({ currencies: detectedCurrencies });
  const { currency } = useUrlValues('currency');

  // TODO: consider moving into BE query
  const invoicesForTable = useMemo(
    () =>
      paymentRunInvoices.reduce<IInvoiceForTable[]>((acc, invoice) => {
        const invoiceWithRateAndProfitAndLoss = appendRateAndProfitAndLossToInvoice(
          invoice,
          ratesByCurrency?.[invoice.currency]
        );

        if (currency && currency !== 'all' && currency !== invoice.currency) {
          return acc;
        }

        acc.push(invoiceWithRateAndProfitAndLoss);

        return acc;
      }, []),
    [currency, paymentRunInvoices, ratesByCurrency]
  );

  if (isLoadingPaymentRunInvoices) {
    return <StaleLoader size="large" />;
  }

  return (
    <InvoicesTable
      isVirtualized
      data={invoicesForTable}
      showFooter={!!paymentRunInvoices.length}
      selectable={false}
      renderActionCell={({ id }) => (
        <PermissionsChecker action="update" resource="payment_runs">
          <ActionButton
            disabled={isUpdatingPaymentRun}
            onClick={() => onRemoveInvoice(id)}
          >
            Remove
          </ActionButton>
        </PermissionsChecker>
      )}
      renderFooterContent={
        paymentRunInvoices.length ? (
          <Row>
            <PermissionsChecker action="update" resource="payment_runs">
              <Button
                disabled={isUpdatingPaymentRun}
                onClick={() =>
                  history.push(
                    getPaymentRunPageLink({
                      step: '1',
                    })
                  )
                }
              >
                Continue
              </Button>
            </PermissionsChecker>
          </Row>
        ) : null
      }
    />
  );
};

export default PaymentRunInvoicesTable;
