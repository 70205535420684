import { useStoreState } from 'state';
import { useHistory, useRouteMatch } from 'react-router';
import { viewTransactionDetails } from 'utils/transactions';
import { Col } from 'components/shared/Col/Col';
import { Row } from 'components/shared/Row/Row';
import Table from 'components/shared/Table/Table';
import CounterTitle from 'components/shared/CounterTitle/CounterTitle';
import {
  generatePaymentRunTableTileColumns,
  paymentRunsTableTileFilterTypes,
} from './tableColumnsGenerator';
import { IBulkPayment } from 'types/bulkPayments';
import { useTheme } from 'styled-components';
import { FilterButton } from 'components/shared/FilterButton/FilterButton.styles';
import useTableFiltering from 'hooks/useTableFiltering';
import { getPaymentRunsPageLink } from 'utils/links';
import { FC, useState } from 'react';
import Button from '../Button/Button';
import { OverflowXWrapper } from '../OverflowXWrapper/OverflowXWrapper.styles';
import useDeviceWidth from 'hooks/useDeviceWidth';
import { cancelBulkPayment } from 'services/firebase';
import { Notify } from 'utils';
import CancelBulkPayment from './components/CancelBulkPayment/CancelBulkPayment';
import CancelBulkPaymentError from './components/CancelBulkPaymentError/CancelBulkPaymentError';

type TFilters = 'recent' | 'scheduled' | 'failed' | '';

interface OwnProps {
  showLimit?: number;
  showMore?: boolean;
}

const PaymentsRunTableTile: FC<OwnProps> = ({ showLimit, showMore }) => {
  const theme = useTheme();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { isMobile } = useDeviceWidth();
  const { bulkPayments } = useStoreState((state) => state.BulkPaymentsState);

  const [bulkPaymentToCancelId, setBulkPaymentToCancelId] = useState<
    false | string
  >(false);
  const [bulkPaymentToCancelErrorId, setBulkPaymentToCancelErrorId] = useState<
    false | string
  >(false);

  const onCancelBulkPaymentClick = (bulkPaymentId: string) =>
    setBulkPaymentToCancelId(bulkPaymentId);

  const paymentRunTransfersTableColumns = generatePaymentRunTableTileColumns({
    onCancelBulkPaymentClick,
  });

  const onRowClick = (bulkPayment: IBulkPayment) =>
    viewTransactionDetails(url, history, {
      id: bulkPayment.id,
      type: 'bulkPayment',
    });

  const { filter, setFilter, tableRef } = useTableFiltering<
    TFilters,
    IBulkPayment
  >({
    filterTypeName: 'buyAmountsBreakdown',
    initialFilterValue: '',
  });

  const onFilterClick = (filterName: TFilters) => {
    setFilter(filterName === filter ? '' : filterName);
  };

  const cancelHandler = async (bulkPaymentId: string) => {
    try {
      setBulkPaymentToCancelId(false);
      await cancelBulkPayment(bulkPaymentId);
      Notify.success(
        `Transaction ${bulkPaymentId} was successfully cancelled.`
      );
    } catch (error: any) {
      setBulkPaymentToCancelErrorId(bulkPaymentId);
    }
  };

  return (
    <>
      <Col>
        <Row mb mbValue={theme.spacing.l}>
          <CounterTitle
            title={
              showLimit
                ? `Last ${bulkPayments.slice(0, showLimit).length} payment runs`
                : 'Payment runs'
            }
            count={showLimit ? undefined : bulkPayments.length}
          />
          {!isMobile && (
            <Row>
              <FilterButton
                onClick={() => onFilterClick('recent')}
                active={filter === 'recent'}
              >
                Recent
              </FilterButton>
              <FilterButton
                onClick={() => onFilterClick('scheduled')}
                active={filter === 'scheduled'}
              >
                Scheduled
              </FilterButton>
              <FilterButton
                onClick={() => onFilterClick('failed')}
                active={filter === 'failed'}
              >
                Failed
              </FilterButton>

              {showMore && (
                <Button
                  ml
                  mr
                  mrValue={theme.spacing.xxs}
                  mlValue={theme.spacing.xxs}
                  variant="link"
                  onClick={() => history.push(getPaymentRunsPageLink())}
                >
                  Show more
                </Button>
              )}
            </Row>
          )}
        </Row>
        <OverflowXWrapper>
          <Table<IBulkPayment>
            ref={tableRef}
            data={showLimit ? bulkPayments.slice(0, showLimit) : bulkPayments}
            columns={paymentRunTransfersTableColumns}
            filterTypes={paymentRunsTableTileFilterTypes}
            autoResetExpanded={false}
            sortable
            onRowClick={onRowClick}
          />
        </OverflowXWrapper>
      </Col>

      {!!bulkPaymentToCancelId && (
        <CancelBulkPayment
          onClose={() => setBulkPaymentToCancelId(false)}
          onContinue={() => cancelHandler(bulkPaymentToCancelId)}
        />
      )}

      {!!bulkPaymentToCancelErrorId && (
        <CancelBulkPaymentError
          bulkPaymentId={bulkPaymentToCancelErrorId}
          onClose={() => setBulkPaymentToCancelErrorId(false)}
        />
      )}
    </>
  );
};

export default PaymentsRunTableTile;
