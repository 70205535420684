import { FC, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useHistory } from 'react-router-dom';
import cx from 'classnames';

import { StaleTitleH3, StaleGetStartedPopup, StaleBetaTag } from 'components';
import StaleHeaderDropdown from './StaleHeaderDropdown/StaleHeaderDropdown';
import { GlobalStyle } from 'components/styles';
import { useStoreActions, useStoreState } from 'state';
import {
  SIDEDRAWER_ITEMS,
  DROPDOWNMENU_ITEMS,
  SOLUTIONS_DROPDOWN_DATA,
  ABOUT_DROPDOWN_DATA,
} from './consts';
import { Header } from './StaleHeader.styles';
import { HELP_URL } from 'variables';
import ProfileNav from 'components/shared/ProfileNav/ProfileNav';
import Button from 'components/shared/Button/Button';

interface OwnProps {
  title: string;
  link?: string;
  theme?: string;
  dashboard?: boolean;
  landing?: boolean;
  hasBtnBack?: boolean;
  overview?: boolean;
  access?: string;
  icon?: string;
  count?: number;
  isBeta?: boolean;
  onClick?: (...args: any) => any;
}

const StaleHeader: FC<OwnProps> = ({
  title,
  link,
  dashboard,
  landing,
  hasBtnBack,
  overview,
  access,
  count,
  theme = 'theme-grey',
  isBeta = false,
  onClick = () => {},
}) => {
  const { userId } = useStoreState(({ UserState }) => UserState);
  const { signOut } = useStoreActions((actions) => actions.UserState);

  const history = useHistory();

  const [showMenu, setShowMenu] = useState(false);

  const [isGetStartedShown, setIsGetStartedShown] = useState(false);

  const headerMenu = landing ? DROPDOWNMENU_ITEMS : SIDEDRAWER_ITEMS;

  return (
    <>
      <GlobalStyle />
      <Header
        className={cx(theme, showMenu && 'menu-show', !userId && 'logged-out')}
      >
        <div className="head-row">
          {dashboard && (
            <Link to="/" className="logo">
              <svg width="24px" height="21px">
                <use xlinkHref="#logo-ico" />
              </svg>
              {title}
            </Link>
          )}

          {landing && (
            <Link to="/" className="logo">
              <svg width="24px" height="21px">
                <use xlinkHref="#logo-ico" />
              </svg>
              {title}
            </Link>
          )}

          {!dashboard && !landing && (
            <StaleTitleH3>
              {hasBtnBack && (
                <Button variant="link" onClick={history.goBack}>
                  <svg>
                    <use xlinkHref="#arrow-left" />
                  </svg>
                </Button>
              )}
              {title}
              {count && (
                <span
                  style={{ color: '#8C9199', marginLeft: 4 }}
                >{` ${count}`}</span>
              )}
              {isBeta && <StaleBetaTag />}
            </StaleTitleH3>
          )}

          {!userId &&
            (isMobile ? (
              <div className="icons">
                <button
                  onClick={() => setShowMenu(!showMenu)}
                  className="burger"
                >
                  <span />
                </button>
              </div>
            ) : (
              <div className="menu">
                <div className="menu-col">
                  <StaleHeaderDropdown
                    component="dropdown"
                    data={SOLUTIONS_DROPDOWN_DATA}
                  />

                  <StaleHeaderDropdown
                    component="dropdown"
                    data={ABOUT_DROPDOWN_DATA}
                  />

                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={HELP_URL}
                    className="menu-item"
                  >
                    Help
                  </a>
                </div>
                <div className="menu-col">
                  <Link to="/login" className="menu-item">
                    Sign in
                  </Link>
                  <Button
                    variant="secondary"
                    onClick={() => history.push('/sign-up')}
                  >
                    Get started
                  </Button>
                </div>
              </div>
            ))}

          {userId &&
            (landing ? (
              <div className="menu">
                <div className="menu-col">
                  {!isMobile && (
                    <>
                      <StaleHeaderDropdown
                        component="dropdown"
                        data={SOLUTIONS_DROPDOWN_DATA}
                      />

                      <StaleHeaderDropdown
                        component="dropdown"
                        data={ABOUT_DROPDOWN_DATA}
                      />

                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={HELP_URL}
                        className="menu-item"
                      >
                        Help
                      </a>
                    </>
                  )}
                </div>

                <div className="menu-col">
                  <ProfileNav />

                  <div className="icons">
                    {link ? (
                      <Button variant="link" onClick={onClick} icon={link} />
                    ) : null}

                    {overview && (
                      <>
                        <button>
                          <svg>
                            <use xlinkHref="#search-ico" />
                          </svg>
                        </button>

                        <button>
                          <svg>
                            <use xlinkHref="#filter-ico" />
                          </svg>
                        </button>
                      </>
                    )}

                    {(dashboard || landing) && (
                      <>
                        {access !== 'limited' ? (
                          <button
                            onClick={() => setShowMenu(!showMenu)}
                            className="burger"
                          >
                            <span />
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              alert(
                                'You have limited access to the HedgeFlows. To get full access please Register your company'
                              )
                            }
                          >
                            <svg>
                              <use xlinkHref="#info-ico" fill="#454D58" />
                            </svg>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              isMobile && (
                <div className="icons">
                  <ProfileNav />

                  {link ? (
                    <Button variant="link" onClick={onClick} icon={link} />
                  ) : null}

                  {overview && (
                    <>
                      <button>
                        <svg>
                          <use xlinkHref="#search-ico" />
                        </svg>
                      </button>

                      <button>
                        <svg>
                          <use xlinkHref="#filter-ico" />
                        </svg>
                      </button>
                    </>
                  )}

                  {(dashboard || landing) && (
                    <>
                      {access !== 'limited' ? (
                        <button
                          onClick={() => setShowMenu(!showMenu)}
                          className="burger"
                        >
                          <span />
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            alert(
                              'You have limited access to the HedgeFlows. To get full access please Register your company'
                            )
                          }
                        >
                          <svg>
                            <use xlinkHref="#info-ico" fill="#454D58" />
                          </svg>
                        </button>
                      )}
                    </>
                  )}
                </div>
              )
            ))}
        </div>

        {(dashboard || landing) &&
          access !== 'limited' &&
          (isMobile || userId) && (
            <div
              className={cx(
                landing && !isMobile && 'landing-menu',
                'mobile-menu'
              )}
            >
              <div className="column">
                {userId ? (
                  headerMenu.map(({ title, slug, icon, externalLink }) => {
                    // TODO: fully rework header
                    return externalLink && !landing ? (
                      <a
                        key={slug}
                        target="_blank"
                        rel="noreferrer"
                        href={externalLink}
                        className="link"
                      >
                        <svg>
                          <use xlinkHref={`#${icon}`} />
                        </svg>
                        {title}
                      </a>
                    ) : (
                      <Button
                        variant="link"
                        key={slug}
                        onClick={() => history.push(slug)}
                        icon={icon}
                      >
                        {title}
                      </Button>
                    );
                  })
                ) : (
                  <>
                    <StaleHeaderDropdown
                      component="accordion"
                      data={SOLUTIONS_DROPDOWN_DATA}
                    />

                    <StaleHeaderDropdown
                      component="accordion"
                      data={ABOUT_DROPDOWN_DATA}
                    />

                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={HELP_URL}
                      className="link"
                    >
                      FAQ
                    </a>
                  </>
                )}
                <div className="bot">
                  {!userId ? (
                    <>
                      <Button onClick={() => history.push('/login')}>
                        Sign In
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => history.push('/sign-up')}
                      >
                        Get started
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="link"
                      onClick={() => signOut()}
                      icon="logout-ico"
                    >
                      Log out
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}

        {isGetStartedShown && (
          <StaleGetStartedPopup
            onContinue={() => setIsGetStartedShown(false)}
          />
        )}
      </Header>
    </>
  );
};

export default StaleHeader;
