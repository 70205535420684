import { range } from 'lodash';
import {
  IPnlDataPerMonth,
  TPastPerformanceDataFromExternalSource,
} from 'types';
import { ITransformedData } from './types';

export const getDataForProfitAndLoss = (
  data: Record<string, IPnlDataPerMonth[]>,
  externalData: TPastPerformanceDataFromExternalSource[]
): ITransformedData[] => {
  const currencies = Object.keys(data);

  const maxLen = currencies.reduce((max, cur) => {
    return Math.max(max, data[cur].length);
  }, 0);

  return range(0, maxLen).map((index) => {
    const obj: ITransformedData = { id: '', pnl: 0, externalPnl: 0 };
    let idSet = false;

    for (const cur of currencies) {
      const curData = data[cur][index];
      const externalDataPerIndex = externalData[index];

      if (curData) {
        if (!idSet) {
          obj.id = curData.id;
          idSet = true;
        }

        const pnl =
          curData.totalFxImpactForPaid +
          curData.transactionCost +
          curData.fxImpactForUnpaid;
        obj.pnl += pnl;
        obj['pnl' + cur] = pnl;

        if (externalDataPerIndex) {
          obj.externalPnl = externalDataPerIndex.pnl;
        }
      }
    }

    return obj;
  });
};
