import { FC, useCallback } from 'react';
import useUrlValues from 'hooks/useUrlValues';
import { PrebookTable } from 'components';
import { isExternalHedge } from 'components/shared/PrebookTable/utils';
import InvoicesTable from '../InvoicesTable/InvoicesTable';
import PaymentRunInvoicesTable from '../PaymentRunInvoicesTable/PaymentRunInvoicesTable';
import { IInvoice } from 'types';

interface OwnProps {
  isLoadingPaymentRunInvoices: boolean;
  paymentRunInvoices: IInvoice[];
  onRemoveInvoice: (id: string) => void;
  currenciesByTab: Record<string, string[]>;
}
const Tables: FC<OwnProps> = ({
  isLoadingPaymentRunInvoices,
  paymentRunInvoices,
  onRemoveInvoice,
  currenciesByTab,
}) => {
  const { setUrlValue, tab, currency } = useUrlValues('tab', 'currency');

  const onPrebookTableRowClick = useCallback(
    (record) =>
      setUrlValue({
        [isExternalHedge(record) ? 'hedgeId' : 'contractId']: record.id,
      }),
    [setUrlValue]
  );

  if (tab === 'prebookings') {
    // TODO: remove currencyCode prop, shared table should be more generic
    return (
      <PrebookTable
        isVirtualized
        currencyCode={currency ?? undefined}
        onRowClick={onPrebookTableRowClick}
      />
    );
  }

  if (tab === 'paymentRun') {
    return (
      <PaymentRunInvoicesTable
        isLoadingPaymentRunInvoices={isLoadingPaymentRunInvoices}
        paymentRunInvoices={paymentRunInvoices}
        onRemoveInvoice={onRemoveInvoice}
      />
    );
  }

  return <InvoicesTable currenciesByTab={currenciesByTab} />;
};

export default Tables;
