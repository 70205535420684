import { FC } from 'react';
import useUrlValues from 'hooks/useUrlValues';
import TransferInner from '../TransferInner/TransferInner';
import PrebookInner from '../PrebookInner/PrebookInner';
import CancellingBalance from '../CancellingBalance/CancellingBalance';
import DeleteContract from '../DeleteContract/DeleteContract';
import HedgeInner from '../HedgeInner/HedgeInner';
import BulkPaymentInner from '../BulkPaymentInner/BulkPaymentInner';

const TransferPrebookPopups: FC = () => {
  const {
    transferId,
    bulkPaymentId,
    contractId,
    hedgeId,
    cancelContractId,
    deleteContractId,
  } = useUrlValues(
    'transferId',
    'bulkPaymentId',
    'contractId',
    'hedgeId',
    'cancelContractId',
    'deleteContractId'
  );

  return (
    <>
      {contractId && <PrebookInner />}
      {transferId && <TransferInner />}
      {bulkPaymentId && <BulkPaymentInner />}
      {hedgeId && <HedgeInner />}
      {cancelContractId && <CancellingBalance />}
      {deleteContractId && <DeleteContract />}
    </>
  );
};

export default TransferPrebookPopups;
