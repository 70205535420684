import { FC, Dispatch, SetStateAction, useMemo } from 'react';
import { ContextMenu } from 'components';
import dayjs from 'dayjs';
import { IInvoiceFromSearch, CONTRACT_STATUS, INVOICE_STATUSES } from 'types';
import { useStoreActions, useStoreState } from 'state';

import {
  isReceivableInvoice,
  isInvoiceDisabled,
  isInvoiceStatusInPayableState,
  isPayableInvoice,
  getInvoiceDaysLeft,
  isInvoiceFromSearchFromXero,
  isInvoiceFromSearchNotPaid,
  isInvoiceDeletable,
  isInvoiceTrackable,
  isApprovalFlowAllowToPayInvoiceFromSearch,
} from 'utils/invoices';
import { useHistory } from 'react-router-dom';
import useXero from 'hooks/useXero';
import {
  getInvoiceTransferOrSimpleTransferLink,
  getInvoiceSimpleTransferLink,
  getCurrencyExchangePageLink,
} from 'utils/links';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';
import StaleLimitedAccess from 'components/shared/StaleLimitedAccess/StaleLimitedAccess';
import { isCurrencyEnabledForBuying } from 'utils/currencies';
import useInvoicePrebook from '../../hooks/useInvoicePrebookAndTransfer';
import useInvoiceFromSearchRecord from 'hooks/useInvoiceFromSearchRecord';
import {
  useCreatePrebooksPermissionsCheck,
  useCreateTransfersPermissionsCheck,
  useUpdateBalancesPermissionsCheck,
  useUpdateSettingsRiskPermissionsCheck,
} from 'hooks/useSpecificPermissionsCheck';

interface OwnProps {
  record: IInvoiceFromSearch;
  setInvoiceDecide: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  setCancelPrebookInvoice: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  setExistingPrebookInvoice: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
  setShowAllDecideFields: Dispatch<SetStateAction<boolean>>;
  setShowInvoiceDelete: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  setShowPurchaseOrderPaymentsOnInvoice: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
  setExistingInvoiceTracking: Dispatch<
    SetStateAction<IInvoiceFromSearch | null>
  >;
}

const InvoiceActionsMenu: FC<OwnProps> = ({
  record,
  setInvoiceDecide,
  setCancelPrebookInvoice,
  setExistingPrebookInvoice,
  setShowAllDecideFields,
  setShowInvoiceDelete,
  setShowPurchaseOrderPaymentsOnInvoice,
  setExistingInvoiceTracking,
}) => {
  const history = useHistory();
  const { isSameCurrency } = useInvoiceFromSearchRecord({ record });
  const {
    deleteInvoiceTracking,
    updateInvoice: toggleInvoiceExcludeFromRisk,
    unbindContractRateToInvoice,
  } = useStoreActions((actions) => actions.InvoicesState);
  const { rateContractsByCurrencyPair } = useStoreState(
    (state) => state.RateContractsState
  );
  const { hasApprovalFlow, isUserApprover, entityCurrencyCode } = useStoreState(
    ({ UserState }) => UserState
  );
  const { currencies } = useStoreState((state) => state.CurrenciesState);
  const { balanceByCurrencyCode } = useStoreState(
    (state) => state.BalancesState
  );
  const { deepLinkKey } = useXero();
  const { updateInvoicesApprovalStatus } = useInvoicesApprovalStatus();
  const {
    showLimitedAccess,
    setShowLimitedAccessFalse,
    goToInvoicePrebook,
  } = useInvoicePrebook();
  const rateContractsToUse = rateContractsByCurrencyPair(
    entityCurrencyCode,
    record.currency
  ).filter(
    (item) =>
      item.remainingBuyAmount >= (record.amountDue ?? 0) &&
      item.status === CONTRACT_STATUS.readyToUse &&
      dayjs(item.expiryDate).isAfter(dayjs().subtract(1, 'day'))
  );
  const balanceByCurrency = balanceByCurrencyCode(record.currency);
  const canPayWithBalance =
    !!balanceByCurrency && balanceByCurrency.amount >= record.amountDue;

  const hasUpdateBalancesPermission = useUpdateBalancesPermissionsCheck();
  const hasCreateTransfersPermission = useCreateTransfersPermissionsCheck();
  const hasCreatePrebooksPermission = useCreatePrebooksPermissionsCheck();
  const hasUpdateSettingsRiskPermissions = useUpdateSettingsRiskPermissionsCheck();

  const dropdownItems = useMemo(() => {
    const dropdownItemsToReturn: any[] = [];

    const isPayable = isPayableInvoice(record);
    const isStatusInPayableState = isInvoiceStatusInPayableState(record);
    const isNotPaid = isInvoiceFromSearchNotPaid(record);
    const isFromXero = isInvoiceFromSearchFromXero(record);
    const daysLeft = getInvoiceDaysLeft(record);
    const isDisabled = isInvoiceDisabled(record);
    const isDeletable = isInvoiceDeletable(record);
    const isTrackable = isInvoiceTrackable(record);
    const isInvoiceCurrencyEnabledForBuying = isCurrencyEnabledForBuying({
      currencyCode: record.currency,
      currencies,
    });
    const isApprovalFlowAllowToPay = isApprovalFlowAllowToPayInvoiceFromSearch({
      hasApprovalFlow,
      record,
    });

    if (isFromXero) {
      dropdownItemsToReturn.push({
        id: 'show-in-xero',
        title: 'Open in Xero',
        onClick: () => {
          if (isReceivableInvoice(record)) {
            window.open(
              `https://go.xero.com/organisationlogin/default.aspx?shortcode=${deepLinkKey}&redirecturl=/AccountsReceivable/Edit.aspx?InvoiceID=${record.externalRefsXeroId}`
            );
          } else {
            window.open(
              `https://go.xero.com/organisationlogin/default.aspx?shortcode=${deepLinkKey}&redirecturl=/AccountsPayable/Edit.aspx?InvoiceID=${record.externalRefsXeroId}`
            );
          }
        },
      });
    }

    if (!isDisabled) {
      if (isPayable) {
        if (
          !isSameCurrency &&
          isApprovalFlowAllowToPay &&
          isStatusInPayableState &&
          !record.contractId &&
          isInvoiceCurrencyEnabledForBuying
        ) {
          dropdownItemsToReturn.push({
            id: 'prebook',
            title: 'Prebook',
            onClick: () => goToInvoicePrebook(record),
          });
        }

        if (
          !isSameCurrency &&
          isStatusInPayableState &&
          isApprovalFlowAllowToPay &&
          !record.contractId &&
          !!rateContractsToUse?.length &&
          isInvoiceCurrencyEnabledForBuying
        ) {
          dropdownItemsToReturn.push({
            id: 'apply-prebooked-rate',
            title: 'Apply prebooked rate',
            onClick: () => {
              setExistingPrebookInvoice(record);
            },
          });
        }

        if (!!record.contractId && record.contractAssignment) {
          dropdownItemsToReturn.push({
            id: 'remove-prebook',
            title: 'Remove prebooked rate',
            onClick: () => {
              if (record.contractId) {
                unbindContractRateToInvoice({
                  contractId: record.contractId,
                  payload: { invoiceId: record.id },
                });
              }
            },
          });
        }

        if (!!record.contractId && !record.contractAssignment) {
          dropdownItemsToReturn.push({
            id: 'cancel-prebook',
            title: 'Cancel prebooking',
            onClick: () => setCancelPrebookInvoice(record),
          });
        }

        if (
          isApprovalFlowAllowToPay &&
          isStatusInPayableState &&
          isInvoiceCurrencyEnabledForBuying &&
          hasCreateTransfersPermission
        ) {
          dropdownItemsToReturn.push({
            id: 'pay',
            title: 'Pay',
            onClick: () => {
              history.push(
                getInvoiceTransferOrSimpleTransferLink(
                  record,
                  entityCurrencyCode
                )
              );
            },
          });

          if (canPayWithBalance) {
            dropdownItemsToReturn.push({
              id: 'payWithBalance',
              title: `Pay in ${record.currency}`,
              onClick: () => {
                history.push(getInvoiceSimpleTransferLink(record));
              },
            });
          }
        }

        if (hasApprovalFlow && isUserApprover && !!record.approvalStatus) {
          dropdownItemsToReturn.push({
            id: 'reject',
            title: 'Reject',
            onClick: () =>
              updateInvoicesApprovalStatus({
                invoiceIds: [record.id],
                approvalStatus: null,
              }),
          });
        }

        if (
          isTrackable &&
          isNotPaid &&
          !record.contractId &&
          !record.trackingId &&
          daysLeft >= 7
        ) {
          dropdownItemsToReturn.push({
            id: 'track',
            title: 'Track',
            onClick: () => {
              setInvoiceDecide(record);
              setShowAllDecideFields(true);
            },
          });
        }

        if (record.trackingId) {
          dropdownItemsToReturn.push({
            id: 'review-tracking',
            title: 'Review tracking',
            onClick: () => {
              setExistingInvoiceTracking(record);
            },
          });

          if (isNotPaid) {
            dropdownItemsToReturn.push({
              id: 'stop-tracking',
              title: 'Stop tracking',
              onClick: () => {
                if (record.trackingId) {
                  deleteInvoiceTracking({
                    trackingId: record.trackingId,
                  });
                }
              },
            });
          }
        }
      } else {
        if (isNotPaid && hasUpdateBalancesPermission && !!record.contractId) {
          dropdownItemsToReturn.push({
            id: 'collect',
            title: 'Collect',
            onClick: () => {
              const link = getCurrencyExchangePageLink({
                predefinedSellAmount: record.amountDue.toString(),
                predefinedSellCurrency: record.currency,
                predefinedRateContractId: record.contractId,
                step: '2',
                invoiceId: record.id,
              });

              history.push(link);
            },
          });
        }

        if (
          !isSameCurrency &&
          isNotPaid &&
          !record.contractId &&
          isInvoiceCurrencyEnabledForBuying &&
          hasCreatePrebooksPermission
        ) {
          dropdownItemsToReturn.push({
            id: 'prebook',
            title: 'Prebook',
            onClick: () => goToInvoicePrebook(record),
          });
        }

        if (!!record.contractId && !record.contractAssignment) {
          dropdownItemsToReturn.push({
            id: 'cancel-prebook',
            title: 'Cancel prebooking',
            onClick: () => setCancelPrebookInvoice(record),
          });
        }
      }
    }

    if (isTrackable && hasUpdateSettingsRiskPermissions) {
      dropdownItemsToReturn.push({
        id: 'toggle-exclude-from-risks',
        title: record.excludeFromRisk ? 'Add to Risk' : 'Remove from Risk',
        onClick: () => {
          toggleInvoiceExcludeFromRisk({
            invoiceId: record.id,
            data: {
              excludeFromRisk: !record.excludeFromRisk,
            },
          });
        },
      });
    }

    if (isDeletable && hasUpdateSettingsRiskPermissions) {
      dropdownItemsToReturn.push({
        id: 'delete',
        title: 'Delete from list',
        onClick: () => setShowInvoiceDelete(record),
      });
    }

    dropdownItemsToReturn.push({
      id: 'see-details',
      title: 'Details',
      link: `/app/invoices/${record.id}`,
    });

    if (record.status === INVOICE_STATUSES.purchaseOrder) {
      dropdownItemsToReturn.push({
        id: 'allocate-prepayment',
        title: !!record.hasPayments ? 'View prepayment' : 'Allocate prepayment',
        onClick: () => setShowPurchaseOrderPaymentsOnInvoice(record),
      });
    }

    return dropdownItemsToReturn;
  }, [
    canPayWithBalance,
    currencies,
    deepLinkKey,
    deleteInvoiceTracking,
    entityCurrencyCode,
    goToInvoicePrebook,
    hasApprovalFlow,
    hasCreatePrebooksPermission,
    hasCreateTransfersPermission,
    hasUpdateBalancesPermission,
    hasUpdateSettingsRiskPermissions,
    history,
    isSameCurrency,
    isUserApprover,
    rateContractsToUse?.length,
    record,
    setCancelPrebookInvoice,
    setExistingInvoiceTracking,
    setExistingPrebookInvoice,
    setInvoiceDecide,
    setShowAllDecideFields,
    setShowInvoiceDelete,
    setShowPurchaseOrderPaymentsOnInvoice,
    toggleInvoiceExcludeFromRisk,
    unbindContractRateToInvoice,
    updateInvoicesApprovalStatus,
  ]);

  return (
    <>
      <ContextMenu list={dropdownItems} strategy="fixed" portal />

      {showLimitedAccess && (
        <StaleLimitedAccess onClose={setShowLimitedAccessFalse} />
      )}
    </>
  );
};

export default InvoiceActionsMenu;
