import { FC } from 'react';

import { StaleSubTitleBold, Subtitle } from 'components';
import ExchangeSummary from '../ExchangeSummary/ExchangeSummary';
import { StepsNotification } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { IConversion } from 'types/conversions';
import StaleInfoBox from 'components/shared/StaleInfoBox/StaleInfoBox';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import { useStoreState } from 'state';
import Button from 'components/shared/Button/Button';

dayjs.extend(isToday);

interface OwnProps {
  onContinue: () => void;
  createdConversion: IConversion | null;
}

const Exchange: FC<OwnProps> = ({ onContinue, createdConversion }) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  if (!createdConversion) {
    return null;
  }

  const {
    conversionDate,
    sellCurrency: sellCurrencyCode,
    buyCurrency: buyCurrencyCode,
    buyAmount,
    sellAmount,
    rate,
    conversionFeeRate,
    delayedFunding,
  } = createdConversion;
  const sellCurrency = currencyByCode(sellCurrencyCode);
  const buyCurrency = currencyByCode(buyCurrencyCode);

  return (
    <FlowStepWrapper>
      <FlowStepContent>
        <FlowContentLeft>
          {!delayedFunding && (
            <>
              {dayjs(conversionDate).isToday() ? (
                <StepsNotification>
                  <Subtitle color="white">
                    Exchange went successfully! Funds are available on your
                    balance.
                  </Subtitle>
                </StepsNotification>
              ) : (
                <StaleInfoBox
                  text={
                    <>
                      {`Your funds will be available on your balance on `}
                      <b>{`${dayjs(conversionDate).format('D MMM YYYY')}`}</b>
                    </>
                  }
                />
              )}
            </>
          )}

          {delayedFunding && (
            <StaleInfoBox text="Your transaction has been booked." />
          )}
        </FlowContentLeft>
        <FlowContentRight>
          <StaleSubTitleBold style={{ marginBottom: 6 }}>
            Summary
          </StaleSubTitleBold>

          {sellCurrency && buyCurrency && (
            <ExchangeSummary
              sellCurrency={sellCurrency}
              buyCurrency={buyCurrency}
              buyAmountAsNumber={buyAmount}
              sellAmountAsNumber={sellAmount}
              rate={rate}
              conversionFeeRate={conversionFeeRate}
            />
          )}
        </FlowContentRight>
      </FlowStepContent>

      <FlowStepFooter>
        <Button onClick={onContinue}>Back to Dashboard</Button>
      </FlowStepFooter>
    </FlowStepWrapper>
  );
};

export default Exchange;
