import { BarCustomLayerProps, ComputedBarDatum } from '@nivo/bar';
import { line } from 'd3-shape';
import groupBy from 'lodash.groupby';
import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { IPnlDataPerMonth } from 'types/analyses';
import ChartBarsGroup from '../ChartBarsGroup/ChartBarsGroup';

interface OwnProps {
  data: BarCustomLayerProps<IPnlDataPerMonth>;
  activeMonth: string;
  onClick: (barMonth: string) => void;
}

const ChartRealisedBarsLayer: FC<OwnProps> = ({
  data,
  activeMonth,
  onClick,
}) => {
  const { color } = useTheme();
  const groupedBars = useMemo(
    () => Object.entries(groupBy(data.bars, 'data.indexValue')),
    [data.bars]
  );

  const lineGenerator = line<
    [
      string,
      [
        ComputedBarDatum<IPnlDataPerMonth>,
        ...ComputedBarDatum<IPnlDataPerMonth>[]
      ]
    ]
  >()
    .x(([_, [bar]]) => bar.x + bar.width / 2)
    .y(([_, [bar]]) => {
      const { totalFxImpactForPaid, transactionCost } = bar.data.data;

      return data.yScale((totalFxImpactForPaid ?? 0) + (transactionCost ?? 0));
    });

  return (
    <>
      {groupedBars.map(([id, barsGroup]) => (
        <ChartBarsGroup
          key={id}
          groupId={id}
          barsGroup={barsGroup}
          barExtraProps={{
            activeMonth,
            data,
          }}
          onClick={onClick}
        />
      ))}

      <path
        d={lineGenerator(groupedBars) || undefined}
        fill="none"
        stroke={color.blueLight}
        style={{ pointerEvents: 'none' }}
      />
    </>
  );
};

export default ChartRealisedBarsLayer;
