import { useState, useEffect } from 'react';
import { getInvoice } from 'services/firebase/invoices';

import { useStoreState } from 'state';
import { IContact, IInvoice, Nullable } from 'types';

const useInvoice = (invoiceId: Nullable<string>) => {
  const { recipientById } = useStoreState((state) => state.RecipientsState);

  const [invoiceRecipient, setInvoiceRecipient] = useState<IContact>();
  const [invoice, setInvoice] = useState<IInvoice>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (invoiceId) {
      getInvoice({
        invoiceId,
      }).then((data) => {
        if (data) {
          setInvoice(data);

          const existingRecipient = recipientById(data.contactId ?? '');

          if (existingRecipient) {
            setInvoiceRecipient(existingRecipient);
          }

          setIsLoading(false);
        }
      });
    }
  }, [invoiceId, recipientById]);

  return {
    invoice,
    invoiceRecipient,
    isLoading,
  };
};

export default useInvoice;
