import firebase from 'firebase/app';

export const openQuery = (query: firebase.firestore.QuerySnapshot) => {
  if (query.empty) {
    return [];
  }
  if (query.docs) {
    const docs: any[] = [];
    query.docs.forEach((doc) => {
      docs.push({
        ...doc.data(),
        id: doc.id,
      });
    });
    return docs;
  }
  return [];
};

export const openQueryWithTimestamp = (
  query: firebase.firestore.QuerySnapshot
) => {
  if (query.empty) {
    return [];
  }
  if (query.docs) {
    const docs: any[] = [];
    query.docs.forEach((doc) => {
      const docData = doc.data();

      //Find all Timestamp fields and convert to date with toDate()
      const timestampFields = Object.keys(docData).reduce((obj, key) => {
        if (docData[key]?.seconds && docData[key]?.nanoseconds) {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          obj[key] = docData[key].toDate();
        }
        return obj;
      }, {});

      docs.push({
        ...docData,
        id: doc.id,
        ...timestampFields,
      });
    });

    return docs;
  }
  return [];
};

export const openDoc = <T = any>(doc: firebase.firestore.DocumentSnapshot) => {
  if (!doc.exists) {
    return null;
  }

  return {
    ...(doc.data() as T),
    id: doc.id,
  };
};
