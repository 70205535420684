import { IBulkPayment, ITransfer, PAYMENT_TYPE, TRANSFER_STATUS } from 'types';

export const getTransferStatusDetails = (
  transfer: ITransfer | IBulkPayment
) => {
  if (!transfer) return null;

  // This is used by both Transfers and BulkPayments. Hence we have two odd cases below.
  // TOOD: Split this up so BulkPayments have their own getStatusDetails.
  switch (transfer.status as any) {
    case TRANSFER_STATUS.initiated:
      return {
        text: 'Transfer initiated',
        color: '#E69138',
      };
    case TRANSFER_STATUS.awaitingPayment:
      return {
        text: 'Awaiting funds',
        color: '#E69138',
      };
    case TRANSFER_STATUS.funded:
    case TRANSFER_STATUS.approved:
      return {
        text: 'Funds received',
        color: '#E69138',
      };
    case TRANSFER_STATUS.processing:
      return {
        text: 'Being processed',
        color: '#000',
      };
    case TRANSFER_STATUS.paymentSent:
      return {
        text: 'Payment sent',
        color: '#39771D',
      };
    case TRANSFER_STATUS.paymentConfirmed:
      return {
        text: 'Payment confirmed',
        color: '#39771D',
      };
    case TRANSFER_STATUS.paymentCancelled:
      return {
        text: 'Payment cancelled',
        color: '#EC4B4B',
      };
    case TRANSFER_STATUS.paymentFailed:
      return {
        text: 'Payment failed',
        color: '#EC4B4B',
      };
    case TRANSFER_STATUS.timedOut:
      return {
        text: 'Timed out',
        color: '#EC4B4B',
      };
    case 'completed':
      return {
        text: 'Completed',
        color: '#39771D',
      };
    case 'cancelled':
      return {
        text: 'Cancelled',
        color: '#EC4B4B',
      };
    default:
      return null;
  }
};

export const getTransferFeeLabel = (paymentType: PAYMENT_TYPE | undefined) => {
  if (!paymentType || paymentType === PAYMENT_TYPE.iban) {
    return '';
  }

  if (paymentType === PAYMENT_TYPE.local) {
    return '(LOCAL)';
  }

  return '(SWIFT)';
};
