import { IEntityDetails, IResponse } from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import { IEntity, TEntityPackages, IEntityUser } from 'state/user';

import { TEntityPackageKeys, IPermitRoleV2 } from 'types/permissions';

export interface CreateEntityPayload {
  companyName: string;
}

export const createEntity = async (payload: CreateEntityPayload) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<
      IResponse<IEntity>
    >('entities', payload);

    return data;
  } catch (error: any) {
    console.warn(error.message);
    return;
  }
};

export const getEntityUsers = async ({ entityId }: { entityId: string }) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IEntityUser[]>>(
    `entities/${entityId}/users`
  );

export const getEntityRoles = async (entityId: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IPermitRoleV2[]>>(
    `entities/${entityId}/roles`
  );

export const editEntityUserRoles = async ({
  entityId,
  userId,
  roles,
}: {
  entityId: string;
  userId: string;
  roles: string[];
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse>( // TODO fix type
    `entities/${entityId}/users/${userId}/roles`,
    { roles }
  );

export const editEntityUserAttributes = async ({
  entityId,
  userId,
  attributes,
}: {
  entityId: string;
  userId: string;
  attributes: string[];
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `entities/${entityId}/users/${userId}/attributes`,
    { attributes }
  );

export const getPackages = async (entityId: string) => {
  const { data } = await AxiosPrivateFirebaseInstance.get<
    IResponse<TEntityPackages>
  >(`entities/${entityId}/packages`);

  return data.data;
};

export const savePackages = async (
  entityId: string,
  packages: TEntityPackages
) => {
  const { data } = await AxiosPrivateFirebaseInstance.put<
    IResponse<TEntityPackages>
  >(`entities/${entityId}/packages`, packages);

  return data.data;
};

export const getUserPermissionsOnEntity = async ({
  userId,
  entityId,
}: {
  userId: string;
  entityId: string;
}) => {
  const { data } = await AxiosPrivateFirebaseInstance.get<IResponse<string[]>>(
    `entities/${entityId}/users/${userId}/permissions`
  );

  return data.data;
};

export const requestFreeTrial = async (
  entityId: string,
  entityPackage: TEntityPackageKeys
) =>
  AxiosPrivateFirebaseInstance.post<IResponse<TEntityPackageKeys>>(
    `entities/${entityId}/packages/free_trial`,
    {
      entityPackage,
    }
  );

export const setEntityApprover = async ({
  userId,
  entityId,
}: {
  userId: string;
  entityId: string;
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `entities/${entityId}/approver/${userId}`
  );

export const enableApprovalFlowForEntity = async ({
  entityId,
}: {
  entityId: string;
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `entities/${entityId}/switch-approval-flow`
  );

export const setRequiredNumberOfApprovals = async ({
  entityId,
  requiredNumberOfApprovals,
}: {
  entityId: string;
  requiredNumberOfApprovals: number;
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `entities/${entityId}/approval-flow/required-number-of-approvals`,
    {
      requiredNumberOfApprovals,
    }
  );

export interface GetEntityCompanyDetailsParams {
  entityId: string;
}

export const getEntityCompanyDetails = async (entityId: string) =>
  AxiosPrivateFirebaseInstance.get<
    IResponse<Pick<IEntityDetails, 'countriesSendingMoneyTo'>>
  >(`entities/${entityId}/companyDetails`);
