import { FC } from 'react';
import { Paragraph, Row, Col, InlineLoader, Icon } from 'components';
import { ICurrency, IInvoice } from 'types';
import {
  parseIntoCurrencyString,
  parseIntoCurrencyStringWithSymbol,
} from 'utils';
import { ConvertInfoWrapper } from './DecideConvertInfo.styles';
import { getInvoiceRemainingAmount } from 'utils/invoices';
import { UseCurrencyRateReturnValues } from 'hooks/useCurrencyRate';
import { useStoreState } from 'state';

interface OwnProps {
  currency: ICurrency | null;
  invoice: IInvoice;
  rate: UseCurrencyRateReturnValues['rate'];
}

const DecideConvertInfo: FC<OwnProps> = ({ currency, invoice, rate }) => {
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const entityCurrency = currencyByCode(entityCurrencyCode);

  return (
    <ConvertInfoWrapper>
      <Col>
        <Paragraph>Amount Due</Paragraph>

        <Row>
          <svg>
            <use xlinkHref={`#${currency?.countryCode}`} />
          </svg>
          <Paragraph variant="bold">{`${
            currency?.symbol
          }${parseIntoCurrencyString(
            getInvoiceRemainingAmount(invoice),
            currency?.precision
          )}`}</Paragraph>
        </Row>
      </Col>
      <Col>
        <Paragraph>Current</Paragraph>

        <Row>
          <Icon icon={entityCurrency?.countryCode ?? ''} />
          <Paragraph variant="bold">
            {rate ? (
              `${parseIntoCurrencyStringWithSymbol(
                getInvoiceRemainingAmount(invoice) / rate,
                entityCurrency?.symbol,
                entityCurrency?.precision
              )}`
            ) : (
              <InlineLoader />
            )}
          </Paragraph>
        </Row>
      </Col>
    </ConvertInfoWrapper>
  );
};

export default DecideConvertInfo;
