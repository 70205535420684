import { FC } from 'react';
import { IInvoiceFromSearch } from 'types';
import { Wrapper } from './InvoicesTableFooterContent.styles';
import { useStoreState } from 'state';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';
import useInvoicesFooterActions from './useInvoicesFooterActions';
import Button from 'components/shared/Button/Button';
import { PermissionsChecker } from 'components';

interface OwnProps {
  selectedInvoices: IInvoiceFromSearch[];
}

const InvoicesTableFooterContent: FC<OwnProps> = ({ selectedInvoices }) => {
  const { isUserApprover, hasApprovalFlow } = useStoreState(
    (state) => state.UserState
  );
  const { isUpdatingPaymentRun } = useStoreState(
    (state) => state.PaymentRunsState
  );

  const {
    isUpdatingInvoicesApprovalStatus,
    updateInvoicesApprovalStatus,
  } = useInvoicesApprovalStatus();

  const {
    invoicesForApproval,
    invoicesForSubmission,
    invoicesForPayment,
    hasReceivableInvoices,
    onAddToPaymentRun,
  } = useInvoicesFooterActions({
    selectedInvoices,
  });

  return (
    <Wrapper>
      {hasApprovalFlow && !!invoicesForSubmission.length && (
        <>
          <Button
            mr
            onClick={() =>
              updateInvoicesApprovalStatus({
                invoiceIds: invoicesForSubmission.map((invoice) => invoice.id),
                approvalStatus: 'submitted',
              })
            }
            disabled={isUpdatingInvoicesApprovalStatus}
            isLoading={isUpdatingInvoicesApprovalStatus}
          >
            {`Submit ${invoicesForSubmission.length} selected`}
          </Button>
        </>
      )}

      {hasApprovalFlow && isUserApprover && !!invoicesForApproval.length && (
        <Button
          mr
          onClick={() =>
            updateInvoicesApprovalStatus({
              invoiceIds: invoicesForApproval.map((invoice) => invoice.id),
              approvalStatus: 'approved',
            })
          }
          disabled={isUpdatingInvoicesApprovalStatus}
          isLoading={isUpdatingInvoicesApprovalStatus}
        >
          {`Approve ${invoicesForApproval.length} selected`}
        </Button>
      )}

      {!!invoicesForPayment.length && (
        <PermissionsChecker action="update" resource="payment_runs">
          <Button
            onClick={onAddToPaymentRun}
            disabled={hasReceivableInvoices || isUpdatingPaymentRun}
            isLoading={isUpdatingPaymentRun}
          >
            Add to payment run
          </Button>
        </PermissionsChecker>
      )}
    </Wrapper>
  );
};

export default InvoicesTableFooterContent;
