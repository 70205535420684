import {
  Icon,
  Paragraph,
  Row,
  Col,
  StaleNotification,
  StaleSwitch,
  Subtitle,
} from 'components';
import Button from 'components/shared/Button/Button';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { updatePaymentRun } from 'services/paymentRuns';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { IPaymentRun } from 'types/paymentRuns';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { errorHandler } from 'utils/errors';

interface OwnProps {
  paymentRunId: string;
  isCombineSameContacts: boolean;
  estimatedCost: number;
  localCurrencyCode: string;
  setPaymentRun: Dispatch<SetStateAction<IPaymentRun>>;
  setIsUpdatingPaymentRun: Dispatch<SetStateAction<boolean>>;
  onSubmit: () => void;
  error?: string;
}

const ReviewStepForm: FC<OwnProps> = ({
  paymentRunId,
  estimatedCost,
  localCurrencyCode,
  isCombineSameContacts,
  setPaymentRun,
  setIsUpdatingPaymentRun,
  onSubmit,
  error,
}) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(localCurrencyCode);

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      isCombine: isCombineSameContacts,
    },
  });
  const isCombine = watch('isCombine');

  useEffect(() => {
    if (isCombine !== isCombineSameContacts) {
      const updatePaymentRunIsCombine = async () => {
        try {
          setIsUpdatingPaymentRun(true);

          const { data: response } = await updatePaymentRun({
            paymentRunId,
            combine: isCombine,
          });

          if (response.data) {
            setPaymentRun(response.data);
          }
        } catch (error: any) {
          errorHandler(error);
        } finally {
          setIsUpdatingPaymentRun(false);
        }
      };

      updatePaymentRunIsCombine();
    }
  }, [
    isCombine,
    isCombineSameContacts,
    paymentRunId,
    setIsUpdatingPaymentRun,
    setPaymentRun,
  ]);

  return (
    <form id="payment-run-review-form" onSubmit={handleSubmit(onSubmit)}>
      <Col>
        {error && (
          <Row>
            <StaleNotification
              title="Issue with the transfers"
              cross={false}
              bgColor={theme.color.red}
              style={{ maxWidth: 'unset' }}
            >
              {error}
            </StaleNotification>
          </Row>
        )}
        <Row>
          <Row gap={theme.spacing.m} justifyContent="flex-start">
            <Button type="submit">Continue</Button>
            <Controller
              name="isCombine"
              control={control}
              render={({ name, value, onChange }) => (
                <Row>
                  <StaleSwitch
                    id={name}
                    isOn={value}
                    handleToggle={() => onChange(!value)}
                  />
                  <Paragraph ml>
                    Combine invoices to the same recipient in a single transfer
                  </Paragraph>
                </Row>
              )}
            />
          </Row>

          {currency && (
            <Row gap={theme.spacing.xs}>
              <Subtitle variant="bold">
                Estimated Cost:{' '}
                {parseIntoCurrencyStringWithSymbol(
                  estimatedCost,
                  currency.symbol,
                  0
                )}
              </Subtitle>
              <Icon icon={currency.countryCode} />
            </Row>
          )}
        </Row>
      </Col>
    </form>
  );
};

export default ReviewStepForm;
