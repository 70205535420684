import { Title, StaleSelectMenu } from 'components';
import { FC, useState } from 'react';
import { Notify } from 'utils';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { saveRegistrationSettings } from 'services/entities';
import { useQuery } from 'hooks';
import { Controller, useForm } from 'react-hook-form';
import useIntegrationDefaultValues from 'hooks/useIntegrationDefaultValues';
import { ICurrencyInput } from 'types';
import Button from 'components/shared/Button/Button';

type Inputs = {
  entityCurrency: ICurrencyInput;
};

const StepInfo: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const URLQuery = useQuery();
  const { url } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(false);
  const { defaultCurrencies, parsedCurrencies } = useIntegrationDefaultValues();

  const { control, handleSubmit, errors } = useForm<Inputs>({
    mode: 'all',
    defaultValues: {
      entityCurrency: defaultCurrencies[0],
    },
  });

  const onSubmit = async (data: Inputs) => {
    try {
      setIsLoading(true);
      const response = await saveRegistrationSettings({
        entityCurrency: data.entityCurrency.value.code,
      });

      if (response?.data?.success) {
        URLQuery.set('step', '3');
        history.push(`${url}?${URLQuery.toString()}`);
      } else {
        Notify.error(response?.data?.message ?? '');
        setIsLoading(false);
      }
    } catch (error: any) {
      Notify.error(error.response?.data?.error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        Two more questions:
      </Title>
      <form id="info-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          id="entityCurrency"
          name="entityCurrency"
          control={control}
          render={({ onChange, value, name }) => (
            <StaleSelectMenu
              id={name}
              name={name}
              label="What is your main currency?"
              data={parsedCurrencies}
              value={value}
              onChange={onChange}
              disabled
              withBackdrop={false}
              // @ts-expect-error TS(2322) FIXME: Type '{ id: string; name: string; label: string; d... Remove this comment to see the full error message
              error={errors.mainSellCurrency?.message}
            />
          )}
        />
      </form>
      <Button form="info-form" disabled={isLoading} isLoading={isLoading}>
        Continue
      </Button>
    </>
  );
};

export default StepInfo;
