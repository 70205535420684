import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Column, FilterTypes } from 'react-table';
import {
  Nullable,
  IRecipient,
  IInvoice,
  IContact,
  IPastPerformanceItemInvoice,
  TAccountingIntegration,
} from 'types';
import { IInvoiceForTable } from './types';
import {
  isReceivableInvoice,
  getInvoiceRemainingAmount,
  isPayableInvoice,
  isInvoiceOverdue,
  isInvoiceDueIn14Days,
} from 'utils/invoices';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import AmountSellCurrencyCell from 'components/shared/AmountSellCurrencyCell/AmountSellCurrencyCell';
import ContactCell from 'components/shared/ContactCell/ContactCell';
import DateCell from 'components/shared/DateCell/DateCell';
import DirectionCell from 'components/shared/DirectionCell/DirectionCell';
import InvoiceNumberCell from 'components/shared/InvoiceNumberCell/InvoiceNumberCell';
import { FlagCell, InvoiceStatusCell } from './components/Cells/Cells';
import StatusIconCell from './components/StatusIconCell/StatusIconCell';
import InvoiceActionsMenu from './components/InvoiceActionsMenu/InvoiceActionsMenu';
import DoActionCell from './components/DoActionCell/DoActionCell';
import InvoiceSyncedToExternalSourceCell from './components/InvoiceSyncedToExternalSourceCell/InvoiceSyncedToExternalSourceCell';
import RealisedPnlCell from './components/RealisedPnlCell/RealisedPnlCell';

import Icon from '../Icon/Icon';
import { Row } from '../Row/Row';
import { mapAccountingIntegrationToIcon } from 'variables';
import ProfitAndLossCell from '../ProfitAndLossCell/ProfitAndLossCell';

export const filterTypes: FilterTypes<IInvoiceForTable> = {
  direction: (rows, _, filterValue) => {
    if (filterValue === 'default') {
      return rows;
    }

    if (filterValue === 'overdue') {
      return rows.filter(({ original }) => isInvoiceOverdue(original));
    }

    if (filterValue === 'payables') {
      return rows.filter(({ original }) => isPayableInvoice(original));
    }

    if (filterValue === 'dueIn14Days') {
      return rows.filter(({ original }) => isInvoiceDueIn14Days(original));
    }

    return rows;
  },
  text: (rows, _, filterValue) => {
    if (!filterValue) {
      return rows;
    }

    return rows.filter(({ original }) => {
      if (original.invoiceNumber?.includes(filterValue)) {
        return true;
      }

      if (
        original.contact &&
        original.contact.recipientName
          ?.toLowerCase()
          .includes(filterValue.toLowerCase())
      ) {
        return true;
      }

      return false;
    });
  },
};

export const generateTableColumns = ({
  recipientById,
  setCancelPrebookInvoice,
  setContactForEdit,
  setExistingInvoiceTracking,
  setExistingPrebookInvoice,
  setInvoiceDecide,
  setInvoiceForAddContact,
  setShowAllDecideFields,
  setShowInvoiceDelete,
  setShowPurchaseOrderPaymentsOnInvoice,
  renderActionCell,
  tab,
  pastPerformancePerRecordInvoices,
  isLoadingPastPerformancePerRecordInvoices,
  accountingIntegration,
  entityCurrencyCode
}: {
  recipientById: (id: Nullable<string>) => IRecipient | undefined;
  setCancelPrebookInvoice: Dispatch<SetStateAction<IInvoice | null>>;
  setContactForEdit: Dispatch<SetStateAction<IContact | null>>;
  setExistingInvoiceTracking: Dispatch<SetStateAction<IInvoice | null>>;
  setExistingPrebookInvoice: Dispatch<SetStateAction<IInvoice | null>>;
  setInvoiceDecide: Dispatch<SetStateAction<IInvoice | null>>;
  setInvoiceForAddContact: Dispatch<SetStateAction<IInvoice | null>>;
  setShowAllDecideFields: Dispatch<SetStateAction<boolean>>;
  setShowInvoiceDelete: Dispatch<SetStateAction<IInvoice | null>>;
  setShowPurchaseOrderPaymentsOnInvoice: Dispatch<
    SetStateAction<IInvoice | null>
  >;
  renderActionCell?: (record: IInvoiceForTable) => ReactNode;
  tab: string | null;
  pastPerformancePerRecordInvoices?: IPastPerformanceItemInvoice[];
  isLoadingPastPerformancePerRecordInvoices?: boolean;
  accountingIntegration: TAccountingIntegration;
  entityCurrencyCode: Nullable<string>;
}): Column<IInvoiceForTable>[] => {
  const dateCells: Column<IInvoiceForTable>[] =
    tab !== 'paid'
      ? [
          {
            accessor: 'date',
            Header: 'Issued',
            Cell: ({ value }) => <DateCell value={value} />,
            width: 100,
            minWidth: 80,
          },
          {
            accessor: 'dueDate',
            Header: 'Due',
            Cell: ({ value }) => <DateCell withOverdueMarker value={value} />,
            width: 100,
            minWidth: 80,
          },
        ]
      : [
          {
            accessor: 'fullyPaidOnDate',
            Header: 'Payment Date',
            Cell: ({ value }) => <DateCell value={value} />,
            width: 100,
            minWidth: 80,
          },
        ];

  const pnlCells: Column<IInvoiceForTable>[] =
    tab !== 'paid'
      ? [
          {
            id: 'unrealisedPnl',
            accessor: 'profitAndLoss',
            Header: 'Unrealised P&L',
            sortType: (rowA, rowB) => {
              const a = rowA.original.profitAndLoss || 0;
              const b = rowB.original.profitAndLoss || 0;

              if (a > b) {
                return 1;
              }
              if (b > a) {
                return -1;
              }

              return 0;
            },
            Cell: ({ row }) => (
              <ProfitAndLossCell
                record={row.original}
                profitAndLoss={row.original.profitAndLoss}
              />
            ),
            width: 120,
            minWidth: 100,
          },
        ]
      : [
          {
            id: 'realisedPnl',
            accessor: 'profitAndLoss',
            Header: 'Realised P&L',
            disableSortBy: true,
            Cell: ({
              row: {
                original: { id },
              },
            }) => {
              const pastPerformanceRecord = pastPerformancePerRecordInvoices?.find(
                ({ invoiceId }) => invoiceId === id
              );

              return (
                <RealisedPnlCell
                  pastPerformanceRecord={pastPerformanceRecord}
                  isLoadingPastPerformancePerRecordInvoices={
                    isLoadingPastPerformancePerRecordInvoices
                  }
                />
              );
            },
            width: 120,
            minWidth: 100,
          },
        ];

  const actionAndIconCells: Column<IInvoiceForTable>[] = [
    {
      id: 'toDo',
      disableSortBy: true,
      Cell: ({ row }: any) =>
        renderActionCell ? (
          renderActionCell(row.original)
        ) : (
          <DoActionCell record={row.original} />
        ),
      width: 80,
      minWidth: 60,
    },
    {
      id: 'icon',
      disableSortBy: true,
      Cell: ({ row }: any) => <StatusIconCell record={row.original} />,
      width: 48,
      minWidth: 48,
    },
  ];

  const invoiceSyncedToExternalSourceCell: Column<IInvoiceForTable>[] = [
    {
      id: 'invoiceSyncedToExternalSourceCell',
      Header: (
        <Row justifyContent="center">
          {accountingIntegration && (
            <Icon
              width="20px"
              height="20px"
              icon={mapAccountingIntegrationToIcon[accountingIntegration]}
            />
          )}
        </Row>
      ),
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <InvoiceSyncedToExternalSourceCell record={row.original} />
      ),
      width: 60,
      minWidth: 40,
    },
  ];

  return [
    {
      accessor: 'invoiceNumber',
      Header: 'Ref',
      disableSortBy: true,
      Cell: ({ row }) => <InvoiceNumberCell record={row.original} />,
      width: 110,
    },
    {
      accessor: 'contact',
      Header: 'Name',
      sortType: (rowA, rowB) => {
        const existingRecipientA = recipientById(rowA.original.contactId);
        const recipientToUseA = existingRecipientA ?? rowA.original.contact;
        const existingRecipientB = recipientById(rowB.original.contactId);
        const recipientToUseB = existingRecipientB ?? rowB.original.contact;

        const a = recipientToUseA?.recipientName || '';
        const b = recipientToUseB?.recipientName || '';

        if (a > b) {
          return 1;
        }
        if (b > a) {
          return -1;
        }

        return 0;
      },
      Cell: ({ row, value }) => {
        const existingRecipient = recipientById(row.original.contactId);
        const recipientToUse = existingRecipient ?? value;

        return (
          <ContactCell
            invoice={row.original}
            recipientToUse={recipientToUse}
            setContactForEdit={setContactForEdit}
            setInvoiceForAddContact={setInvoiceForAddContact}
            withReviewStatus={tab !== 'paid'}
          />
        );
      },
      width: 180,
      minWidth: 150,
    },
    ...dateCells,
    {
      accessor: 'currency',
      Cell: ({ value }) => <FlagCell currencyCode={value} />,
      width: 50,
      minWidth: 50,
      disableSortBy: true,
    },
    {
      accessor: () => null,
      id: 'direction',
      Header: tab === 'paid' ? 'Paid Amount' : 'Amount Due',
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <>
          <DirectionCell
            withTitle={false}
            isReceivable={isReceivableInvoice(row.original)}
            currencyCode={row.original.currency}
          />
          <AmountCell
            amount={getInvoiceRemainingAmount(row.original)}
            currencyCode={row.original.currency}
          />
        </>
      ),
      filter: 'direction',
      width: 120,
      minWidth: 100,
    },
    {
      id: entityCurrencyCode ?? undefined,
      Header: `${entityCurrencyCode ?? ''} Value`,
      disableSortBy: true,
      Cell: ({ row }: any) => <AmountSellCurrencyCell record={row.original} />,
      width: 120,
      minWidth: 100,
    },
    ...pnlCells,
    {
      accessor: 'status',
      Header: 'Status',
      Cell: ({ row }) => <InvoiceStatusCell invoice={row.original} />,
      width: 110,
      minWidth: 90,
    },
    ...(tab !== 'paid' ? actionAndIconCells : []),
    ...(tab === 'paid' && !!accountingIntegration
      ? invoiceSyncedToExternalSourceCell
      : []),
    {
      id: 'dots',
      Header: () => null,
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <InvoiceActionsMenu
          record={row.original}
          setInvoiceDecide={setInvoiceDecide}
          setCancelPrebookInvoice={setCancelPrebookInvoice}
          setExistingInvoiceTracking={setExistingInvoiceTracking}
          setExistingPrebookInvoice={setExistingPrebookInvoice}
          setShowAllDecideFields={setShowAllDecideFields}
          setShowInvoiceDelete={setShowInvoiceDelete}
          setShowPurchaseOrderPaymentsOnInvoice={
            setShowPurchaseOrderPaymentsOnInvoice
          }
        />
      ),
      width: 60,
      minWidth: 55,
    },
  ];
};
