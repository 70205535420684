import { FC, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import firebase from 'firebase/app';
import TooManyTimePasswordAttemptsPopup from 'components/shared/TooManyTimePasswordAttemptsPopup/TooManyTimePasswordAttemptsPopup';

import {
  StaleInput,
  StaleGetStartedPopup,
  StaleOverflowScroll,
  StaleCarousel,
  Paragraph,
  Title,
  Row,
  Col,
} from 'components';
import StaleGoogleSignInButton from 'components/shared/StaleGoogleLoginButton/StaleGoogleSignInButton';
import { Wrapper, LoginWrap, LoginNav, LoginContent } from './Login.styles';
import { useStoreActions, useStoreState } from 'state';
import { useForm } from 'react-hook-form';
import { useRecaptchaVerifier } from 'hooks';
import { getDashboardPageLink, getMyClientsLink, Notify } from 'utils';
import auth from 'services/auth';
import { ERROR_MESSAGES } from 'variables';
import Button from 'components/shared/Button/Button';
import { useTheme } from 'styled-components';

const Login: FC<
  RouteComponentProps<
    {},
    any,
    {
      from: {
        pathname: string;
      };
    }
  >
> = ({ history, location }) => {
  const theme = useTheme();
  const { control, handleSubmit, watch, reset } = useForm<{
    email: string;
    password: string;
    verificationCode: string;
  }>({
    defaultValues: {
      email: '',
      password: '',
      verificationCode: '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isGetStartedShown, setIsGetStartedShown] = useState(false);
  const [authResolver, setAuthResolver] = useState<any>(null);
  const [verificationId, setVerificationId] = useState('');
  const [valuesStepOne, setValuesStepOne] = useState(null);
  const [
    isTooManyPasswordAttemptsError,
    setIsTooManyPasswordAttemptsError,
  ] = useState(false);

  const { userId, isAuthLoading, isAccountant } = useStoreState(
    (state) => state.UserState
  );
  const { signIn, signInWithGoogle } = useStoreActions(
    (actions) => actions.UserState
  );

  const isEmailVerified = auth.currentUser?.emailVerified;

  const recaptchaContainer = useRecaptchaVerifier('recaptcha');

  /**
   * Handles 2 cases:
   * 1. When user enters username and password
   * 2. When user requests another verification code
   */
  const onPasswordSigninAndResendVerificationCode = async (values: any) => {
    try {
      const response = await signIn(values);

      setValuesStepOne(values);

      // user has 2FA enabled
      if (response?.hints) {
        setAuthResolver(response);

        const phoneInfoOptions = {
          multiFactorHint: response.hints[0],
          session: response.session,
        };

        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

        if (!recaptchaContainer) {
          return;
        }

        const verificationResp = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaContainer
        );

        setVerificationId(verificationResp);

        reset();

        control.fieldsRef.current.verificationCode?.ref?.focus?.();
      }
    } catch (ex) {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      if (ex.code === 'auth/too-many-requests') {
        setIsTooManyPasswordAttemptsError(true);
      }
    }
  };

  const onSignInWithGoogle = async () => {
    const response = await signInWithGoogle();

    // user has 2FA enabled
    if (response?.hints) {
      setAuthResolver(response);

      const phoneInfoOptions = {
        multiFactorHint: response.hints[0],
        session: response.session,
      };

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

      if (!recaptchaContainer) {
        return;
      }

      const verificationResp = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaContainer
      );

      setVerificationId(verificationResp);

      reset();
    }
  };

  const onSubmitCode = async (values: any) => {
    setIsLoading(true);
    const cred = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      values.verificationCode
    );

    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
      cred
    );

    try {
      await authResolver.resolveSignIn(multiFactorAssertion);
    } catch (error: any) {
      Notify.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onError = () => {
    // TODO: discuss error handling
  };

  if (!isAuthLoading && isEmailVerified && userId) {
    if (location.state?.from?.pathname) {
      return <Redirect to={location.state?.from?.pathname} />;
    }

    return (
      <Redirect
        to={isAccountant ? getMyClientsLink() : getDashboardPageLink()}
      />
    );
  }

  return (
    <Wrapper>
      <LoginWrap>
        <LoginNav>
          {isMobile ? (
            <Title variant="h5">Sign in</Title>
          ) : (
            <>
              <Title variant="h5">Sign in to HedgeFlows to:</Title>

              <StaleCarousel />
            </>
          )}
        </LoginNav>

        {verificationId ? (
          <LoginContent className="rounded">
            <StaleOverflowScroll style={{ paddingBottom: 0 }}>
              <div className="block">
                <Title variant="h5">Please enter a code from SMS</Title>

                <form onSubmit={handleSubmit(onSubmitCode, onError)}>
                  <div className="field">
                    <StaleInput
                      id="verificationCode"
                      name="verificationCode"
                      label="Verification code"
                      view="moving"
                      control={control}
                      rules={{
                        required: ERROR_MESSAGES.requiredField,
                      }}
                      autoFocus
                    />
                  </div>

                  <Row mb mt>
                    <Paragraph>Haven’t received the code? </Paragraph>
                    <Button
                      variant="link"
                      type="button"
                      onClick={async (event) => {
                        event.preventDefault();
                        onPasswordSigninAndResendVerificationCode(
                          valuesStepOne
                        );
                      }}
                    >
                      Resend
                    </Button>
                  </Row>

                  <Button
                    variant="primary"
                    disabled={!watch('verificationCode') || isLoading}
                    isLoading={isLoading}
                    type="submit"
                  >
                    Continue
                  </Button>
                </form>
              </div>
            </StaleOverflowScroll>
          </LoginContent>
        ) : (
          <LoginContent className="rounded">
            <StaleOverflowScroll style={{ paddingBottom: 0 }}>
              <div className="block">
                <Title variant="h5">Please enter your email and password</Title>

                <form
                  onSubmit={handleSubmit(
                    onPasswordSigninAndResendVerificationCode,
                    onError
                  )}
                >
                  <Col>
                    <div className="field">
                      <StaleInput
                        id="email"
                        name="email"
                        label="Email"
                        view="moving"
                        type="email"
                        control={control}
                        rules={{
                          required: ERROR_MESSAGES.requiredField,
                        }}
                        autoFocus
                      />
                    </div>

                    <div className="field">
                      <StaleInput
                        id="password"
                        name="password"
                        label="Password"
                        view="moving"
                        type="password"
                        control={control}
                        rules={{
                          required: ERROR_MESSAGES.requiredField,
                        }}
                      />
                    </div>

                    <Button
                      mb
                      mt
                      variant="link"
                      onClick={(event) => {
                        event.preventDefault();

                        history.push('/reset-password');
                      }}
                      type="button"
                    >
                      Forgot your password?
                    </Button>

                    <Button
                      disabled={!watch('email') || !watch('password')}
                      type="submit"
                    >
                      Log in
                    </Button>

                    <div className="or">
                      <span>or</span>
                    </div>

                    <StaleGoogleSignInButton
                      onSignInWithGoogle={onSignInWithGoogle}
                    />
                  </Col>
                </form>

                <Row justifyContent="center">
                  <Paragraph>Don't have an account?</Paragraph>
                  <Button
                    ml
                    mlValue={theme.spacing.xs}
                    variant="link"
                    onClick={() => history.push('/sign-up')}
                  >
                    Sign up
                  </Button>
                </Row>
              </div>
            </StaleOverflowScroll>
          </LoginContent>
        )}
      </LoginWrap>

      {isGetStartedShown && (
        <StaleGetStartedPopup onContinue={() => setIsGetStartedShown(false)} />
      )}

      {isTooManyPasswordAttemptsError && (
        <TooManyTimePasswordAttemptsPopup
          onClose={() => setIsTooManyPasswordAttemptsError(false)}
        />
      )}

      <div id="recaptcha" />
    </Wrapper>
  );
};

export default Login;
