import useFindAccountingIntegration from 'hooks/useFindAccountingIntegration';
import { IInvoiceFromSearch } from 'types';

export const useInvoiceFromSearchLastSyncDetails = (
  invoice: IInvoiceFromSearch
) => {
  const {
    lastSyncFromExternalSourceDate,
    lastSyncFromExternalSourceStatus,
    lastSyncToExternalSourceDate,
    lastSyncToExternalSourceStatus,
  } = invoice;
  const accountingIntegration = useFindAccountingIntegration();

  return {
    accountingIntegration,
    lastSyncFromExternalSourceDate,
    lastSyncFromExternalSourceStatus,
    lastSyncToExternalSourceDate,
    lastSyncToExternalSourceStatus,
  };
};
