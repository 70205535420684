import { Icon } from 'components';
import { IMenuItem } from 'components/shared/StaleSelectMenu/StaleSelectMenu';
import { orderBy } from 'lodash';
import { Nullable } from 'types';
import { ICurrency } from 'types/currencies';
import { POPULAR_CURRENCIES_WITH_COLORS } from 'variables';

interface IIsCurrencyEnabledForBuyingParams {
  currencyCode: ICurrency['code'];
  currencies: ICurrency[];
}

export const isCurrencyEnabledForBuying = ({
  currencyCode,
  currencies,
}: IIsCurrencyEnabledForBuyingParams) =>
  currencies.find(({ code, buy }) => code === currencyCode && buy);

export const getDefaultBuyCurrencyCode = (sellCurrency?: string) =>
  sellCurrency === 'USD' ? 'GBP' : 'USD';

export const selectCurrenciesFromCurrencyCodes = (
  detectedCurrencyCodes: string[],
  currencyByCode: (code: string) => Nullable<ICurrency>
) =>
  orderBy(
    detectedCurrencyCodes.reduce<IMenuItem[]>((acc, item) => {
      const currency = currencyByCode(item);

      if (currency) {
        acc.push({
          value: item,
          label: (
            <>
              <Icon
                icon="square"
                fill={POPULAR_CURRENCIES_WITH_COLORS[currency.code]}
              />{' '}
              {item}
            </>
          ),
        });
      }

      return acc;
    }, []),
    'label'
  );
