import dayjs from 'dayjs';
import { FC, useMemo } from 'react';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ChartBar from 'components/shared/ChartBar/ChartBar';
import { parseIntoShortNumberWithSymbol } from 'utils';
import {
  IPnlDataPerMonth,
  TPastPerformanceDataFromExternalSource,
} from 'types';
import { getDataForProfitAndLoss } from './utils';
import ChartTick from 'components/shared/ChartTick/ChartTick';
import Tooltip from './components/Tooltip/Tooltip';

dayjs.extend(customParseFormat);

interface OwnProps {
  data: Record<string, IPnlDataPerMonth[]>;
  externalData: TPastPerformanceDataFromExternalSource[];
  onChartPeriodClick: (period: string) => void;
}

const ChartProfitAndLoss: FC<OwnProps> = ({
  data,
  externalData,
  onChartPeriodClick,
}) => {
  const dataForProfitAndLoss = useMemo(
    () => getDataForProfitAndLoss(data, externalData),
    [data, externalData]
  );

  return (
    <ChartBar
      data={dataForProfitAndLoss}
      keys={['externalPnl', 'pnl']}
      margin={{
        right: 30,
        bottom: 40,
        top: 20,
        left: 42,
      }}
      groupMode="grouped"
      animate
      isInteractive
      axisTop={null}
      axisRight={null}
      enableGridX={false}
      enableLabel={false}
      axisBottom={{
        renderTick: ChartTick,
      }}
      axisLeft={{
        format: (value) => parseIntoShortNumberWithSymbol(value),
      }}
      tooltip={Tooltip}
      onClick={(datum) => {
        if (typeof datum.indexValue === 'string') {
          onChartPeriodClick(datum.indexValue);
        }
      }}
    />
  );
};

export default ChartProfitAndLoss;
