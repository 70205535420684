import {
  Title,
  StaleTitleH5,
  StaleParagraphMedium,
  StaleCarousel,
  ButtonStyleLink,
  StaleInputSelect,
  Icon,
  SignUpContent,
  SignUpNav,
  SignUpWrap,
  Col,
} from 'components';
import { Block } from '../../SignUp.styles';
import { FC, useState } from 'react';
import { Notify } from 'utils';
import { useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { saveRegistrationSettings } from 'services/entities';
import { ICurrency, RECIPIENT_TYPE } from 'types';
import Field from 'components/shared/Field/Field.styles';
import {
  getDashboardPageLink,
  getMyClientsLink,
  getSettingsPageLink,
} from 'utils/links';
import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';
import Button from 'components/shared/Button/Button';
import { ACCOUNTING_SYSTEMS, HOW_DID_YOU_FIND_US_DATA } from './variables';
import { Controller, useForm } from 'react-hook-form7';

type Inputs = {
  entityCurrency: ICurrency | null;
  accountingSystem?: string;
  howDidYouFindUs?: string;
};

interface OwnProps {
  firstName: string;
  lastName: string;
  companyCountry: string;
  companyType: RECIPIENT_TYPE;
  companyName: string;
  isAccountant: boolean;
  onNavItemClick: (stepNumber?: number) => void;
  onCloseHandler: () => void;
}

const StepFour: FC<OwnProps> = ({
  firstName,
  lastName,
  companyCountry,
  companyName,
  companyType,
  isAccountant,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const shouldIntegrate = false;
  const history = useHistory();

  const { userEmail } = useStoreState((state) => state.UserState);
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { currencies, currencyByCode } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { createUser } = useStoreActions(({ UserState }) => UserState);
  const { featureFlagById } = useStoreState(
    ({ FeatureFlagsState }) => FeatureFlagsState
  );

  const entityCurrencies = currencies.reduce<ISelectItem[]>((acc, value) => {
    if (value.canBeEntityCurrency) {
      acc.push({
        name: value.name,
        id: value.code,
        icon: value.countryCode,
        value: { ...value },
      });
    }

    return acc;
  }, []);

  const { handleSubmit, control } = useForm<Inputs>({
    defaultValues: {
      entityCurrency: currencyByCode(systemVariables?.defaultSellCurrency),
      accountingSystem: undefined,
      howDidYouFindUs: undefined,
    },
  });

  const onSubmit = async (values: Inputs) => {
    try {
      if (!userEmail || !values.entityCurrency) {
        return;
      }

      setIsLoading(true);

      const response = await createUser({
        email: userEmail,
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        companyCountry,
        companyType,
        companyName,
        isAccountant,
      });

      if (!response?.success) {
        Notify.error(response?.message ?? '');
        setIsLoading(false);
        return;
      }

      const res = await saveRegistrationSettings({
        entityCurrency: values.entityCurrency.code,
        accountingSystem: values.accountingSystem || 'none',
        howDidYouFindUs: values.howDidYouFindUs || 'none',
      });

      if (res.data.success) {
        if (shouldIntegrate) {
          // TODO: Once the integration flows have been updated, this should kick off the relevant integration
          history.push(getSettingsPageLink({ tab: 'integrations' }));
        } else if (isAccountant) {
          history.push(getMyClientsLink());
        } else {
          history.push(getDashboardPageLink());
        }
      } else {
        Notify.error(res.data?.message ?? '');
      }
    } catch (error: any) {
      Notify.error(
        'Sign up was unsuccessful. If the issue persists then please contact support (support@hedgeflows.com)'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const theme = useTheme();
  const isMultipleCurrenciesEnabled = featureFlagById(
    `multipleEntityCurrencies`
  );

  return (
    <SignUpWrap>
      <SignUpNav style={{ justifyContent: 'flex-start' }}>
        <StaleTitleH5>Let’s sign you up to HedgeFlows</StaleTitleH5>

        <StaleParagraphMedium>
          You are about 10 minutes away from having access to our best features:
        </StaleParagraphMedium>

        <StaleCarousel />
      </SignUpNav>

      <SignUpContent className="rounded">
        <ButtonStyleLink className="cross" onClick={() => history.push('/')}>
          <Icon icon="cross-ico" />
        </ButtonStyleLink>
        <Block>
          <Title variant="h1" mb>
            Few more questions
          </Title>

          <form id="more-questions-form" onSubmit={handleSubmit(onSubmit)}>
            <Col mb gap={theme.spacing.m}>
              <Field fluid>
                <Controller
                  control={control}
                  name="entityCurrency"
                  render={({ field: { name, onChange, value } }) => {
                    return (
                      <StaleInputSelect
                        id={name}
                        label="What is your main currency?"
                        view="moving"
                        data={entityCurrencies}
                        selected={value}
                        onSelect={(item) => onChange(item.value)}
                        autoFocus
                        strategy="fixed"
                        style={{
                          flex: 1,
                        }}
                        disabled={!isMultipleCurrenciesEnabled}
                      />
                    );
                  }}
                />
              </Field>
              <Field fluid>
                <Controller
                  control={control}
                  name="accountingSystem"
                  render={({ field: { name, onChange, value } }) => {
                    return (
                      <StaleInputSelect
                        id={name}
                        label="What accounting system do you use?"
                        view="moving"
                        data={ACCOUNTING_SYSTEMS}
                        selected={ACCOUNTING_SYSTEMS.find(
                          (accountingSystem) => accountingSystem.value === value
                        )}
                        onSelect={(item) => onChange(item.value)}
                        strategy="fixed"
                        style={{
                          flex: 1,
                        }}
                      />
                    );
                  }}
                />
              </Field>
              <Field fluid>
                <Controller
                  control={control}
                  name="howDidYouFindUs"
                  render={({ field: { name, onChange, value } }) => {
                    return (
                      <StaleInputSelect
                        id={name}
                        label="How did you find out about HedgeFlows?"
                        view="moving"
                        data={HOW_DID_YOU_FIND_US_DATA}
                        selected={HOW_DID_YOU_FIND_US_DATA.find(
                          (howDidYouFindUs) => howDidYouFindUs.value === value
                        )}
                        onSelect={(item) => onChange(item.value)}
                        strategy="fixed"
                        style={{
                          flex: 1,
                        }}
                      />
                    );
                  }}
                />
              </Field>
            </Col>
          </form>

          <Button
            form="more-questions-form"
            disabled={isLoading}
            isLoading={isLoading}
            mt
            mtValue={theme.spacing.l}
            mb
            mbValue={theme.spacing.l}
          >
            Continue
          </Button>
        </Block>
      </SignUpContent>
    </SignUpWrap>
  );
};

export default StepFour;
