import { ENTITY_TYPE } from "types";

export const BUSINESS_TYPES = [
  {
    id: 'soleTrader',
    name: 'Sole Trader',
    value: ENTITY_TYPE.soleTrader,
  },
  {
    id: 'limitedCompany',
    name: 'Limited Company (LTD)',
    value: ENTITY_TYPE.limitedCompany,
  },
  {
    id: 'simplePartnership',
    name: 'Simple Partnership (coming soon)',
    value: ENTITY_TYPE.simplePartnership,
    disabled: true,
  },
  {
    id: 'limitedLiabilityPartnership',
    name: 'Limited Liability Partnership (LLP) (coming soon)',
    value: ENTITY_TYPE.limitedLiabilityPartnership,
    disabled: true,
  },
  {
    id: 'PLC',
    name: 'PLC (coming soon)',
    value: ENTITY_TYPE.publicLimitedCompany,
    disabled: true,
  },
];
