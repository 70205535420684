import styled, { CSSProperties } from 'styled-components';

type TStyledFormProps = Pick<
  CSSProperties,
  'flexDirection' | 'justifyContent' | 'alignItems'
>;

export const StyledForm = styled.form<TStyledFormProps>`
  display: flex;
  flex-direction: ${({ flexDirection = 'column' }) => flexDirection};
  justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
  align-items: ${({ alignItems = 'flex-start' }) => alignItems};
`;
