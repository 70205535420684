import dayjs from 'dayjs';
import { useStoreState } from 'state';
import { IInvoice } from 'types';
import {
  isInvoiceDisabled,
  isInvoiceStatusInPayableState,
  isPayableInvoice,
  isReceivableInvoice,
  isInvoicePrebookable,
  isInvoiceHasValidContact,
  isInvoiceSubmittableForReview,
  isInvoiceApprovable,
  isApprovalFlowAllowToPayInvoice,
  isInvoiceApprovableByUser,
} from 'utils/invoices';

interface UseInvoiceRecordParams {
  record: IInvoice;
}

const useInvoiceRecord = ({ record }: UseInvoiceRecordParams) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const {
    hasApprovalFlow,
    isUserApprover,
    isUserSubmitter,
    entityCurrencyCode,
    userId,
  } = useStoreState((state) => state.UserState);

  const currency = currencyByCode(record.currency);
  const isDisabled = isInvoiceDisabled(record);
  const isSameCurrency = record.currency === entityCurrencyCode;
  const daysUntilDueDate = dayjs(record.dueDate).diff(dayjs(), 'days');
  const isPayable = isPayableInvoice(record);
  const isReceivable = isReceivableInvoice(record);
  const isCanBePaid =
    isInvoiceStatusInPayableState(record) &&
    isApprovalFlowAllowToPayInvoice({
      hasApprovalFlow,
      isUserApprover,
      record,
    });
  const isPrebookable = isInvoicePrebookable(record);
  const hasTracking = !!record.trackingId;
  const hasPrebook = !!record.contractId;
  const hasValidContact = isInvoiceHasValidContact(record);
  const submittableForReview =
    hasApprovalFlow && isUserSubmitter && isInvoiceSubmittableForReview(record);
  const isApprovable =
    hasApprovalFlow && isUserApprover && isInvoiceApprovable(record);
  const isApprovableByCurrentUser =
    isApprovable && isInvoiceApprovableByUser(record, userId);
  const isInvoiceInEntityCurrency = record.currency === entityCurrencyCode;

  return {
    currency,
    isDisabled: !!isDisabled,
    isSameCurrency,
    daysUntilDueDate,
    isPayable,
    isReceivable,
    hasTracking,
    hasPrebook,
    hasValidContact,
    isCanBePaid,
    isPrebookable,
    submittableForReview,
    isApprovable,
    isApprovableByCurrentUser,
    isInvoiceInEntityCurrency,
  };
};

export default useInvoiceRecord;
