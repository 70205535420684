import {
  CashflowsRisksData,
  ICurrency,
  IRiskDashboardItem,
  IRiskDashboardItemPerMonth,
  IRiskDashboardItemPerMonthWithDate,
  IRiskDashboardItemPerMonthWithDateAndPeriod,
} from 'types';
import dayjs from 'dayjs';
import { get, groupBy, orderBy } from 'lodash';
import {
  DB_MONTH_FORMAT,
  MONTH_DATE_FORMAT,
  QUARTER_DATE_FORMAT,
} from 'variables';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {
  getQuarterFromQuarterDateString,
  getYearFromQuarterDateString,
} from 'utils/dates';
import { THedgeRatiosFilledData } from 'pages/Risks/components/MonthlyBreakdown/components/Charts/utils';

dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);

// TODO: take this info from the backend when we have it
export const getRiskLevelRatingText = (riskLevel?: number) => {
  if (typeof riskLevel === 'undefined') {
    return '';
  }

  if (riskLevel <= 1) {
    return 'VERY LOW';
  } else if (riskLevel === 2) {
    return 'LOW';
  } else if (riskLevel === 3) {
    return 'AVERAGE';
  } else if (riskLevel === 4) {
    return 'HIGH';
  } else {
    return 'VERY HIGH';
  }
};

export const getCashflowsPerCurrencyValues = (
  cashflowsPerCurrencyValues?: IRiskDashboardItem
) => ({
  netExposure: get(cashflowsPerCurrencyValues, 'netExposure', 0),
  netExposureInSellCurrency: get(
    cashflowsPerCurrencyValues,
    'netExposureInSellCurrency',
    0
  ),
  payables: get(cashflowsPerCurrencyValues, 'payables', 0),
  receivables: get(cashflowsPerCurrencyValues, 'receivables', 0),
  prebooked: get(cashflowsPerCurrencyValues, 'prebooked', 0),
  balances: get(cashflowsPerCurrencyValues, 'balance', 0),
  externalBalances: get(cashflowsPerCurrencyValues, 'externalBalance', 0),
  externalHedges: get(cashflowsPerCurrencyValues, 'externalHedges', 0),
  saleOrders: get(cashflowsPerCurrencyValues, 'saleOrders', 0),
  purchaseOrders: get(cashflowsPerCurrencyValues, 'purchaseOrders', 0),
});

interface IDetectCurrenciesFromCashflowsParams {
  currencies: ICurrency[];
  cashflowsRisks: CashflowsRisksData | null;
  entityCurrencyCode?: string | null;
}

export const detectCurrenciesFromCashflows = ({
  currencies,
  cashflowsRisks,
  entityCurrencyCode,
}: IDetectCurrenciesFromCashflowsParams) =>
  cashflowsRisks
    ? Object.keys(cashflowsRisks.currencyRisk)
        .sort()
        .filter(
          (currencyCode) =>
            currencyCode !== entityCurrencyCode &&
            currencies.some(
              (currency) => currency.code === currencyCode && currency.enabled
            )
        )
    : [];

export const formatCashflowsAtRiskValuesPerMonth = (
  rawValues: IRiskDashboardItem['perMonth']
) => {
  const rawValuesWithMonth = Object.entries(rawValues).reduce<
    IRiskDashboardItemPerMonthWithDate[]
  >((acc, [key, value]) => {
    const dateForRecord = dayjs(key, DB_MONTH_FORMAT);
    const today = dayjs();
    // Anything before today's month should be aggregated to today's month's numbers
    const dateToUse = dateForRecord.isBefore(today) ? today : dateForRecord;
    const date = dateToUse.format(MONTH_DATE_FORMAT);
    const currentAcc = acc.find((item) => item.date === date);
    // If today's month already has values in the accumulator then add to that object
    if (currentAcc) {
      currentAcc.externalHedges += value.externalHedges;
      currentAcc.netExposure += value.netExposure;
      currentAcc.netExposureInSellCurrency += value.netExposureInSellCurrency;
      currentAcc.payables += value.payables;
      currentAcc.prebooked += value.prebooked;
      currentAcc.purchaseOrders += value.purchaseOrders;
      currentAcc.receivables += value.receivables;
      currentAcc.saleOrders += value.saleOrders;
      return acc;
    }
    return [
      ...acc,
      {
        ...value,
        date,
      },
    ];
  }, []);

  return orderBy(rawValuesWithMonth, ({ date }) =>
    dayjs(date, MONTH_DATE_FORMAT)
  );
};

export const fillValuesForGivenPeriodWith = <
  T extends { date: string; periodSum?: number }
>({
  existingValues,
  period = 12,
  periodType,
  fillWith,
}: {
  existingValues: T[];
  period?: number;
  periodType: 'month' | 'quarter';
  fillWith: (date: string, prevValue?: T) => T;
}) => {
  const today = dayjs();
  const nextTwelvePeriods = Array.from({ length: period }, (_, i) =>
    today
      .add(i, periodType)
      .format(periodType === 'month' ? MONTH_DATE_FORMAT : QUARTER_DATE_FORMAT)
  );

  const valuesPerPeriodWithEmptyValues = nextTwelvePeriods.reduce<T[]>(
    (acc, month) => {
      const monthValue = existingValues.find(({ date }) => date === month) as T;
      const lastValues = { ...existingValues[existingValues.length - 1] };
      const fillWithValuesForMonth = fillWith(month, acc[acc.length - 1]);
      // TODO: revisit this as function became very specific to trends chart, while it was supposed to be generic
      const monthValueBeforeFirstValue = dayjs(
        month,
        MONTH_DATE_FORMAT
      ).isBefore(dayjs(existingValues[0]?.date, MONTH_DATE_FORMAT));
      // All values should be zero, but when working with charts then y and sum
      // needs to stay constant based on the last period value.
      const fillWithValues =
        lastValues.periodSum && !monthValueBeforeFirstValue
          ? {
              ...fillWithValuesForMonth,
              y: lastValues.periodSum,
              periodSum: lastValues.periodSum,
            }
          : fillWithValuesForMonth;

      return [...acc, monthValue ? monthValue : fillWithValues];
    },
    []
  );

  return valuesPerPeriodWithEmptyValues;
};

const summarizeMonthlyCashflowsAtRiskValues = (
  riskDashboardItemsPerMonth: IRiskDashboardItemPerMonth[]
) =>
  riskDashboardItemsPerMonth.reduce<IRiskDashboardItemPerMonth>(
    (acc, value) => {
      const {
        payables,
        netExposure,
        receivables,
        purchaseOrders,
        saleOrders,
        prebooked,
        externalHedges,
        netExposureInSellCurrency,
      } = acc;

      const {
        payables: valuePayables,
        netExposure: valueNetExposure,
        receivables: valueReceivables,
        purchaseOrders: valuePurchaseOrders,
        saleOrders: valueSaleOrders,
        prebooked: valuePrebooked,
        externalHedges: valueExternalHedges,
        netExposureInSellCurrency: valueNetExposureInSellCurrency,
      } = value;

      return {
        netExposure: netExposure + valueNetExposure,
        payables: payables + valuePayables,
        receivables: receivables + valueReceivables,
        purchaseOrders: purchaseOrders + valuePurchaseOrders,
        saleOrders: saleOrders + valueSaleOrders,
        prebooked: prebooked + valuePrebooked,
        externalHedges: externalHedges + valueExternalHedges,
        netExposureInSellCurrency:
          netExposureInSellCurrency + valueNetExposureInSellCurrency,
      };
    },
    {
      netExposure: 0,
      payables: 0,
      receivables: 0,
      purchaseOrders: 0,
      saleOrders: 0,
      prebooked: 0,
      externalHedges: 0,
      netExposureInSellCurrency: 0,
    }
  );

export const formatCashflowsAtRiskValuesPerQuarter = (
  monthlyValues: {
    date: string;
    netExposure: number;
    payables: number;
    receivables: number;
    prebooked: number;
    externalHedges: number;
    saleOrders: number;
    purchaseOrders: number;
    netExposureInSellCurrency: number;
    periodSum?: number;
  }[]
) => {
  const monthlyValuesByQuarter = groupBy(monthlyValues, ({ date }) =>
    dayjs(date, MONTH_DATE_FORMAT).format(QUARTER_DATE_FORMAT)
  );

  return Object.entries(monthlyValuesByQuarter).reduce<
    IRiskDashboardItemPerMonthWithDate[]
  >((acc, [_, value]) => {
    const summarizedValues = summarizeMonthlyCashflowsAtRiskValues(value);

    return [
      ...acc,
      {
        ...summarizedValues,
        date: dayjs(value[0].date, MONTH_DATE_FORMAT).format(
          QUARTER_DATE_FORMAT
        ),
      },
    ];
  }, []);
};

export const formatValuesForTrendsLineChart = (
  rawValues: IRiskDashboardItem,
  isQuarterFormat = false
) => {
  const { buyCurrency, sellCurrency, perMonth } = rawValues;
  let formattedValues = formatCashflowsAtRiskValuesPerMonth(perMonth);

  if (isQuarterFormat) {
    formattedValues = formatCashflowsAtRiskValuesPerQuarter(formattedValues);
  }

  const formattedValuesForLineChart = formattedValues.reduce<
    Array<
      IRiskDashboardItemPerMonthWithDateAndPeriod & {
        x: string;
        y: number;
        buyCurrency: string;
        sellCurrency: string;
      }
    >
  >((acc, value, index) => {
    const { netExposure, date } = value;

    let formattedDate = dayjs(date, MONTH_DATE_FORMAT);

    if (isQuarterFormat) {
      const quarter = getQuarterFromQuarterDateString(date);
      const year = getYearFromQuarterDateString(date);

      formattedDate = dayjs(`Q${quarter} ${year}`, QUARTER_DATE_FORMAT);
    }

    const today = dayjs();
    let isDateSameOrBeforeToday = formattedDate.isSameOrBefore(today, 'month');

    if (isQuarterFormat) {
      const quarter = Number(getQuarterFromQuarterDateString(date));
      const year = Number(getYearFromQuarterDateString(date));

      if (year < today.year()) {
        isDateSameOrBeforeToday = true;
      }

      if (year === today.year()) {
        isDateSameOrBeforeToday = quarter <= today.quarter();
      }
    }

    if (index === 0) {
      return [
        {
          ...value,
          x: date,
          y: netExposure,
          buyCurrency,
          sellCurrency,
          periodSum: netExposure,
        },
      ];
    }

    if (isDateSameOrBeforeToday) {
      const currentValue = acc[0];

      const periodValue = {
        date: value.date,
        x: value.date,
        buyCurrency,
        sellCurrency,
        y: currentValue.y + netExposure,
        periodSum: currentValue.periodSum + netExposure,
        externalHedges: currentValue.externalHedges + value.externalHedges,
        netExposure: currentValue.netExposure + value.netExposure,
        netExposureInSellCurrency:
          currentValue.netExposureInSellCurrency +
          value.netExposureInSellCurrency,
        payables: currentValue.payables + value.payables,
        prebooked: currentValue.prebooked + value.prebooked,
        purchaseOrders: currentValue.purchaseOrders + value.purchaseOrders,
        receivables: currentValue.receivables + value.receivables,
        saleOrders: currentValue.saleOrders + value.saleOrders,
      };

      return [periodValue];
    }

    // we keep adding to the first value until we reach today, so index - 1 is not always there, it could be 5 - 1 = 4 while we have only arr[0]
    const previousValue = acc[index - 1] ?? acc[0];
    const periodSum = previousValue.periodSum + netExposure;
    const periodValue = {
      ...value,
      periodSum,
      x: date,
      y: periodSum,
      buyCurrency,
      sellCurrency,
    };

    return [...acc, periodValue];
  }, []);

  return {
    id: buyCurrency,
    data: formattedValuesForLineChart,
  };
};

export const formatValuesForHedgeRatioLineChart = (
  rawValues: IRiskDashboardItem,
  isQuarterFormat = false
) => {
  const { buyCurrency, sellCurrency, perMonth } = rawValues;
  let formattedValues = formatCashflowsAtRiskValuesPerMonth(perMonth);

  if (isQuarterFormat) {
    formattedValues = formatCashflowsAtRiskValuesPerQuarter(formattedValues);
  }

  const formattedValuesForLineChart = formattedValues.reduce<
    Array<{
      date: string;
      x: string;
      y: number;
      buyCurrency?: string;
      sellCurrency?: string;
    }>
  >((acc, value) => {
    const { externalHedges, prebooked, netExposure, date } = value;

    const hedgeRatio =
      ((externalHedges + prebooked) /
        (netExposure - prebooked - externalHedges)) *
      100 *
      -1;

    const result = {
      date,
      x: date,
      y: !Number.isFinite(hedgeRatio) ? 0 : hedgeRatio,
      buyCurrency,
      sellCurrency,
    };

    return [...acc, result];
  }, []);

  return {
    id: buyCurrency,
    data: formattedValuesForLineChart,
  };
};

interface IHedgeRatioLineChartDataItem {
  date: string;
  x: string;
  y: number;
}

export const hedgeRatiosToHedgeRatioLineChartData = ({
  hedgeRatios,
  isQuarterFormat = false,
}: {
  hedgeRatios?: THedgeRatiosFilledData;
  isQuarterFormat: boolean;
}): {
  minValues: Array<IHedgeRatioLineChartDataItem>;
  maxValues: Array<IHedgeRatioLineChartDataItem>;
} => {
  if (!hedgeRatios) {
    return {
      minValues: [],
      maxValues: [],
    };
  }

  const currentPeriod = isQuarterFormat
    ? dayjs().quarter()
    : dayjs().month() + 1;

  const sortedHedgeRatios = Object.entries(hedgeRatios ?? {}).sort(
    (a, b) => Number(a[0]) - Number(b[0])
  );

  const getFormattedDate = (index: number): string => {
    const date = isQuarterFormat
      ? dayjs().quarter(currentPeriod + index)
      : dayjs().month(currentPeriod - 1 + index);

    return isQuarterFormat
      ? `Q${date.quarter()} ${date.year()}`
      : date.format(MONTH_DATE_FORMAT);
  };

  const createDataPoint = (
    date: string,
    value: number
  ): IHedgeRatioLineChartDataItem => ({
    date,
    x: date,
    y: value,
  });

  return sortedHedgeRatios.reduce<{
    minValues: Array<IHedgeRatioLineChartDataItem>;
    maxValues: Array<IHedgeRatioLineChartDataItem>;
  }>(
    (acc, [_, values], index) => {
      const date = getFormattedDate(index);
      acc.minValues.push(createDataPoint(date, values.min));
      acc.maxValues.push(createDataPoint(date, values.max));

      return acc;
    },
    {
      minValues: [],
      maxValues: [],
    }
  );
};
