import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Title, Col, Row } from 'components';
import { useStoreState } from 'state';
import useUrlValues from 'hooks/useUrlValues';
import Popup from 'components/shared/Popup/Popup';
import { StepperItem } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import Button from '../Button/Button';
import TransferDetails from './components/TransferDetails/TransferDetails';
import RecipientDetails from './components/RecipientDetails/RecipientDetails';
import MT103Details from './components/MT103Details/MT103Details';
import { InnerDetailsWrapper } from '../InnerDetails/InnerDetails.styles';
import PaymentCredentials from '../PaymentCredentials/PaymentCredentials';
import useTransferOrBulkPaymentInner from 'hooks/useTransferOrBulkPaymentInner';
import TransferOrBulkPaymentInnerInvoices from '../TransferOrBulkPaymentInnerInvoices/TransferOrBulkPaymentInnerInvoices';

const TransferInner: FC = () => {
  const theme = useTheme();
  const { transferId, setUrlValue } = useUrlValues('transferId');
  const { transferById } = useStoreState((state) => state.TransfersState);
  const selectedTransfer = transferById(transferId);
  const {
    contentType,
    setContentType,
    relatedInvoices,
    tabs,
  } = useTransferOrBulkPaymentInner({
    selectedTransferOrBulkPayment: selectedTransfer,
  });

  const onClose = () => setUrlValue({ transferId: '' });

  if (!selectedTransfer) {
    return null;
  }

  return (
    <Popup
      HeaderContent={<Title variant="h3">Transfer</Title>}
      FooterContent={<Button onClick={onClose}>Close</Button>}
      width="900px"
      height="800px"
      onClose={onClose}
    >
      <Col rowGap={theme.spacing.xl}>
        <InnerDetailsWrapper>
          <TransferDetails transfer={selectedTransfer} />
          <RecipientDetails transfer={selectedTransfer} />
        </InnerDetailsWrapper>

        <Row justifyContent="flex-start">
          {tabs.map(({ title, id }) => (
            <StepperItem
              onClick={() => setContentType(id)}
              key={id}
              current={id === contentType}
            >
              {title}
            </StepperItem>
          ))}
        </Row>

        {contentType === 'invoices' && (
          <TransferOrBulkPaymentInnerInvoices
            selectedTransfer={selectedTransfer}
            relatedInvoices={relatedInvoices}
          />
        )}

        {contentType === 'payment' && (
          <PaymentCredentials transfer={selectedTransfer} />
        )}

        {contentType === 'mt103' && (
          <MT103Details transfer={selectedTransfer} />
        )}
      </Col>
    </Popup>
  );
};

export default TransferInner;
