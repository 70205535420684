import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { Wrapper } from './StaleSidebar.styles';
import { useStoreState } from 'state';
import {
  getCurrencyExchangePageLink,
  getInvoicesPageLink,
  getLink,
  getMyClientsLink,
  getRisksPageLink,
} from 'utils/links';
import { Icon } from 'components/shared';
import PermissionsChecker from 'components/PermissionsChecker/PermissionsChecker';
import {
  useCreatePrebooksPermissionsCheck,
  useCreateTransfersPermissionsCheck,
} from 'hooks/useSpecificPermissionsCheck';

interface OwnProps {
  hideIconLabels?: boolean;
}

const StaleSidebar: FC<OwnProps> = ({ hideIconLabels }) => {
  const { pathname } = useLocation();
  const {
    showReports,
    isAccountant,
    isFxManagementPackageEnabled,
  } = useStoreState((state) => state.UserState);
  const { featureFlagById } = useStoreState((state) => state.FeatureFlagsState);

  const hasCreatePrebooksPermission = useCreatePrebooksPermissionsCheck();
  const hasCreateTransfersPermission = useCreateTransfersPermissionsCheck();

  return (
    <Wrapper isIconsOnly={!hideIconLabels}>
      <a
        href="https://www.hedgeflows.com"
        rel="noreferrer"
        target="_blank"
        className="logo"
      >
        <img src="/assets/imgs/logo.png" alt="Logo" />
        HedgeFlows
      </a>

      <div className="menu-wrap">
        <div className="menu">
          {isAccountant && (
            <Link
              to={getMyClientsLink()}
              title={!hideIconLabels ? 'My Clients' : ''}
              className={cx(
                'link',
                pathname.includes('my-clients') && 'active'
              )}
            >
              <Icon icon="briefcase-ico" />
              My Clients
            </Link>
          )}
          <Link
            to="/app/dashboard"
            title={!hideIconLabels ? 'Dashboard' : ''}
            className={cx('link', pathname.includes('dashboard') && 'active')}
          >
            <Icon icon="dashboard-ico" />
            Dashboard
          </Link>
          {!isAccountant && (
            <>
              <PermissionsChecker action="update" resource="balances">
                <Link
                  to={getCurrencyExchangePageLink({ step: '1' })}
                  title={!hideIconLabels ? 'Exchange' : ''}
                  className={cx(
                    'link',
                    pathname.includes('currency-exchange') && 'active'
                  )}
                >
                  <Icon icon="prebook-filled" />
                  Exchange
                </Link>
              </PermissionsChecker>
              {hasCreatePrebooksPermission && (
                <Link
                  to={getLink('/app/prebook')}
                  title={!hideIconLabels ? 'Prebook' : ''}
                  className={cx(
                    'link',
                    pathname.includes('prebook') && 'active'
                  )}
                >
                  <Icon icon="lock-filled-ico" />
                  Prebook
                </Link>
              )}

              {hasCreateTransfersPermission && (
                <Link
                  to={getLink('/app/transfers')}
                  title={!hideIconLabels ? 'Transfer' : ''}
                  className={cx(
                    'link',
                    pathname.includes('transfers') && 'active'
                  )}
                >
                  <Icon icon="transfer-ico" />
                  Transfer
                </Link>
              )}
            </>
          )}

          {isFxManagementPackageEnabled && (
            <Link
              to={getRisksPageLink()}
              title={!hideIconLabels ? 'Risks' : ''}
              className={cx('link', pathname.includes('risks') && 'active')}
            >
              <Icon icon="risks-ico" />
              Risks
            </Link>
          )}

          <Link
            to={getInvoicesPageLink({
              currency: 'all',
              tab: 'outstanding',
            })}
            title={!hideIconLabels ? 'Invoices' : ''}
            className={cx('link', pathname.includes('invoices') && 'active')}
          >
            <Icon icon="invoices" />
            Invoices
          </Link>

          {featureFlagById('backtester') && showReports && (
            <Link
              to="/app/reports"
              title={!hideIconLabels ? 'Reports' : ''}
              className={cx('link', pathname.includes('reports') && 'active')}
            >
              <Icon icon="reports" />
              Reports
            </Link>
          )}
          {!isAccountant && (
            <Link
              to="/app/contacts"
              title={!hideIconLabels ? 'Contacts' : ''}
              className={cx('link', pathname.includes('contacts') && 'active')}
            >
              <Icon icon="users-group" />
              Contacts
            </Link>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default StaleSidebar;
