import { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useStoreActions, useStoreState } from 'state';
import { Firebase } from 'services';
import { IClient } from 'state/user';
import { Notify } from 'utils';
import { getDashboardPageLink } from 'utils/links';
import { checkIfClientsFulfilled } from 'pages/MyClients/utils';

const useSwitchClients = () => {
  const history = useHistory();
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [clients, setClients] = useState<Array<IClient>>([]);
  const { userId } = useStoreState(({ UserState }) => UserState);
  const { getUser } = useStoreActions(({ UserState }) => UserState);

  const onSwitchAccount = useMemo(
    () => async (id: string) => {
      const switchUserAccount = async () => {
        try {
          if (userId) {
            await Firebase.updateUser({
              user: {
                entityId: id,
              },
            });

            await getUser({ id: userId });
            history.push(getDashboardPageLink());
            // force reload to ensure clean redux state
            window.location.reload();
          }
        } catch (error: any) {
          console.error(error);
          Notify.error(`Error switching account ${error.message}`);
        }
      };

      switchUserAccount();
    },
    [getUser, userId, history]
  );

  useEffect(() => {
    const getUserEntities = async () => {
      if (!userId) {
        return;
      }

      try {
        setIsLoadingClients(true);
        const userEntities = await Firebase.getUserEntities();

        if (!!userEntities?.entities && Array.isArray(userEntities.entities)) {
          const responses = await Promise.allSettled(
            userEntities.entities.map(async (entity) => {
              const data = await Firebase.getUserEntitiesSummary({
                entityId: entity.id,
              });

              if (data) {
                return {
                  ...entity,
                  ...data,
                };
              }
              return undefined;
            })
          );

          const clientsResponse = responses
            .filter(checkIfClientsFulfilled)
            .map((el) => el.value);

          setClients(clientsResponse);
        }
      } catch (error: any) {
        Notify.error(error.message);
      } finally {
        setIsLoadingClients(false);
      }
    };

    getUserEntities();
  }, [userId]);

  return {
    clients,
    isLoadingClients,
    onSwitchAccount,
  };
};

export default useSwitchClients;
