import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Title, TransferTable, Col, Row } from 'components';
import { useStoreState } from 'state';
import useUrlValues from 'hooks/useUrlValues';
import Popup from 'components/shared/Popup/Popup';
import { StepperItem } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import Button from '../Button/Button';
import BulkPaymentDetails from './components/BulkPaymentDetails/BulkPaymentDetails';
import PaymentCredentials from '../PaymentCredentials/PaymentCredentials';
import { OverflowXWrapper } from '../OverflowXWrapper/OverflowXWrapper.styles';
import useTransferOrBulkPaymentInner from 'hooks/useTransferOrBulkPaymentInner';
import TransferOrBulkPaymentInnerInvoices from '../TransferOrBulkPaymentInnerInvoices/TransferOrBulkPaymentInnerInvoices';

const BulkPaymentInner: FC = () => {
  const theme = useTheme();
  const { bulkPaymentId, setUrlValue } = useUrlValues('bulkPaymentId');
  const { bulkPaymentById } = useStoreState((state) => state.BulkPaymentsState);
  const selectedBulkPayment = bulkPaymentById(bulkPaymentId);
  const {
    tabs,
    contentType,
    setContentType,
    relatedInvoices,
    relatedTransfers,
  } = useTransferOrBulkPaymentInner({
    selectedTransferOrBulkPayment: selectedBulkPayment,
  });

  const onClose = () => setUrlValue({ bulkPaymentId: '' });

  if (!selectedBulkPayment) {
    return null;
  }

  return (
    <Popup
      HeaderContent={<Title variant="h3">Bulk payment</Title>}
      FooterContent={<Button onClick={onClose}>Close</Button>}
      width="900px"
      height="800px"
      onClose={onClose}
    >
      <Col rowGap={theme.spacing.xl}>
        <BulkPaymentDetails transfer={selectedBulkPayment} />

        <Row justifyContent="flex-start">
          {tabs.map(({ title, id }) => (
            <StepperItem
              onClick={() => setContentType(id)}
              key={id}
              current={id === contentType}
            >
              {title}
            </StepperItem>
          ))}
        </Row>

        {contentType === 'transfers' && (
          <Col>
            <Title mb variant="h5">
              Transfers <span>{relatedTransfers?.length}</span>
            </Title>
            <OverflowXWrapper>
              <TransferTable data={relatedTransfers} />
            </OverflowXWrapper>
          </Col>
        )}

        {contentType === 'invoices' && (
          <TransferOrBulkPaymentInnerInvoices
            selectedTransfer={selectedBulkPayment}
            relatedInvoices={relatedInvoices}
          />
        )}

        {contentType === 'payment' && (
          <PaymentCredentials transfer={selectedBulkPayment} />
        )}
      </Col>
    </Popup>
  );
};

export default BulkPaymentInner;
