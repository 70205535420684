import ChartBar from 'components/shared/ChartBar/ChartBar';
import { FC } from 'react';
import { ICurrency, IRiskDashboardItemPerMonthWithDate } from 'types';
import { parseIntoShortNumberString, roundToPrecision } from 'utils';
import ChartContributorsLegendsLayer from './components/ChartContributorsLegendsLayer/ChartContributorsLegendsLayer';
import ChartContributorsChartBar from './components/ChartContributorsChartBar/ChartContributorsChartBar';
import ChartContributorsChartLabel from './components/ChartContributorsChartLabel/ChartContributorsChartLabel';

interface OwnProps {
  data: Array<
    IRiskDashboardItemPerMonthWithDate & { [key: string]: number | string }
  >;
  keys?: string[];
  currencySymbol?: ICurrency['symbol'];
  /**
   * Date in format MMM YYYY or [Q]Q YYYY
   */
  activeDate: string | null;
  onChartBarClick: (period: string, periodIndex: number) => void;
}

const ChartContributors: FC<OwnProps> = ({
  data,
  keys = [
    'payables',
    'receivables',
    'prebookedAndHedged',
    'purchaseOrders',
    'saleOrders',
  ],
  activeDate,
  onChartBarClick,
}) => {
  const maxValue = data.reduce((acc, item) => {
    const itemValues = Object.values(item).filter(
      (val) => typeof val !== 'string'
    ) as number[];

    return Math.max(acc, ...itemValues);
  }, 0);
  const minValue = data.reduce((acc, item) => {
    const itemValues = Object.values({
      // only this value can be negative
      prebookedAndHedged: item.prebooked + item.externalHedges,
    }).filter((val) => typeof val !== 'string') as number[];

    return Math.min(acc, ...itemValues);
  }, 0);

  const horizontalData = [
    0,
    roundToPrecision(maxValue / 2, 0),
    roundToPrecision(maxValue, 0),
  ];

  return (
    <ChartBar
      data={data}
      keys={keys}
      indexBy="date"
      margin={{
        right: 20,
        bottom: 70,
        top: 20,
        left: 54,
      }}
      padding={0.3}
      innerPadding={10}
      groupMode="grouped"
      axisTop={null}
      axisRight={null}
      enableGridX={false}
      maxValue={maxValue}
      minValue={minValue}
      gridYValues={horizontalData}
      axisLeft={{
        tickPadding: 12,
        tickSize: 0,
        tickValues: horizontalData,
        format: parseIntoShortNumberString,
      }}
      axisBottom={{
        tickPadding: 16,
        tickSize: 0,
        format: (t) => (
          <ChartContributorsChartLabel data={t} variant="bottom" />
        ),
      }}
      barComponent={(data) => (
        <ChartContributorsChartBar
          data={data}
          onClick={onChartBarClick}
          activeDate={activeDate}
        />
      )}
      layers={[
        'grid',
        'axes',
        'bars',
        // @ts-expect-error TS(2322) FIXME: Type 'PropsWithChildren<BarCustomLayerProps<IRiskD... Remove this comment to see the full error message
        (layerData) => <ChartContributorsLegendsLayer data={layerData} />,
        'markers',
        'legends',
        'annotations',
      ]}
    />
  );
};

export default ChartContributors;
