import { scaleLinear } from 'd3-scale';
import dayjs from 'dayjs';
import {
  getQuarterFromQuarterDateString,
  getYearFromQuarterDateString,
} from 'utils/dates';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);

export const getQuarterDateToUse = (dateString: string) => {
  const quarter = getQuarterFromQuarterDateString(dateString);
  const year = getYearFromQuarterDateString(dateString);

  return dayjs().set('year', Number(year)).quarter(Number(quarter));
};

export const getScaleX = ({
  minDate,
  maxDate,
  width,
}: {
  minDate: number;
  maxDate: number;
  width: number;
}) => {
  return scaleLinear().domain([minDate, maxDate]).range([0, width]);
};

export const getScaleY = ({
  height,
  minValue,
  maxValue,
}: {
  height: number;
  minValue: number;
  maxValue: number;
}) => {
  return scaleLinear().domain([minValue, maxValue]).range([height, 0]);
};
