import styled, { css } from 'styled-components';

const link = ({ theme }: any) => css`
  font-weight: 500;
  display: flex;
  align-items: center;
  color: ${theme.color.dark};
  transition: ${theme.transition};

  svg {
    height: 24px;
    width: 24px;
    margin: 0 4px;
    transition: fill 0.4s;
    fill: ${theme.color.dark};
  }

  &:hover {
    color: ${theme.color.greyDark};

    svg {
      fill: ${theme.color.greyDark};
    }
  }

  &:focus,
  &:active {
    color: ${theme.color.emeraldDark};
    border-color: ${theme.color.emeraldDark};

    svg {
      fill: ${theme.color.emeraldDark};
    }
  }

  &.disabled,
  &[disabled] {
    color: ${theme.color.grey};

    svg {
      fill: ${theme.color.grey};
    }
  }

  .btns-group & {
    justify-content: center;
  }
`;

export const ButtonStyleLink = styled.button`
  ${link}
`;
