import { FC, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import firebase from 'firebase/app';

import {
  StaleBtnGroup,
  StaleParagraphMedium,
  StaleInput,
  StaleInputPhone,
  Title,
  Row,
  StaleContainer,
} from 'components';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { useRecaptchaVerifier } from 'hooks';
import { useStoreActions, useStoreState } from 'state';
import { ICountry } from 'types';
import { Notify } from 'utils';
import { Content, Heading, Wrapper, LogOutButton } from './MFASetup.styles';
import auth from 'services/auth';
import { ERROR_MESSAGES } from 'variables';
import Button from 'components/shared/Button/Button';

type Inputs = {
  phoneNumber: string;
  password: string;
  verificationCode: string;
};

const sendVerificationCode = async (
  phoneNumber: string,
  recaptchaVerifier: any
) => {
  try {
    const multiFactorSession = await auth.currentUser?.multiFactor.getSession();

    const phoneInfoOptions = {
      phoneNumber,
      session: multiFactorSession,
    };

    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

    return phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier
    );
  } catch (error: any) {
    Notify.error(error.message);
  }
};

const verifyCode = async (verificationId: string, verificationCode: string) => {
  // Ask user for the verification code.
  const cred = firebase.auth.PhoneAuthProvider.credential(
    verificationId,
    verificationCode
  );

  const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
    cred
  );

  // Complete enrollment.
  return auth.currentUser?.multiFactor
    .enroll(multiFactorAssertion, 'My phone number')
    .then(() => {})
    .catch((data) => Notify.error(data.message));
};

const MFASetup: FC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeReady, setIsCodeReady] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [savedPhone, setSavedPhone] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const { countries } = useStoreState((state) => state.ReferenceDataState);
  const { signOut } = useStoreActions((actions) => actions.UserState);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | undefined>(
    countries.find((item) => item.id === 'GB')
  );

  const countriesWithPhoneCodes = useMemo(
    () => countries.filter((country) => country.enabled && !!country.phoneCode),
    [countries]
  );
  const hasPassword = auth.currentUser?.providerData.find(
    (item) => item?.providerId === 'password'
  );

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    shouldUnregister: false,
  });

  const phoneNumber = watch('phoneNumber');

  const verifyCodeSubmit = async (values: any) => {
    try {
      setIsLoading(true);

      await verifyCode(verificationId, values.verificationCode);

      setIsLoading(false);

      history.push('/app/dashboard');
    } catch (error: any) {
      Notify.open(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onError = async () => {};

  const recaptchaVerifier = useRecaptchaVerifier(
    'recaptcha-phone-verification'
  );

  const onSendVerificationCode = async () => {
    try {
      const response = await sendVerificationCode(
        `${selectedCountry?.phoneCode}${phoneNumber}`,
        recaptchaVerifier
      );

      if (!response) {
        return;
      }

      setVerificationId(response);
      setSavedPhone(phoneNumber);
      setIsCodeReady(true);
    } catch (error: any) {
      Notify.error(error.message);
    }
  };

  const sendCodeSubmit = async ({ password }: any) => {
    try {
      if (!isCodeReady) {
        setIsLoading(true);
      }

      if (hasPassword) {
        const email = auth.currentUser?.email;

        if (!email) {
          return;
        }

        const cred = firebase.auth.EmailAuthProvider.credential(
          email,
          password
        );

        await auth.currentUser?.reauthenticateWithCredential(cred);
      } else {
        await auth.currentUser?.reauthenticateWithPopup(
          new firebase.auth.GoogleAuthProvider()
        );
      }

      await onSendVerificationCode();
    } catch (error: any) {
      Notify.error(error.message);
    } finally {
      if (!isCodeReady) {
        setIsLoading(false);
      }
    }
  };

  const logOutHandler = async () => {
    await signOut();
    history.replace('/login');
  };

  return (
    <Wrapper>
      {isMobile && (
        <Heading>
          <Title variant="h5">Strong Customer Authentication setup</Title>

          <LogOutButton
            variant="link"
            className="cross"
            onClick={logOutHandler}
          >
            Log out
            <svg width="24" height="24">
              <use xlinkHref="#logout-ico" />
            </svg>
          </LogOutButton>
        </Heading>
      )}

      <Content>
        <StaleContainer>
          {!isMobile && (
            <LogOutButton
              variant="link"
              className="cross"
              onClick={logOutHandler}
            >
              Log out
              <svg width="24" height="24">
                <use xlinkHref="#logout-ico" />
              </svg>
            </LogOutButton>
          )}

          <div className="block">
            <Title variant={isMobile ? 'h5' : 'h1'}>
              {isCodeReady ? 'Verify your number' : 'Secure your account'}
            </Title>

            <form
              style={{
                marginTop: 16,
              }}
              id="phone-verification-form"
            >
              {isCodeReady ? (
                <>
                  <StaleParagraphMedium>
                    We have sent you an SMS with the verification code to your
                    mobile. Please enter the code to verify your number:
                  </StaleParagraphMedium>
                  <div
                    className="field"
                    style={{
                      marginTop: 8,
                      marginBottom: 8,
                    }}
                  >
                    <StaleInput
                      control={control}
                      name="verificationCode"
                      id="verificationCode"
                      label="Verification Code"
                      view="moving"
                      rules={{
                        required: ERROR_MESSAGES.requiredField,
                      }}
                      error={errors?.verificationCode?.message}
                      autoFocus
                    />
                  </div>
                  <Row
                    style={{
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Button
                      type="button"
                      variant="link"
                      onClick={(event) => {
                        event.preventDefault();
                        setIsCodeReady(false);
                        setVerificationId('');
                        control.setValue('password', '');
                      }}
                    >
                      Change phone number
                    </Button>

                    <Button
                      type="button"
                      variant="link"
                      onClick={async (event) => {
                        event.preventDefault();

                        const { password } = getValues();

                        await sendCodeSubmit({ password });

                        Notify.success('Code resent');
                      }}
                    >
                      Resend code
                    </Button>
                  </Row>
                </>
              ) : (
                <>
                  <StaleParagraphMedium
                    style={{
                      marginBottom: 16,
                    }}
                  >
                    We use Strong Customer Authentication (SCA) to protect your
                    account. We will send you a one-time security code to your
                    mobile each time you login to HedgeFlows.
                  </StaleParagraphMedium>

                  <StaleParagraphMedium
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Please enter your mobile number and we will send you a code
                    to verify it:
                  </StaleParagraphMedium>

                  <div
                    className="field"
                    style={{
                      marginTop: 8,
                      marginBottom: 8,
                    }}
                  >
                    <Controller
                      name="phoneNumber"
                      control={control}
                      defaultValue={savedPhone}
                      render={({ value, name, onChange }) => {
                        return (
                          <StaleInputPhone
                            id={name}
                            title="Select country"
                            label="Phone number"
                            view="moving"
                            type="text"
                            value={value}
                            data={countriesWithPhoneCodes.map((item) => ({
                              name: item.name,
                              id: item.alpha2,
                              icon: item.alpha2,
                              value: { ...item },
                            }))}
                            selected={selectedCountry}
                            onSelect={(item) => {
                              setSelectedCountry(item.value);
                            }}
                            theme="theme-grey"
                            onChange={onChange}
                            autoFocus
                          />
                        );
                      }}
                    />
                  </div>

                  {hasPassword && (
                    <>
                      <StaleParagraphMedium>
                        Re-enter your password to enable SCA:
                      </StaleParagraphMedium>

                      <div
                        className="field"
                        style={{
                          marginTop: 8,
                          marginBottom: 8,
                        }}
                      >
                        <StaleInput
                          control={control}
                          name="password"
                          id="password"
                          label="Password"
                          view="moving"
                          type="password"
                          defaultValue={''}
                          rules={{
                            required: ERROR_MESSAGES.requiredField,
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </form>

            {!isMobile && (
              <StaleBtnGroup>
                <Button
                  isLoading={isLoading}
                  form="phone-verification-form"
                  onClick={
                    isCodeReady
                      ? handleSubmit(verifyCodeSubmit, onError)
                      : handleSubmit(sendCodeSubmit)
                  }
                >
                  {isCodeReady ? 'Continue' : 'Send me a verification code'}
                </Button>
              </StaleBtnGroup>
            )}
          </div>
        </StaleContainer>
      </Content>

      {isMobile && (
        <StaleBtnGroup
          style={{
            alignSelf: 'stretch',
          }}
        >
          <Button
            isLoading={isLoading}
            form="phone-verification-form"
            onClick={
              isCodeReady
                ? handleSubmit(verifyCodeSubmit, onError)
                : handleSubmit(sendCodeSubmit)
            }
          >
            {isCodeReady ? 'Continue' : 'Send me a verification code'}
          </Button>
        </StaleBtnGroup>
      )}

      <div id="recaptcha-phone-verification" />
    </Wrapper>
  );
};

export default MFASetup;
