import { BarItemProps } from '@nivo/bar';
import { Col, Paragraph } from 'components';
import ChartRect from 'components/shared/ChartRect/ChartRect.styles';
import ChartTooltip from 'components/shared/ChartTooltip/ChartTooltip';
import useChartTooltip from 'hooks/useChartTooltip';
import { FC, PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';
import { IRiskDashboardItemPerMonthWithDate } from 'types';
import { parseIntoCurrencyStringWithSymbol } from 'utils';

interface OwnProps {
  data: PropsWithChildren<
    BarItemProps<
      IRiskDashboardItemPerMonthWithDate & {
        [key: string]: string | number;
      }
    >
  >;
  /**
   * Date in format MMM YYYY or [Q]Q YYYY
   */
  activeDate: string | null;
  onClick: (period: string, periodIndex: number) => void;
}

const ChartContributorsChartBar: FC<OwnProps> = ({
  data,
  activeDate,
  onClick,
}) => {
  const currencySymbol = '$';
  const theme = useTheme();
  const { tooltipRef, ...mouseEvents } = useChartTooltip();
  const { bar } = data;
  const { prebooked, externalHedge, prebookedAndHedged } = bar?.data?.data;
  const isPrebookedAndHedged = bar?.data?.id === 'prebookedAndHedged';
  const colors = {
    payables: theme.color.red,
    receivables: theme.color.emeraldDark,
    prebookedAndHedged: theme.color.blueLight,
    purchaseOrders: theme.color.redDark,
    saleOrders: theme.color.green,
  };

  return (
    <g
      {...mouseEvents}
      onClick={() => onClick(bar.data.data.date, bar.data.index)}
      style={{ cursor: 'pointer' }}
    >
      <ChartRect
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        fill={colors[bar?.data?.id]}
        x={bar?.x}
        y={bar?.y}
        style={{
          width: bar?.width,
          height: bar?.height,
        }}
        active={bar.data.indexValue === activeDate}
      />

      <ChartTooltip wrapperRef={tooltipRef}>
        {isPrebookedAndHedged ? (
          <Col>
            {prebooked && (
              <Paragraph color="white">
                Prebooked:{' '}
                {parseIntoCurrencyStringWithSymbol(
                  prebooked,
                  currencySymbol,
                  0
                )}
              </Paragraph>
            )}
            {externalHedge && (
              <Paragraph color="white">
                Hedged:{' '}
                {parseIntoCurrencyStringWithSymbol(
                  // TODO: avoid type assertion
                  externalHedge as number,
                  currencySymbol,
                  0
                )}
              </Paragraph>
            )}
            {prebookedAndHedged && (
              <Paragraph color="white">
                Total:{' '}
                {parseIntoCurrencyStringWithSymbol(
                  // TODO: avoid type assertion
                  prebookedAndHedged as number,
                  currencySymbol,
                  0
                )}
              </Paragraph>
            )}
          </Col>
        ) : (
          <Paragraph color="white">
            {parseIntoCurrencyStringWithSymbol(
              Number(data?.label),
              currencySymbol,
              0
            )}
          </Paragraph>
        )}
      </ChartTooltip>
    </g>
  );
};

export default ChartContributorsChartBar;
