import { Dispatch, SetStateAction } from 'react';
import { IContact, IInvoice } from 'types';
import { ContactButton } from './ContactCell.styles';
import { ParagraphWithEllipsis } from '../Typography/Typography';
import ContactReviewStatus from '../ContactReviewStatus/ContactReviewStatus';
import { isReceivableInvoice } from 'utils/invoices';

interface OwnProps {
  recipientToUse?: IContact;
  setContactForEdit?: Dispatch<SetStateAction<IContact | null>>;
  setInvoiceForAddContact?: Dispatch<SetStateAction<IInvoice | null>>;
  invoice: IInvoice;
  withReviewStatus?: boolean;
}

const ContactCell: React.FC<OwnProps> = ({
  recipientToUse,
  setContactForEdit,
  setInvoiceForAddContact,
  invoice,
  withReviewStatus = true,
}) => (
  <ContactButton
    as="button"
    disabled={!recipientToUse}
    onClick={(e: any) => {
      e.stopPropagation();

      // contacts with ID from invoices
      if (invoice.contactId && recipientToUse?.enabled && recipientToUse?.id) {
        setContactForEdit?.(recipientToUse);
        // contacts without ID from OrderWise
      } else if (recipientToUse) {
        setInvoiceForAddContact?.(invoice);
      }
    }}
  >
    <ParagraphWithEllipsis
      style={{
        textDecoration: recipientToUse ? 'underline' : 'none',
      }}
    >
      {recipientToUse?.recipientName ?? 'Contact is missing'}
    </ParagraphWithEllipsis>

    {withReviewStatus && !isReceivableInvoice(invoice) && recipientToUse && (
      <ContactReviewStatus contact={recipientToUse} />
    )}
  </ContactButton>
);

export default ContactCell;
