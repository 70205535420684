import {
  INVOICE_STATUSES,
  TPastPerformanceItem,
  isPastPerformanceItemInvoice,
  isPastPerformanceItemTransfer,
} from 'types';
import { TReportsContentType } from './types';
import { Dispatch, SetStateAction } from 'react';
import { Column } from 'react-table';
import { Paragraph, ParagraphWithEllipsis } from 'components';
import { Link } from 'react-router-dom';
import NameCell from './components/NameCell/NameCell';
import DateCell from 'components/shared/DateCell/DateCell';
import DirectionCell from 'components/shared/DirectionCell/DirectionCell';
import AmountCell from './components/AmountCell/AmountCell';
import RiskContributionCell from './components/RiskContributionCell/RiskContributionCell';
import GainLossCell from './components/GainLossCell/GainLossCell';

const getFxImpactTableValue = (
  item: TPastPerformanceItem,
  contentType: TReportsContentType,
  activeMonth: string
) => {
  if (contentType === 'marketImpact' && isPastPerformanceItemInvoice(item)) {
    return item.monthlyImpactContributions?.[activeMonth]?.fxImpact ?? 0;
  }

  return item.fxImpact + item.fxCost;
};

export const generateReportsTableColumns = ({
  setRecordForFxBreakdown,
  contentType,
  activeMonth,
  entityCurrency,
}: {
  setRecordForFxBreakdown: Dispatch<
    SetStateAction<TPastPerformanceItem | undefined>
  >;
  contentType: TReportsContentType;
  activeMonth: string;
  entityCurrency: string;
}): Column<TPastPerformanceItem>[] => {
  return [
    {
      Header: 'Cashflow',
      disableSortBy: true,
      Cell: (props: any) => {
        const item = props.row.original;
        const isInvoice = isPastPerformanceItemInvoice(item);

        return (
          <ParagraphWithEllipsis
            title={item.invoiceId || item.transferId}
            maxWidth="100px"
          >
            {isInvoice ? (
              <Link to={`/app/invoices/${item.invoiceId}`}>
                {item.reference}
              </Link>
            ) : (
              <Paragraph>{item.reference}</Paragraph>
            )}
          </ParagraphWithEllipsis>
        );
      },
      width: 80,
      minWidth: 55,
    },
    {
      accessor: 'contactName',
      Header: 'Name',
      Cell: (props) => <NameCell item={props.row.original} />,
      width: 156,
      minWidth: 136,
    },
    {
      accessor: 'creationDate',
      Header: 'Issued',
      Cell: ({ value }) => <DateCell value={value} />,
      width: 130,
      minWidth: 110,
    },
    {
      accessor: 'paidDate',
      Header: 'Paid',
      Cell: ({ value }) => <DateCell value={value} />,
      width: 120,
      minWidth: 100,
    },
    {
      id: 'status',
      Header: 'Status',
      Cell: (props: any) => {
        const item = props.row.original;
        if (isPastPerformanceItemTransfer(item)) {
          return <Paragraph>PAID</Paragraph>;
        } else {
          return <Paragraph>{item.status}</Paragraph>;
        }
      },
      width: 140,
      minWidth: 120,
    },
    {
      id: 'direction',
      Header: 'Amount Due',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const item = row.original;
        let isReceivable = false;
        if (isPastPerformanceItemTransfer(item)) {
          isReceivable = item.status === 'TRANSFER IN';
        } else {
          isReceivable = item.type === 'Receivable';
        }
        return (
          <>
            <DirectionCell withTitle={false} isReceivable={isReceivable} />
            <AmountCell
              value={row.original.amount}
              currencyCode={row.original.currency}
            />
          </>
        );
      },
      filter: 'direction',
      width: 120,
      minWidth: 100,
    },
    {
      accessor: 'amount',
      Header: `${entityCurrency} Value`,
      disableSortBy: true,
      Cell: ({ value, row }) => (
        <AmountCell
          value={value / row.original.payRate}
          currencyCode={entityCurrency}
        />
      ),
      width: 120,
      minWidth: 100,
    },
    {
      id: 'riskContribution',
      Header: 'Risk contrib.',
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <RiskContributionCell
          amount={
            // 0 risk contribution for transfer items
            isPastPerformanceItemTransfer(row.original)
              ? 0
              : row.original.amount / row.original.payRate
          }
          currencyCode={entityCurrency}
          creationDate={row.original.creationDate}
          paidDate={row.original.paidDate}
        />
      ),
      width: 100,
      minWidth: 100,
    },
    {
      accessor: 'fxImpact',
      Header: 'FX Impact',
      Cell: ({ value }) => (
        <AmountCell value={value} currencyCode={entityCurrency} />
      ),
      width: 140,
      minWidth: 140,
    },
    {
      accessor: 'fxCost',
      Header: 'FX Cost',
      Cell: ({ value }) => (
        <AmountCell value={value} currencyCode={entityCurrency} />
      ),
      width: 140,
      minWidth: 140,
    },
    {
      accessor: 'fxCostPercentage',
      Header: 'FX Gain/Loss',
      sortType: (rowA, rowB) => {
        const a = rowA.original.fxCost + rowA.original.fxImpact;
        const b = rowB.original.fxCost + rowB.original.fxImpact;

        if (a > b) {
          return 1;
        }
        if (b > a) {
          return -1;
        }

        return 0;
      },
      Cell: ({ row }) => {
        const fxImpact = getFxImpactTableValue(
          row.original,
          contentType,
          activeMonth
        );

        return (
          <GainLossCell
            value={fxImpact}
            valueInPerc={
              contentType === 'marketImpact'
                ? undefined
                : row.original.fxCostPercentage +
                  row.original.fxImpactPercentage
            }
            currencyCode={entityCurrency}
            setRecordForFxBreakdown={() =>
              setRecordForFxBreakdown(row.original)
            }
            disableButton={
              row.original.recordType === 'invoice' &&
              row.original.status !== INVOICE_STATUSES.paid
            }
          />
        );
      },
      width: 140,
      minWidth: 140,
    },
  ];
};
