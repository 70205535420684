import { IContractInput, IRateContract, ICurrency, IResponse } from 'types';
import { Notify, openQueryWithTimestamp } from 'utils';
import { AxiosPrivateFirebaseInstance } from 'settings';
import db from 'services/firestore';

export const createRateContract = async (
  payload: IContractInput
): Promise<IResponse | undefined> => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<IResponse>(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/contracts`,
      payload
    );

    if (data.success) {
      return data;
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      Notify.error(data.message);
    }
  } catch (error: any) {
    console.warn(error);
  }
};

export interface SubscribeToRateContractsParams {
  entityId: string;
  callback: (rateContracts: IRateContract[]) => void;
}

export const subscribeToRateContracts = ({
  entityId,
  callback,
}: SubscribeToRateContractsParams) => {
  try {
    return db
      .collection('rateContracts')
      .where('_owner', '==', entityId)
      .onSnapshot((query) => callback(openQueryWithTimestamp(query)));
  } catch (error: any) {
    console.warn(error);
  }
};

export const cancelContract = async (contractId: string) =>
  await AxiosPrivateFirebaseInstance.post<IResponse>(
    `/contracts/${contractId}/cancel`
  );

export interface HedgeCashflowsParams {
  buyCurrency: ICurrency['code'];
}
export const hedgeCashflows = async ({ buyCurrency }: HedgeCashflowsParams) => {
  await AxiosPrivateFirebaseInstance.post(
    `/contracts/hedgecashflows/${buyCurrency}`
  );
};

export const hedgeCountAndPrebookAmount = async ({
  buyCurrency,
}: HedgeCashflowsParams) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.get(
      `/contracts/hedgeCount/${buyCurrency}`
    );

    return data;
  } catch (error: any) {
    console.warn(error);
    return { success: false, exception: true, message: error.message };
  }
};

export interface HedgeCashflowsByIdsParams {
  invoiceIds: string[];
}
export const hedgeCashflowsByIds = async (
  payload: HedgeCashflowsByIdsParams
) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/contracts/hedgecashflows`,
    payload
  );
};

export const exchangeCurrency = async (payload: IContractInput) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    '/contracts/exchange-currency',
    payload
  );
};

interface GetConversionDatesParams {
  currencyPair: string;
}

export const getConversionDates = async ({
  currencyPair,
}: GetConversionDatesParams) => {
  return AxiosPrivateFirebaseInstance.get<IResponse>(
    `/contracts/conversion-dates/${currencyPair}`
  );
};

export interface UpdateRateContractParams
  extends Pick<IRateContract, 'whenFundsArriveAction'> {
  contractId: string;
}

export const updateRateContract = async ({
  contractId,
  whenFundsArriveAction,
}: UpdateRateContractParams) => {
  return AxiosPrivateFirebaseInstance.put<IResponse>(
    `/contracts/${contractId}`,
    { whenFundsArriveAction }
  );
};
