import { useCallback, useEffect, useState } from 'react';
import { createAndOpenDownloadLinkToInMemoryFileData } from 'utils';
import { useStoreState, useStoreActions } from 'state';
import {
  deletePaymentRunInvoice,
  getPaymentRunInvoicesAsCSVFile,
  subscribeToDraftPaymentRun,
} from 'services/paymentRuns';
import { errorHandler } from 'utils/errors';
import { IInvoice } from 'types';
import { getInvoicesByIdArray } from 'services/firebase/invoices';
import { IPaymentRun } from 'types/paymentRuns';

const useDraftPaymentRun = () => {
  const { entityId } = useStoreState(({ UserState }) => UserState);
  const { setState } = useStoreActions((state) => state.PaymentRunsState);
  const [draftPaymentRun, setDraftPaymentRun] = useState<IPaymentRun>();

  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(true);

  const [
    isLoadingPaymentRunInvoicesCSV,
    setIsLoadingPaymentRunInvoicesCSV,
  ] = useState(false);

  useEffect(() => {
    let unsubscribe: () => void;
    if (entityId) {
      unsubscribe = subscribeToDraftPaymentRun({
        entityId,
        callback: (paymentRun) => {
          setDraftPaymentRun(paymentRun);

          if (!paymentRun) {
            setIsLoadingInvoices(false);
          }
        },
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [entityId]);

  useEffect(() => {
    if (draftPaymentRun) {
      const getPaymentRunInvoices = async () => {
        try {
          const paymentRunInvoices = await getInvoicesByIdArray(
            draftPaymentRun.instructions.invoiceIds
          );

          setInvoices(paymentRunInvoices);
        } catch (error: any) {
          console.error('Failed to get payment run invoices: ', error);
        } finally {
          setIsLoadingInvoices(false);
        }
      };

      getPaymentRunInvoices();
    }
  }, [draftPaymentRun, setState]);

  const onRemoveInvoice = useCallback(
    async (paymentRunInvoiceId: string) => {
      if (draftPaymentRun) {
        try {
          setState(['isUpdatingPaymentRun', true]);

          const { data: response } = await deletePaymentRunInvoice({
            paymentRunId: draftPaymentRun.id,
            paymentRunInvoiceId,
          });

          if (response.data) {
            const paymentRunInvoices = await getInvoicesByIdArray(
              response.data.instructions.invoiceIds
            );
            setInvoices(paymentRunInvoices);
          }
        } catch (error: any) {
          errorHandler(error);
        } finally {
          setState(['isUpdatingPaymentRun', false]);
        }
      }
    },
    [draftPaymentRun, setState]
  );

  const getPaymentRunInvoicesAsCsv = async () => {
    if (draftPaymentRun) {
      try {
        setIsLoadingPaymentRunInvoicesCSV(true);
        const response = await getPaymentRunInvoicesAsCSVFile(
          draftPaymentRun.id
        );
        createAndOpenDownloadLinkToInMemoryFileData(
          response.data,
          `payment_run_${draftPaymentRun.id}.csv`,
          'text/csv'
        );
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoadingPaymentRunInvoicesCSV(false);
      }
    }
  };

  return {
    paymentRunId: draftPaymentRun?.id ?? '',
    getPaymentRunInvoicesAsCsv,
    onRemoveInvoice,
    paymentRunInvoices: invoices,
    isLoadingPaymentRunInvoices: isLoadingInvoices,
    isLoadingPaymentRunInvoicesCSV,
  };
};

export default useDraftPaymentRun;
