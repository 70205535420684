import { FC, useCallback, useEffect, useState } from 'react';
import CashflowAtRisk from './components/CashflowAtRisk/CashflowAtRisk';
import { useTheme } from 'styled-components';
import MonthlyBreakdown from './components/MonthlyBreakdown/MonthlyBreakdown';
import DataTable from './components/DataTable/DataTable';
import { BottomContentWrapper, TopContentWrapper } from './Risks.styles';
import { getCashflowsRisksPerCurrency } from 'services/firebase/analysis';
import useUrlValues from 'hooks/useUrlValues';
import { TCashflowAtRiskNew } from 'types';
import { errorHandler } from 'utils/errors';
import { InlineLoader, Row } from 'components';
import { useStoreState } from 'state';

const Risks: FC = () => {
  const theme = useTheme();
  const { isRefreshingCashflowsRisksData } = useStoreState(
    ({ ReferenceDataState }) => ReferenceDataState
  );
  const { currency, period } = useUrlValues('currency', 'period');
  const [cashflowsRisksData, setCashflowsRisksData] = useState<
    TCashflowAtRiskNew[]
  >([]);
  const [
    isLoadingCashflowsRisksData,
    setIsLoadingCashflowsRisksData,
  ] = useState(true);
  const isLoadingAndHaveData =
    isLoadingCashflowsRisksData && cashflowsRisksData.length > 0;

  const getCashflowsRisksPerCurrencyHandler = useCallback(
    async (currencyCode: string, period: 'month' | 'quarter') => {
      try {
        setIsLoadingCashflowsRisksData(true);

        const result = await getCashflowsRisksPerCurrency({
          currencyCode,
          period,
        });

        setCashflowsRisksData(result.data.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoadingCashflowsRisksData(false);
      }
    },
    []
  );

  useEffect(() => {
    if (currency && period && !isRefreshingCashflowsRisksData) {
      getCashflowsRisksPerCurrencyHandler(
        currency,
        period as 'month' | 'quarter'
      );
    }
  }, [
    currency,
    getCashflowsRisksPerCurrencyHandler,
    isRefreshingCashflowsRisksData,
    period,
  ]);

  return (
    <>
      <TopContentWrapper
        columnGap={theme.spacing.xl}
        mb
        mbValue={theme.spacing.xl}
      >
        <CashflowAtRisk />
        <MonthlyBreakdown
          cashflowsRisksData={cashflowsRisksData}
          isLoadingCashflowsRisksData={isLoadingCashflowsRisksData}
          isLoadingAndHaveData={isLoadingAndHaveData}
        />
      </TopContentWrapper>

      <BottomContentWrapper>
        <DataTable
          cashflowsRisksData={cashflowsRisksData}
          isLoadingCashflowsRisksData={isLoadingCashflowsRisksData}
          isLoadingAndHaveData={isLoadingAndHaveData}
          getCashflowsRisksPerCurrency={getCashflowsRisksPerCurrencyHandler}
        />

        {isLoadingAndHaveData && (
          <Row
            style={{
              position: 'absolute',
              top: 12,
              left: 12,
            }}
          >
            <InlineLoader />
          </Row>
        )}
      </BottomContentWrapper>
    </>
  );
};

export default Risks;
