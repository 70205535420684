import useDynamics365 from './useDynamics365';
import useNetSuite from './useNetSuite';
import useQuickBooks from './useQuickBooks';
import useSage50 from './useSage50';

const useCodat = () => {
  const {
    onImportSage50Data: onImportCodatData,
    isImportingSage50Data,
    isIntegratedWithSage50,
  } = useSage50();

  const {
    isImportingQuickBooksData,
    isIntegratedWithQuickBooks,
  } = useQuickBooks();

  const {
    isImportingDynamics365Data,
    isIntegratedWithDynamics365,
  } = useDynamics365();

  const { isImportingNetSuiteData, isIntegratedWithNetSuite } = useNetSuite();

  const isImportingFromCodat =
    isImportingSage50Data ||
    isImportingQuickBooksData ||
    isImportingDynamics365Data ||
    isImportingNetSuiteData;

  const isIntegratedViaCodat =
    isIntegratedWithSage50 ||
    isIntegratedWithQuickBooks ||
    isIntegratedWithDynamics365 ||
    isIntegratedWithNetSuite;

  return {
    onImportCodatData,
    isImportingFromCodat,
    isIntegratedViaCodat,
    isIntegratedWithSage50,
    isIntegratedWithQuickBooks,
    isIntegratedWithDynamics365,
    isIntegratedWithNetSuite,
  };
};

export default useCodat;
