import { FC, useEffect, useMemo, useState } from 'react';
import { Col, Table, Title } from 'components';
import { TPastPerformanceItem } from 'types/analyses';
import {
  getPastPerformanceDataPerRecordByCurrency,
  getPastPerformanceCurrencies,
} from 'services/firebase/analysis';
import { useStoreState } from 'state';
import { filterPastPerformanceItem, filterTypes } from './utils';
import TableControls from './components/TableControls/TableControls';
import ReportCalculationStatus from './components/ReportCalculationStatus/ReportCalculationStatus';
import useTableFiltering from 'hooks/useTableFiltering';
import CurrencyTiles from './components/CurrencyTiles/CurrencyTiles';
import FxBreakdownPopup from './components/FxBreakdownPopup/FxBreakdownPopup';
import { HF_GURU_TASKS } from 'types/entities';
import useHfGuru from 'hooks/useHfGuru';
import Placeholder from 'components/shared/Placeholder/Placeholder';
import { useTheme } from 'styled-components';
import { TReportsContentType, TReportsTableFilterValue } from './types';
import { generateReportsTableColumns } from './tableColumnsGenerator';
import { errorHandler } from 'utils/errors';
import useXero from 'hooks/useXero';

const Reports: FC = () => {
  const theme = useTheme();
  const { isIntegrated: isIntegratedWithXero } = useXero();
  const [contentType, setContentType] = useState<TReportsContentType>(
    isIntegratedWithXero ? 'pnl' : 'realised'
  );
  const { entityId, entityCurrencyCode } = useStoreState(
    (state) => state.UserState
  );
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const [activeCurrencyCode, setActiveCurrencyCode] = useState<string>(
    isIntegratedWithXero ? 'all' : ''
  );
  const [data, setData] = useState<TPastPerformanceItem[]>([]);
  const [
    recordForFxBreakdown,
    setRecordForFxBreakdown,
  ] = useState<TPastPerformanceItem>();
  const [activeMonth, setActiveMonth] = useState('');
  const [currencyCodes, setCurrencyCodes] = useState<string[]>([]);
  const { hfGuruData, updateHfGuruStatus } = useHfGuru();

  const columns = useMemo(
    () =>
      generateReportsTableColumns({
        setRecordForFxBreakdown,
        contentType,
        activeMonth,
        entityCurrency: entityCurrencyCode,
      }),
    [activeMonth, contentType, entityCurrencyCode]
  );

  const { filter, setFilter, tableRef } = useTableFiltering<
    TReportsTableFilterValue,
    TPastPerformanceItem
  >({
    filterTypeName: 'direction',
  });

  useEffect(() => {
    if (
      hfGuruData &&
      !hfGuruData.completed &&
      hfGuruData.statuses?.healthCheck &&
      !hfGuruData.statuses.healthCheck.completed
    ) {
      updateHfGuruStatus(HF_GURU_TASKS.healthCheck, false);
    }
  }, [hfGuruData, updateHfGuruStatus]);

  useEffect(() => {
    if (entityId) {
      const fetchPastPerformanceCurrencies = async () => {
        try {
          const result = await getPastPerformanceCurrencies({ entityId });
          const currencyCodesToSave = result
            .filter(
              (pastPerformanceCurrency) =>
                currencyByCode(pastPerformanceCurrency.id)?.tier1
            )
            .map((pastPerformanceCurrency) => pastPerformanceCurrency.id);

          if (currencyCodesToSave.length) {
            setCurrencyCodes(currencyCodesToSave);

            if (!activeCurrencyCode) {
              setActiveCurrencyCode(currencyCodesToSave[0]);
            }
          }
        } catch (error) {
          errorHandler(error);
        }
      };

      fetchPastPerformanceCurrencies();
    }
  }, [activeCurrencyCode, currencyByCode, entityId]);

  useEffect(() => {
    if (entityId && activeCurrencyCode) {
      const fetchPastPerformanceData = async ({
        activeCurrencyCode,
        currencyCodes,
        entityId,
        contentType,
        activeMonth,
      }: {
        activeCurrencyCode: string;
        currencyCodes: string[];
        entityId: string;
        contentType: TReportsContentType;
        activeMonth: string;
      }) => {
        try {
          if (activeCurrencyCode === 'all') {
            const promises = currencyCodes.map((currencyCode) =>
              getPastPerformanceDataPerRecordByCurrency({
                entityId,
                currency: currencyCode,
              })
            );

            const result = await Promise.all(promises);
            const flatResult = result.flat();
            const filteredData = flatResult.filter((item) =>
              filterPastPerformanceItem(item, contentType, activeMonth)
            );
            setData(filteredData);
          } else {
            const result = await getPastPerformanceDataPerRecordByCurrency({
              entityId,
              currency: activeCurrencyCode,
            });

            const filteredData = result.filter((item) =>
              filterPastPerformanceItem(item, contentType, activeMonth)
            );

            setData(filteredData);
          }
        } catch (error) {
          errorHandler(error);
        }
      };

      fetchPastPerformanceData({
        activeCurrencyCode,
        currencyCodes,
        entityId,
        contentType,
        activeMonth,
      });
    }
  }, [activeCurrencyCode, activeMonth, contentType, currencyCodes, entityId]);

  return (
    <>
      {entityId && (
        <CurrencyTiles
          contentType={contentType}
          setContentType={setContentType}
          entityId={entityId}
          activeCurrencyCode={activeCurrencyCode}
          setActiveCurrencyCode={setActiveCurrencyCode}
          activeMonth={activeMonth}
          setActiveMonth={setActiveMonth}
          currencyCodes={currencyCodes}
        />
      )}

      <ReportCalculationStatus />

      {activeMonth ? (
        <>
          <TableControls
            activeMonth={activeMonth}
            invoiceCount={data.length}
            filter={filter}
            setFilter={setFilter}
            activeCurrencyCode={activeCurrencyCode}
            contentType={contentType}
          />

          <Table
            ref={tableRef}
            isVirtualized
            data={data}
            columns={columns}
            sortable
            filterTypes={filterTypes}
            globalFilter="text"
          />
        </>
      ) : (
        <Col>
          <Title mb mbValue={theme.spacing.l} variant="h5">
            Invoices
          </Title>

          <Placeholder
            fullWidth
            description="Choose a period on the graph to see the breakdown"
          />
        </Col>
      )}

      {!!recordForFxBreakdown && (
        <FxBreakdownPopup
          data={recordForFxBreakdown}
          onClose={() => setRecordForFxBreakdown(undefined)}
        />
      )}
    </>
  );
};

export default Reports;
