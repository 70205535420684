import { FC, useMemo, useEffect } from 'react';
import { useTheme } from 'styled-components';
import useUrlValues from 'hooks/useUrlValues';
import { Row } from 'components';
import Button from 'components/shared/Button/Button';
import TabsShared, { ITab } from 'components/shared/Tabs/Tabs';
import { TableHeader } from 'components/shared/TableHeader/TableHeader.styles';

type TabsType = 'invoices' | 'prebookings';

const Tabs: FC = () => {
  const theme = useTheme();
  const { setUrlValue, tab } = useUrlValues('tab');

  const tabs = useMemo(
    () =>
      [
        { id: 'invoices', title: 'Invoices' },
        {
          id: 'prebookings',
          title: `Prebooked FX`,
        },
      ] as ITab<TabsType>[],
    []
  );

  // TODO: Remove after refactor of useUrlValues (add initial values for props to avoid useEffect)
  useEffect(() => {
    if (!tab) {
      setUrlValue({ tab: 'invoices' });
    }
  }, [tab, setUrlValue]);

  return (
    <TableHeader>
      <Row>
        <TabsShared<TabsType>
          data={tabs}
          activeTab={(tab as TabsType) ?? tabs[0].id}
          setActiveTab={({ id }) => {
            setUrlValue({ tab: id });
          }}
          whiteSpace="nowrap"
        />
      </Row>
      <Row>
        <Button
          onClick={() => setUrlValue({ dateRange: '' })}
          variant="link"
          ml
          mlValue={theme.spacing.xs}
        >
          Back to main view
        </Button>
      </Row>
    </TableHeader>
  );
};

export default Tabs;
