import dayjs from 'dayjs';
import { FC } from 'react';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ChartLine from 'components/shared/ChartLine/ChartLine';
import { CustomLayerProps } from '@nivo/line';
import ChartRatesArea from './components/ChartRatesArea/ChartRatesArea';
import { TCashflowAtRiskNew } from 'types';
import { getMinMaxValues, reduceDataToChartValues } from './utils';
import SliceTooltip from './components/SliceTooltip/SliceTooltip';
import { DB_MONTH_FORMAT } from 'variables';

dayjs.extend(customParseFormat);

interface OwnProps {
  data: TCashflowAtRiskNew[];
}

const ChartRates: FC<OwnProps> = ({ data }) => {
  const isMonthly = dayjs(data[0].date, DB_MONTH_FORMAT).isValid();
  const { min, max } = getMinMaxValues(data);
  const { effectiveRate, ...rest } = reduceDataToChartValues(data, isMonthly);

  const xFormat = isMonthly ? '%b %Y' : 'Q%q %Y';

  return (
    <ChartLine
      data={Object.values(rest)}
      margin={{
        right: 30,
        bottom: 40,
        top: 20,
        left: 42,
      }}
      yScale={{
        type: 'linear',
        min: min,
        max: max,
      }}
      xScale={{
        type: 'time',
        format: xFormat,
      }}
      xFormat={`time:${xFormat}`}
      animate
      isInteractive
      enableSlices="x"
      lineWidth={2}
      axisTop={null}
      axisRight={null}
      enableGridX={false}
      enablePoints={false}
      axisBottom={{
        format: xFormat,
      }}
      layers={[
        'grid',
        'markers',
        'axes',
        (customLayerProps: CustomLayerProps) => (
          <ChartRatesArea
            {...customLayerProps}
            effectiveRateData={effectiveRate.data}
          />
        ),
        'crosshair',
        'lines',
        'points',
        'slices',
        'mesh',
        'legends',
      ]}
      sliceTooltip={SliceTooltip}
    />
  );
};

export default ChartRates;
