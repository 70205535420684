import { Datum } from '@nivo/line';
import dayjs from 'dayjs';
import { TCashflowAtRiskNew } from 'types';
import {
  DB_MONTH_FORMAT,
  MONTH_DATE_FORMAT,
} from 'variables';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { notNullTypeGuard } from 'utils';
import { getQuarterDateToUse } from 'utils/dates';

dayjs.extend(customParseFormat);

export const reduceDataToChartValues = (
  data: TCashflowAtRiskNew[],
  isMonthly: boolean
) =>
  data.reduce<{
    hedgeRate: { id: string; data: Datum[] };
    budgetRate: { id: string; data: Datum[] };
    marketRate: { id: string; data: Datum[] };
    effectiveRate: {
      id: string;
      data: { x: number; y0: number | null; y1: number | null }[];
    };
  }>(
    (acc, item) => {
      const dateStringToUse = isMonthly
        ? dayjs(item.date, DB_MONTH_FORMAT).format(MONTH_DATE_FORMAT)
        : item.date;
      const dateToUse = isMonthly
        ? dayjs(item.date, DB_MONTH_FORMAT).toDate()
        : getQuarterDateToUse(item.date).startOf('quarter').toDate();

      acc.hedgeRate.data.push({
        x: dateStringToUse,
        y: item.hedgeRate,
      });

      acc.budgetRate.data.push({
        x: dateStringToUse,
        y: item.budgetRate,
      });

      acc.marketRate.data.push({
        x: dateStringToUse,
        y: item.marketRate ?? null,
      });

      acc.effectiveRate.data.push({
        x: dateToUse.getTime(),
        y0: item.minEffectiveRate,
        y1: item.maxEffectiveRate,
      });

      return acc;
    },
    {
      hedgeRate: {
        id: 'hedgeRate',
        data: [],
      },
      marketRate: {
        id: 'marketRate',
        data: [],
      },
      budgetRate: {
        id: 'budgetRate',
        data: [],
      },
      effectiveRate: {
        id: 'effectiveRate',
        data: [],
      },
    }
  );

export const getMinMaxValues = (data: TCashflowAtRiskNew[]) => {
  const hedgeRates = data
    .map((item) => item.hedgeRate)
    .filter(notNullTypeGuard);
  const budgetRates = data
    .map((item) => item.budgetRate)
    .filter(notNullTypeGuard);
  const marketRates = data
    .map((item) => item.marketRate)
    .filter(notNullTypeGuard);
  const minEffectiveRates = data
    .map((item) => item.minEffectiveRate)
    .filter(notNullTypeGuard);
  const maxEffectiveRates = data
    .map((item) => item.maxEffectiveRate)
    .filter(notNullTypeGuard);
  const min = Math.min(
    ...hedgeRates,
    ...budgetRates,
    ...marketRates,
    ...minEffectiveRates,
    ...maxEffectiveRates
  );
  const max = Math.max(
    ...hedgeRates,
    ...budgetRates,
    ...marketRates,
    ...minEffectiveRates,
    ...maxEffectiveRates
  );

  return { min, max };
};
