import { FC } from 'react';
import {
  StaleBtnGroup,
  StaleTitleH1,
  StaleParagraphMedium,
  StaleOverflowScroll,
} from 'components';
import { SignUpContent } from '..';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { useStoreState } from 'state';
import Button from 'components/shared/Button/Button';

const StepSix: FC = () => {
  const history = useHistory();

  const { isEntityEnabled, userEntity } = useStoreState(
    (state) => state.UserState
  );

  const isOnReview =
    !isEntityEnabled && userEntity?.status === 'onboardingStep4Completed';

  return (
    <>
      <SignUpContent>
        <StaleOverflowScroll>
          <div className="block reviewed">
            <StaleTitleH1>
              Congrats!
              <br />
              You’re all set!
            </StaleTitleH1>

            <StaleParagraphMedium>
              {isOnReview
                ? 'We will review your request and notify you when you have full access. In the meantime please take a look at our main features.'
                : 'We will review your request and will notify you when you have full access. Now take a look what are the main features.'}
            </StaleParagraphMedium>

            <svg width="210px" height="185px">
              <use xlinkHref="#complete" />
            </svg>

            {!isMobile && (
              <StaleBtnGroup>
                <Button onClick={() => history.push('/app/dashboard')}>
                  {isOnReview ? 'Go to your Dashboard' : 'Start'}
                </Button>
              </StaleBtnGroup>
            )}
          </div>
        </StaleOverflowScroll>
      </SignUpContent>

      {isMobile && (
        <StaleBtnGroup>
          <Button onClick={() => history.push('/app/dashboard')}>Start</Button>
        </StaleBtnGroup>
      )}
    </>
  );
};

export default StepSix;
