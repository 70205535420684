import dayjs from 'dayjs';
import { Column } from 'react-table';
import { Paragraph, Row } from 'components';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import { TCashflowAtRiskNew } from 'types';
import { roundToPrecision } from 'utils';
import { DB_MONTH_FORMAT, MONTH_DATE_FORMAT } from 'variables';
import DateThreeDotsMenu from './components/DateThreeDotsMenu/DateThreeDotsMenu';

export const generateTableColumns = ({
  currencyCode,
  cashflowsRisksData,
  getCashflowsRisksPerCurrency,
}: {
  currencyCode: string;
  cashflowsRisksData: TCashflowAtRiskNew[];
  getCashflowsRisksPerCurrency: (
    currencyCode: string,
    period: 'month' | 'quarter'
  ) => Promise<void>;
}): Column<TCashflowAtRiskNew>[] => {
  return [
    {
      accessor: 'date',
      Cell: ({ value }) => {
        const monthlyDate = dayjs(value, DB_MONTH_FORMAT);
        const dateToUse = monthlyDate.isValid()
          ? monthlyDate.format(MONTH_DATE_FORMAT)
          : value;

        return (
          <>
            <Row justifyContent="space-between" flex={1}>
              <Paragraph>{dateToUse}</Paragraph>
              <DateThreeDotsMenu
                currencyCode={currencyCode}
                cashflowsRisksData={cashflowsRisksData}
                getCashflowsRisksPerCurrency={getCashflowsRisksPerCurrency}
                period={value}
              />
            </Row>
          </>
        );
      },
      minWidth: 80,
    },
    {
      accessor: 'startingBalance',
      Header: 'Starting Balance',
      Cell: ({ value }: { value: any }) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCode}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      accessor: 'payables',
      Header: 'Payables',
      Cell: ({ value }: { value: any }) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCode}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      accessor: 'receivables',
      Header: 'Receivables',
      Cell: ({ value }: { value: any }) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCode}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      accessor: 'forecastedCashflows',
      Header: 'Forecasted CFs',
      Cell: ({ value }: { value: any }) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCode}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      accessor: 'hedges',
      Header: 'Hedges',
      Cell: ({ value }: { value: any }) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCode}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      accessor: 'closingBalance',
      Header: 'Closing balance',
      Cell: ({ value }: { value: any }) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCode}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      accessor: 'hedgeRatio',
      Header: 'Hedge Ratio',
      Cell: ({ value }: { value: any }) => (
        <Paragraph>
          {value ? `${roundToPrecision(value, 2)}%` : 'N/A'}
        </Paragraph>
      ),
      minWidth: 80,
    },
    {
      accessor: 'budgetRate',
      Header: 'Budget rate',
      Cell: ({ value }: { value: any }) => (
        <Paragraph>{value ? `${roundToPrecision(value, 2)}` : 'N/A'}</Paragraph>
      ),
      minWidth: 80,
    },
  ];
};
