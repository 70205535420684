import { getRate } from 'services/firebase';
import { INVOICE_STATUSES } from 'types';
import { checkIfRatesFulfilled } from 'utils';

export const getInvoicesFilters = ({
  currency,
  dateFrom,
  dateTo,
}: {
  currency: string | null;
  /** ISO date string */
  dateFrom?: string;
  /** ISO date string */
  dateTo: string;
}): any => ({
  all: [
    dateFrom
      ? {
          due_date: {
            from: dateFrom,
            to: dateTo,
          },
        }
      : {
          due_date: {
            to: dateTo,
          },
        },
    {
      currency,
    },
    {
      status: [
        INVOICE_STATUSES.authorised,
        INVOICE_STATUSES.partiallyPaid,
        INVOICE_STATUSES.paymentScheduled,
        INVOICE_STATUSES.processingPayment,
        INVOICE_STATUSES.purchaseOrder,
        INVOICE_STATUSES.salesOrder,
      ],
    },
  ],
});

export const getRatesPerCurrency = async (
  currencies: string[],
  entityCurrencyCode: string
) => {
  const response = await Promise.allSettled(
    currencies.map(async (currencyCode) => {
      const { data } = await getRate({
        sellCurrency: entityCurrencyCode,
        buyCurrency: currencyCode,
      });
      return { currencyCode, rate: data?.rate };
    })
  );

  const fulfilledRatesByCurrency = response.reduce(
    (acc, currencyRateFulfilled) => {
      if (
        checkIfRatesFulfilled(currencyRateFulfilled) &&
        currencyRateFulfilled?.value.currencyCode
      ) {
        acc[currencyRateFulfilled.value.currencyCode] =
          currencyRateFulfilled.value.rate;
      }

      return acc;
    },
    {} as Record<typeof currencies[number], number | undefined>
  );

  return fulfilledRatesByCurrency;
};
