import dayjs from 'dayjs';
import { FC } from 'react';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ChartLine from 'components/shared/ChartLine/ChartLine';
import { TCashflowAtRiskNew } from 'types';
import { getMinMaxValues, reduceDataToChartValues } from './utils';
import { DB_MONTH_FORMAT } from 'variables';
import { parseIntoShortNumberWithSymbol } from 'utils';
import PointTooltip from './components/PointTooltip/PointTooltip';

dayjs.extend(customParseFormat);

interface OwnProps {
  data: TCashflowAtRiskNew[];
  onChartPeriodClick: (period: string, periodIndex: number) => void;
}

const ChartBalances: FC<OwnProps> = ({ data, onChartPeriodClick }) => {
  const isMonthly = dayjs(data[0]?.date, DB_MONTH_FORMAT).isValid();
  const { min, max } = getMinMaxValues(data);
  const closingBalance = reduceDataToChartValues(data, isMonthly);
  const xFormat = isMonthly ? '%b %Y' : 'Q%q %Y';

  return (
    <ChartLine
      data={closingBalance}
      margin={{
        right: 30,
        bottom: 40,
        top: 20,
        left: 42,
      }}
      yScale={{
        type: 'linear',
        min: min,
        max: max,
      }}
      xScale={{
        type: 'time',
        format: xFormat,
      }}
      xFormat={`time:${xFormat}`}
      animate
      isInteractive
      curve="monotoneX"
      lineWidth={2}
      axisTop={null}
      axisRight={null}
      enableGridX={false}
      pointSize={12}
      useMesh
      axisBottom={{
        format: xFormat,
      }}
      axisLeft={{
        format: (value) => parseIntoShortNumberWithSymbol(value),
      }}
      tooltip={PointTooltip}
      onClick={(point) => {
        if (typeof point.data.xFormatted === 'string') {
          onChartPeriodClick(point.data.xFormatted, point.index);
        }
      }}
    />
  );
};

export default ChartBalances;
