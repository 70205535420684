import { FC, useState } from 'react';
import { Col, StaleSwitch, Subtitle, Paragraph, Row, Title } from 'components';
import { useTheme } from 'styled-components';
import {
  IIntegrationPermissions,
  isCodatPlatform,
  isXeroPlatform,
  TPlatformNames,
} from 'types/integrations';
import Button from '../Button/Button';
import { Controller, useForm } from 'react-hook-form7';
import { saveCodatPermissions, saveXeroPermissions } from 'services/firebase';
import { errorHandler } from 'utils/errors';
import { useStoreState } from 'state';
import { getDefaultImportPermissionValues, IMPORT_DATA } from './utils';

type TIntegrationImportPermissions = Pick<
  IIntegrationPermissions,
  | 'importBills'
  | 'importBankBalances'
  | 'importContactDetails'
  | 'importInvoices'
  | 'importPayments'
  | 'importPurchaseAndSalesOrders'
>;

interface OwnProps {
  onContinue: () => void;
  platformName: TPlatformNames;
}

const StepImport: FC<OwnProps> = ({ onContinue, platformName }) => {
  const theme = useTheme();
  const { xero, codat } = useStoreState(
    ({ UserState }) => UserState.integrationsSummary
  );

  const { control, handleSubmit } = useForm<TIntegrationImportPermissions>({
    defaultValues: getDefaultImportPermissionValues({
      platformName,
      xeroData: xero,
      codatData: codat,
    }),
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values: TIntegrationImportPermissions) => {
    try {
      setIsLoading(true);

      const isCodat = isCodatPlatform(platformName);
      const isXero = isXeroPlatform(platformName);

      if (isCodat) {
        await saveCodatPermissions({
          permissions: values,
        });
      }

      if (isXero) {
        await saveXeroPermissions({
          permissions: values,
        });
      }

      onContinue();
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        What shall we import from {platformName}?
      </Title>

      <Paragraph mb mbValue={theme.spacing.xl}>
        We will need to import the following data from {platformName} to help
        manage your currency needs and streamline you cross-border payments:
      </Paragraph>

      <Col
        as="form"
        id="integration-import-settings-form"
        gap={theme.spacing.xl}
        mb
        mbValue={theme.spacing.xxl}
        onSubmit={handleSubmit(onSubmit)}
      >
        {IMPORT_DATA.map(({ id, disabled, title }) => (
          <Row key={id} justifyContent="flex-start">
            <Controller
              name={id}
              control={control}
              render={({ field }) => (
                <>
                  <StaleSwitch
                    id={id}
                    isOn={field.value}
                    disabled={disabled}
                    handleToggle={field.onChange}
                  />
                  <Subtitle ml>{title}</Subtitle>
                </>
              )}
            />
          </Row>
        ))}
      </Col>

      <Button
        form="integration-import-settings-form"
        isLoading={isLoading}
        disabled={isLoading}
      >
        Continue
      </Button>
    </>
  );
};

export default StepImport;
