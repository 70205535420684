import { FC, useEffect, useState } from 'react';
import Card from 'components/shared/Card/Card.styles';
import { InlineLoader, Row, StaleInputSelect, StaleLoader } from 'components';
import useUrlValues from 'hooks/useUrlValues';
import dayjs from 'dayjs';
import { DB_MONTH_FORMAT, MONTH_DATE_FORMAT } from 'variables';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { StepperItem } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import { TMonthlyBreakdownContentType } from './types';
import Charts from './components/Charts/Charts';
import { TCashflowAtRiskNew } from 'types';
import { getMonthlyBreakdownTabs } from './utils';
import { useStoreState } from 'state';

dayjs.extend(advancedFormat);

interface OwnProps {
  cashflowsRisksData: TCashflowAtRiskNew[];
  isLoadingCashflowsRisksData: boolean;
  isLoadingAndHaveData: boolean;
}

const MonthlyBreakdown: FC<OwnProps> = ({
  cashflowsRisksData,
  isLoadingCashflowsRisksData,
  isLoadingAndHaveData,
}) => {
  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);
  const [contentType, setContentType] = useState<TMonthlyBreakdownContentType>(
    'balances'
  );
  const { setUrlValue, period, dateRange, currency } = useUrlValues(
    'currency',
    'period',
    'dateRange'
  );

  useEffect(() => {
    if (!period) {
      setUrlValue({ period: 'month' });
    }
  }, [period, setUrlValue]);

  const onChartPeriodClick = (periodFromChart: string, periodIndex: number) => {
    const monthDate = dayjs(periodFromChart, MONTH_DATE_FORMAT);
    const isFirstPeriod = periodIndex === 0 ? 'true' : '';

    if (period === 'month' && monthDate.isValid()) {
      const formattedMonth = monthDate.format(DB_MONTH_FORMAT);

      setUrlValue({
        dateRange: formattedMonth !== dateRange ? formattedMonth : '',
        isFirstPeriod,
      });
    }

    if (period === 'quarter') {
      setUrlValue({
        dateRange: periodFromChart !== dateRange ? periodFromChart : '',
        isFirstPeriod,
      });
    }
  };

  const onSelectPeriod = ({ value }: { value: 'month' | 'quarter' }) => {
    setUrlValue({ period: value, dateRange: '' });
  };

  const showCharts =
    !isLoadingCashflowsRisksData || cashflowsRisksData.length > 0;
  const showLargeLoader = isLoadingCashflowsRisksData && !isLoadingAndHaveData;
  const tabs = getMonthlyBreakdownTabs(currency === entityCurrencyCode);

  return (
    <>
      <Card
        alignSelf="stretch"
        justifyContent="space-between"
        flex={1}
        position="relative"
      >
        <Row alignItems="flex-start">
          <Row justifyContent="flex-start">
            {tabs.map(({ title, id }) => (
              <StepperItem
                onClick={() => setContentType(id)}
                key={id}
                current={id === contentType}
              >
                {title}
              </StepperItem>
            ))}
          </Row>

          <StaleInputSelect
            selected={period}
            data={[
              {
                id: 'month',
                name: 'Monthly',
                value: 'month',
              },
              {
                id: 'quarter',
                name: 'Quarterly',
                value: 'quarter',
              },
            ]}
            onSelect={onSelectPeriod}
            id="risks-data-duration"
          />
        </Row>

        <Row flex={1} alignItems="unset">
          {showLargeLoader && <StaleLoader size="large" />}
          {showCharts && (
            <Charts
              cashflowsRisksData={cashflowsRisksData}
              contentType={contentType}
              activeDate={dateRange}
              onChartPeriodClick={onChartPeriodClick}
            />
          )}
        </Row>

        {isLoadingAndHaveData && (
          <Row
            style={{
              position: 'absolute',
              bottom: 12,
              left: 12,
            }}
          >
            <InlineLoader />
          </Row>
        )}
      </Card>
    </>
  );
};

export default MonthlyBreakdown;
