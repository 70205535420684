import { FC } from 'react';
import { InlineLoader, ButtonStyleLink } from 'components';
import { Flex } from 'components/shared/Flex/Flex.styles';
import { Paragraph } from 'components/shared/Typography/Typography';
import { IInvoice, isXeroInvoice } from 'types';
import { useGetXeroInvoiceAttachments } from './useGetXeroInvoiceAttachments';

interface Props {
  invoice: IInvoice;
}

const Attachments: FC<Props> = ({ invoice }) => {
  const {
    isLoading: isLoadingAttachments,
    attachments,
    onFileClickHandler,
    isLoadingAttachment,
  } = useGetXeroInvoiceAttachments(invoice);

  const onClickHander = (fileName: string, mimeType: string) => {
    if (isXeroInvoice(invoice)) {
      onFileClickHandler({
        fileName,
        mimeType,
        xeroInvoiceId: invoice.externalRefs.xeroId,
      });
    }
  };

  if (!isXeroInvoice(invoice)) {
    return null;
  }

  return (
    <>
      <Paragraph variant="bold">
        <Flex alignItems="center">
          Attachments {isLoadingAttachments && <InlineLoader />}
        </Flex>
      </Paragraph>

      {!isLoadingAttachments &&
        attachments.map((attachment) => (
          <ButtonStyleLink
            key={attachment.fileName}
            onClick={() =>
              onClickHander(attachment.fileName, attachment.mimeType)
            }
          >
            {attachment.fileName}
            {isLoadingAttachment === attachment.fileName && <InlineLoader />}
          </ButtonStyleLink>
        ))}
    </>
  );
};

export default Attachments;
