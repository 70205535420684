import { useEffect, useState } from 'react';
import { getRate } from 'services/firebase';
import { useStoreState } from 'state';
import { checkIfRatesFulfilled } from 'utils';
import { errorHandler } from 'utils/errors';

interface OwnProps<T> {
  currencies: T[];
}

const useRates = <T extends string>({ currencies }: OwnProps<T>) => {
  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);
  const [ratesByCurrency, setRatesByCurrency] = useState<
    Record<T, number | undefined>
  >();
  const [areRatesLoading, setAreRatesLoading] = useState(true);

  useEffect(() => {
    if (currencies.length && !ratesByCurrency && entityCurrencyCode) {
      const getAllRates = async () => {
        try {
          setAreRatesLoading(true);
          const response = await Promise.allSettled(
            currencies.map(async (currencyCode) => {
              const { data } = await getRate({
                sellCurrency: entityCurrencyCode,
                buyCurrency: currencyCode,
              });
              return { currencyCode, rate: data?.rate };
            })
          );

          const fulfilledRatesByCurrency = response.reduce(
            (acc, currencyRateFulfilled) => {
              if (
                checkIfRatesFulfilled(currencyRateFulfilled) &&
                currencyRateFulfilled?.value.currencyCode
              ) {
                acc[currencyRateFulfilled.value.currencyCode] =
                  currencyRateFulfilled.value.rate;
              }

              return acc;
            },
            {} as Record<typeof currencies[number], number | undefined>
          );

          setRatesByCurrency(fulfilledRatesByCurrency);
        } catch (error: any) {
          errorHandler(error);
        } finally {
          setAreRatesLoading(false);
        }
      };

      getAllRates();
    }
  }, [currencies, entityCurrencyCode, ratesByCurrency]);

  return { ratesByCurrency, areRatesLoading };
};

export default useRates;
