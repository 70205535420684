import styled, { css } from 'styled-components';
import {
  StaleHint,
  StaleTitleH5,
  StaleParagraphMedium,
  StaleParagraphBold,
  Paragraph,
  StaleSubTitleBold,
  Subtitle,
  StaleTextHint,
  StaleOverflowScroll,
} from 'components';
import { isMobile } from 'react-device-detect';
import { TabContent } from 'components/shared/StaleTabs/StaleTabs.styles';
import { StaleOverflowContent } from '../StaleOverflow/StaleOverflow';

export const StepsNav = styled.div(
  ({ theme }) => css`
    z-index: 12;
    color: ${theme.color.white};
    min-height: 56px;
    display: flex;
    flex-direction: column;

    .back {
      position: absolute;
      right: 16px;
      top: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      height: 24px;

      svg {
        fill: ${theme.color.white};
      }
    }

    .item {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${theme.color.grey};
      position: relative;
      padding: ${isMobile ? '6px 24px 2px 0' : '0 32px 0 0'};
      min-height: ${!isMobile && '72px'};
      margin-bottom: ${!isMobile && '2px'};

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        font-size: 16px;
      }

      &:last-child {
        padding-right: 0;
      }
      &:not(:last-of-type):after {
        content: '';
      }

      &:after {
        width: 0;
        height: 0;
        border-top: ${isMobile && '4.5px solid transparent'};
        border-left: ${isMobile && `6px solid ${theme.color.grey}`};
        border-bottom: ${isMobile && '4.5px solid transparent'};
        position: absolute;
        top: ${isMobile && '3px'};
        right: ${isMobile ? '8px' : '0'};
        bottom: 1px;
        margin: auto;
      }

      &.passed {
        color: ${isMobile ? theme.color.white : theme.color.dark};
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        &:after {
          border-left-color: ${theme.color.white};
        }

        &.disabled {
          pointer-events: none;
        }
      }
      &.current {
        color: ${theme.color.emeraldDark};

        span {
          border-color: ${theme.color.emeraldDark};
        }
      }
    }

    ${!isMobile
      ? css`
          flex: 0 0 110px;
          padding: 24px 0 10px 16px;

          @media (min-width: ${theme.breakpoint.largeMin}px) {
            padding: 48px 0 10px 48px;
            flex: 0 0 174px;
          }

          .item {
            span {
              position: absolute;
              right: 0;
              top: 0;
              border-right: 2px solid ${theme.color.dark};
              padding-right: 10px;
            }

            &:after {
              width: 1px;
              height: calc(100% - 24px);
              background: ${theme.color.greyLight_1};
            }
          }

          .row {
            flex-direction: column;
          }
        `
      : css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          padding: 6px 16px 10px;
          background: ${theme.color.dark};
          justify-content: center;
        `}
  `
);

interface StepContentProps {
  isInvoice?: boolean;
}

export const StepContent = styled.div<StepContentProps>(
  ({ theme, isInvoice }) => css`
    display: flex;
    flex: 0 1 auto;
    background: ${theme.color.white};

    ${StaleOverflowContent} {
      padding: 0;
      ${!isMobile &&
      css`
        width: 100%;
        display: flex;
        flex-direction: row;
      `}
    }

    & > ${StaleOverflowScroll} {
      & > div:not(.notification):first-child {
        padding-top: 0;
      }
      background: ${theme.color.white};

      ${TabContent} {
        padding-bottom: 0;
      }

      @media (min-width: ${theme.breakpoint.mediumMin}px) {
        flex-direction: row;
        width: 100%;
      }
    }

    .block {
      position: relative;

      & + .block:not(.no-border) {
        border-top: 1px solid ${theme.color.greyLight_2};
      }

      .row {
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;

        ${StaleParagraphMedium}, ${Paragraph} {
          display: flex;
          align-items: center;
        }
        .text${StaleParagraphMedium}, .checkbox ${StaleParagraphMedium} {
          display: inline;
        }

        & + ${StaleHint} {
          @media (min-width: ${theme.breakpoint.largeMin}px) {
            padding: 0;
            min-height: auto;
            color: ${theme.color.grey};
          }
        }

        p${StaleSubTitleBold}, p${Subtitle} {
          margin-bottom: 0;
        }

        ${StaleTextHint} {
          color: ${theme.color.dark};
          margin-top: -6px;
        }
      }

      .red-hint {
        color: ${theme.color.red};
        text-align: right;
        margin-left: auto;
      }

      ${StaleHint} {
        padding: 0;
        min-height: 21px;
        display: inline;
      }

      ${StaleTitleH5},
      ${Subtitle} {
        margin-bottom: 8px;

        & + div > button {
          margin-bottom: 8px;
        }
      }

      .calendar-input {
        display: flex;
        align-items: center;
        margin: 0;

        div button {
          margin: 0 0 0 6px;

          &:focus svg {
            fill: ${theme.color.emeraldDark};
          }
        }
      }

      ${StaleParagraphBold} {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        min-height: 21px;

        button {
          margin: -2px 0 0 4px;
        }
      }

      p:last-child {
        margin-bottom: 0;
      }

      ${isMobile
        ? css`
            padding: 10px 16px 0;

            &:first-child {
              padding-top: 16px;
            }

            @media (min-width: ${theme.breakpoint.mediumMin}px) {
              max-width: 576px;
              width: 100%;
              margin-left: auto;
              margin-right: auto;
            }
          `
        : css`
            &:not(:first-child) {
              padding-top: 10px;
            }

            ${StaleHint} {
              display: flex;
              align-items: center;

              button {
                margin: -2px 0 0 4px;
              }
            }
          `}
    }

    .checkbox {
      background: ${theme.color.greyLight_4};
      border: 1px solid ${theme.color.greyLight_2};
      border-radius: 8px;
      min-height: 44px;
      padding: 32px 8px 7px 8px;
      position: relative;
      flex: 0 0 32%;
      max-width: 32%;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 9px;

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 13px 40px 13px 16px;
        cursor: pointer;

        span {
          margin-right: 6px;
          padding-right: 6px;
          border-right: 1px solid ${theme.color.dark};
        }
      }

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        top: 9px;
        border-radius: ${theme.borderRadius.round};
        background: ${theme.color.emeraldDark};
        border: 2px solid ${theme.color.emeraldDark};
        box-shadow: inset 0 0 0 7px ${theme.color.white};
        transition: box-shadow 0.2s linear;

        @media (max-width: ${theme.breakpoint.largeMax}px) {
          left: 9px;
        }
        @media (min-width: ${theme.breakpoint.largeMin}px) {
          top: 16px;
          right: 20px;
        }
      }

      &.checked {
        &:before {
          box-shadow: inset 0 0 0 2px ${theme.color.white};
        }

        span,
        ${StaleParagraphMedium} {
          font-weight: 700;
        }
      }

      span {
        display: block;

        @media (min-width: ${theme.breakpoint.largeMin}px) {
          display: inline-block;
        }
      }
    }

    .save {
      width: 100%;
      background: ${theme.color.greyLight_4};
      border-radius: 8px;
      min-height: 40px;
      padding: ${isMobile ? '8px 44px 8px 16px' : '16px'};
      color: ${theme.color.grey};
      line-height: 24px;
      position: ${isMobile && 'relative'};
      margin-top: ${isMobile && '16px'};

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        position: ${!isMobile && 'absolute'};
        top: ${!isMobile && 0};
        max-width: ${!isMobile && '220px'};
        right: ${!isMobile && '-238px'};
      }
      @media (min-width: 1120px) {
        max-width: ${!isMobile && '274px'};
        right: ${!isMobile && '-300px'};
      }

      &.active {
        color: ${theme.color.greyDark};
        background: ${theme.color.emeraldLight};

        span {
          color: ${theme.color.dark};
        }

        svg {
          fill: ${theme.color.dark};
        }
      }

      svg {
        position: absolute;
        right: 16px;
        top: 8px;
        width: 24px;
        height: 24px;
        fill: ${theme.color.grey};
      }
    }

    .explained {
      margin-bottom: 40px;
      max-height: 0;
      overflow: hidden;
      transition: ${theme.transition} linear;

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        margin-bottom: 16px;
      }

      &.show {
        max-height: 100vh;
        overflow: visible;
        animation: show 0.3s linear;
      }

      @keyframes show {
        0% {
          overflow: hidden;
        }
        100% {
          overflow: visible;
        }
      }

      &-table {
        display: flex;
        background: ${theme.color.greyLight_4};
        border: 1px solid ${theme.color.greyLight_1};
        color: ${theme.color.greyDark};
        border-radius: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;

        ${StaleParagraphBold},
        ${StaleParagraphMedium} {
          color: ${theme.color.dark};
          font-size: 12px;
        }

        .col {
          min-height: 64px;
          padding: 12px;
          flex: 0 1 100%;
          position: relative;

          &:not(:last-of-type) {
            border-right: 1px solid ${theme.color.greyLight_1};

            &:before {
              content: '';
              position: absolute;
              top: 0;
              right: -8px;
              bottom: 0;
              margin: auto;
              width: 16px;
              height: 16px;
              border-radius: ${theme.borderRadius.round};
              background: ${theme.color.white};
              border: 1px solid ${theme.color.greyLight_1};
              text-align: center;
              line-height: 17px;
              color: ${theme.color.dark};
              font-weight: 500;
              font-size: 17px;
            }
          }

          &:nth-last-of-type(2) {
            padding-left: 15px;
            padding-right: 16px;

            &:before {
              content: '=';
            }
          }

          &:first-of-type {
            &:before {
              content: '+';
            }
          }

          &:last-of-type {
            text-align: right;

            ${StaleParagraphBold} {
              justify-content: flex-end;
            }
          }
        }
      }

      &-info {
        padding: 12px 10px 0 20px;
        margin: 8px 0 0 15px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: -8px;
          left: 0;
          height: 100%;
          width: 1px;
          background: ${theme.color.greyLight_1};
        }

        .item {
          position: relative;

          &:not(:last-of-type) {
            padding-bottom: 15px;
          }

          &:before {
            content: '';
            position: absolute;
            top: 1px;
            left: -27px;
            width: 16px;
            height: 16px;
            border-radius: ${theme.borderRadius.round};
            background: ${theme.color.white};
            border: 1px solid ${theme.color.greyLight_1};
            text-align: center;
            line-height: 17px;
            color: ${theme.color.dark};
            font-weight: 500;
            font-size: 17px;
          }

          &:first-of-type:before {
            content: '+';
          }

          &:last-of-type {
            &:before {
              content: '=';
              z-index: 1;
            }
            &:after {
              content: '';
              position: absolute;
              top: 2px;
              left: -21px;
              height: calc(100% - 5px);
              width: 3px;
              background: ${theme.color.white};
            }
          }
        }

        ${StaleParagraphBold} {
          margin-bottom: 1.5px;
        }

        .row {
          margin-top: 0;
        }
      }

      &-head {
        display: flex;
        padding: 12px 0;
        justify-content: space-between;

        svg {
          transition: ${theme.transition} linear;
        }

        ${StaleParagraphBold} {
          font-weight: ${isMobile && '400'};
          text-decoration: ${isMobile && 'underline'};
          text-underline-offset: 2px;
          svg {
            margin-left: 6px;
          }
        }
      }
    }

    .notification {
      padding: 16px 12px 15px 48px;
      border-radius: 10px;
      position: relative;
      color: ${theme.color.white};
      background: ${theme.color.emeraldDark};

      ${isMobile
        ? css`
            margin: 0 16px 14px;

            @media (min-width: ${theme.breakpoint.mediumMin}px) {
              max-width: 576px;
              width: 100%;
              margin-left: auto;
              margin-right: auto;
            }
          `
        : css`
            margin: 0 0 14px;
          `}

      &:before {
        content: '';
        position: absolute;
        left: 18px;
        top: 20px;
        width: 18px;
        height: 8px;
        border-bottom: 2px solid ${theme.color.white};
        border-left: 2px solid ${theme.color.white};
        transform: rotate(-45deg);
      }

      ${StaleParagraphBold} {
        margin-bottom: 7px;
      }
    }

    .download {
      margin: 12px 0;
      padding: 13px 0;
      display: flex;
      align-items: center;
    }

    .download-link {
      svg {
        margin: 0 8px 0 0;
        fill: ${theme.color.emeraldDark};
      }
    }

    .react-date-picker {
      width: 100vw;
      left: -16px;

      &__calendar {
        left: 0 !important;
        width: 100% !important;
        position: relative !important;
        padding-top: 8px;

        &-button {
          position: absolute;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;

          svg {
            display: none;
          }
        }
      }

      &__clear-button {
        display: none;
      }

      &__inputGroup {
        &__input {
          padding: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.002em;
          color: ${theme.color.dark};
        }

        &__month {
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          width: 28px;
        }
      }

      &__wrapper {
        border: 0;
        position: absolute;
        right: 16px;
        top: -32px;
      }
    }

    .col-step {
      display: flex;
      flex-direction: column;
    }

    ${StaleTitleH5} {
      @media (min-width: ${theme.breakpoint.largeMin}px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .booking-name {
      margin-top: 24px;
      padding-top: 15px;
      border-top: 1px solid ${theme.color.greyLight_2};

      ${StaleTitleH5} {
        @media (min-width: ${theme.breakpoint.largeMin}px) {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 8px;
        }
      }
    }

    ${isMobile
      ? css`
          overflow: auto;
          height: 100%;
          flex-direction: column;

          & > ${StaleOverflowScroll} {
            padding: ${isInvoice ? '56px 0 16px' : '24px 0 16px'};
          }

          .col-step {
            width: 100%;
            overflow: hidden;
          }
        `
      : css`
          flex-direction: row;
          border-radius: 0 0 8px 8px;
          flex: 1 1 auto;

          & > ${StaleOverflowScroll} {
            padding-top: 24px;
            overflow: visible;

            @media (min-width: ${theme.breakpoint.largeMin}px) {
              padding-top: 46px;
            }
          }

          .col-step {
            &:first-child {
              @media (min-width: ${theme.breakpoint.mediumMin}px) {
                flex: 0 1 100%;
                max-width: 500px;
                padding-left: 24px;
              }
              @media (min-width: ${theme.breakpoint.largeMin}px) {
                max-width: 432px;
                padding-left: 40px;
              }
            }

            &:not(:first-child) {
              @media (min-width: ${theme.breakpoint.mediumMin}px) {
                flex: 0 0 45%;
                max-width: 45%;
                padding-right: 16px;
                padding-left: 16px;
              }

              @media (min-width: ${theme.breakpoint.largeMin}px) {
                flex: 0 0 42.4%;
                max-width: 42.4%;
                padding-right: 24px;
                padding-left: 24px;
              }
              @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
                padding-right: 48px;
              }
            }
          }
        `}
  `
);
