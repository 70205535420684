import { TEntityPackageKeys } from 'types';
import { openInNewTab } from 'utils';

interface IGetSubscriptionActionProps {
  key: TEntityPackageKeys;
  link?: string;
  onRequestFreeTrial: (trialPackage: TEntityPackageKeys) => void;
  isAutomationPackageEnabled: boolean;
  isFxManagementPackageEnabled: boolean;
  isAutomationPackageFreeTrialUsed: boolean;
  isFxManagementPackageFreeTrialUsed: boolean;
}

export const getSubscriptionAction = ({
  key,
  link,
  onRequestFreeTrial,
  isAutomationPackageEnabled,
  isFxManagementPackageEnabled,
  isAutomationPackageFreeTrialUsed,
  isFxManagementPackageFreeTrialUsed,
}: IGetSubscriptionActionProps) => {
  if (
    (key === 'automation' && isAutomationPackageEnabled) ||
    (key === 'fxManagement' && isFxManagementPackageEnabled)
  ) {
    return {
      actionText: 'Active',
      isActionDisabled: true,
    };
  }

  if (
    (key === 'automation' && isAutomationPackageFreeTrialUsed) ||
    (key === 'fxManagement' && isFxManagementPackageFreeTrialUsed)
  ) {
    return {
      actionText: 'Subscribe',
      isActionDisabled: false,
      onActionClickHandler: () => openInNewTab(link),
    };
  }

  return {
    actionText: 'Free trial',
    isActionDisabled: false,
    onActionClickHandler: () => onRequestFreeTrial(key),
  };
};
