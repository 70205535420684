import React from 'react';
import { Controller, useForm } from 'react-hook-form7';
import Button from 'components/shared/Button/Button';
import { IInvoice } from 'types';
import { useStoreState } from 'state';
import {
  getCurrencyExchangePageLink,
  getLink,
  parseIntoCurrencyStringWithSymbol,
} from 'utils';
import { getInvoiceRemainingAmount } from 'utils/invoices';
import { useHistory } from 'react-router';
import { StyledForm } from 'components/shared/Form/Form.styles';
import {
  Col,
  Icon,
  InlineLoader,
  Paragraph,
  PermissionsChecker,
  Row,
  StaleInputConvert,
} from 'components';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';
import useInvoiceRecord from 'hooks/useInvoiceRecord';
import { getInvoiceSellAmount } from '../../utils';
import { ControlsWrapper } from './Controls.styles';
import Field from 'components/shared/Field/Field.styles';

type TInputs = {
  amount: number;
};

interface OwnProps {
  invoice: IInvoice;
  rate: number | null;
}

export const Controls: React.FC<OwnProps> = ({ invoice, rate }) => {
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { transferById } = useStoreState((state) => state.TransfersState);
  const { rateContractById } = useStoreState(
    (state) => state.RateContractsState
  );
  const history = useHistory();
  const { handleSubmit, control, watch } = useForm<TInputs>({
    defaultValues: {
      amount: getInvoiceRemainingAmount(invoice),
    },
  });
  const amountToPay = watch('amount');

  const onPay = (isSameCurrencyTransfer: boolean) => (data: TInputs) => {
    const { amount } = data;

    let link = getLink('/app/transfers', {
      step: '1',
      invoiceId: invoice.id,
      predefinedBuyCurrency: invoice.currency,
      predefinedBuyAmount: amount.toString(),
      predefinedRateContractId: invoice.contractId,
    });

    if (isSameCurrencyTransfer) {
      link = getLink('/app/simple-transfer', {
        invoiceId: invoice.id,
        predefinedCurrency: invoice.currency,
        predefinedAmount: amount.toString(),
      });
    }

    history.push(link);
  };

  const {
    isSameCurrency,
    isCanBePaid,
    isPayable,
    submittableForReview,
    isApprovable,
  } = useInvoiceRecord({
    record: invoice,
  });

  const currency = currencyByCode(invoice.currency);
  const entityCurrency = currencyByCode(entityCurrencyCode);
  const invoiceTransferIds = invoice.transferIds ?? [];
  const invoiceTransfers = invoiceTransferIds.map(transferById);
  const prebookedRateContract = rateContractById(invoice.contractId);
  const showYouPayingNow =
    getInvoiceRemainingAmount(invoice) !== Number(amountToPay);

  const {
    isUpdatingInvoicesApprovalStatus: isUpdatingInvoiceApprovalStatus,
    updateInvoicesApprovalStatus,
  } = useInvoicesApprovalStatus();

  const goToCurrencyExchange = async () => {
    const link = getCurrencyExchangePageLink({
      predefinedSellAmount: invoice.amountDue.toString(),
      predefinedSellCurrency: invoice.currency,
      predefinedRateContractId: invoice.contractId,
      step: '2',
      invoiceId: invoice.id,
    });

    history.push(link);
  };

  const renderActionButtons = () => {
    return (
      <>
        {currency && isPayable && isCanBePaid && (
          <StyledForm flexDirection="row" alignItems="center">
            <Field
              flexDirection="column"
              style={{
                maxWidth: 200,
              }}
            >
              <Controller
                control={control}
                name="amount"
                rules={{
                  max: {
                    value: getInvoiceRemainingAmount(invoice),
                    message: 'Amount is too high',
                  },
                  required: true,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <StaleInputConvert
                    id="amount"
                    value={value}
                    onChange={onChange}
                    currencies={[currency]}
                    selectedValue={currency}
                    readOnlyCurrency
                    hideSelectButton
                    error={error?.message}
                  />
                )}
              />
            </Field>
            {!isSameCurrency && (
              <Button
                ml
                onClick={handleSubmit(onPay(false))}
              >{`Pay in ${entityCurrencyCode}`}</Button>
            )}
            <Button
              ml
              variant={isSameCurrency ? 'primary' : 'secondary'}
              onClick={handleSubmit(onPay(true))}
            >{`Pay in ${invoice.currency}`}</Button>
          </StyledForm>
        )}

        {invoice.contractId && !isPayable && (
          <PermissionsChecker action="update" resource="balances">
            <Button mr onClick={goToCurrencyExchange}>
              Collect
            </Button>
          </PermissionsChecker>
        )}
        {submittableForReview && (
          <Button
            mr
            disabled={isUpdatingInvoiceApprovalStatus}
            onClick={() =>
              updateInvoicesApprovalStatus({
                invoiceIds: [invoice.id],
                approvalStatus: 'submitted',
              })
            }
          >
            Submit
          </Button>
        )}

        {isApprovable && (
          <Button
            mr
            disabled={isUpdatingInvoiceApprovalStatus}
            onClick={() =>
              updateInvoicesApprovalStatus({
                invoiceIds: [invoice.id],
                approvalStatus: 'approved',
              })
            }
          >
            Approve
          </Button>
        )}
      </>
    );
  };

  return (
    <ControlsWrapper>
      {renderActionButtons()}

      <Col ml mlValue="auto">
        {!isSameCurrency && (
          <Row justifyContent="flex-end">
            <Paragraph whiteSpace="break-spaces" variant="bold">
              {showYouPayingNow ? `You paying now: ` : `Invoice total: `}
            </Paragraph>
            <Paragraph variant="bold">
              {`${currency?.symbol}${parseIntoCurrencyStringWithSymbol(
                showYouPayingNow ? amountToPay : invoice.totalAmount
              )}`}
            </Paragraph>
            <Icon icon={currency?.countryCode ?? ''} ml />
          </Row>
        )}

        <Row justifyContent="flex-end">
          <Paragraph variant="bold">
            {!showYouPayingNow &&
              rate &&
              getInvoiceSellAmount({
                invoice,
                entityCurrency,
                invoiceRateContract: prebookedRateContract,
                invoiceTransfer: invoiceTransfers[0],
                rate,
              })}
            {showYouPayingNow &&
              rate &&
              parseIntoCurrencyStringWithSymbol(
                amountToPay / rate,
                entityCurrency?.symbol,
                entityCurrency?.precision
              )}
            {!rate && <InlineLoader />}
          </Paragraph>
          <Icon icon={entityCurrency?.countryCode ?? ''} ml />
        </Row>
      </Col>
    </ControlsWrapper>
  );
};

export default Controls;
