import styled from 'styled-components';
import { StyledButton } from 'components/shared/Button/Button.styles';
import { Col } from 'components/shared/Col/Col';
import { Row } from 'components/shared/Row/Row';

export const FullSizeStepsWrapper = styled(Row)`
  flex: 1;
  align-self: stretch;
`;

export const FullSizeContentContainer = styled(Col)`
  flex: 1;
  align-self: stretch;
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.m};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    padding: 10%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.extraLargeMin}px) {
    padding: 12% 15%;
  }
`;

export const FullSizeStepsLeftCol = styled(Col)`
  flex: 1;
  align-self: stretch;
  justify-content: center;
  background: ${({ theme }) => theme.color.dark};
  color: ${({ theme }) => theme.color.white};
`;

export const FullSizeStepsRightCol = styled(Col)`
  flex: 1;
  justify-content: center;
  align-self: stretch;
  overflow: auto;
  background: ${({ theme }) => theme.color.white};
`;

export const FullSizeStepsCrossButton = styled(StyledButton)`
  position: absolute;
  right: 28px;
  top: 28px;
  z-index: 10;

  @media (min-width: ${({ theme }) => theme.breakpoint.extraLargeMin}px) {
    right: 48px;
    top: 48px;
  }
`;
