import { TMonthlyBreakdownContentType } from './types';

export const getMonthlyBreakdownTabs = (isEntityCurrency: boolean) => {
  let tabsToReturn: Array<{
    id: TMonthlyBreakdownContentType;
    title: string;
  }> = [
    {
      id: 'balances',
      title: 'Balances',
    },
    {
      id: 'contributors',
      title: 'Contributors',
    },
  ];

  if (!isEntityCurrency) {
    tabsToReturn = [
      ...tabsToReturn,
      {
        id: 'rates',
        title: 'Rates',
      },
      {
        id: 'hedgeRatio',
        title: 'Hedge Ratios',
      },
    ];
  }

  return tabsToReturn;
};
