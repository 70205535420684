import { AddContact, StaleRecipientList } from 'components';
import { FC, useState } from 'react';
import { updateInvoice } from 'services/firebase/invoices';
import { updateRecipient } from 'services/firebase/recipients';
import { useStoreState } from 'state';
import {
  IContact,
  IInvoiceFromSearch,
  IRecipient,
  PAYMENT_TYPE,
  TRANSFER_TYPE,
} from 'types';
import { Notify } from 'utils';
import LinkContactToSourceContactPopup from 'components/shared/LinkContactToSourceContactPopup/LinkContactToSourceContactPopup';
import Button from 'components/shared/Button/Button';
import {
  isCodatContactIdsNotMatchWithInvoiceFromSearch,
  isXeroContactIdsNotMatchWithInvoiceFromSearch,
} from 'utils/integrations';
import Popup from 'components/shared/Popup/Popup';
import { Title } from 'components/shared/Typography/Typography';
import useGetXeroInvoiceFromSearchContact from 'hooks/useGetXeroInvoiceFromSearchContact';

interface OwnProps {
  onClose: () => void;
  invoice: IInvoiceFromSearch;
  updateInMemoryInvoices?: (invoiceId: string, contact: IContact) => void;
}

// TODO: unify component in Transfer Review
const SelectExistingContact: FC<OwnProps> = ({
  onClose,
  invoice,
  updateInMemoryInvoices,
}) => {
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { recipients } = useStoreState((state) => state.RecipientsState);
  const [recipient, setRecipient] = useState<IRecipient>();
  const [recipientForEdit, setRecipientForEdit] = useState<IRecipient>();
  const [isAddRecipient, setIsAddRecipient] = useState(false);
  const [isUpdatingInvoice, setIsUpdatingInvoice] = useState(false);
  const buyCurrency = currencyByCode(invoice.currency);
  const sellCurrency = currencyByCode(systemVariables?.defaultSellCurrency);
  const [
    openLinkContactToSourceContactPopup,
    setOpenLinkContactToSourceContactPopup,
  ] = useState(false);

  const {
    isLoadingXeroInvoice,
    recipientFromInvoice,
  } = useGetXeroInvoiceFromSearchContact(invoice);

  const { id } = invoice;

  const onAddRecipient = () => {
    setRecipientForEdit(recipientFromInvoice);
    setIsAddRecipient(true);
  };

  const onEditRecipient = (recipient: IContact) => {
    setRecipientForEdit(recipient);
  };

  const onConfirm = async () => {
    if (!recipient) {
      return;
    }

    if (
      isXeroContactIdsNotMatchWithInvoiceFromSearch({ invoice, recipient }) ||
      isCodatContactIdsNotMatchWithInvoiceFromSearch({ invoice, recipient })
    ) {
      setOpenLinkContactToSourceContactPopup(true);
    } else {
      onConfirmContinue();
    }
  };

  const onLinkContactAndConfirm = async () => {
    try {
      if (!recipient) {
        return;
      }
      const recipientData = recipient;

      recipientData.externalRefs = {
        ...recipient.externalRefs,
        ...(isXeroContactIdsNotMatchWithInvoiceFromSearch({
          invoice,
          recipient,
        }) && {
          xeroId: invoice.externalRefsXeroContactId,
        }),
        ...(isCodatContactIdsNotMatchWithInvoiceFromSearch({
          invoice,
          recipient,
        }) && {
          xeroId: invoice.externalRefsCodatContactId,
        }),
      };

      const updateRes = await updateRecipient({
        recipientId: recipient.id,
        recipientData,
      });

      if (!updateRes.success) {
        throw new Error(updateRes?.message);
      }
    } catch (error: any) {
      Notify.info(
        `Unfortunately we weren't able to link the contact for future invoices this time around.`
      );
    }

    await onConfirmContinue();
  };

  const onConfirmContinue = async () => {
    try {
      if (!recipient) {
        return;
      }

      setIsUpdatingInvoice(true);

      const response = await updateInvoice({
        invoiceId: id,
        data: {
          contactId: recipient.id,
        },
        updateForSameContact: true,
      });

      if (response.data.success) {
        updateInMemoryInvoices?.(id, recipient);
      }

      onClose();
    } catch (error: any) {
      Notify.error(`Failed to update invoice. Error: ${error}`);
    } finally {
      setIsUpdatingInvoice(false);
    }
  };

  const getInitialTransferType = () => {
    if (
      recipientForEdit?.paymentType === PAYMENT_TYPE.local ||
      recipientForEdit?.paymentType === PAYMENT_TYPE.iban
    ) {
      return TRANSFER_TYPE.regular;
    }
    return TRANSFER_TYPE.priority;
  };

  const initialCurrency = recipientForEdit
    ? currencyByCode(recipientForEdit.currency)
    : buyCurrency;

  const recipientToUse = recipient ?? recipientFromInvoice;

  return (
    <>
      {(isAddRecipient || !!recipientForEdit) &&
      sellCurrency &&
      initialCurrency ? (
        <AddContact
          // need that because OrderWise contacts have no ID-s, but we need them for initial values anyway
          isForceCreate={isAddRecipient}
          onClose={() => {
            setRecipientForEdit(undefined);
            setIsAddRecipient(false);
          }}
          withSaveAsDraft={false}
          sellCurrency={sellCurrency}
          initialCurrency={initialCurrency}
          recipientForEdit={recipientForEdit || undefined}
          initialTransferType={getInitialTransferType()}
          setRecipient={setRecipient}
          onContinue={() => {
            setIsAddRecipient(false);
            setRecipientForEdit(undefined);
          }}
        />
      ) : (
        <Popup
          width="539px"
          height="760px"
          onClose={onClose}
          HeaderContent={<Title variant="h3">Select existing contact</Title>}
          FooterContent={
            <>
              <Button
                onClick={onClose}
                disabled={isUpdatingInvoice}
                variant="secondary"
              >
                Close
              </Button>
              <Button
                ml
                onClick={onConfirm}
                disabled={isUpdatingInvoice || !recipient}
                isLoading={isUpdatingInvoice}
              >
                Confirm
              </Button>
            </>
          }
        >
          {buyCurrency && sellCurrency && (
            <StaleRecipientList
              buyCurrency={buyCurrency}
              sellCurrency={sellCurrency}
              onAddRecipient={onAddRecipient}
              onEditRecipient={onEditRecipient}
              recipient={recipientToUse}
              recipients={recipients}
              setRecipient={setRecipient}
              checkForDisabledRecipients={false}
              recipientItemType="dropdown"
              disabled={isLoadingXeroInvoice}
            />
          )}
        </Popup>
      )}

      {openLinkContactToSourceContactPopup && (
        <LinkContactToSourceContactPopup
          onClose={() => setOpenLinkContactToSourceContactPopup(false)}
          onAccept={() => {
            setOpenLinkContactToSourceContactPopup(false);
            onLinkContactAndConfirm();
          }}
          onReject={onConfirmContinue}
        />
      )}
    </>
  );
};

export default SelectExistingContact;
