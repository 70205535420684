import { FC } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  StaleContainer,
  StaleParagraphMedium,
  StaleNotification,
  StaleHexagonList,
  StaleOverflowScroll,
} from 'components';

import { MainContent } from './Main.styles';
import { useTheme } from 'styled-components';
import Button from 'components/shared/Button/Button';
import { getDashboardPageLink } from 'utils';

const Main: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <>
      <MainContent className="signup-company">
        <StaleOverflowScroll>
          <StaleContainer>
            <StaleNotification
              title="Let’s register your business"
              cross={false}
              bgColor={theme.gradients.blue}
            >
              {''}
            </StaleNotification>

            <StaleParagraphMedium>
              Before we can provide a full access to our platform we need your
              business and identity details - it should take 15 minutes of your
              time. Please note, you must be an owner or director of the
              business to be able to register.
            </StaleParagraphMedium>

            <StaleParagraphMedium>
              Here’s what you will need to provide:
            </StaleParagraphMedium>

            <StaleHexagonList color="#121213">
              <li>
                <svg>
                  <use xlinkHref="#star-ico" />
                </svg>
                <StaleParagraphMedium>Your business info</StaleParagraphMedium>
                The nature of your business, annual turnover and so on
              </li>
              <li>
                <svg>
                  <use xlinkHref="#star-ico" />
                </svg>
                <StaleParagraphMedium>
                  Your company details
                </StaleParagraphMedium>
                Details about it’s registration, owners contact info, VAT
              </li>
              <li>
                <svg>
                  <use xlinkHref="#star-ico" />
                </svg>
                <StaleParagraphMedium>
                  eID Verification <span>(if required)</span>
                </StaleParagraphMedium>
                Please make sure you have one of your documents with you
              </li>
              <li>
                <svg>
                  <use xlinkHref="#star-ico" />
                </svg>
                <StaleParagraphMedium>
                  Proof of Funds <span>(if required)</span>
                </StaleParagraphMedium>
                Be ready to answer couple of questions
              </li>
            </StaleHexagonList>
          </StaleContainer>
        </StaleOverflowScroll>
      </MainContent>

      <div className="btns-group">
        <Button onClick={() => history.push(`${url}?step=1`)}>
          Let's start
        </Button>

        <Button
          variant="link"
          onClick={() => history.push(getDashboardPageLink())}
        >
          Keep limited access
        </Button>
      </div>
    </>
  );
};

export default Main;
