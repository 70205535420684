import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';

import { useQuery } from 'hooks';
import { StepOne, StepTwo, StepThree, StepFour } from './components';
import { Wrapper } from './SignUp.styles';
import { useStoreState, useStoreActions } from 'state';
import auth from 'services/auth';
import { goToProductionLanding, isEmulated } from 'utils';
import { RECIPIENT_TYPE } from 'types';

const SignUp: FC<RouteComponentProps> = ({ history }) => {
  const [stepOneValues, setStepOneValues] = useState({
    email: '',
    password: '',
    repeatPassword: '',
    isPolicyAccepted: false,
  });
  const [stepThreeValues, setStepThreeValues] = useState({
    firstName: '',
    lastName: '',
    companyCountry: '',
    companyType: RECIPIENT_TYPE.company,
    companyName: '',
    isAccountant: false,
  });
  const { userId, isUserRegistrationDone } = useStoreState(
    (state) => state.UserState
  );
  const { signOut } = useStoreActions((actions) => actions.UserState);
  const URLQuery = useQuery();
  const activeStep = URLQuery.get('step');
  const isEmailVerified = auth.currentUser?.emailVerified;
  const { url } = useRouteMatch();

  // redirect user to step 1 when he directly access different steps
  useEffect(() => {
    if (
      !userId &&
      !Object.values(stepOneValues).filter(Boolean).length &&
      activeStep &&
      parseInt(activeStep) !== 1
    ) {
      history.push('/sign-up?step=1');
    }
  }, [userId, isUserRegistrationDone, activeStep, stepOneValues, history]);

  useEffect(() => {
    if (userId && !isEmailVerified && !isEmulated()) {
      history.replace('/sign-up?step=2');
    }
  }, [userId, isEmailVerified, history]);

  const onContinueHandler = () => {
    if (!activeStep) {
      URLQuery.append('step', '2');
      history.push(`${url}?${URLQuery.toString()}`);
    } else if (parseInt(activeStep) < 4) {
      URLQuery.set('step', (parseInt(activeStep) + 1).toString());
      history.replace(`${url}?${URLQuery.toString()}`);
    }
  };

  const onCloseHandler = async () => {
    await signOut();
    goToProductionLanding();
  };

  const onNavItemClick = (stepNumber?: number) => {
    if (!stepNumber) {
      history.push(url);
      return;
    }

    const stepAsString = stepNumber.toString();
    if (stepAsString !== activeStep) {
      URLQuery.set('step', stepAsString);
      history.push(`${url}?${URLQuery.toString()}`);
    }
  };

  const renderContent = () => {
    switch (parseInt(activeStep ?? '1')) {
      case 2:
        return (
          <StepTwo
            email={stepOneValues.email}
            password={stepOneValues.password}
            onContinueHandler={onContinueHandler}
            onCloseHandler={onCloseHandler}
            onNavItemClick={onNavItemClick}
          />
        );
      case 3:
        return (
          <StepThree
            onContinueHandler={(values) => {
              setStepThreeValues(values);
              onContinueHandler();
            }}
            onNavItemClick={onNavItemClick}
            onCloseHandler={onCloseHandler}
          />
        );
      case 4:
        return (
          <StepFour
            onNavItemClick={onNavItemClick}
            onCloseHandler={onCloseHandler}
            {...stepThreeValues}
          />
        );
      default:
        return (
          <StepOne
            onContinueHandler={onContinueHandler}
            onSaveValues={setStepOneValues}
            savedValues={stepOneValues}
          />
        );
    }
  };

  return <Wrapper>{renderContent()}</Wrapper>;
};

export default SignUp;
