import { DatumValue, PointTooltipProps } from '@nivo/line';
import { Col } from 'components';
import { StyledChartTooltip } from 'components/shared/ChartTooltip/ChartTooltip.styles';
import ChartTooltipAmount from 'components/shared/ChartTooltipAmount/ChartTooltipAmount';
import useUrlValues from 'hooks/useUrlValues';
import { PropsWithChildren } from 'react';
import { useStoreState } from 'state';
import { TCashflowAtRiskNew } from 'types';

const PointTooltip = ({ point }: PropsWithChildren<PointTooltipProps>) => {
  const { currency } = useUrlValues('currency');
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const activeCurrency = currencyByCode(currency);
  const { data } = point;
  const { userData } = data as {
    x: DatumValue;
    xFormatted: string | number;
    y: DatumValue;
    yFormatted: string | number;
    yStacked?: number | undefined;
    userData: TCashflowAtRiskNew;
  };
  const { closingBalance } = userData;

  return (
    <StyledChartTooltip>
      <Col>
        <ChartTooltipAmount
          amount={closingBalance}
          currencySymbol={activeCurrency?.symbol}
          title="Closing balance:"
        />
      </Col>
    </StyledChartTooltip>
  );
};

export default PointTooltip;
