import { ContextMenu, Row } from 'components';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import DateCell from 'components/shared/DateCell/DateCell';
import InvoiceNumberCell from 'components/shared/InvoiceNumberCell/InvoiceNumberCell';
import { Column } from 'react-table';
import { IInvoice, IInvoiceFromSearch } from 'types';
import { getInvoiceRemainingAmount, isReceivableInvoice } from 'utils/invoices';

export const generateInvoiceTableColumns = (
  goToInvoice: (record: IInvoice | IInvoiceFromSearch) => void,
  deepLinkKey: string
): Column<IInvoice | IInvoiceFromSearch>[] => {
  return [
    {
      accessor: 'invoiceNumber',
      Header: 'Invoice',
      disableSortBy: true,
      Cell: ({ row }) => <InvoiceNumberCell record={row.original} />,
      width: 130,
      minWidth: 110,
    },
    {
      accessor: 'date',
      Header: 'Invoice date',
      disableSortBy: true,
      Cell: ({ value }) => <DateCell value={value} />,
      width: 130,
      minWidth: 110,
    },
    {
      accessor: 'dueDate',
      Header: 'Due date',
      disableSortBy: true,
      Cell: ({ value }) => <DateCell value={value} />,
      width: 130,
      minWidth: 110,
    },
    {
      id: 'CCY',
      Header: 'Amount',
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <AmountCell
          amount={getInvoiceRemainingAmount(row.original)}
          currencyCode={row.original.currency}
        />
      ),
      width: 130,
      minWidth: 110,
    },
    {
      id: 'dots',
      Header: () => null,
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <Row className="delete" style={{ justifyContent: 'flex-end', flex: 1 }}>
          <ContextMenu
            list={[
              {
                id: 0,
                title: 'See details',
                onClick: () => goToInvoice(row.original),
              },
              {
                id: 'show-in-xero',
                title: 'Open in Xero',
                onClick: () => {
                  if (isReceivableInvoice(row.original)) {
                    window.open(
                      `https://go.xero.com/organisationlogin/default.aspx?shortcode=${deepLinkKey}&redirecturl=/AccountsReceivable/Edit.aspx?InvoiceID=${row.original.externalRefs.xeroId}`
                    );
                  } else {
                    window.open(
                      `https://go.xero.com/organisationlogin/default.aspx?shortcode=${deepLinkKey}&redirecturl=/AccountsPayable/Edit.aspx?InvoiceID=${row.original.externalRefs.xeroId}`
                    );
                  }
                },
              },
            ]}
          />
        </Row>
      ),
      width: 60,
      minWidth: 70,
    },
  ];
};
