import { FC, useMemo, useCallback, useState, ReactNode, Ref } from 'react';
import Table from '../Table/Table';
import { useStoreState } from 'state';
import {
  isInvoiceDisabled,
  isInvoiceFromSearchRowSelectable,
} from 'utils/invoices';
import InvoicesTableFooterContent from './components/InvoicesTableFooterContent/InvoicesTableFooterContent';
import { generateTableColumns } from './tableColumnsGenerator';
import Popups from './components/Popups/Popups';
import useInvoicePopups from './hooks/useInvoicePopups';
import useUrlValues from 'hooks/useUrlValues';
import {
  IContact,
  IInvoiceFromSearch,
  IPastPerformanceItemInvoice,
} from 'types';
import useFindAccountingIntegration from 'hooks/useFindAccountingIntegration';
import { ExposedUseTableProps, TableProps } from '../Table/types';

type TTablePropsToPick =
  | 'isVirtualized'
  | 'data'
  | 'renderFooterContent'
  | 'selectable'
  | 'autoResetSelectedRows'
  | 'withInfiniteLoading'
  | 'onLoadMoreItems'
  | 'itemsCount'
  | 'loadingThreshold'
  | 'isLoadingMoreItems'
  | 'hasMoreToLoad'
  | 'manualSortBy'
  | 'onSort';

interface OwnProps
  extends Pick<TableProps<IInvoiceFromSearch>, TTablePropsToPick> {
  showFooter?: boolean;
  renderActionCell?: (invoice: IInvoiceFromSearch) => ReactNode;
  pastPerformancePerRecordInvoices?: IPastPerformanceItemInvoice[];
  isLoadingPastPerformancePerRecordInvoices?: boolean;
  updateInMemoryInvoices?: (invoiceId: string, contact: IContact) => void;
  tableRef?: Ref<ExposedUseTableProps<IInvoiceFromSearch>>;
}

const InvoicesTableNew: FC<OwnProps> = ({
  data,
  isVirtualized = true,
  autoResetSelectedRows = false,
  renderFooterContent,
  showFooter,
  selectable,
  renderActionCell,
  pastPerformancePerRecordInvoices,
  isLoadingPastPerformancePerRecordInvoices,
  withInfiniteLoading,
  onLoadMoreItems,
  loadingThreshold,
  isLoadingMoreItems,
  itemsCount,
  hasMoreToLoad,
  updateInMemoryInvoices,
  manualSortBy,
  onSort,
  tableRef,
}) => {
  const invoicePopupsHandlers = useInvoicePopups();
  const { currency, tab } = useUrlValues('currency', 'tab');
  const accountingIntegration = useFindAccountingIntegration();

  const {
    setExistingInvoiceTracking,
    setInvoiceDecide,
    setCancelPrebookInvoice,
    setExistingPrebookInvoice,
    setShowAllDecideFields,
    setContactForEdit,
    setInvoiceForAddContact,
    setShowInvoiceDelete,
    setShowPurchaseOrderPaymentsOnInvoice,
  } = invoicePopupsHandlers;

  const { recipientById } = useStoreState((state) => state.RecipientsState);
  const {
    isAutomationPackageEnabled,
    isFxManagementPackageEnabled,
    entityCurrencyCode,
  } = useStoreState(({ UserState }) => UserState);
  const [selectedInvoices, setSelectedInvoices] = useState<
    IInvoiceFromSearch[]
  >([]);
  const isPaymentRunsEnabled =
    isAutomationPackageEnabled || isFxManagementPackageEnabled;
  const onSelectInvoice = useCallback(
    (_, rows) => {
      const invoicesToSelect: IInvoiceFromSearch[] = rows.map(
        (row: any) => row.original
      );
      setSelectedInvoices(invoicesToSelect);
    },
    [setSelectedInvoices]
  );

  const showFooterInternal = showFooter ?? selectedInvoices.length > 0;

  const {
    selectedInvoicesTotalAmount,
    selectedInvoicesTotalBookingCost,
  } = useMemo(
    () =>
      selectedInvoices.reduce(
        (total, value) => {
          return {
            selectedInvoicesTotalAmount:
              total.selectedInvoicesTotalAmount + value.totalAmount,
            selectedInvoicesTotalBookingCost:
              total.selectedInvoicesTotalBookingCost + (value.prebookFee ?? 0),
          };
        },
        { selectedInvoicesTotalAmount: 0, selectedInvoicesTotalBookingCost: 0 }
      ),
    [selectedInvoices]
  );

  const tableColumns = useMemo(
    () =>
      generateTableColumns({
        setExistingInvoiceTracking,
        setInvoiceDecide,
        setShowAllDecideFields,
        setCancelPrebookInvoice,
        setExistingPrebookInvoice,
        setContactForEdit,
        recipientById,
        setInvoiceForAddContact,
        setShowInvoiceDelete,
        setShowPurchaseOrderPaymentsOnInvoice,
        renderActionCell,
        tab,
        pastPerformancePerRecordInvoices,
        isLoadingPastPerformancePerRecordInvoices,
        accountingIntegration,
        entityCurrencyCode,
      }),
    [
      recipientById,
      renderActionCell,
      setCancelPrebookInvoice,
      setContactForEdit,
      setExistingInvoiceTracking,
      setExistingPrebookInvoice,
      setInvoiceDecide,
      setInvoiceForAddContact,
      setShowAllDecideFields,
      setShowInvoiceDelete,
      setShowPurchaseOrderPaymentsOnInvoice,
      tab,
      pastPerformancePerRecordInvoices,
      isLoadingPastPerformancePerRecordInvoices,
      accountingIntegration,
      entityCurrencyCode,
    ]
  );

  return (
    <>
      <Table<IInvoiceFromSearch>
        ref={tableRef}
        autoResetGlobalFilter={false}
        autoResetSortBy={false}
        autoResetFilters={false}
        autoResetSelectedRows={autoResetSelectedRows}
        data={data}
        sortable
        isRowDisabled={isInvoiceDisabled}
        withSelectAll={isPaymentRunsEnabled}
        selectable={selectable ?? isPaymentRunsEnabled}
        isRowSelectable={({ row: { original: record } }) =>
          isInvoiceFromSearchRowSelectable(record)
        }
        onSelect={onSelectInvoice}
        disabledCheckboxHint={`Invoice is disabled, receivable or has no valid contact`}
        columns={tableColumns}
        renderFooterContent={
          showFooterInternal
            ? renderFooterContent ?? (
                <InvoicesTableFooterContent
                  selectedInvoices={selectedInvoices}
                />
              )
            : null
        }
        isVirtualized={isVirtualized}
        withInfiniteLoading={withInfiniteLoading}
        onLoadMoreItems={onLoadMoreItems}
        loadingThreshold={loadingThreshold}
        isLoadingMoreItems={isLoadingMoreItems}
        itemsCount={itemsCount}
        hasMoreToLoad={hasMoreToLoad}
        manualSortBy={manualSortBy}
        onSort={onSort}
      />
      <Popups
        {...invoicePopupsHandlers}
        selectedInvoices={selectedInvoices}
        invoicesCurrency={currency ?? undefined}
        invoicesTotalAmount={selectedInvoicesTotalAmount}
        invoicesTotalBookingCost={selectedInvoicesTotalBookingCost}
        updateInMemoryInvoices={updateInMemoryInvoices}
      />
    </>
  );
};

export default InvoicesTableNew;
