import { FC, useMemo } from 'react';
import { Col, Subtitle, Paragraph } from 'components';
import { parseIntoCurrencyString } from 'utils';
import { useStoreState } from 'state';
import { IRateContract } from 'types';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import CopyInfo from '../CopyInfo/CopyInfo';

interface IPaymentCredentials {
  selectedRateContract: IRateContract;
}

const PaymentCredentials: FC<IPaymentCredentials> = ({
  selectedRateContract,
}) => {
  const { userEntity } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { fundingAccountByCurrency } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const fundingAccount = useMemo(
    () => fundingAccountByCurrency(selectedRateContract?.sellCurrency),
    [selectedRateContract, fundingAccountByCurrency]
  );
  const sellCurrency = currencyByCode(selectedRateContract?.sellCurrency);

  return (
    <Col mt>
      <Subtitle variant="bold" mb>
        {`Please pay the prepayment of ${
          sellCurrency?.symbol
        } ${parseIntoCurrencyString(
          selectedRateContract?.prepaymentAmount,
          sellCurrency?.precision
        )} to`}
      </Subtitle>

      <RowInfo>
        <Paragraph>Account name</Paragraph>
        <Paragraph variant="bold">The Currency Cloud Limited</Paragraph>
      </RowInfo>
      {selectedRateContract.sellCurrency !== 'GBP' ? (
        <>
          <CopyInfo title="SWIFT / BIC" value={fundingAccount?.bicSwift} />
          <CopyInfo title="IBAN" value={fundingAccount?.iban} />
        </>
      ) : (
        <>
          <CopyInfo
            title="Account Number"
            value={fundingAccount?.accountNumber}
          />
          <CopyInfo title="Sort Code" value={fundingAccount?.sortCode} />
        </>
      )}

      <CopyInfo
        title="Reference"
        value={userEntity.externalRefs.ccShortReference}
      />

      <RowInfo justifyContent="flex-end">
        <Paragraph error>
          Please ensure the reference is
          <br />
          added to your transfer details
        </Paragraph>
      </RowInfo>

      <RowInfo>
        <Paragraph>Bank name</Paragraph>
        <Paragraph>Barclays Bank plc</Paragraph>
      </RowInfo>
      <RowInfo>
        <Paragraph>Bank address</Paragraph>
        <Paragraph>1 Churchill Place, London, E14 546</Paragraph>
      </RowInfo>

      {selectedRateContract?.sellCurrency === 'GBP' && (
        <>
          <RowInfo>
            <Paragraph>SWIFT / BIC</Paragraph>
            <Paragraph>{fundingAccount?.bicSwift}</Paragraph>
          </RowInfo>
          <RowInfo>
            <Paragraph>IBAN</Paragraph>
            <Paragraph>{fundingAccount?.iban}</Paragraph>
          </RowInfo>
        </>
      )}
    </Col>
  );
};

export default PaymentCredentials;
