import dayjs from 'dayjs';
import { FC } from 'react';
import { IPnlDataPerMonth } from 'types/analyses';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ChartTick from 'components/shared/ChartTick/ChartTick';
import ChartLine from 'components/shared/ChartLine/ChartLine';
import ChartNetOutstandingLinesLayer from '../ChartNetOutstandingLinesLayer/ChartNetOutstandingLinesLayer';
import ChartNetOutstandingLegendsLayer from '../ChartNetOutstandingLegendsLayer/ChartNetOutstandingLegendsLayer';

dayjs.extend(customParseFormat);

const VALUE_BUFFER = 1.15;

interface OwnProps {
  data: IPnlDataPerMonth[];
  activeMonth: string;
  onLinePointClick: (month: string) => void;
}

const ChartNetOutstanding: FC<OwnProps> = ({
  data,
  activeMonth,
  onLinePointClick,
}) => {
  const minValue = data.reduce(
    (total, { averageOutstandingBalance, turnover }) =>
      Math.min(total, averageOutstandingBalance, turnover),
    0
  );
  const maxValue = data.reduce(
    (total, { turnover, averageOutstandingBalance }) =>
      Math.max(total, turnover, averageOutstandingBalance),
    0
  );
  const largestAbsoluteValue = Math.floor(
    Math.max(Math.abs(minValue), maxValue)
  );

  return (
    <ChartLine
      data={[
        {
          id: 'turnover',
          data: data.map((value) => ({ x: value.id, y: value.turnover })),
        },
        {
          id: 'averageOutstandingBalance',
          data: data.map((value) => ({
            x: value.id,
            y: value.averageOutstandingBalance,
          })),
        },
      ]}
      margin={{
        top: 20,
        bottom: 70,
        left: `${largestAbsoluteValue}`.length * 6 + 18,
        right: 20,
      }}
      yScale={{
        type: 'linear',
        min: minValue * VALUE_BUFFER,
        max: maxValue * VALUE_BUFFER,
      }}
      lineWidth={0.4}
      axisTop={null}
      axisRight={null}
      enableGridX={false}
      enablePoints={false}
      axisBottom={{
        tickPadding: 16,
        tickSize: 0,
        renderTick: (tick) => <ChartTick {...tick} />,
      }}
      layers={[
        'grid',
        'markers',
        'areas',
        (layerData) => (
          <ChartNetOutstandingLinesLayer
            customLayerProps={layerData}
            activeMonth={activeMonth}
            onClick={onLinePointClick}
          />
        ),
        'slices',
        'axes',
        'points',
        (layerData) => (
          <ChartNetOutstandingLegendsLayer
            chartHeight={layerData.innerHeight}
            chartWidth={layerData.innerWidth}
          />
        ),
      ]}
    />
  );
};

export default ChartNetOutstanding;
