import debounce from 'lodash/debounce';
import { FC, useMemo } from 'react';
import { StaleInput, Row, Paragraph, StaleSwitch } from 'components';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStoreActions, useStoreState } from 'state';
import auth from 'services/auth';
import {
  SettingsContent,
  SettingsField,
  SettingsFooter,
} from 'pages/Settings/Settings.styles';
import { Firebase } from 'services';
import { Notify } from 'utils';
import Button from 'components/shared/Button/Button';
import { usePrevious } from 'hooks';
import { Nullable } from 'types/utilities';
import StaleRadioButtonGroup from 'components/shared/StaleRadioButton/StaleRadioButton';
import { useTheme } from 'styled-components';
import { useUpdateSettingsPermissionsCheck } from 'hooks/useSpecificPermissionsCheck';

interface OwnProps {
  setShowChangePassword: (value: boolean) => void;
}

const UserSettings: FC<OwnProps> = ({ setShowChangePassword }) => {
  const theme = useTheme();
  const { control } = useForm();
  const { user, userEntity } = useStoreState((state) => state.UserState);
  const { getUserPermissionsOnEntity } = useStoreActions(
    ({ UserState }) => UserState
  );
  const {
    entityId,
    hasApprovalFlow,
    isAutomationPackageEnabled,
  } = useStoreState(({ UserState }) => UserState);
  const { photoURL } = auth.currentUser?.providerData[0] ?? {};
  const [isLoading, setIsLoading] = useState(false);

  const requiredNumberOfApprovalsOnUserEntity =
    userEntity.requiredNumberOfApprovals &&
    userEntity.requiredNumberOfApprovals > 0 &&
    userEntity.requiredNumberOfApprovals < 3
      ? userEntity.requiredNumberOfApprovals
      : 1;

  const [requiredNumberOfApprovals, setRequiredNumberOfApprovals] = useState(
    requiredNumberOfApprovalsOnUserEntity
  );
  const requiredMinimumNumberOfApprovals = usePrevious(
    requiredNumberOfApprovals
  );

  const onUpdateRequiredNumberOfApprovers = useMemo(
    () => async (
      requiredNumberOfApprovals: number,
      entityId: Nullable<string>
    ) => {
      try {
        if (!entityId) {
          return;
        }

        if (requiredNumberOfApprovals === requiredMinimumNumberOfApprovals) {
          return;
        }

        const response = await Firebase.setRequiredNumberOfApprovals({
          entityId,
          requiredNumberOfApprovals,
        });
        if (response?.data?.success) {
          Notify.success(
            `Required number of approvals set to ${requiredNumberOfApprovals}`
          );
          setRequiredNumberOfApprovals(requiredNumberOfApprovals);
        } else {
          Notify.error(response?.data?.message ?? '');
        }
      } catch (error: any) {
        Notify.error(error.message);
      }
    },
    [requiredMinimumNumberOfApprovals]
  );

  const debouncedCallback = useMemo(
    () => debounce(onUpdateRequiredNumberOfApprovers, 500),
    [onUpdateRequiredNumberOfApprovers]
  );

  const hasUpdateSettingsPermission = useUpdateSettingsPermissionsCheck();

  const onEnableApprovalFlowForEntity = async (status: boolean) => {
    if (!entityId) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await Firebase.enableApprovalFlowForEntity({
        entityId,
      });

      if (response?.data?.success) {
        /**
         * update permissions, as switching the approval flow can change user permissions.
         *
         * note, we can don't this call in parallel with enableApprovalFlowForEntity,
         * as that call needs to complete first to get the correct permissions.
         */
        await getUserPermissionsOnEntity();
        Notify.success(`Approval flow ${status ? 'disabled' : 'enabled'}`);
      } else {
        Notify.error(response?.data?.message ?? '');
      }
    } catch (error: any) {
      Notify.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form>
        <SettingsContent>
          <SettingsField>
            <StaleInput
              id="email"
              name="email"
              label="Email"
              view="moving"
              type="email"
              defaultValue={user?.email}
              locked={true}
              control={control}
            />
          </SettingsField>
          {!photoURL ? (
            <StaleInput
              id="password"
              name="password"
              label="Password"
              view="moving"
              type="password"
              defaultValue="its-password-variable"
              renderIcon={false}
              locked
              control={control}
            />
          ) : (
            <SettingsField>
              <StaleInput
                id="textWithGoogle"
                name="textWithGoogle"
                view="moving"
                type="text"
                defaultValue="Authenticated using Google credentials"
                locked={true}
                control={control}
              />
            </SettingsField>
          )}

          {isAutomationPackageEnabled && (
            <Row mt justifyContent="flex-start">
              <Paragraph mr variant="bold">
                Approval flow:
              </Paragraph>
              <StaleSwitch
                id="approval-flow"
                isOn={hasApprovalFlow}
                handleToggle={() =>
                  onEnableApprovalFlowForEntity(hasApprovalFlow)
                }
                disabled={isLoading || !hasUpdateSettingsPermission}
              />
            </Row>
          )}

          {hasApprovalFlow && (
            <>
              <Row mt justifyContent="flex-start">
                <Paragraph mr variant="bold">
                  Required number of approvers:
                </Paragraph>
              </Row>
              <Row
                justifyContent="flex-start"
                mt
                mtValue={`-${theme.spacing.m}`}
              >
                <StaleRadioButtonGroup
                  onChange={(item) =>
                    debouncedCallback(Number(item.value), entityId)
                  }
                  disabled={!hasUpdateSettingsPermission}
                  cardTransparent
                  list={[
                    {
                      id: 1,
                      value: 1,
                      name: <Paragraph mr>1 </Paragraph>,
                      checked: requiredNumberOfApprovals === 1,
                    },
                    {
                      id: 2,
                      value: 2,
                      name: <Paragraph>2 </Paragraph>,
                      checked: requiredNumberOfApprovals === 2,
                    },
                  ]}
                />
              </Row>
            </>
          )}
        </SettingsContent>

        {!photoURL && (
          <SettingsFooter>
            <Button
              variant="link"
              type="button"
              onClick={() => setShowChangePassword(true)}
            >
              Change password
            </Button>
          </SettingsFooter>
        )}
      </form>
    </>
  );
};

export default UserSettings;
